<template>
  <div>
    <el-dialog title="TiNPad&Inpillar" :visible.sync="TiNPadInPillarDialogVisible" width="1108px" :before-close="handleClose" :modal="false" @opened="TiNPadInPillarDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{ active: selectTiNPadRule, 'Header-tabs__item': true }" @click="TiNPadInPillarDialogSelect('rule')">{{ $t('messages.toolRules') }}</div>
              <div :class="{ active: selectTiNPadSize, 'Header-tabs__item': true }" @click="TiNPadInPillarDialogSelect('size')">{{ $t('messages.toolSize') }}</div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectTiNPadRule">
            <div class="tool-main__item">
              <el-table class="t-table" :data="cellLayerList" ref="TiNPadTable" height="175px" header-cell-class-name="table_header">
                <el-table-column :label="$t('messages.toolNumber')" prop="layerNumber"></el-table-column>
                <el-table-column :label="$t('messages.toolLayer')" prop="layerName"></el-table-column>
                <el-table-column :label="$t('messages.toolDistance') + '(d:um)'" prop="distance" width="150" align="center">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.distance"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.toolAvoid')" width="100" align="center">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.selected"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table_select">
                <div class="table_select_left">{{ $t('messages.toolAll') }}</div>
                <div class="table_select_right">
                  <button class="table_select__btn" @click="toggleSelection('TiNPadTable', true)">{{ $t('messages.toolSelectAll') }}</button>
                  <button class="table_select__btn" @click="toggleSelection('TiNPadTable', false)">{{ $t('messages.toolSelected') }}</button>
                </div>
              </div>
            </div>
            <div class="tool-main__title">
              <div class="title-font">{{ $t('messages.toolTinpadInpillarPlaceArea') }}</div>
              <div class="title-btn">
                <el-button @click="addTiNPadPlacePoint" type="sure" size="mini">
                  <i class="el-icon-plus" style="color: #fff; font-weight: bold"></i>
                  &nbsp;{{ $t('messages.toolAdd') }}
                </el-button>
              </div>
            </div>
            <div class="tool-main__item">
              <el-table class="t-table" :data="TiNPad.rule.place_polygon" ref="TiNPadPlaceTable" height="175px" header-cell-class-name="table_header">
                <el-table-column type="index" :label="$t('messages.toolNumber')" width="55" align="center"></el-table-column>
                <el-table-column :label="$t('messages.toolCoord')" prop="distance" align="center">
                  <template slot-scope="scope">
                    <div style="display: inline-block; margin-right: 10px">
                      X：
                      <el-input-number v-model="scope.row[0]" :controls="false"></el-input-number>
                      &nbsp;{{ unit }}
                    </div>
                    <div style="display: inline-block">
                      Y：
                      <el-input-number v-model="scope.row[1]" :controls="false"></el-input-number>
                      &nbsp;{{ unit }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.toolOperate')" width="55" align="center">
                  <template slot-scope="scope">
                    <img alt="" @click="deleteTiNPadPlacePoint(scope.$index, scope.row)" class="operateIcon" src="../../../assets/icons/tools/删除.svg" />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="tool-main__title">
              <div class="title-font">{{ $t('messages.toolTinpadInpillarDigArea') }}</div>
              <div class="title-btn">
                <el-button @click="addTiNPadExcavatingData" type="sure" size="mini">
                  <i class="el-icon-plus" style="color: #fff; font-weight: bold"></i>
                  &nbsp;{{ $t('messages.toolAdd') }}
                </el-button>
              </div>
            </div>
            <div class="tool-main__item">
              <div v-for="(item, k) in TiNPad.rule.dig_polygons" :key="k">
                <div class="table_select">
                  <div class="table_select_left">{{ $t('messages.toolArea') }}{{ k + 1 }}</div>
                  <div class="table_select_right">
                    <div @click="addTiNPadExcavatingPoint(k)" class="table_select__btnGroup">
                      <i class="el-icon-circle-plus" style="color: #0c7de6; font-size: 14px"></i>
                      &nbsp;{{ $t('messages.toolAdd') }}
                    </div>
                  </div>
                </div>
                <el-table class="t-table" :data="item" height="175px" header-cell-class-name="table_header">
                  <el-table-column type="index" :label="$t('messages.toolNumber')" width="55" align="center"></el-table-column>
                  <el-table-column :label="$t('messages.toolCoord')" prop="distance" align="center">
                    <template slot-scope="scope">
                      <div style="display: inline-block; margin-right: 25px">
                        X：
                        <el-input-number v-model="scope.row[0]" :controls="false" @change="TiNPadInPillarSizeUpdate"></el-input-number>
                        &nbsp;{{ unit }}
                      </div>
                      <div style="display: inline-block">
                        Y：
                        <el-input-number v-model="scope.row[1]" :controls="false"></el-input-number>
                        &nbsp;{{ unit }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('messages.toolOperate')" width="55" align="center">
                    <template slot-scope="scope">
                      <img alt="" @click="deleteTiNPadExcavatingPoint(scope.$index, k)" class="operateIcon" src="../../../assets/icons/tools/删除.svg" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="tool-main__title">
              <div class="title-font">a ({{ $t('messages.toolTinpadInpillarStep') }})</div>
              <div class="title-btn">
                <el-input-number v-model="TiNPad.rule.step" :controls="false" :min="1" :max="10000"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="tool-main__title">
              <div class="title-font">b ({{ $t('messages.toolTinpadInpillarCenterMinDistance') }})</div>
              <div class="title-btn">
                <el-input-number v-model="TiNPad.rule.min_distance" :controls="false" :min="1" :max="10000"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
          </div>
          <div class="normal-rule" v-if="selectTiNPadSize">
            <div class="rule-item__title">TINPad</div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolShape') }}：</div>
              <div class="rule-item__radio">
                <el-radio v-model="TiNPad.size.tin_shape" label="Rectangle" @change="TiNPadInPillarSizeUpdate">
                  {{ $t('messages.toolSquare') }}
                </el-radio>
                <el-radio v-model="TiNPad.size.tin_shape" label="Round" @change="TiNPadInPillarSizeUpdate">
                  {{ $t('messages.toolCircle') }}
                </el-radio>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="TiNPad.size.A" @change="TiNPadInPillarSizeUpdate" :min="20" :max="80"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>

            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolTinpadInpillarLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="TiNPad.size.tin_layer" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="TiNPadInPillarSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolTinpadInpillarFlipLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="TiNPad.size.tin_flip_layer" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="TiNPadInPillarSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>

            <div v-if="TiNPad.size.tin_shape === 'Round'" class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolPrecision') }}：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="TiNPad.size.tin_tolerance" @change="TiNPadInPillarSizeUpdate" :min="0.01"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="rule-item__title" style="border-top: 1px solid #dddddd">Inpillar</div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolShape') }}：</div>
              <div class="rule-item__radio">
                <el-radio v-model="TiNPad.size.indium_shape" label="Rectangle" @change="TiNPadInPillarSizeUpdate">
                  {{ $t('messages.toolSquare') }}
                </el-radio>
                <el-radio v-model="TiNPad.size.indium_shape" label="Round" @change="TiNPadInPillarSizeUpdate">
                  {{ $t('messages.toolCircle') }}
                </el-radio>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A1：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="TiNPad.size.A1" @change="TiNPadInPillarSizeUpdate" :min="10" :max="60"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolTinpadInpillarLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="TiNPad.size.indium_layer" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="TiNPadInPillarSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolTinpadInpillarFlipLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="TiNPad.size.indium_flip_layer" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="TiNPadInPillarSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>

            <!-- <div class="normal-rule-item">
              <div class="rule-item__label">图层：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="TiNPad.size.inpillar.layer"
                  @change="TiNPadInPillarSizeUpdate"></el-input-number>
              </div>
            </div> -->
            <div v-if="TiNPad.size.indium_shape === 'Round'" class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolPrecision') }}：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="TiNPad.size.indium_tolerance" @change="TiNPadInPillarSizeUpdate" :min="0.01"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img alt="" v-if="selectTiNPadRule" class="ann-image" src="../../../assets/icons/tools/TiNPadInpillar-规则.svg" />
            <img
              alt=""
              v-if="selectTiNPadSize && TiNPad.size.tin_shape == 'Rectangle' && TiNPad.size.indium_shape == 'Rectangle'"
              class="ann-image"
              src="../../../assets/icons/tools/TiNPad-方形-Inpillar-方形.svg"
            />
            <img
              alt=""
              v-if="selectTiNPadSize && TiNPad.size.tin_shape == 'Rectangle' && TiNPad.size.indium_shape == 'Round'"
              class="ann-image"
              src="../../../assets/icons/tools/TiNPad-方形-Inpillar-圆形.svg"
            />
            <img
              alt=""
              v-if="selectTiNPadSize && TiNPad.size.tin_shape == 'Round' && TiNPad.size.indium_shape == 'Rectangle'"
              class="ann-image"
              src="../../../assets/icons/tools/TiNPad-圆形-Inpillar-方形.svg"
            />
            <img
              alt=""
              v-if="selectTiNPadSize && TiNPad.size.tin_shape == 'Round' && TiNPad.size.indium_shape == 'Round'"
              class="ann-image"
              src="../../../assets/icons/tools/TiNPad-圆形-Inpillar-圆形.svg"
            />
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <canvas class="ann-image" id="TiNPadInPillarCtx" width="270" height="270"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="TiNPadInPillarDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="TiNPadInPillarDialogVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
      <canvas id="fill" :height="100" :width="100" style="display: none"></canvas>
    </el-dialog>
  </div>
</template>
<script>
import bus from '../../common/bus'
import i18n from '../../../common/lang/i18n'
const ToolComponents = require('../../../utils/tools/components')
const AutoToolTiNPadInpillar = require('../../../utils/tools/tinpad_inpillar')
const AutoToolCanvasComponent = require('../../../utils/tools/canvas_component')

export default {
  data() {
    return {
      unit: 'um',
      tool_ti: new AutoToolTiNPadInpillar(),
      tc: new ToolComponents(),
      TiNPadInPillarDialogVisible: false,
      selectTiNPadRule: true,
      selectTiNPadSize: false,
      cellLayerList: [],
      TiNPad: {
        rule: {
          place_polygon: [
            [0, 0],
            [5000, 0],
            [5000, 5000],
            [0, 5000],
            [0, 0],
          ],
          dig_polygons: [[]],
          step: 20,
          min_distance: 80,
        },
        size: {
          A: 26,
          A1: 16,
          indium_shape: 'Round',
          indium_tolerance: 0.15,
          indium_layer: 7,
          indium_datatype: 0,
          indium_flip_layer: 8,
          indium_flip_datatype: 0,
          tin_shape: 'Round',
          tin_tolerance: 0.15,
          tin_layer: 9,
          tin_datatype: 0,
          tin_flip_layer: 10,
          tin_flip_datatype: 0,
        },
      },

      cell_layer: [],
      all_layer: [],
      canvas2D: null,
      bus_type: '',
    }
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent()
    }, 1000)
  },
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = []
      }
      done()
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach(item => {
          item.selected = true
        })
      } else {
        this.$refs[table_name].data.forEach(item => {
          item.selected = !item.selected
        })
      }
    },
    addTiNPadPlacePoint() {
      //添加TiNPad放置区域
      let params = [0, 0]
      this.TiNPad.rule.place_polygon.push(params)
    },
    deleteTiNPadPlacePoint(index, row) {
      //删除TiNPad放置区域
      this.TiNPad.rule.place_polygon.splice(index, 1)
    },
    addTiNPadExcavatingData() {
      //添加TiNPad挖取区域
      this.TiNPad.rule.dig_polygons.push([])
    },
    addTiNPadExcavatingPoint(k) {
      const params = [0, 0]
      this.TiNPad.rule.dig_polygons[k].push(params)
    },
    deleteTiNPadExcavatingPoint(index, k) {
      //删除TiNPad挖取区域
      this.TiNPad.rule.dig_polygons[k].splice(index, 1)
    },
    TiNPadInPillarDialogOpened() {
      // this.TiNPadSetDefault()
      this.TiNPadInPillarSizeUpdate()
      this.TiNPad.size.indium_layer = this.fileLayerList[0].layerNumber
      this.TiNPad.size.indium_flip_layer = this.fileLayerList[0].layerNumber
      this.TiNPad.size.tin_layer = this.fileLayerList[0].layerNumber
      this.TiNPad.size.tin_flip_layer = this.fileLayerList[0].layerNumber
    },
    TiNPadInPillarDialogSelect(type) {
      if (type == 'rule') {
        this.selectTiNPadRule = true
        this.selectTiNPadSize = false
      }
      if (type == 'size') {
        this.selectTiNPadRule = false
        this.selectTiNPadSize = true
      }
      this.TiNPadInPillarSizeUpdate()
    },
    TiNPadInPillarSizeUpdate() {
      let size = this.TiNPad.size
      let element = null
      element = this.tool_ti.TiNIndium(size)
      if (element) {
        let lib = new QGdstk.Library(element.name, 1e-6, 1e-9)
        lib.add(element)
        this.canvas2D.render(document.getElementById('TiNPadInPillarCtx'), lib, element.name)
      }
    },
    TiNPadInPillarDialogOK() {
      let layer_data = []
      this.cellLayerList.forEach(item => {
        if (item.selected) {
          layer_data.push({
            layer: item.layerNumber,
            distance: item.distance,
            datatype: 0,
          })
        }
      })
      let conf = {
        size: this.TiNPad.size,
        place_polygon: this.TiNPad.rule.place_polygon,
        dig_polygons: this.TiNPad.rule.dig_polygons,
        grid_step: this.TiNPad.rule.step,
        min_distance: this.TiNPad.rule.min_distance,
        avoid: layer_data,
        fileLayerList: this.fileLayerList,
      }
      this.TiNPadInPillarDialogVisible = false
      if (this.bus_type == 'TOOL_TINPAD_INPILLAR') {
        this.$message({
          message: i18n.t('messages.toolTipTinpadPlaceWait'),
          type: 'warning',
        })
        setTimeout(() => {
          bus.$emit('TOOL_TINPAD_INPILLAR_RUN', conf)
        }, 500)
      }
      if (this.bus_type == 'TOOL_TINPAD_INPILLAR_BIG') {
        bus.$emit('TOOL_TINPAD_INPILLAR_BIG_RUN', conf)
      }
    },
    TiNPadSetDefault() {
      this.TiNPad = JSON.parse(JSON.stringify(TiNPadDefault))
    },
    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList
      this.cellLayerList = []
      cellLayerList.forEach(item => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            layer: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          })
        }
      })

      let fileLayerList = this.$store.state.fileLayerList
      this.fileLayerList = []
      fileLayerList.forEach(item => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
            datatype: 0,
          })
        }
      })

      this.cellLayerList = this.cellLayerList.sort((a, b) => a.layerNumber - b.layerNumber)
      this.fileLayerList = this.fileLayerList.sort((a, b) => a.layerNumber - b.layerNumber)
    },
  },
  mounted() {
    bus.$on('TOOL_TINPAD_INPILLAR', data => {
      bus.$emit('updateCellLayerList', null)
      this.cellLayerInit()
      this.TiNPadInPillarDialogVisible = true
      this.bus_type = 'TOOL_TINPAD_INPILLAR'
    })
    bus.$on('TOOL_TINPAD_INPILLAR_BIG', data => {
      this.cellLayerInit()
      this.TiNPadInPillarDialogVisible = true
      this.bus_type = 'TOOL_TINPAD_INPILLAR_BIG'
    })
  },
  watch: {},
}
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
        /deep/.el-input__icon {
          line-height: 28px;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
