<template>
  <div class="generatePDKDialog">
    <el-dialog :title="$t('messages.generatePDK')" :visible.sync="generatePDKDialogVisible" :modal="false"
      :close-on-click-modal="false" :before-close="generatePDKDialogClose" @opened="dialogOpened">
      <div class="top-item">
        <el-checkbox v-model="layerFileChecked"
          :disabled="Object.keys(currentNode).length==0 || (currentNode && currentNode.data.cell_type==3)"></el-checkbox>
        <div class="top-item-text">{{$t('messages.layerFile')}}<span style="color:#FF0000;"
            v-show="Object.keys(currentNode).length==0">({{$t('messages.layerFileTips')}})</span></div>
      </div>
      <div class="top-item">
        <el-checkbox v-model="designFileChecked"></el-checkbox>
        <div class="top-item-text">
          {{$t('messages.designFile')}}
        </div>
        <div class="library-select" v-show="designFileChecked">
          <div class="group-select">
            <el-select style="width: 176px" class="componentSelect" size="mini" v-model="export_drc_id">
              <el-option v-for="item in drcFileOptions" :key="item.label" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="top-item" v-show="false">
        <el-checkbox v-model="libraryFileChecked"></el-checkbox>
        <div class="top-item-text">{{$t('messages.libraryFile')}}</div>
        <div class="library-select" v-show="libraryFileChecked">
          <div class="top-radio">
            <el-radio v-model="selectLibrary" label="1">{{$t('messages.g_personalLibrary')}}</el-radio>
            <div v-show="selectLibrary=='1'">
              <div class="group-select">
                <el-select style="width: 176px" class="componentSelect" size="mini" v-model="export_library_id">
                  <el-option v-for="item in libraryOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="group-select">
                <el-select style="width: 176px" class="componentSelect" size="mini" v-model="export_symbol_id">
                  <el-option v-for="item in symbolOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="top-radio">
            <el-radio v-model="selectLibrary" label="2">{{$t('messages.g_teamLibrary')}}</el-radio>
            <div v-show="selectLibrary=='2'">
              <div class="group-select">
                <el-select style="width: 176px" class="componentSelect" size="mini" v-model="export_team_id">
                  <el-option v-for="item in teamOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="top-radio">
            <el-radio v-model="selectLibrary" label="3">{{$t('messages.g_PDKLibrary')}}</el-radio>
          </div>
          <div class="top-radio">
            <el-radio v-model="selectLibrary" label="4">{{$t('messages.g_publicLibrary')}}</el-radio>
          </div>
          <div class="top-radio">
            <el-radio v-model="selectLibrary" label="5">{{$t('messages.g_officialLibrary')}}</el-radio>
          </div>

        </div>
      </div>
      <!-- <div class="top-item">
        <el-checkbox v-model="fileOf3DChecked"></el-checkbox>
        <div class="top-item-text">{{$t('messages.fileOf3D')}}</div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="generatePDKDialogSureBtn" size="mini" class="deactivate"
          ref="generatePDKDialogBtn" disabled>{{$t("messages.sure")}}</el-button>
        <el-button @click="generatePDKDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Md5 from "js-md5";
import { saveAs } from "file-saver";
import bus from "@/components/common/bus";
import {
  getLibraryList,
  getSymbolList,
  getTeamList,
  getDrcList,
  batchExportLibrary,
} from "@/api/api.js";
import { getPDKInfo_api } from "@/api/objectStorage/objectStorage.js";
import {
  getObjectDataFn,
  downloadFileData,
} from "@/components/homes/fileList/function/objectStorageFn";
import { downloadZip } from "@/utils/utils";
export default {
  data() {
    return {
      generatePDKDialogVisible: false, //显示隐藏
      layerFileChecked: false,
      designFileChecked: false,
      libraryFileChecked: false,
      fileOf3DChecked: false,
      currentNode: false,
      selectLibrary: "1",
      export_library_id: "",
      export_symbol_id: "",
      export_team_id: "",
      export_drc_id: "",
      libraryOptions: [],
      symbolOptions: [],
      teamOptions: [],
      drcFileOptions: [],
    };
  },
  methods: {
    //PDK关闭按钮
    generatePDKDialogClose() {
      this.resetPDKDialog();
    },
    //PDK取消按钮
    generatePDKDialogCancelButton() {
      this.resetPDKDialog();
    },
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    //激活判断
    PDKDialogBtnActive() {
      if (this.generatePDKDialogVisible) {
        if (
          this.layerFileChecked ||
          this.designFileChecked ||
          this.libraryFileChecked ||
          this.fileOf3DChecked
        ) {
          if (
            this.libraryFileChecked &&
            this.selectLibrary == "1" &&
            this.export_library_id &&
            this.export_symbol_id
          ) {
            this.activateBtn(this.$refs.generatePDKDialogBtn);
          } else if (
            this.libraryFileChecked &&
            this.selectLibrary == "2" &&
            this.export_team_id
          ) {
            this.activateBtn(this.$refs.generatePDKDialogBtn);
          } else if (this.designFileChecked && this.export_drc_id) {
            this.activateBtn(this.$refs.generatePDKDialogBtn);
          } else if (this.layerFileChecked || this.fileOf3DChecked) {
            this.activateBtn(this.$refs.generatePDKDialogBtn);
          } else {
            this.disableBtn(this.$refs.generatePDKDialogBtn);
          }
        } else {
          this.disableBtn(this.$refs.generatePDKDialogBtn);
        }
      }
    },
    //重置表单
    resetPDKDialog() {
      this.generatePDKDialogVisible = false;
      this.layerFileChecked = false;
      this.designFileChecked = false;
      this.libraryFileChecked = false;
      this.fileOf3DChecked = false;
      this.selectLibrary = "1";
      this.export_library_id = "";
      this.export_symbol_id = "";
      this.export_drc_id = "";
      this.export_team_id = "";
    },
    async getObjectData(fileUrl) {
      let apiKey = localStorage.getItem("apiKey");
      let minio_name = localStorage.getItem("minio_name");
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      let file_content = await fetch("/file/api/v1/get_object_data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          username: minio_name,
          sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
          time,
          bucket_name,
          object_path: fileUrl,
        }),
      });
      let array_buffer = await file_content.arrayBuffer();
      let u8 = new Uint8Array(array_buffer);
      let enc = new TextDecoder("utf-8");
      return JSON.parse(enc.decode(u8));
    },
    async getPersonalLibraryOptions() {
      let res = await getLibraryList();
      if (res.code == 200000) {
        this.libraryOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
    },
    async getPersonalSymbolOptions() {
      let res = await getSymbolList();
      if (res.code == 200000) {
        this.symbolOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
    },
    async getTeamOptions() {
      let params = {
        start: 0,
        rows: 200,
        key_word: "",
      };
      let res = await getTeamList(params);
      if (res.code == 200000) {
        this.teamOptions = res.data.data.map((item) => {
          const ob = { label: item.team_name, value: item.snow_team_id };
          return Object.assign(item, ob);
        });
      }
    },
    async getDRCOptions() {
      this.drcFileOptions = [];
      let res = await getDrcList();
      if (res.code == 200000) {
        // for (let i in res.data) {
        //   if (res.data[i].compile_status == 3) {
        //     const ob = { label: res.data[i].name, value: res.data[i].url }
        //     this.drcFileOptions.push(ob)
        //   }
        // }
        this.drcFileOptions = res.data.map((item) => {
          const ob = { label: item.name, value: item.url };
          return Object.assign(item, ob);
        });
      }
    },
    dialogOpened() {
      this.currentNode = this.$store.state.activeNode;

      this.getPersonalLibraryOptions();
      this.getPersonalSymbolOptions();
      this.getTeamOptions();
      this.getDRCOptions();
    },
    async generatePDKDialogSureBtn() {
      if (this.layerFileChecked) {
        // let project_data = this.currentNode.parent.parent.data;
        // let res = await getPDKInfo_api({project_snow_id: project_data.snow_id})
        // if (res.code == 200000) {
        //   let drf_file_url = res.data.url_data.drf;
        //   let tf_file_url = res.data.url_data.tf;
        //   let drf_data_u8 = await getObjectDataFn(drf_file_url);
        //   let tf_data_u8 = await getObjectDataFn(tf_file_url);
        //   downloadFileData(drf_data_u8, 'drf', 'json');
        //   downloadFileData(tf_data_u8, 'tf', 'json');
        // }
        bus.$emit("exportLayers", true);
      }
      if (this.designFileChecked) {
        let res = await this.getObjectData(this.export_drc_id);
        let code = res.drc;
        const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "drc.py");
      } else if (this.libraryFileChecked) {
        let params = {
          library_type: this.selectLibrary,
          component_library_snow_id:
            this.selectLibrary == "1" ? this.export_library_id : "",
          symbol_library_snow_id:
            this.selectLibrary == "1" ? this.export_library_id : "",
          team_id: this.selectLibrary == "2" ? this.export_team_id : "",
        };
        let res = await batchExportLibrary(params);
        if (res.code == 200000) {
          let time = parseInt(new Date().getTime() / 1000);
          let params = {
            time: time,
            batch_path_data: res.data.data,
          };
          downloadZip(params);
        }
      }
      this.resetPDKDialog();
    },
  },
  created() {},
  mounted() {
    bus.$on("generatePDK", (data) => {
      this.generatePDKDialogVisible = true;
    });
  },
  watch: {
    layerFileChecked(val) {
      this.PDKDialogBtnActive();
    },
    designFileChecked(val) {
      this.PDKDialogBtnActive();
    },
    libraryFileChecked(val) {
      this.PDKDialogBtnActive();
    },
    fileOf3DChecked(val) {
      this.PDKDialogBtnActive();
    },
    selectLibrary(val) {
      this.PDKDialogBtnActive();
    },
    export_library_id(val) {
      this.PDKDialogBtnActive();
    },
    export_symbol_id(val) {
      this.PDKDialogBtnActive();
    },
    export_team_id(val) {
      this.PDKDialogBtnActive();
    },
    export_drc_id(val) {
      this.PDKDialogBtnActive();
    },
  },
};
</script>
<style lang="less" scoped>
.generatePDKDialog {
  /deep/.el-dialog {
    width: 576px;
    // min-height: 376px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .top-item {
    margin: 10px 0;
    .top-item-text {
      display: inline-block;
      margin-left: 10px;
    }
    .iconfont {
      color: #e15423;
      font-size: 15px;
      cursor: pointer;
      vertical-align: middle;
    }
    .library-select {
      margin-left: 20px;
      .group-select {
        display: inline-block;
        margin-top: 10px;
        margin-left: 20px;
      }
    }
    .top-radio {
      margin: 10px 0;
    }
  }
}
</style>