<template>
  <div>
    <!-- 显示设置 -->
    <div class="schematicBoardOptionDialog">
      <el-dialog :title="$t('messages.BoardDesign')" :visible.sync="boardOptionDialogVisible" :modal="false"
        :close-on-click-modal="false">
        <!-- 背景颜色 -->
        <div class="inputArea">
          <span>{{$t('messages.backgroundColor')}}:</span>
          <div class="fillColor__btn">
            <el-color-picker v-model="value_backgroundColor" @change="backgroundColorChange"></el-color-picker>
          </div>
        </div>
        <!-- 格点颜色 -->
        <div class="inputArea">
          <span>{{$t('messages.gridColor')}}:</span>
          <div class="fillColor__btn">
            <el-color-picker v-model="value_gridColor" @change="gridColorChange"></el-color-picker>
          </div>
        </div>
        <!-- 网格可见 -->
        <div class="inputArea">
          <span style="padding-right:90px">{{$t('messages.isGridVisible')}}:</span>
          <div class="radioArea">
            <el-radio v-model="gridVisible" label="1" style="padding-right:50px">{{$t('messages.yes')}}</el-radio>
            <el-radio v-model="gridVisible" label="2">{{$t('messages.no')}}</el-radio>
          </div>
        </div>
        <!-- 网格大小 -->
        <div class="inputArea">
          <span>{{$t('messages.gridSize')}}:</span>
          <el-input v-model="gridSize" @focus="getPrecisionOld" @blur="getPrecision"><i slot="suffix">mm</i></el-input>
        </div>
        <!-- 网格吸附精度 -->
        <div class="inputArea">
          <span>{{$t('messages.gridAdsorptionAccuracy')}}:</span>
          <el-select popper-class="optionClass" v-model="adsorption" :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in adsorptionAccuracyArr" :key="item.value" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="boardOptionDialogSureBtn" type="primary" @click="boardOptionDialogSureBtn"
            size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="boardOptionDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 检查设置 -->
    <div class="checkOptionDialog">
      <el-dialog :title="$t('messages.CheckSettings')" :visible.sync="checkOptionDialogVisible" :modal="false"
        :close-on-click-modal="false">
        <el-table :data="tableData" @current-change="handleCurrentChange" :span-method="objectSpanMethod" border
          :highlight-current-row='false'>
          <el-table-column type="index" :label="$t('messages.orderNumber')" width="60">
          </el-table-column>
          <el-table-column prop="type" :label="$t('messages.type')" width="90">
          </el-table-column>
          <el-table-column prop="content" :label="$t('messages.checkContent')">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.content }}</span>
              <el-tooltip class="item" effect="light" placement="right" v-if="scope.row.id=='symbol_2'">
                <div slot="content">
                  <div v-for="(item,index) in scope.row.children" :key="index">{{item.content}}<br></div>
                </div>
                <div class="checkOptionTips">?</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="level" :label="$t('messages.checkLevel')" width="130">
            <template slot-scope="scope">
              <el-select popper-class="optionClass" v-model="scope.row.level" @change="changeLevel(scope.row)">
                <el-option v-for="item in checkLevel" :key="item.value" :value="item.label">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkOptionDialogSureBtn" size="mini">{{$t("messages.sure")}}</el-button>
          <el-button @click="checkOptionDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import {
  get_user_config_api, //NOSONAR
  set_user_config_api,
} from "@/api/setting/setting.js";
export default {
  data() {
    return {
      boardOptionDialogVisible: false,
      value_backgroundColor: "#ffffff",
      value_gridColor: "#000000",
      gridVisible: "1",
      adsorption: "1mm",
      gridSize: 1,
      adsorptionAccuracyOld: null,
      adsorptionAccuracyArr: [{ value: "1mm" }, { value: "10mm" }],
      //检查设置
      checkOptionDialogVisible: false,
      tableData: [],
      checkLevel: [
        {
          value: "ignore",
          label: this.$t("messages.ignore"),
        },
        {
          value: "wrong",
          label: this.$t("messages.wrong"),
        },
        {
          value: "warning",
          label: this.$t("messages.Warning"),
        },
      ],
      netCheckIndex: 0,
      symbolCheckIndex: 0,
      defaultTableData: [
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet1"),
          level: this.$t("messages.Warning"),
          id: "net_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet2"),
          level: this.$t("messages.Warning"),
          id: "net_2",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet3"),
          level: this.$t("messages.Warning"),
          id: "net_3",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: this.$t("messages.CheckSet4"),
          level: this.$t("messages.Warning"),
          id: "symbol_1",
          selector: 3,
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: this.$t("messages.CheckSet5"),
          level: this.$t("messages.Warning"),
          id: "symbol_2",
          selector: 3,
          children: [
            {
              content: this.$t("messages.CheckSet6"),
            },
            {
              content: this.$t("messages.CheckSet7"),
            },
            {
              content: this.$t("messages.CheckSet8"),
            },
            {
              content: this.$t("messages.CheckSet9"),
            },
            {
              content: this.$t("messages.CheckSet10"),
            },
            {
              content: this.$t("messages.CheckSet11"),
            },
            {
              content: this.$t("messages.CheckSet12"),
            },
            {
              content: this.$t("messages.CheckSet13"),
            },
            {
              content: this.$t("messages.CheckSet14"),
            },
            {
              content: this.$t("messages.CheckSet15"),
            },
            {
              content: this.$t("messages.CheckSet16"),
            },
          ],
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      const key = e.key.toLowerCase();
      if (
        key == "z" &&
        e.shiftKey === false &&
        e.ctrlKey === true &&
        e.altKey === false
      ) {
        e.preventDefault();
      }
    });
    bus.$on("schematicBoardOption", (data) => {
      //获取当前项目,获取id,得到显示设置
      let projectId = this.$store.state.proInfo.projectId;
      this.$store.commit("getShemaBoardSettings", projectId);
      let boardSettings = this.$store.state.currentSchemaBoardSettings;

      this.value_backgroundColor = boardSettings.value_backgroundColor;
      this.value_gridColor = boardSettings.value_gridColor;
      this.gridVisible = boardSettings.gridVisible;
      this.adsorption = boardSettings.adsorption + "mm";

      this.gridSize = boardSettings.gridSize;
      this.boardOptionDialogVisible = true;
      this.disableBoardOption();
    });
    bus.$on("openCheckOption", (data) => {
      let projectId = this.$store.state.proInfo.projectId;
      this.$store.commit("getShemaCheckSettings", projectId);
      this.tableData = this.$store.state.currentSchemaCheckSettings;

      this.tableData.forEach((item) => {
        let index = this.defaultTableData.findIndex(
          (data) => data.id === item.id
        );
        item.content = this.defaultTableData[index].content;
        item.type = this.defaultTableData[index].type;
        if (item.selector === 1) {
          item.level = this.$t("messages.ignore");
        } else if (item.selector === 2) {
          item.level = this.$t("messages.wrong");
        } else {
          item.level = this.$t("messages.Warning");
        }

        if (item.id === "symbol_2") {
          item.children = this.defaultTableData[index].children;
        }
      });
      this.checkOptionDialogVisible = true;
    });
    this.$bus.$on("changeLang", (data) => {
      this.checkLevel = [
        {
          value: "ignore",
          label: this.$t("messages.ignore"),
        },
        {
          value: "wrong",
          label: this.$t("messages.wrong"),
        },
        {
          value: "warning",
          label: this.$t("messages.Warning"),
        },
      ];

      this.defaultTableData = [
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet1"),
          id: "net_1",
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet2"),
          id: "net_2",
          children: [],
        },
        {
          type: this.$t("messages.net"),
          content: this.$t("messages.CheckSet3"),
          id: "net_3",
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: this.$t("messages.CheckSet4"),
          id: "symbol_1",
          children: [],
        },
        {
          type: this.$t("messages.Symbol"),
          content: this.$t("messages.CheckSet5"),
          id: "symbol_2",
          children: [
            {
              content: this.$t("messages.CheckSet6"),
            },
            {
              content: this.$t("messages.CheckSet7"),
            },
            {
              content: this.$t("messages.CheckSet8"),
            },
            {
              content: this.$t("messages.CheckSet9"),
            },
            {
              content: this.$t("messages.CheckSet10"),
            },
            {
              content: this.$t("messages.CheckSet11"),
            },
            {
              content: this.$t("messages.CheckSet12"),
            },
            {
              content: this.$t("messages.CheckSet13"),
            },
            {
              content: this.$t("messages.CheckSet14"),
            },
            {
              content: this.$t("messages.CheckSet15"),
            },
            {
              content: this.$t("messages.CheckSet16"),
            },
          ],
        },
      ];
    });
  },
  beforeDestroy() {
    bus.$off("schematicBoardOption");
    bus.$off("openCheckOption");
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    ////显示设置
    //显示设置确认按钮
    boardOptionDialogSureBtn() {
      let boardData = {};
      let adsorption = 1;
      let projectId = this.$store.state.proInfo.projectId;
      if (this.adsorption == "1mm") {
        adsorption = 1;
      } else if (this.adsorption == "10mm") {
        adsorption = 10;
      }
      boardData = {
        value_backgroundColor: this.value_backgroundColor,
        value_gridColor: this.value_gridColor,
        gridVisible: this.gridVisible,
        adsorption,
        gridSize: Number(this.gridSize),
      };
      let schematicBoardSet = {
        projectId,
        schematicBoardSettings: boardData,
      };
      set_user_config_api({
        set_type: "show_set",
        project_id: projectId,
        set_content: boardData,
      })
        .then((res) => {
          this.$store.commit("setPrecision", adsorption);
          this.$store.commit("setShemaBoardSettings", schematicBoardSet);
          bus.$emit("boardDesign", boardData);
          this.resetBoardOptionDialog();
        })
        .catch((err) => {
          this.resetBoardOptionDialog();
        });
    },
    //显示设置取消
    boardOptionDialogCancelBtn() {
      this.resetBoardOptionDialog();
    },
    //显示设置确认按钮禁用
    disableBoardOption() {
      if (
        this.value_backgroundColor === "" ||
        this.value_gridColor === "" ||
        this.adsorption === ""
      ) {
        this.disableBtn(this.$refs.boardOptionDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.boardOptionDialogSureBtn);
      }
    },
    // 获取格点吸附精度初始值
    getPrecisionOld() {},
    // 获取格点吸附精度
    getPrecision() {},
    resetBoardOptionDialog() {
      this.boardOptionDialogVisible = false;
      this.value_backgroundColor = "#ffffff";
      this.value_gridColor = "#000000";
      this.gridVisible = "1";
      this.adsorption = this.$store.state.value_adsorptionAccuracy;
    },
    backgroundColorChange(data) {
      this.value_backgroundColor = data;
    },
    gridColorChange(data) {
      this.value_gridColor = data;
    },
    ////设置检测
    handleCurrentChange(val) {},
    changeLevel(row) {
      if (row.level === this.$t("messages.ignore")) {
        row.selector = 1;
      } else if (row.level === this.$t("messages.wrong")) {
        row.selector = 2;
      } else {
        row.selector = 3;
      }
    },
    checkOptionDialogSureBtn() {
      let data = {};
      let projectId = this.$store.state.proInfo.projectId;

      data = {
        tableData: this.tableData,
      };
      set_user_config_api({
        set_type: "check_set",
        project_id: projectId,
        set_content: data,
      })
        .then((res) => {})
        .catch((err) => {});
      this.checkOptionDialogVisible = false;
    },
    checkOptionDialogCancelBtn() {
      this.checkOptionDialogVisible = false;
    },
    //合并表格s
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 12,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
  watch: {
    value_backgroundColor() {
      this.disableBoardOption();
    },
    value_gridColor() {
      this.disableBoardOption();
    },
    adsorption() {
      this.disableBoardOption();
    },
  },
};
</script>
<style lang="less" scoped>
//显示设置
.schematicBoardOptionDialog {
  color: #333;
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 35px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 495px;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 348px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 348px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    /deep/.el-input__count-inner {
      background: #f4f4f4;
    }
    /deep/.el-input__suffix {
      right: 10px;
      .el-input__suffix-inner {
        line-height: 30px;
        i {
          font-style: normal;
          color: #333;
          font-size: 12px;
        }
      }
    }
    .labelInput {
      /deep/.el-input {
        width: 175px;
        height: 30px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 175px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
      }
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 30px;
      }
    }
    .fillColor__btn {
      width: 348px;
      height: 30px;
      text-align: center;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      overflow: hidden;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    /deep/.el-color-picker {
      width: 348px;
      height: 30px;
    }
    /deep/.el-color-picker__trigger {
      height: 100%;
      width: 100%;
      border: none;
      padding: 0;
      .el-icon-arrow-down {
        color: #ccc !important;
      }
    }
    /deep/.el-color-picker__color {
      border: none;
    }
    /deep/.el-color-picker__color-inner {
      height: 100%;
      width: 100%;
    }
  }
  .labelOption {
    background-color: #f4f4f4;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//检查设置
.checkOptionDialog {
  color: #333;
  /deep/.el-dialog {
    width: 770px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 10px;
    .el-table {
      .cell {
        display: flex;
        align-items: center;
      }
      .checkOptionTips {
        cursor: pointer;
        margin-left: 10px;
        color: #fff;
        background-color: #4385f2;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        font-size: 12px;
      }
      th.el-table__cell {
        background-color: #e9eff3;
      }
      .el-table__cell {
        padding: 1px;
        height: 0;
      }
      .el-select {
        width: 130px;
        height: 26px;
        line-height: 26px;
        .el-input__inner {
          height: 27px;
          line-height: 27px;
          border: none;
          background-color: #fff !important;
        }
        .el-input__icon {
          height: 27px;
          line-height: 27px;
        }
        .el-input--suffix {
          height: 27px;
          line-height: 27px;
        }
      }
      tr td:last-child {
        padding: 0px;
        div {
          padding: 0px;
        }
      }
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>