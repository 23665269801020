const { v4: uuidv4 } = require('uuid')

function generateUUID() {
  var v = uuidv4();
  return v.replaceAll('-', '');
}

function generateUUIDNumber(number) {
  var v = uuidv4();
  return v.replaceAll('-', '').slice(number);
}

function forceFileDownload(response, fileName) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {generateUUID, forceFileDownload, generateUUIDNumber};