<template>
  <div>
    <el-dialog :title="$t('messages.toolSplitGdsTitle')" :visible.sync="CellSplitDialogVisible" width="686px"
      :before-close="handleClose" :modal="false" @opened="CellSplitDialogOpened">
      <div class="dialog-body">
        <div style="display: flex">
          <div class="table-item">
            <el-input v-model.trim="searchLayer" @keyup.enter.native="layerSearch" class="table-item__input"
              :placeholder="$t('messages.toolKeyWord')"></el-input>
            <el-table class="t-table" ref="layerTable" :data="layerTableData" :show-header="false" height="400">
              <el-table-column width="100" prop="number"></el-table-column>
              <el-table-column prop="layerName">
                <template slot-scope="scope">
                  <img alt="" :src="scope.row.image" class="layer-image" />
                  {{ scope.row.layerName }}
                </template>
              </el-table-column>
              <el-table-column width="25">
                <template slot-scope="scope">
                  <div :style="{ background: scope.row.color }" class="layer-color"></div>
                </template>
              </el-table-column>
              <el-table-column width="60" type="selection" :selectable="layerTableSelectable"></el-table-column>
            </el-table>
          </div>
          <div class="table-item" style="font-size: 13px">
            <div v-for="(v, k) in mergeLayerTableData" class="merge-table-data" :key="k">
              <div style="display: flex; padding: 5px 10px; border-bottom: 1px solid #f4f4f4; cursor: pointer"
                class="merge-data-hover" @click="v.show_child = !v.show_child">
                <div>
                  <span class="el-icon-circle-plus-outline icons" v-if="!v.show_child"></span>
                  <span v-else class="el-icon-remove-outline icons"></span>
                </div>
                <div style="width: 90%">{{ $t('messages.toolSplitGdsLayout') }}{{ k + 1 }}</div>
                <div class="merge-layer-color" :style="{ background: getMergeColor(k) }"></div>
              </div>
              <div v-show="v.children.length > 0 && v.show_child">
                <div v-for="(item, index) in v.children" :key="index" class="merge-table-data__child">
                  <div style="display: flex; padding: 5px 10px 5px 20px">
                    <div style="width: 90%">{{ item.layerName }}</div>
                    <div class="merge-layer-color" :style="{ background: item.color }"></div>
                    <div @click="delete_merge_child(v.children, index, k)" style="margin-left: 10px; cursor: pointer">
                      <span class="el-icon-circle-close"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <el-table class="t-table" ref="mergeLayerTable" :data="mergeLayerTableData" :show-header="false" height="400">
              <el-table-column align="center" prop="name"></el-table-column>
              <el-table-column align="center" width="100" type="selection"></el-table-column>
              <el-button type="pramary">添加版图</el-button>
            </el-table> -->
            <div class="add-layer-div">
              <el-button class="add-layer-btn" type="default"
                @click="addMergeData">{{ $t('messages.toolSplitGdsAdd') }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="CellSplitDialogOK" disabled ref="sureBtn">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="cancelDelDialog" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";
import { deepClone, deepCopy } from "@/utils/utils";
const ToolComponents = require("../../../utils/tools/components");
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");

const CellSplitDefault = {
  rule: {
    d: 10,
  },
};

export default {
  data() {
    return {
      unit: "um",
      tc: new ToolComponents(),

      CellSplitTableData: [],
      CellSplitDialogVisible: false,
      CellSplit: CellSplitDefault,

      cellLayerList: [],
      canvas2D: null,
      layerTableData: [],
      searchLayer: "",
      mergeLayerTableData: [],
      merge_color_list: [
        "#EC6941",
        "#32B16C",
        "#8957A1",
        "#F19149",
        "#13B5B1",
        "#AE5DA1",
        "#ECC282",
        "#00B7EE",
        "#EA68A2",
        "#FFF45C",
        "#448ACA",
        "#EB6877",
        "#B3D465",
        "#556FB5",
        "#AA89BD",
        "#F29C9F",
        "#A6937C",
        "#89CDED",
        "#88ABDA",
        "#468EB4",
      ],
    };
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();
    }, 1000);
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add('deactivate')
      ref.$el.classList.add('is-disabled')
      ref.$el.disabled = true
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove('deactivate')
      ref.$el.classList.remove('is-disabled')
      ref.$el.disabled = false
    },
    //是否添加了版图
    disabledCellSplit() {
      if (this.mergeLayerTableData.length > 0) {
        this.activateBtn(this.$refs.sureBtn);
      } else {
        this.disableBtn(this.$refs.sureBtn);
      }
    },
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    layerTableSelectable(row, index) {
      return !row.is_select;
    },
    resetSelection(tableName) {
      try {
        this.$refs[tableName].clearSelection();
      } catch {
        return;
      }
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = !item.selected;
        });
      }
    },

    CellSplitDialogOpened() {
      this.CellSplitSetDefault();
    },
    cancelDelDialog() {
      this.CellSplitDialogVisible = false;
      this.layerTableData = [];
      this.mergeLayerTableData = [];
      this.searchLayer = "";
    },
    CellSplitDialogOK() {
      // 最终输出版图数据为mergeLayerTableData;
      let filename = this.$store.state.fileInfo.name;
      let cellname = this.$store.state.cellInfo.name;
      let splits = [];
      for (let i = 0; i < this.mergeLayerTableData.length; i++) {
        let layer_datatypes = [];
        let data = this.mergeLayerTableData[i];
        for (let j = 0; j < data.children.length; j++) {
          layer_datatypes.push({
            layer: data.children[j].layerNumber,
            datatype: 0,
            texttype: 0,
          });
        }
        splits.push({
          name: `cell_${i + 1}`,
          layer_datatypes: layer_datatypes,
        });
      }
      let conf = {
        library: {
          name: `${filename}_${cellname}_split`,
          unit: 1e-6,
          percision: 1e-9,
        },
        splits: splits,
        fileLayerList: this.fileLayerList,
      };
      this.CellSplitDialogVisible = false;
      if (this.bus_type == "TOOL_SPLIT_CELL") {
        this.$message({
          message: i18n.t("messages.toolTipLayoutSpliting"),
          type: "warning",
        });
        setTimeout(() => {
          bus.$emit("TOOL_SPLIT_CELL_RUN", conf);
        }, 500);
      }
      if (this.bus_type == "TOOL_SPLIT_CELL_BIG") {
        bus.$emit("TOOL_SPLIT_CELL_BIG_RUN", conf);
      }
    },
    CellSplitSetDefault() {
      this.CellSplit = JSON.parse(JSON.stringify(CellSplitDefault));
    },
    getLayerData(fileID) {
      if (!fileID) return;
      let allData = this.$store.state.layerDatas;
      for (let i in allData) {
        if (fileID == allData[i].file_snow_id) {
          return allData[i].layerDatas;
        }
      }
    },
    layerSearch() {
      let searchData = [];
      for (let i in this.cellLayerList) {
        if (this.cellLayerList[i].layerName.indexOf(this.searchLayer) != -1) {
          searchData.push(this.cellLayerList[i]);
        }
      }
      this.layerTableData = searchData;
    },
    getMergeColor(index) {
      return this.merge_color_list[index % 20];
    },
    addMergeData() {
      let select_list = this.$refs.layerTable.selection;
      if (select_list.length == 0) return;
      this.mergeLayerTableData.push({
        children: select_list,
        show_child: false,
      });
      for (let i in select_list) {
        select_list[i].is_select = true;
        select_list[i].color = this.getMergeColor(
          this.mergeLayerTableData.length - 1
        );
      }
      this.resetSelection("layerTable");
    },
    delete_merge_child(row, index, k) {
      this.layerTableData[row[index].index].color = deepCopy(
        row[index].origin_color
      );
      this.layerTableData[row[index].index].is_select = false;
      row.splice(index, 1);
      if (row.length == 0) {
        this.mergeLayerTableData.splice(k, 1);
      }
    },
  },
  mounted() {
    bus.$on("TOOL_SPLIT_CELL", (data) => {
      bus.$emit("updateCellLayerList", null);
      this.layerTableData = [];
      this.mergeLayerTableData = [];
      this.searchLayer = "";

      // cell layer
      let cellLayerList = this.$store.state.cellLayerList;
      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push(item);
        }
      });
      this.cellLayerList = this.cellLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );

      // file layer
      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push(item);
        }
      });
      this.fileLayerList = this.fileLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );

      this.layerTableData = deepClone(this.cellLayerList);
      for (let i in this.layerTableData) {
        this.layerTableData[i].color = "#FFFFFF";
        this.layerTableData[i].is_select = false;
        this.layerTableData[i].index = i;
        this.layerTableData[i].number = this.layerTableData[i].layerNumber;
        this.layerTableData[i].origin_color = deepCopy(
          this.layerTableData[i].color
        );
      }
      this.CellSplitDialogVisible = true;
      this.bus_type = "TOOL_SPLIT_CELL";
    });
    bus.$on("TOOL_SPLIT_CELL_BIG", (data) => {
      this.layerTableData = [];
      this.mergeLayerTableData = [];
      this.searchLayer = "";
      this.cellLayerList = this.$store.state.cellLayerList;
      this.fileLayerList = this.$store.state.fileLayerList;
      this.layerTableData = deepClone(this.cellLayerList);
      for (let i in this.layerTableData) {
        this.layerTableData[i].color = "#FFFFFF";
        this.layerTableData[i].is_select = false;
        this.layerTableData[i].index = i;
        this.layerTableData[i].origin_color = deepCopy(
          this.layerTableData[i].color
        );
      }
      this.CellSplitDialogVisible = true;
      this.bus_type = "TOOL_SPLIT_CELL_BIG";
    });
  },
  watch: {
    mergeLayerTableData() {
      this.disabledCellSplit()
    }
  },
}
</script>

<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
.el-button--default {
  color: #4385f2;
  border: 1px solid #4385f2;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  /deep/ .el-input__inner {
    background: #ffffff !important;
    height: 30px;
    line-height: 30px;
  }
}
.table-item {
  width: 320px;
  /deep/ .el-input__inner {
    background: #ffffff !important;
    height: 30px;
    line-height: 30px;
  }
  .add-layer-div {
    text-align: center;
  }
  .t-table {
    width: 100%;
    background: #ffffff;
    /deep/ .el-table__cell {
      padding: 4px !important;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .layer-image {
      width: 25px;
      height: 15px;
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
  }
}
.layer-color {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.merge-layer-color {
  position: relative;
  top: 6px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.icons {
  color: #5c5a5a;
  margin-right: 5px;
}
.add-layer-btn {
  margin-top: 10px;
  width: 280px;
  border-radius: 16px !important;
}
.merge-data-hover:hover {
  background: #f6f7f8;
}
.merge-table-data__child:hover {
  background: #f6f7f8;
}
.merge-table-data__child {
  border-bottom: 1px solid #f4f4f4;
}
</style>
