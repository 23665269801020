<template>
  <div>
    <!-- 文本图形 -->
    <div class="textGraphicsDialog">
      <el-dialog :title="$t('messages.Text')" :visible.sync="textGraphicsDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="textGraphicsDialogClose">
        <el-form :model="textGraphicsForm" :rules="rules" ref="textGraphicsForm" class="demo-ruleForm">
          <div class="inputArea">
            <span>{{ $t('messages.TextContent') }}:</span>
            <el-form-item prop="value_textContent">
              <el-input v-model="textGraphicsForm.value_textContent" :placeholder="$t('messages.placeholder_1')"
                maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </div>
          <div class="inputArea">
            <span>{{ $t('messages.layer') }}:</span>
            <el-select popper-class="optionClass" v-model="value_textGraphicsLayer"
              :placeholder="$t('messages.placeholder_0')" @change="textGraphicsLayerChange">
              <el-option v-for="item in textGraphicsLayer" :key="item.layerNumber" :value="item.layerName">
                <span style="width: 35px">{{ item.layerNumber }}</span>
                <img alt="" :src="item.image" style="width: 25px; height: 15px" />
                <span style="">{{ item.layerName }}</span>
              </el-option>
            </el-select>
          </div>
          <div class="inputArea">
            <span>{{ $t('messages.ReferenceLocation') }}:</span>
            <el-select popper-class="optionClass" v-model="value_textGraphicsReferencePoint"
              :placeholder="$t('messages.placeholder_0')" @change="textGraphicsReferencePointSelect">
              <el-option v-for="item in textGraphicsReferencePoint" :key="item.value" :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="inputArea">
            <span class="textGraphicsInput">
              <span style="padding-right: 113px">{{ $t('messages.Size') }}:</span>
              <el-input v-model="value_textGraphicsSize"></el-input>
              um
            </span>
          </div>
          <div class="inputArea">
            <span class="textGraphicsInput">
              <span style="padding-right: 113px">{{ $t('messages.Rotate') }}:</span>
              <el-input v-model="value_textGraphicsRotate"></el-input>
              °
            </span>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="textGraphicsSureBtn" type="primary" @click="textGraphicsSureBtn"
            size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="resetTextGraphics()" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 标签 -->
    <div class="labelDialog">
      <el-dialog :title="$t('messages.Label')" :visible.sync="labelDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="labelDialogClose">
        <el-form :model="labelForm" :rules="rules" ref="labelForm" class="demo-ruleForm">
          <div class="inputArea">
            <span style="padding-right: 90px">{{ $t('messages.layerLocation') }}:</span>
            <div class="radioArea">
              <el-radio v-model="layerLocation" label="1"
                @change="customLayerPosition = false">{{ $t('messages.SelectedLayer') }}</el-radio>
              <!-- <el-radio v-model="layerLocation" label="2" @change="customLayerPosition=false">图形的图层</el-radio> -->
              <el-radio v-model="layerLocation" label="3"
                @change="customLayerPosition = true">{{ $t('messages.Defined') }}</el-radio>
            </div>
          </div>
          <div class="inputArea" v-if="customLayerPosition">
            <span>{{ $t('messages.Defined') }}:</span>
            <el-select popper-class="optionClass" v-model="value_labelLayer" :placeholder="$t('messages.placeholder_0')"
              @change="labelLayerChange">
              <el-option v-for="item in labelLayer" :key="item.layerNumber" :value="item.layerName">
                <span style="width: 35px">{{ item.layerNumber }}</span>
                <img alt="" :src="item.image" style="width: 25px; height: 15px" />
                <span style="">{{ item.layerName }}</span>
              </el-option>
            </el-select>
          </div>
          <div class="labelOption">{{ $t('messages.LabelSettings') }}</div>
          <div class="inputArea">
            <span>{{ $t('messages.ReferenceLocation') }}:</span>
            <el-select popper-class="optionClass" v-model="value_referencePoint"
              :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in referencePointLocation" :key="item.value" :value="item.value"></el-option>
            </el-select>
          </div>
          <!-- <div class="inputArea">
          <span>字体:</span>
          <el-select popper-class="optionClass" v-model="value_fontFamily" placeholder="请选择字体">
            <el-option v-for="item in fontFamily" :key="item.value" :value="item.label">
            </el-option>
          </el-select>
        </div> -->
          <div class="inputArea">
            <span>{{ $t('messages.aText') }}:</span>
            <el-form-item prop="value_labelText">
              <el-input v-model="labelForm.value_labelText" :placeholder="$t('messages.placeholder_0')" maxlength="20"
                show-word-limit></el-input>
            </el-form-item>
          </div>
          <div class="inputArea">
            <span class="labelInput">
              <span style="padding-right: 91px">{{ $t('messages.fontSize') }}:</span>
              <el-input v-model="fontSize"></el-input>
            </span>
          </div>
          <!-- <div class="inputArea">
          <span style="padding-right:120px">关联:</span>
          <div class="radioArea">
            <el-radio v-model="layerAssociation" label="1" style="padding-right:50px">是</el-radio>
            <el-radio v-model="layerAssociation" label="2">否</el-radio>
          </div>
        </div> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="labelDialogSureBtn" type="primary" @click="labelDialogSureBtn" size="mini"
            disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="resetLabelDialog()" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 关键点 -->
    <div class="keyPointDialog">
      <el-dialog :title="$t('messages.createKeyPoint')" :visible.sync="keyPointDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="keyPointDialogClose">
        <el-form :model="keyPointForm" :rules="rules" ref="keyPointForm" class="demo-ruleForm">
          <div class="inputArea">
            <span>{{ $t('messages.name') }}:</span>
            <el-form-item prop="value_keyPointName">
              <el-input v-model="keyPointForm.value_keyPointName" :placeholder="$t('messages.placeholder_0')"
                maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </div>
          <!-- <div class="inputArea">
          <span>形状:</span>
          <el-select popper-class="optionClass" v-model="value_keyPointShape" placeholder="请选择形状">
            <el-option v-for="item in keyPointShape" :key="item.value" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
          <div class="inputArea">
            <span style="padding-right: 90px">{{ $t('messages.createLabel') }}:</span>
            <div class="radioArea">
              <el-radio v-model="createLabel" label="1" style="padding-right: 50px">{{ $t('messages.yes') }}</el-radio>
              <el-radio v-model="createLabel" label="2">{{ $t('messages.no') }}</el-radio>
            </div>
          </div>
          <!-- <div class="inputArea" v-if="createLabel=='1'">
          <span>字体:</span>
          <el-select popper-class="optionClass" v-model="value_fontFamily" placeholder="请选择字体">
            <el-option v-for="item in fontFamily" :key="item.value" :value="item.label">
            </el-option>
          </el-select>
        </div> -->
          <div class="inputArea" v-if="createLabel == '1'">
            <span class="labelInput">
              <span style="padding-right: 87px">{{ $t('messages.fontSize') }}:</span>
              <el-input v-model="value_keyPointSize"></el-input>
            </span>
          </div>
          <!-- <div class="inputArea" v-if="createLabel=='1'">
          <span class="labelInput">
            <span style="padding-right:87px">标签旋转:</span>
            <el-input v-model="value_keyPointRotate"></el-input>
          </span>
        </div> -->
          <!-- <div class="inputArea">
          <span style="padding-right:60px">上级器件继承:</span>
          <div class="radioArea">
            <el-radio v-model="superiorDeviceInheritance" label="1" style="padding-right:50px">是</el-radio>
            <el-radio v-model="superiorDeviceInheritance" label="2">否</el-radio>
          </div>
        </div> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="keyPointDialogSureBtn" type="primary" @click="keyPointDialogSureBtn"
            size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="resetKeyPointDialog()" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import { getFileLayerListFromTable } from "./rightList/layerListFn.js";
export default {
  data() {
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(this.$t("messages.fileTip2"));
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        callback(this.$t("messages.fileTip2"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        value_keyPointName: [{ validator: fileNameRule, trigger: "blur" }],
        value_labelText: [{ validator: fileNameRule, trigger: "blur" }],
        value_textContent: [{ validator: fileNameRule, trigger: "blur" }],
      },
      //文本图形
      textGraphicsDialogVisible: false,
      textGraphicsLayer: [],
      textGraphicsForm: {
        value_textContent: "",
      },
      textContent: [
        {
          value: this.$t("messages.alignLeft"),
          icon: "",
        },
        {
          value: this.$t("messages.alignRight"),
          icon: "",
        },
        {
          value: this.$t("messages.alignTop"),
          icon: "",
        },
        {
          value: this.$t("messages.alignBottom"),
          icon: "",
        },
        {
          value: this.$t("messages.alignHorizontalCenter"),
          icon: "",
        },
        {
          value: this.$t("messages.alignVerticalCenter"),
          icon: "",
        },
      ],
      value_textGraphicsLayer: "",
      value_textGraphicsLayerNumber: null,
      value_textGraphicsReferencePoint: "",
      value_textGraphicsReferencePointData: "",
      textGraphicsReferencePoint: [
        {
          value: this.$t("messages.Center"),
          data: "o",
        },
        {
          value: this.$t("messages.Up"),
          data: "n",
        },
        {
          value: this.$t("messages.Down"),
          data: "s",
        },
        {
          value: this.$t("messages.Left"),
          data: "w",
        },
        {
          value: this.$t("messages.Right"),
          data: "e",
        },
        {
          value: this.$t("messages.upperLeft"),
          data: "nw",
        },
        {
          value: this.$t("messages.rightUpper"),
          data: "ne",
        },
        {
          value: this.$t("messages.bottomLeft"),
          data: "sw",
        },
        {
          value: this.$t("messages.lowerRight"),
          data: "se",
        },
      ],
      value_textGraphicsRotate: 0,
      value_textGraphicsArrangement: 0,
      value_textGraphicsSize: 5,
      //标签
      labelDialogVisible: false,
      layerLocation: "1",
      customLayerPosition: false,
      labelLayer: [],
      value_labelLayer: "",
      value_labelLayerNumber: null,
      labelForm: {
        value_labelText: "",
      },
      value_referencePoint: "",
      referencePointLocation: [
        {
          value: this.$t("messages.Center"),
        },
        {
          value: this.$t("messages.Up"),
        },
        {
          value: this.$t("messages.Down"),
        },
        {
          value: this.$t("messages.Left"),
        },
        {
          value: this.$t("messages.Right"),
        },
        {
          value: this.$t("messages.upperLeft"),
        },
        {
          value: this.$t("messages.rightUpper"),
        },
        {
          value: this.$t("messages.bottomLeft"),
        },
        {
          value: this.$t("messages.lowerRight"),
        },
      ],
      fontFamily: [
        {
          label: "思源雅黑",
          value: "Noto Sans SC",
        },
        {
          label: "微软雅黑",
          value: "Noto Sans CJK SC",
        },
      ],
      value_fontFamily: "",
      fontSize: 20,
      layerAssociation: "1",
      //关键点
      keyPointDialogVisible: false,
      keyPointForm: { value_keyPointName: "" },
      value_keyPointShape: "",
      keyPointShape: [
        {
          value: "圆形",
        },
      ],
      createLabel: "1",
      value_keyPointSize: 30,
      value_keyPointRotate: 5,
      superiorDeviceInheritance: "1",
    };
  },
  created() {},
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    //文本图形
    textGraphicsReferencePointSelect() {
      this.value_textGraphicsReferencePointData =
        this.textGraphicsReferencePoint.filter(
          (item) => item.value === this.value_textGraphicsReferencePoint
        )[0].data;
    },
    textGraphicsSureBtn() {
      this.$refs.textGraphicsForm.validate((valid) => {
        if (valid) {
          let data = {
            text: this.textGraphicsForm.value_textContent,
            layerNumber: this.value_textGraphicsLayerNumber,
            position: this.value_textGraphicsReferencePointData,
            size: this.value_textGraphicsSize,
            rotate: this.value_textGraphicsRotate,
          };

          bus.$emit("textGraphicsInfo", data);
          bus.$emit("cancelSidebarSelect", true);
          this.textGraphicsDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    textGraphicsLayerChange(val) {
      this.value_textGraphicsLayerNumber = this.textGraphicsLayer.filter(
        (item) => item.layerName == val
      )[0].layerNumber;
    },
    textGraphicsDialogClose() {
      this.resetTextGraphics();
    },

    resetTextGraphics() {
      this.textGraphicsForm.value_textContent = "";
      this.value_textGraphicsLayer = "";
      this.value_textGraphicsLayerNumber = null;
      this.value_textGraphicsReferencePoint = "";
      this.value_textGraphicsReferencePointData = "";
      this.value_textGraphicsSize = 5;
      this.value_textGraphicsRotate = 0;
      this.textGraphicsDialogVisible = false;
      setTimeout(() => {
        this.$refs.textGraphicsForm.clearValidate();
      }, 500);
      bus.$emit("cancelSidebarSelect", true);
    },
    disableTextGraphics() {
      this.$refs.textGraphicsForm.validate((valid) => {
        if (
          this.textGraphicsForm.value_textContent === "" ||
          // this.value_textGraphicsLayer === "" ||
          this.value_textGraphicsReferencePoint === "" ||
          this.value_textGraphicsSize === "" ||
          this.value_textGraphicsRotate === "" ||
          !valid
        ) {
          this.disableBtn(this.$refs.textGraphicsSureBtn);
        } else if (valid) {
          this.activateBtn(this.$refs.textGraphicsSureBtn);
        }
      });
    },
    //标签
    labelDialogSureBtn() {
      this.$refs.labelForm.validate((valid) => {
        if (valid) {
          if (this.layerLocation == "1") {
            this.value_labelLayerNumber =
              this.$store.state.selectedLayer.layerNumber;
          }
          let data = {
            layerNumber: this.value_labelLayerNumber,
            position: this.value_referencePoint,
            text: this.labelForm.value_labelText,
            fontSize: this.fontSize,
          };

          bus.$emit("labelInfo", data);
          this.labelDialogVisible = false;
        }
      });
    },
    labelDialogClose() {
      this.resetLabelDialog();
    },
    labelLayerChange(val) {
      this.value_labelLayerNumber = this.labelLayer.filter(
        (item) => item.layerName == val
      )[0].layerNumber;
    },
    resetLabelDialog() {
      this.layerLocation = "1";
      this.layerAssociation = "1";
      this.value_labelLayer = "";
      this.value_labelLayerNumber = null;
      this.value_referencePoint = "";
      this.value_fontFamily = "";
      this.labelForm.value_labelText = "";
      this.fontSize = 20;
      this.customLayerPosition = false;
      this.labelDialogVisible = false;
      setTimeout(() => {
        this.$refs.labelForm.clearValidate();
      }, 500);
    },
    disableLabel() {
      this.$refs.labelForm.validate((valid) => {
        if (this.layerLocation == "1" || this.layerLocation == "2") {
          if (
            this.value_referencePoint === "" ||
            this.fontSize === "" ||
            this.labelForm.value_labelText === "" ||
            !valid
          ) {
            this.disableBtn(this.$refs.labelDialogSureBtn);
          } else if (valid) {
            this.activateBtn(this.$refs.labelDialogSureBtn);
          }
        } else {
          if (
            this.value_labelLayer === "" ||
            this.value_referencePoint === "" ||
            this.fontSize === "" ||
            this.labelForm.value_labelText === "" ||
            !valid
          ) {
            this.disableBtn(this.$refs.labelDialogSureBtn);
          } else if (valid) {
            this.activateBtn(this.$refs.labelDialogSureBtn);
          }
        }
      });
    },
    //关键点
    keyPointDialogSureBtn() {
      this.$refs.keyPointForm.validate((valid) => {
        if (valid) {
          let data = [];
          let layerNumber = this.$store.state.fileLayerList[0].layerNumber;
          if (this.createLabel == "1") {
            data = {
              layerNumber,
              keyPointName: this.keyPointForm.value_keyPointName,
              isCreate: true,
              keyPointSize: this.value_keyPointSize,
            };
          } else {
            data = {
              layerNumber,
              keyPointName: this.keyPointForm.value_keyPointName,
              isCreate: false,
              keyPointSize: null,
            };
          }
          bus.$emit("keyPointInfo", data);
          this.keyPointDialogVisible = false;
        }
      });
    },
    disableKeyPoint() {
      this.$refs.keyPointForm.validate((valid) => {
        if (this.createLabel == "1") {
          if (
            this.keyPointForm.value_keyPointName === "" ||
            this.value_keyPointSize === "" ||
            !valid
          ) {
            this.disableBtn(this.$refs.keyPointDialogSureBtn);
          } else if (valid) {
            this.activateBtn(this.$refs.keyPointDialogSureBtn);
          }
        } else {
          if (this.keyPointForm.value_keyPointName === "" || !valid) {
            this.disableBtn(this.$refs.keyPointDialogSureBtn);
          } else if (valid) {
            this.activateBtn(this.$refs.keyPointDialogSureBtn);
          }
        }
      });
    },
    keyPointDialogClose() {
      this.resetKeyPointDialog();
    },
    resetKeyPointDialog() {
      this.keyPointForm.value_keyPointName = "";
      this.value_keyPointSize = 30;
      this.createLabel = "1";
      this.keyPointDialogVisible = false;
      setTimeout(() => {
        this.$refs.keyPointForm.clearValidate();
      }, 500);
    },
  },
  mounted() {
    this.$bus.$on("changeLang", (data) => {
      let fileNameRule = (rule, value, callback) => {
        if (value === "") {
          callback();
        } else if (
          [...value][0] == " " ||
          [...value][[...value].length - 1] == " "
        ) {
          callback(this.$t("messages.fileTip2"));
        } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
          callback(this.$t("messages.fileTip2"));
        } else {
          callback();
        }
      };
      this.rules.value_keyPointName[0].validator = fileNameRule;
      this.rules.value_labelText[0].validator = fileNameRule;
      this.rules.value_textContent[0].validator = fileNameRule;

      this.textContent = [
        {
          value: this.$t("messages.alignLeft"),
          icon: "",
        },
        {
          value: this.$t("messages.alignRight"),
          icon: "",
        },
        {
          value: this.$t("messages.alignTop"),
          icon: "",
        },
        {
          value: this.$t("messages.alignBottom"),
          icon: "",
        },
        {
          value: this.$t("messages.alignHorizontalCenter"),
          icon: "",
        },
        {
          value: this.$t("messages.alignVerticalCenter"),
          icon: "",
        },
      ];

      this.textGraphicsReferencePoint = [
        {
          value: this.$t("messages.Center"),
          data: "o",
        },
        {
          value: this.$t("messages.Up"),
          data: "n",
        },
        {
          value: this.$t("messages.Down"),
          data: "s",
        },
        {
          value: this.$t("messages.Left"),
          data: "w",
        },
        {
          value: this.$t("messages.Right"),
          data: "e",
        },
        {
          value: this.$t("messages.upperLeft"),
          data: "nw",
        },
        {
          value: this.$t("messages.rightUpper"),
          data: "ne",
        },
        {
          value: this.$t("messages.bottomLeft"),
          data: "sw",
        },
        {
          value: this.$t("messages.lowerRight"),
          data: "se",
        },
      ];

      this.referencePointLocation = [
        {
          value: this.$t("messages.Center"),
        },
        {
          value: this.$t("messages.Up"),
        },
        {
          value: this.$t("messages.Down"),
        },
        {
          value: this.$t("messages.Left"),
        },
        {
          value: this.$t("messages.Right"),
        },
        {
          value: this.$t("messages.upperLeft"),
        },
        {
          value: this.$t("messages.rightUpper"),
        },
        {
          value: this.$t("messages.bottomLeft"),
        },
        {
          value: this.$t("messages.lowerRight"),
        },
      ];
    });
    bus.$on("textGraphics", (data) => {
      this.textGraphicsDialogVisible = true;
      let file_snow_id = this.$store.state.fileInfo.fileId;
      let fileLayerListObj = getFileLayerListFromTable(file_snow_id);

      this.textGraphicsLayer = fileLayerListObj.fileLayerDatas;
    });
    bus.$on("label", (data) => {
      this.labelDialogVisible = true;
      let file_snow_id = this.$store.state.fileInfo.fileId;
      let fileLayerListObj = getFileLayerListFromTable(file_snow_id);

      this.labelLayer = fileLayerListObj.fileLayerDatas;
    });
    bus.$on("keyPoint", (data) => {
      this.keyPointDialogVisible = true;
    });

    bus.$on("closeDialog", (data) => {
      this.textGraphicsDialogVisible = false;
      this.labelDialogVisible = false;
      this.keyPointDialogVisible = false;
    });
  },
  beforeDestroy() {
    bus.$off("textGraphics");
    bus.$off("label");
    bus.$off("keyPoint");
    bus.$off("closeDialog");
  },
  watch: {
    "textGraphicsForm.value_textContent"() {
      this.disableTextGraphics();
    },
    value_textGraphicsLayer() {
      this.disableTextGraphics();
    },
    value_textGraphicsReferencePoint() {
      this.disableTextGraphics();
    },
    value_textGraphicsSize() {
      this.disableTextGraphics();
    },
    value_textGraphicsRotate() {
      this.disableTextGraphics();
    },
    layerLocation() {
      this.disableLabel();
    },
    value_referencePoint() {
      this.disableLabel();
    },
    fontSize() {
      this.disableLabel();
    },
    value_labelLayer() {
      this.disableLabel();
    },
    "labelForm.value_labelText"() {
      this.disableLabel();
    },
    "keyPointForm.value_keyPointName"() {
      this.disableKeyPoint();
    },
    value_fontFamily() {
      this.disableKeyPoint();
    },
    value_keyPointSize() {
      this.disableKeyPoint();
    },
    value_keyPointRotate() {
      this.disableKeyPoint();
    },
  },
};
</script>
<style lang="less" scoped>
//文本图形
.textGraphicsDialog {
  color: #333;
  /deep/.el-dialog {
    width: 533px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 20px 35px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 493px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 348px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 348px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    /deep/.el-input__count-inner {
      background: #f4f4f4;
    }
    .textGraphicsInput {
      /deep/.el-input {
        width: 175px;
        height: 30px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 175px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
      }
    }
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//标签
.labelDialog {
  color: #333;
  /deep/.el-dialog {
    width: 686px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 65px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 646px;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 495px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 495px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    /deep/.el-input__count-inner {
      background: #f4f4f4;
    }
    .labelInput {
      /deep/.el-input {
        width: 175px;
        height: 30px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 175px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
      }
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 30px;
      }
    }
  }
  .labelOption {
    background-color: #f4f4f4;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//关键点
.keyPointDialog {
  color: #333;
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 35px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 495px;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 348px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 348px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    /deep/.el-input__count-inner {
      background: #f4f4f4;
    }
    .labelInput {
      /deep/.el-input {
        width: 175px;
        height: 30px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 175px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
      }
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 30px;
      }
    }
  }
  .labelOption {
    background-color: #f4f4f4;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>
