var QGdstk = null
;(function () {
  function AutoToolComponents() {
    QGdstk = window.QGdstk
  }

  // InlinepadPad Config
  // A: 20
  // A1: 10
  // B: 30
  // B1: 21
  // angle_pad: 0.7853981633974483
  // layer_pad1: 0
  // layer_pad2: 0
  // angle_spout1: 0.7853981633974483
  // angle_spout2: 3.9269908169872414
  // layer_spout1: 1
  // layer_spout2: 2
  AutoToolComponents.prototype.InlinepadPad = function (conf) {
    function spout(A = 30, A1 = 21, layer = 0, datatype = 0, o_num = 0) {
      let A2 = 10
      let A3 = 5
      let A4 = 4
      let A5 = 2
      let A6 = 1.2169
      // 计算角度
      let a1 = Math.asin((A4 / 2 + A5 + A3) / (A + A3))
      let a2 = Math.asin((A4 / 2 + A3) / (A1 + A3))
      // 采用curve一次走完
      let curve = new QGdstk.Curve([0, A4 / 2], 1e-3)
      curve.segment([0, A5], true)
      curve.segment([A6, 0], true)
      curve.arc(A3, -Math.PI / 2, -a1)
      curve.arc(A, Math.PI - a1, -(Math.PI - a1))
      curve.arc(A3, a1, Math.PI / 2)
      curve.segment([-A6, 0], true)
      curve.segment([0, A5], true)
      curve.segment([A2, 0], true)
      curve.arc(A3, Math.PI / 2, a2)
      curve.arc(A1, -(Math.PI - a2), Math.PI - a2)
      curve.arc(A3, -a2, -Math.PI / 2)
      curve.segment([-A2, 0], true)
      // 通过curve生成多边形, 使壶嘴朝向右侧
      let spout = new QGdstk.Polygon(curve.points(), layer, datatype)
      spout.translate(-((A + A3) * Math.cos(a1) + A6), 0)
      spout.rotate(Math.PI, [0, 0])

      // let o1 = new QGdstk.Label(text='O1', origin=[-(A+A3)*cos(a1), 0], anchor='o', rotation=,1, )
      let cell = new QGdstk.Cell(`InLinePad_Spout_l${layer}d${datatype}`)
      let key_o1 = new QGdstk.Label(`O${o_num}`, [(A + A3) * Math.cos(a1) + A6, 0])

      cell.add([spout, key_o1])
      return cell
    }

    function pad(A1 = 40, layer_in = 0, datatype_in = 0, A = 50, layer_out = 1, datatype_out = 0, angle = 0) {
      let pad_w = 50
      let pad_h = 50
      // 四周圆盘
      let pad_inter = new QGdstk.ellipse(
        (center = [0, 0]),
        (radius = A1),
        (inter_radius = null),
        (initial_angle = 0),
        (final_angle = 0),
        (tolerance = 0.01),
        (layer = layer_in),
        (datatype = datatype_in)
      )
      let pad_inter_1 = pad_inter.copy()
      let pad_inter_2 = pad_inter.copy()
      let pad_inter_3 = pad_inter.copy()
      let pad_inter_4 = pad_inter.copy()

      pad_inter_1.translate(pad_w, pad_h)
      pad_inter_2.translate(-pad_w, pad_h)
      pad_inter_3.translate(pad_w, -pad_h)
      pad_inter_4.translate(-pad_w, -pad_h)
      pad_inter_1.rotate(angle)
      pad_inter_2.rotate(angle)
      pad_inter_3.rotate(angle)
      pad_inter_4.rotate(angle)

      let pad_outer = new QGdstk.ellipse(
        (center = [0, 0]),
        (radius = A),
        (inter_radius = null),
        (initial_angle = 0),
        (final_angle = 0),
        (tolerance = 0.01),
        (layer = layer_out),
        (datatype = datatype_out)
      )
      let pad_outer_1 = pad_outer.copy()
      let pad_outer_2 = pad_outer.copy()
      let pad_outer_3 = pad_outer.copy()
      let pad_outer_4 = pad_outer.copy()
      pad_outer_1.translate(pad_w, pad_h)
      pad_outer_2.translate(-pad_w, pad_h)
      pad_outer_3.translate(pad_w, -pad_h)
      pad_outer_4.translate(-pad_w, -pad_h)
      pad_outer_1.rotate(angle)
      pad_outer_2.rotate(angle)
      pad_outer_3.rotate(angle)
      pad_outer_4.rotate(angle)
      let cell = new QGdstk.Cell('InLinePad_Pad')
      cell.add([pad_inter])
      cell.add([pad_inter_1])
      cell.add([pad_inter_2])
      cell.add([pad_inter_3])
      cell.add([pad_inter_4])
      cell.add([pad_outer])
      cell.add([pad_outer_1])
      cell.add([pad_outer_2])
      cell.add([pad_outer_3])
      cell.add([pad_outer_4])
      return cell
    }

    let cell_pad = pad((A1 = conf.A1), (layer_in = conf.layer_pad1), (datatype_in = 0), (A = conf.A), (layer_out = conf.layer_pad2), (datatype_out = 0), (angle = conf.angle_pad))

    let cell_spout_top = spout((A = conf.B), (A1 = conf.B1), (layer = conf.layer_spout1), (datatype = 0), (o_num = 1))
    let cell_spout_bottom = spout((A = conf.B), (A1 = conf.B1), (layer = conf.layer_spout2), (datatype = 0), (o_num = 2))

    let ref_spout_top = new QGdstk.Reference(
      (cell = cell_spout_top),
      (origin = [0, 0]),
      (rotation = conf.angle_spout1),
      (magnification = 1),
      (x_reflection = false),
      (columns = 1),
      (rows = 1),
      (spacing = null)
    )
    let ref_spout_bottom = new QGdstk.Reference(
      (cell = cell_spout_bottom),
      (origin = [0, 0]),
      (rotation = conf.angle_spout2),
      (magnification = 1),
      (x_reflection = false),
      (columns = 1),
      (rows = 1),
      (spacing = null)
    )
    let ref_pad = new QGdstk.Reference((cell = cell_pad), (origin = [0, 0]), (rotation = 0), (magnification = 1), (x_reflection = false), (columns = 1), (rows = 1), (spacing = null))

    let cell = new QGdstk.Cell('InLinePad')
    cell.add([ref_spout_top, ref_spout_bottom, ref_pad])
    return cell
  }

  AutoToolComponents.prototype.WBPad = function (conf) {
    let A = conf.A
    let A1 = conf.A1
    let B = conf.B
    let B1 = conf.B1
    let B2 = conf.B2
    let C = conf.C
    let C1 = conf.C1
    let layer = conf.layer
    let datatype = conf.datatype
    // path
    var path = new QGdstk.Curve([-C1 / 2, 0])
    path.commands([
      'l',
      -(C - C1) / 2,
      0,
      'l',
      -(A - C) / 2,
      B2,
      'l',
      0,
      B,
      'l',
      A,
      0,
      'l',
      0,
      -B,
      'l',
      -(A - C) / 2,
      -B2,
      'l',
      -(C - C1) / 2,
      0,
      'l',
      (A1 - C) / 2,
      B2,
      'l',
      0,
      B1,
      'l',
      -A1,
      0,
      'l',
      0,
      -B1,
    ])

    var pad = new QGdstk.Polygon(path.points(), (layer = layer), (datatype = datatype))
    var O1 = new QGdstk.Label('O1', [0, 0])
    // cell
    var cell = new QGdstk.Cell('WBPad')
    cell.add([pad, O1])
    return cell
  }
  AutoToolComponents.prototype.tinpadInpillar = function (conf) {
    let tinpad = null
    if (conf.tinpad.shape === 'Round') {
      tinpad = new QGdstk.ellipse(
        (center = [0, 0]),
        (radius = conf.tinpad.radius),
        (inner_radius = null),
        (initial_angle = 0),
        (final_angle = 0),
        (tolerance = 0.01),
        (layer = conf.tinpad.layer),
        (datatype = conf.tinpad.datatype)
      )
    }
    if (conf.tinpad.shape === 'Rectangle') {
      let w = conf.tinpad.radius
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      tinpad = new QGdstk.Polygon(points, 0, 0)
    }
    let inpillar = null
    if (conf.inpillar.shape === 'Round') {
      inpillar = new QGdstk.ellipse(
        (center = [0, 0]),
        (radius = conf.inpillar.radius),
        (inner_radius = null),
        (initial_angle = 0),
        (final_angle = 0),
        (tolerance = 0.01),
        (layer = conf.inpillar.layer),
        (datatype = conf.inpillar.datatype)
      )
    }
    if (conf.inpillar.shape === 'Rectangle') {
      let w = conf.inpillar.radius
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      inpillar = new QGdstk.Polygon(points, 0, 0)
    }

    let cell = new QGdstk.Cell('tinpad&inpillar')
    cell.add([tinpad])
    cell.add([inpillar])
    return cell
  }

  AutoToolComponents.prototype.ariBridge = function (size) {
    let A = size.A
    let A1 = size.A1
    let A2 = size.A2
    let A3 = size.A3
    let A4 = size.A4
    let laber_a = size.layer1
    let laber_b = size.layer2
    let datatype1 = size.datatype1
    let datatype2 = size.datatype2

    let points_1 = [
      [-A4 / 2, 0],
      [-A4 / 2, A],
      [A4 / 2, A],
      [A4 / 2, 0],
    ]
    let points_2 = [
      [-A1 / 2, (A - A3 * 2 - A2) / 2],
      [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3],
      [A1 / 2, (A - A3 * 2 - A2) / 2 + A3],
      [A1 / 2, (A - A3 * 2 - A2) / 2],
    ]
    let points_3 = [
      [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2],
      [-A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2],
      [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 * 2 + A2],
      [A1 / 2, (A - A3 * 2 - A2) / 2 + A3 + A2],
    ]
    let poly1 = new QGdstk.Polygon((points = points_1), (layer = laber_a), (datatype = datatype1))
    let poly2 = new QGdstk.Polygon((points = points_2), (layer = laber_b), (datatype = datatype2))
    let poly3 = new QGdstk.Polygon((points = points_3), (layer = laber_b), (datatype = datatype2))
    poly1.translate((dx = 0), (dy = -A / 2))
    poly2.translate((dx = 0), (dy = -A / 2))
    poly3.translate((dx = 0), (dy = -A / 2))

    let cell = new QGdstk.Cell('airbridge')
    cell.add([poly1])
    cell.add([poly2])
    cell.add([poly3])
    return cell
  }

  AutoToolComponents.prototype.waveGuide = function (size) {
    let points = size.points
    let out_width = size.b
    let inner_width = size.a
    let radius = size.R
    let layer = size.layer
    let datatype = size.datatype
    let outer = new QGdstk.FlexPath(
      (points = points),
      (width = out_width),
      (offset = 0),
      (joins = 'round'),
      (ends = 'flush'),
      (bend_radius = radius),
      (bend_function = null),
      (tolerance = 1e-2),
      (simple_path = false),
      (scale_width = true),
      (layer = layer),
      (datatype = datatype)
    )
    let inner = new QGdstk.FlexPath(
      (points = points),
      (width = inner_width),
      (offset = 0),
      (joins = 'round'),
      (ends = 'flush'),
      (bend_radius = radius),
      (bend_function = null),
      (tolerance = 1e-2),
      (simple_path = false),
      (scale_width = true),
      (layer = layer),
      (datatype = datatype)
    )
    let res = QGdstk.boolean((operand1 = outer), (operand2 = inner), (operation = 'not'), (precision = 1e-3), (layer = layer), (datatype = datatype))
    return res
  }

  AutoToolComponents.prototype.getLibrary = function (name, elements) {
    let lib = new QGdstk.Library(name, 1e-6, 1e-9)
    let cell = new QGdstk.Cell(name)
    cell.add(elements)
    lib.add(cell)
    return lib
  }

  AutoToolComponents.prototype.getLibraryByCell = function (name, cell) {
    let lib = new QGdstk.Library(name, 1e-6, 1e-9)
    lib.add(cell)
    return lib
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolComponents
  } else {
    window.AutoToolComponents = AutoToolComponents
  }
})()
