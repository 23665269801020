<template>
  <div>
    <el-dialog :title="$t('messages.toolCavity')" :visible.sync="CavityDialogVisible" width="680px" :before-close="handleClose" :modal="false">
      <div class="dialog-body__civity">
        <div class="civity-header">
          <!-- <div class="civity-header__item btn1">绘制多边形</div> -->
          <!-- <div class="civity-header__item btn2">放置起点</div> -->
          <!-- <div class="civity-header__item btn3">放置终点</div> -->
          <!-- <div class="civity-header__item btn4">参数修改</div> -->
          <el-button type="primary" @click="CavityChangePos">{{ $t('messages.toolCavityChangeStartEnd') }}</el-button>
          <el-button type="success" @click="CavityGenerate">{{ $t('messages.toolCavityAutoGen') }}</el-button>
        </div>
        <!-- <div class="civity-btn">
          <el-button type="sure" @click="CavityDialogOk">自动生成</el-button>
        </div> -->
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolStartPos') }}:</span>
          <span class="text xy">X:</span>
          <div style="display: inline-block; margin-right: 25px">
            <el-input-number :controls="false" v-model="Cavity.start_pos.x" disabled></el-input-number>
            &nbsp;{{ unit }}
          </div>
          <span class="text xy">Y:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.start_pos.y" disabled></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolEndPos') }}:</span>
          <span class="text xy">X:</span>
          <div style="display: inline-block; margin-right: 25px">
            <el-input-number :controls="false" v-model="Cavity.end_pos.x" disabled></el-input-number>
            &nbsp;{{ unit }}
          </div>
          <span class="text xy">Y:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.end_pos.y" disabled></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolLength') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.length" :min="100"></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolCavityMinRadius') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.min_radius" :min="10" :max="50"></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolCavityMaxRadius') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.max_radius" :min="50" :max="500"></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolCavityOuterWidth') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.pipe_out_width" :min="5" :max="24"></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolCavityInnerWidth') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.pipe_inner_width" :min="3" :max="12"></el-input-number>
            &nbsp;{{ unit }}
          </div>
        </div>
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolLayer') }}:</span>
          <div style="display: inline-block">
            <el-input-number :controls="false" v-model="Cavity.layer"></el-input-number>
            &nbsp;
          </div>
        </div>
        <!-- <div class="cavity-body-item">
          <span class="text name"> 多边形: </span>
        </div>
        <el-table class="t-table" :data="Cavity.polygon" ref="CavityPolygonTable" height="175px" width="489px"
          header-cell-class-name="table_header">
          <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
          <el-table-column label="点坐标" prop="distance" align="center">
            <template slot-scope="scope">
              <div class="cavity-table-item">
                <div style="display:inline-block;margin-right:25px;">
                  X：<el-input-number v-model="scope.row[0]" :controls="false"> </el-input-number> &nbsp;{{unit}}
                </div>
                <div style="display:inline-block;">
                  Y：<el-input-number v-model="scope.row[1]" :controls="false"> </el-input-number> &nbsp;{{unit}}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table> -->
        <div class="cavity-body-item">
          <span class="text name">{{ $t('messages.toolCavityResult') }}:</span>
        </div>
        <!-- <span> 生成结果: </span> -->
        <!-- <div class="cavity-body-item">
          <span class="text name"> 生成结果: </span>
          <el-table class="t-table" :data="AirBridgeTableData" ref="AirBridgeTable" height="175px" width="489px"
            header-cell-class-name="table_header">
            <el-table-column label="长度" width="153" align="center"></el-table-column>
            <el-table-column label="半径" width="153" align="center"></el-table-column>
            <el-table-column label="拐角数" width="133" align="center">
              <span>xsfsdfsd</span>
            </el-table-column>
          </el-table>
        </div> -->
        <div class="Ann_image">
          <div class="Ann_image__body">
            <canvas class="ann-image" id="CavityCtx" width="270" height="270"></canvas>
          </div>
        </div>
        <div class="cavity-body-item">
          <!-- <span class="text name"> 生成结果: </span> -->
          <span class="text" style="margin-right: 80px">{{ $t('messages.toolLength') }}: {{ Cavity.result.length }}</span>
          <span class="text" style="margin-right: 80px">{{ $t('messages.toolRadius') }}: {{ Cavity.result.radius }}</span>
          <span class="text" style="margin-right: 20px">{{ $t('messages.toolCavityInflectNumber') }}: {{ Cavity.result.inflects }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="CavityDialogOk">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="CavityDialogCancel" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from '../../common/bus'
import i18n from '../../../common/lang/i18n'
const ToolComponents = require('../../../utils/tools/components')
const AutoToolCanvasComponent = require('../../../utils/tools/canvas_component')
const AutoToolSqidZline = require('../../../utils/tools/squid_z')
// const AutoToolCavity = require('../../../utils/tools/cavity')
const AutoToolCavity = require('../../../utils/tools/cavity')
const CavityDefault = {
  polygon: [
    [0, 0],
    [500, 0],
    [500, 1000],
    [0, 1000],
    [0, 0],
  ],
  length: 5000,
  start_pos: { x: 500, y: 800 },
  end_pos: { x: 250, y: 0 },
  min_radius: 10,
  max_radius: 50,
  pipe_out_width: 10,
  pipe_inner_width: 5,
  layer: 1,
  datatype: 0,
  result: {
    length: 0,
    radius: 0,
    inflects: 0,
    add_cells: [],
    add_references: [],
  },
}
export default {
  data() {
    return {
      unit: 'um',
      CavityDialogVisible: false,
      civityTableData: [],
      Cavity: CavityDefault,
      all_layer: [],
      canvas2D: null,
    }
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent()
    }, 1000)
  },
  methods: {
    handleClose(done) {
      this.canvas2D.clear()
      if (this.CavityDialogVisible) {
        this.civityTableData = []
      }
      done()
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach(item => {
          item.selected = true
        })
      } else {
        this.$refs[table_name].data.forEach(item => {
          item.selected = !item.selected
        })
      }
    },
    CavityDefault() {
      this.Cavity = CavityDefault
    },
    CavityGenerate() {
      let conf = JSON.parse(JSON.stringify(this.Cavity))
      conf.start_pos = [conf.start_pos.x, conf.start_pos.y]
      conf.end_pos = [conf.end_pos.x, conf.end_pos.y]
      conf.fileLayerList = this.fileLayerList
      conf.fileLayerDict = this.fileLayerDict
      this.canvas2D.clear()
      let c = new AutoToolCavity()
      // let data = c.run(conf)
      c.run(conf).then(data => {
        if (data.cavity) {
          this.Cavity.result.length = data.cavity.length
          this.Cavity.result.radius = data.cavity.radius
          this.Cavity.result.inflects = data.cavity.corner
          this.Cavity.result.add_cells = data.cavity.add_cells
          this.Cavity.result.add_references = data.cavity.add_references
          let cell = data.cavity.cell
          let lib = new QGdstk.Library(cell.name, 1e-6, 1e-9)
          lib.add(cell)
          setTimeout(() => {
            this.canvas2D.render(document.getElementById('CavityCtx'), lib, cell.name)
          }, 200)
          this.$message({
            message: i18n.t('messages.toolCavityGenerateSuccess'),
            type: 'success',
          })
        } else {
          this.$message({
            message: `${data.err}~`,
            type: 'warning',
          })
        }
      })
      // .catch(err => {
      //   console.error(err)
      // })
    },
    CavityChangePos() {
      let start_pos = JSON.parse(JSON.stringify(this.Cavity.start_pos))
      let end_pos = JSON.parse(JSON.stringify(this.Cavity.end_pos))
      this.Cavity.start_pos = end_pos
      this.Cavity.end_pos = start_pos
    },
    CavityDialogOk() {
      this.canvas2D.clear()
      let res = {
        add_cells: this.Cavity.result.add_cells,
        add_references: this.Cavity.result.add_references,
      }
      bus.$emit('TOOL_CAVITY_RUN', res)
      this.CavityDialogVisible = false
    },
    CavityDialogCancel() {
      this.canvas2D.clear()
      this.CavityDialogVisible = false
    },

    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList
      this.cellLayerList = []
      cellLayerList.forEach(item => {
        if (item) {
          this.cellLayerList.push({
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          })
        }
      })

      this.fileLayerList = this.$store.state.fileLayerList
      this.fileLayerDict = {}
      this.fileLayerList.forEach(item => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item
        }
      })
    },
  },
  mounted() {
    bus.$on('TOOL_CAVITY_SELECT', data => {
      let conf = JSON.parse(JSON.stringify(CavityDefault))
      conf.polygon = data.polygon
      conf.polygon.push(conf.polygon[0])
      conf.start_pos = { x: data.points[0][0], y: data.points[0][1] }
      conf.end_pos = { x: data.points[1][0], y: data.points[1][1] }
      this.Cavity = conf
      this.CavityDialogVisible = true
      this.cellLayerInit()
    })
  },
  watch: {},
}
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
</style>
