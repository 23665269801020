<template>
  <div>
    <el-dialog :title="$t('messages.toolSquidLine')" :visible.sync="squidZlineDialogVisible" width="1108px"
      :before-close="handleClose" :modal="false" @opened="SquidZlineDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{ active: selectSquidZlineRule, 'Header-tabs__item': true }"
                @click="SquidZlineDialogSelect('rule')">{{ $t('messages.toolRules') }}</div>
              <div :class="{ active: selectSquidZlineSize, 'Header-tabs__item': true }"
                @click="SquidZlineDialogSelect('size')">{{ $t('messages.toolSize') }}</div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectSquidZlineRule">
            <div class="tool-main__normal">
              <div class="main-group">
                <div class="main-label">{{ $t('messages.toolSquidLineLen') }}:</div>
                <el-input-number :controls="false" v-model="SquidZline.rule.line_length" disabled></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">(d1){{ $t('messages.toolSquid2StartDistance') }}:</div>
                <el-input-number :controls="false" v-model="SquidZline.rule.d1"
                  @change="SquidZlineSizeUpdate"></el-input-number>
                &nbsp;{{ unit }}
                <el-button type="sure" size="mini"
                  @click="SqiudZlineSetPos('start')">{{ $t('messages.toolStartPos') }}</el-button>
                <el-button type="sure" size="mini"
                  @click="SqiudZlineSetPos('end')">{{ $t('messages.toolEndPos') }}</el-button>
                <el-button type="sure" size="mini"
                  @click="SqiudZlineSetPos('center')">{{ $t('messages.toolCenterPos') }}</el-button>
              </div>
              <div class="main-group">
                <div class="main-label">{{ $t('messages.toolSquidDirect') }}:</div>
                <el-select v-model="SquidZline.rule.direction" :placeholder="$t('messages.toolPleaseSelect')"
                  @change="SquidZlineSizeUpdate" size="mini">
                  <el-option v-for="item in SquidZlineDirectionOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="main-group">
                <div class="main-label">{{ $t('messages.toolSquidType') }}:</div>
                <el-select v-model="SquidZline.rule.type" :placeholder="$t('messages.toolPleaseSelect')"
                  @change="SquidZlineSizeUpdate" size="mini">
                  <el-option v-for="item in SquidZlineTypeOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="main-group">
                <div class="main-label">(d2){{ $t('messages.toolSquid2ZlineDistance') }}:</div>
                <el-input-number :controls="false" v-model="SquidZline.rule.d2"
                  @change="SquidZlineSizeUpdate"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
          </div>
          <div class="normal-rule" v-if="selectSquidZlineSize">
            <div class="normal-rule-item" v-for="k in Object.keys(SquidZline.size)" :key="k"
              v-if="SquidZline.size[k].change">
              <div v-if="k !== 'zline_layer' && k !== 'squid_layer'">
                <div class="rule-item__label">{{ SquidZline.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-input-number :controls="false" v-model="SquidZline.size[k].value" @change="SquidZlineSizeUpdate"
                    :min="SquidZline.size[k].min" :max="SquidZline.size[k].max"></el-input-number>
                  &nbsp;{{ SquidZline.size[k].unit }}
                </div>
              </div>

              <div v-if="k === 'zline_layer' || k === 'squid_layer'">
                <div class="rule-item__label">{{ SquidZline.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-select v-model="SquidZline.size[k].value" :placeholder="$t('messages.toolWaveGuidePleaseSelect')"
                    @change="SquidZlineSizeUpdate">
                    <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                      :value="item.layerNumber">
                      <div class="layerOption">
                        <div class="layerImgBox">
                          <span style="float: left">{{ item.layerNumber }}</span>
                          <img :src="item.image" alt="" class="layerImage" />
                        </div>
                        <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img alt="" v-if="selectSquidZlineRule" class="ann-image"
              src="../../../assets/icons/tools/SQUID和Z线的自动布局-规则.svg" />
            <img alt="" v-if="selectSquidZlineSize" class="ann-image"
              src="../../../assets/icons/tools/squid-z-line-size.svg" />
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <canvas class="ann-image" id="SquidZlineCtx" width="270" height="270"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="SqiudZlineDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="squidZlineDialogVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "../../common/bus";
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");
// const AutoToolSqidZline = require('../../../utils/tools/squid_z')
import { AutoToolSqidZline } from "../../../utils/tools/squid_z";
const SquidZlineTypeOptionsDefault = [
  {
    value: "SQUID-1",
    label: "SQUID-1",
  },
  {
    value: "SQUID-2",
    label: "SQUID-2",
  },
  {
    value: "SQUID-3",
    label: "SQUID-3",
  },
  {
    value: "SQUID-4",
    label: "SQUID-4",
  },
  {
    value: "SQUID-5",
    label: "SQUID-5",
  },
];
const SquidZlineDirectionOptionsDefault = [
  {
    value: "left",
    label: "left",
  },
  {
    value: "right",
    label: "right",
  },
];
const SquidZlineDefault = {
  rule: {
    line_length: 0,
    line: [],
    direction: "left",
    type: "SQUID-1",
    d1: 10,
    d2: 10,
  },
  size: {
    b: { name: "b", value: 40, min: 20, max: 80, change: true, unit: "um" },
    c: { name: "c", value: 4, min: 2, max: 8, change: true, unit: "um" },
    d: { name: "d", value: 4, min: 2, max: 10, change: true, unit: "um" },
    e: { name: "e", value: 2, min: 1, max: 5, change: true, unit: "um" },
    f: { name: "f", value: 3, min: 2, max: 8, change: true, unit: "um" },
    g: { name: "g", value: 7, min: 3, max: 1, change: true, unit: "um" },
    zline_layer: {
      name: "zline-layer",
      value: 1,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    squid_layer: {
      name: "squid-layer",
      value: 1,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    datatype: {
      name: "datatype",
      value: 0,
      min: 0,
      max: 255,
      change: false,
      unit: "",
    },
  },
};
export default {
  data() {
    return {
      unit: "um",
      SquidZlineTypeOptions: SquidZlineTypeOptionsDefault,
      SquidZlineDirectionOptions: SquidZlineDirectionOptionsDefault,
      SquidZline: SquidZlineDefault,
      selectSquidZlineRule: true,
      selectSquidZlineSize: false,
      squidZlineDialogVisible: false,
      SquidZlineTableData: [],
      all_layer: [],
      canvas2D: new AutoToolCanvasComponent(),
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = !item.selected;
        });
      }
    },
    SquidZlineDialogOpened() {
      this.SqiudZlineDialogSetDefalult();
      this.SquidZlineSizeUpdate();
      this.SquidZline.size.squid_layer.value =
        this.fileLayerList[0].layerNumber;
      this.SquidZline.size.zline_layer.value =
        this.fileLayerList[0].layerNumber;
    },
    SquidZlineDialogSelect(type) {
      if (type == "rule") {
        this.selectSquidZlineRule = true;
        this.selectSquidZlineSize = false;
      }
      if (type == "size") {
        this.selectSquidZlineRule = false;
        this.selectSquidZlineSize = true;
      }
      this.SquidZlineSizeUpdate();
    },
    SqiudZlineSetPos(data) {
      if (data == "start") {
        this.SquidZline.rule.d1 = 0;
      }
      if (data == "end") {
        this.SquidZline.rule.d1 = this.SquidZline.rule.line_length;
      }
      if (data == "center") {
        this.SquidZline.rule.d1 = this.SquidZline.rule.line_length / 2;
      }
      this.SquidZlineSizeUpdate();
    },
    SquidZlineSizeUpdate() {
      let size = {};
      for (let k in this.SquidZline.size) {
        size[k] = this.SquidZline.size[k].value;
      }
      let conf = {
        line: this.SquidZline.rule.line,
        type: this.SquidZline.rule.type,
        direction: this.SquidZline.rule.direction,
        d1: this.SquidZline.rule.d1,
        d2: this.SquidZline.rule.d2,
        size: size,
        fileLayerList: this.fileLayerList,
      };

      let s = new AutoToolSqidZline();
      let res = s.generate(conf);

      if (res) {
        let element = res.cell_2d;
        let lib = new QGdstk.Library(element.name, 1e-6, 1e-9);
        lib.add(element);
        lib.add(element.dependencies(true));
        this.canvas2D.render(
          document.getElementById("SquidZlineCtx"),
          lib,
          element.name
        );
        return {
          add_cells: res.add_cells,
          add_references: res.add_references,
        };
      }
    },
    SqiudZlineDialogOK() {
      let res = this.SquidZlineSizeUpdate();
      this.squidZlineDialogVisible = false;
      bus.$emit("TOOL_SQUID_ZLINE_RUN", res);
    },
    SqiudZlineDialogSetDefalult() {
      this.SquidZline = JSON.parse(JSON.stringify(SquidZlineDefault));
      this.SquidZlineTypeOptions = JSON.parse(
        JSON.stringify(SquidZlineTypeOptionsDefault)
      );
      this.SquidZlineDirectionOptions = JSON.parse(
        JSON.stringify(SquidZlineDirectionOptionsDefault)
      );
    },
    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;

      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
          });
        }
      });
      this.fileLayerDict = {};
      this.fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item;
        }
      });
    },
  },
  mounted() {
    bus.$on("TOOL_SQUID_ZLINE_SELECT", (data) => {
      this.cellLayerInit();
      this.SquidZline = SquidZlineDefault;
      let line = data;
      this.SquidZline.rule.line = line;
      let line_length = Math.sqrt(
        Math.pow(line[0][0] - line[1][0], 2) +
          Math.pow(line[0][1] - line[1][1], 2)
      );
      this.SquidZline.rule.line_length = line_length.toFixed(3);
      this.squidZlineDialogVisible = true;
    });
  },
  watch: {},
};
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
        /deep/.el-input__icon {
          line-height: 28px;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}

.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
