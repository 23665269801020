<template>
  <div>
    <el-dialog :title="$t('messages.toolCheckLine')" :visible.sync="CheckLineDialogVisible" width="1108px"
      :before-close="handleClose" :modal="false" @opened="CheckLineDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs"></div>
          </div>
          <div class="tool-main__body">
            <div class="tool-main__item">
              <el-table class="t-table" :data="cellLayerList" ref="CheckLineTableData" height="175px"
                header-cell-class-name="table_header">
                <el-table-column :label="$t('messages.toolNumber')" prop="layerNumber"></el-table-column>
                <el-table-column :label="$t('messages.toolLayer')" prop="layerName"></el-table-column>
                <!-- <el-table-column label="间隔(um)" prop="distance" width="190" align="center">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.distance"></el-input-number>
                  </template>
                </el-table-column> -->
                <el-table-column :label="$t('messages.toolCheckLineCheck')" width="150" align="center">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.selected"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table_select">
                <div class="table_select_left">{{ $t('messages.toolAll') }}</div>
                <div class="table_select_right">
                  <button class="table_select__btn"
                    @click="toggleSelection('CheckLineTableData', true)">{{ $t('messages.toolSelectAll') }}</button>
                  <button class="table_select__btn"
                    @click="toggleSelection('CheckLineTableData', false)">{{ $t('messages.toolSelected') }}</button>
                </div>
              </div>
            </div>
            <div class="tool-main__normal">
              <div class="main-group">
                <div class="main-label">d（{{ $t('messages.toolCheckLineMinDistance') }}）:</div>
                <el-input-number v-model="CheckLine.rule.d" :controls="false" :min="0.1"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img alt="" class="ann-image" src="../../../assets/icons/tools/智能连线-规则.svg" />
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image">
            <div class="Ann_image__header">
              <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
            </div>
            <div class="Ann_image__body">
              <!-- <span></span> -->
              <!-- <canvas class="ann-image" id="TiNPadInPillarCtx" width="270" height="270"></canvas> -->
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="CheckLineDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="CheckLineDialogVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";
const ToolComponents = require("../../../utils/tools/components");
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");
const AutoToolSqidZline = require("../../../utils/tools/squid_z");
const AutoToolCavity = require("../../../utils/tools/cavity");
const CheckLineDefault = {
  rule: {
    d: 10,
  },
};
export default {
  data() {
    return {
      unit: "um",
      tc: new ToolComponents(),
      CheckLineTableData: [],
      CheckLineDialogVisible: false,
      CheckLine: CheckLineDefault,
      cellLayerList: [],
      fileLayerList: [],
      canvas2D: null,
    };
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();
    }, 1000);
  },
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = !item.selected;
        });
      }
    },
    CheckLineDialogOpened() {
      this.CheckLineSetDefault();
    },
    CheckLineDialogOK() {
      let layer_data = [];
      this.cellLayerList.forEach((item) => {
        if (item.selected) {
          layer_data.push({
            layer: item.layerNumber,
            distance: this.CheckLine.rule.d,
            datatype: 0,
          });
        }
      });
      this.CheckLineDialogVisible = false;
      this.$message({
        message: i18n.t("messages.toolTipCheckLineWait"),
        type: "warning",
      });
      setTimeout(() => {
        bus.$emit("TOOL_CHECK_LINE_RUN", layer_data);
      }, 500);
    },
    CheckLineSetDefault() {
      this.CheckLine = JSON.parse(JSON.stringify(CheckLineDefault));
    },
    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;
      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });
      this.cellLayerList = this.cellLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );
      this.fileLayerList = this.fileLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );
    },
  },
  mounted() {
    bus.$on("TOOL_CHECK_LINE", (data) => {
      bus.$emit("updateCellLayerList", null);
      this.cellLayerInit();
      this.CheckLineDialogVisible = true;
    });
  },
  watch: {},
};
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
</style>
