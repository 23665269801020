<template>
  <div>
    <el-dialog title="WBPad" :visible.sync="WBPadDialogVisible" width="1108px" :before-close="handleClose"
      :modal="false" @opened="WBPadDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{ active: selectWBPadRule, 'Header-tabs__item': true }" @click="WBPadDialogSelect('rule')">
                {{ $t('messages.toolRules') }}
              </div>
              <div :class="{ active: selectWBPadSize, 'Header-tabs__item': true }" @click="WBPadDialogSelect('size')">
                {{ $t('messages.toolSize') }}
              </div>
              <div :class="{ active: selectWBPadText, 'Header-tabs__item': true }" @click="WBPadDialogSelect('text')">
                {{ $t('messages.toolText') }}
              </div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectWBPadRule">
            <div class="tool-main__title">
              <div class="title-font">{{ $t('messages.toolWBPadBounds') }}:</div>
            </div>
            <div class="tool-main__item">
              <el-table class="t-table" :data="WBPad.rule.bound" ref="WBPadBoundTable" height="175px"
                header-cell-class-name="table_header">
                <el-table-column type="index" :label="$t('messages.toolNumber')" width="50"
                  align="center"></el-table-column>
                <el-table-column :label="$t('messages.toolCoord')" prop="distance" align="center">
                  <template slot-scope="scope">
                    <div style="display: inline-block; margin-right: 25px">
                      X：
                      <el-input-number v-model="scope.row[0]" :controls="false"></el-input-number>
                      &nbsp;{{ unit }}
                    </div>
                    <div style="display: inline-block">
                      Y：
                      <el-input-number v-model="scope.row[1]" :controls="false"></el-input-number>
                      &nbsp;{{ unit }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="tool-main__normal">
              <div class="main-group">
                <div class="main-label">a（{{ $t('messages.toolWBPad2Bounds') }}）:</div>
                <el-input-number :controls="false" v-model="WBPad.rule.a"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">b（{{ $t('messages.toolWBPad2Bounds') }}）:</div>
                <el-input-number :controls="false" v-model="WBPad.rule.b"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">c（{{ $t('messages.toolWBPadMinDistance') }}）:</div>
                <el-input-number :controls="false" v-model="WBPad.rule.c"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
          </div>
          <div class="normal-rule" v-if="selectWBPadSize">
            <div class="normal-rule-item" v-for="k in Object.keys(WBPad.size)" :key="k" v-if="WBPad.size[k].change">
              <div v-if="k !== 'layer'">
                <div class="rule-item__label">{{ WBPad.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-input-number :controls="false" v-model="WBPad.size[k].value" @change="WBPadSizeUpdate"
                    :min="WBPad.size[k].min" :max="WBPad.size[k].max"></el-input-number>
                  &nbsp;{{ WBPad.size[k].unit }}
                </div>
              </div>
              <div v-if="k === 'layer'">
                <div class="rule-item__label">{{ WBPad.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-select v-model="WBPad.size[k].value" :placeholder="$t('messages.toolWaveGuidePleaseSelect')"
                    @change="WBPadSizeUpdate">
                    <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                      :value="item.layerNumber">
                      <div class="layerOption">
                        <div class="layerImgBox">
                          <span style="float: left">{{ item.layerNumber }}</span>
                          <img :src="item.image" alt="" class="layerImage" />
                        </div>
                        <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <!-- <div class="normal-rule-item" v-for="k in Object.keys(WBPad.size)" :key="k" v-if="WBPad.size[k].change">
              <div class="rule-item__label">{{ WBPad.size[k].name }}：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="WBPad.size[k].value" @change="WBPadSizeUpdate" :min="WBPad.size[k].min" :max="WBPad.size[k].max"></el-input-number>
                &nbsp;{{ WBPad.size[k].unit }}
              </div>
            </div> -->
          </div>
          <div class="tab-text" v-if="selectWBPadText">
            <div class="tab-text-item">
              <div class="tab-text-item-label">X ({{ $t('messages.toolWBPadTextX') }})：</div>
              <div class="tab-text-item-input">
                <el-input-number :controls="false" v-model="WBPad.text.x" @change="WBPadSizeUpdate"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="tab-text-item">
              <div class="tab-text-item-label">Y ({{ $t('messages.toolWBPadTextY') }})：</div>
              <div class="tab-text-item-input">
                <el-input-number :controls="false" v-model="WBPad.text.y" @change="WBPadSizeUpdate"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="tab-text-item">
              <div class="tab-text-item-label">{{ $t('messages.toolWBPadText') }}：</div>
              <div class="tab-text-item-input">
                <el-input-number :controls="false" @change="WBPadSizeUpdate" :disabled="true"
                  :placeholder="$t('messages.toolWBPadTextNumber')"></el-input-number>
              </div>
            </div>
            <div class="tab-text-item">
              <div class="tab-text-item-label">{{ $t('messages.toolWBPadTextSize') }}：</div>
              <div class="tab-text-item-input">
                <el-input-number :controls="false" v-model="WBPad.text.size"
                  @change="WBPadSizeUpdate"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
            <div class="tab-text-item">
              <div class="tab-text-item-label">{{ $t('messages.toolWBPadTextLayer') }}：</div>
              <el-select v-model="WBPad.text.layer" placeholder="请选择" @change="WBPadSizeUpdate">
                <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                  :value="item.layerNumber">
                  <div class="layerOption">
                    <div class="layerImgBox">
                      <span style="float: left">{{ item.layerNumber }}</span>
                      <img :src="item.image" alt="" class="layerImage" />
                    </div>
                    <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                  </div>
                </el-option>
              </el-select>
              <!-- <div class="tab-text-item-input">
                <el-input-number :controls="false" v-model="WBPad.text.layer" @change="WBPadSizeUpdate"></el-input-number>
              </div> -->
            </div>
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img v-if="selectWBPadRule" class="ann-image" src="../../../assets/icons/tools/WBPad-规则.svg" />
            <img v-if="selectWBPadSize" class="ann-image" src="../../../assets/icons/tools/WBPad-工具.svg" />
            <img v-if="selectWBPadText" class="ann-image" src="../../../assets/icons/tools/WBPad-线标.svg" />
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <canvas class="ann-image" id="WBPadCtx" width="270" height="270"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="WBPadDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="WBPadDialogVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";
// const ToolComponents = require("../../../utils/tools/components");
// import { AutoToolWBPad } from '../../../utils/tools/index'
const AutoToolWBPad = require("../../../utils/tools/wbpad");
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");

export default {
  data() {
    return {
      WBPad: {
        rule: {
          a: 573,
          b: 973,
          c: 500,
          bound: [
            [0, 0],
            [33000, 33000],
          ],
        },
        text: {
          x: 50,
          y: 40,
          size: 50,
          // layer: 1001,
          layer: 0,
        },
        size: {
          A: {
            name: "A",
            value: 456,
            min: 300,
            max: 600,
            change: true,
            unit: "um",
          },
          A1: {
            name: "A1",
            value: 250,
            min: 100,
            max: 500,
            change: true,
            unit: "um",
          },
          B: {
            name: "B",
            value: 323,
            min: 200,
            max: 500,
            change: true,
            unit: "um",
          },
          B1: {
            name: "B1",
            value: 250,
            min: 150,
            max: 400,
            change: true,
            unit: "um",
          },
          B2: {
            name: "B2",
            value: 100,
            min: 50,
            max: 200,
            change: true,
            unit: "um",
          },
          C: { name: "C", value: 8, min: 8, max: 20, change: true, unit: "um" },
          C1: {
            name: "C1",
            value: 4,
            min: 4,
            max: 10,
            change: true,
            unit: "um",
          },
          layer: {
            name: "Layer",
            value: 1,
            min: 0,
            max: 255,
            change: true,
            unit: "",
          },
          datatype: {
            name: "Datatype",
            value: 0,
            min: 0,
            max: 255,
            change: false,
            unit: "",
          },
        },
      },
      size_layer: 0,
      bus_type: "",
      // tc: new AutoToolWBPad(),
      unit: "um",
      WBPadDialogVisible: false,
      selectWBPadRule: true,
      selectWBPadSize: false,
      selectWBPadText: false,
      WBPadTableData: [],

      fileLayerList: [],
      cellLayerList: [],

      canvas2D: null,
    };
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();
    }, 1000);
  },
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          // ;
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          // ;
          item.selected = !item.selected;
        });
      }
    },
    addWBPadBoundPoint() {
      //添加TiNPad放置区域
      let params = [0, 0];
      this.WBPad.rule.bound.push(params);
    },
    deleteWBPadBoundPoint(index, row) {
      this.WBPad.rule.bound.splice(index, 1);
    },
    WBPadDialogOpened() {
      // this.WBPadSetDefault()
      this.WBPadSizeUpdate();
      this.WBPad.size.layer.value = this.fileLayerList[0].layerNumber;
      this.WBPad.text.layer = this.fileLayerList[0].layerNumber;
    },
    WBPadDialogSelect(type) {
      this.selectWBPadRule = false;
      this.selectWBPadSize = false;
      this.selectWBPadText = false;
      if (type == "rule") {
        this.selectWBPadRule = true;
      }
      if (type == "size") {
        this.selectWBPadSize = true;
      }
      if (type == "text") {
        this.selectWBPadText = true;
      }
      this.WBPadSizeUpdate();
    },
    WBPadSetDefault() {
      this.WBPad = JSON.parse(JSON.stringify(WBPadDefault));
    },
    WBPadSizeUpdate() {
      let size = {};
      for (let k in this.WBPad.size) {
        size[k] = this.WBPad.size[k].value;
      }
      let tc = new AutoToolWBPad();
      let element = tc.WBPadCell(
        size.layer,
        size.datatype,
        size.A,
        size.A1,
        size.B,
        size.B1,
        size.B2,
        size.C,
        size.C1
      );
      let lib = new QGdstk.Library(element.name, 1e-6, 1e-9);
      lib.add(element);
      this.canvas2D.render(
        document.getElementById("WBPadCtx"),
        lib,
        element.name
      );
    },
    WBPadDialogOK() {
      bus.$emit("WBPadLayerUpdate", [1002]);
    },

    fileLayerUpdate() {
      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });
      this.fileLayerList = this.fileLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );
      this.fileLayerDict = {};
      this.fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item;
        }
      });
    },

    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;

      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });
      this.fileLayerDict = {};
      this.fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item;
        }
      });

      // // 检测wbpad图层是否存在
      // if (!this.fileLayerDict[1002]) {

      //   bus.$emit('updateFileLayers', [1002])
      // }
    },
  },
  mounted() {
    bus.$on("TOOL_WBPAD", (data) => {
      this.cellLayerInit();
      this.WBPadDialogVisible = true;
      this.bus_type = "TOOL_WBPAD";
    });
    bus.$on("TOOL_WBPAD_BIG", (data) => {
      this.cellLayerInit();
      this.WBPadDialogVisible = true;
      this.bus_type = "TOOL_WBPAD_BIG";
    });

    bus.$on("WBPadLayerUpdateComplete", (data) => {
      this.fileLayerUpdate();
      let size = {};
      for (let k in this.WBPad.size) {
        size[k] = this.WBPad.size[k].value;
      }

      let conf = {
        base_bounds: this.WBPad.rule.bound,
        a: this.WBPad.rule.a,
        b: this.WBPad.rule.b,
        c: this.WBPad.rule.c,
        size: size,
        text: this.WBPad.text,
        fileLayerList: this.fileLayerList,
        fileLayerDict: this.fileLayerDict,
      };

      this.WBPadDialogVisible = false;
      if (this.bus_type == "TOOL_WBPAD") {
        this.$message({
          message: i18n.t("messages.toolTipWBPadPlaceWait"),
          type: "warning",
        });
        setTimeout(() => {
          bus.$emit("TOOL_WBPAD_RUN", conf);
        }, 500);
      }

      if (this.bus_type == "TOOL_WBPAD_BIG") {
        bus.$emit("TOOL_WBPAD_BIG_RUN", conf);
      }
    });
  },
  watch: {},
};
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
        /deep/.el-input__icon {
          line-height: 28px;
        }
      }
    }
  }

  .tab-text {
    font-size: 12px;
    .tab-text-item {
      padding: 16px;
      .tab-text-item-label {
        width: calc(100% - 200px);
        display: inline-block;
      }
      .tab-text-item-input {
        // width: calc(100% - 254px);
        width: 200px;
        display: inline-block;
        /deep/ .el-input-number {
          width: 159px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 159px;
          height: 28px;
        }
      }
      /deep/ .el-input__inner {
        width: 159px;
        height: 28px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        text-align: left;
        display: inline-block;
        padding-left: 10px;
      }
      /deep/.el-input__icon {
        line-height: 28px;
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}

.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
