<template>
  <div>
    <el-dialog :title="$t('messages.toolWaferLayout')" :visible.sync="WaferLayoutDialogVisible" width="608px"
      :before-close="handleClose" :modal="false" @opened="WaferDialogOpened">
      <div class="dialog-body">
        <div class="settings">
          <div class="tab settings__cell">
            <div class="tab__header">
              <div style="padding: 6px">{{ $t('messages.toolWaferFillArea') }}</div>
            </div>
            <div class="tab__body">
              <div class="row">
                <div style="padding: 10px">{{ $t('messages.toolWaferRadius') }}:</div>
                <div>
                  <el-input-number :controls="false" v-model="data.wafer.radius"></el-input-number>
                  um
                </div>
              </div>
              <div class="row">
                <div style="padding: 10px">{{ $t('messages.toolWaferCenterPos') }}:</div>
                <div class="row" style="margin-right: 26px">
                  <div style="padding: 10px">X:</div>
                  <el-input-number style="width: 100px" :controls="false"
                    v-model="data.wafer.center.x"></el-input-number>
                  <div style="padding: 10px">Y:</div>
                  <el-input-number style="width: 100px" :controls="false"
                    v-model="data.wafer.center.y"></el-input-number>
                </div>
              </div>
              <div class="row">
                <div style="padding: 10px">{{ $t('messages.toolWaferLayer') }}:</div>

                <el-select style="padding-top: 10px; margin-right: 26px" v-model="data.wafer.layer" filterable
                  :placeholder="$t('messages.toolJJpleaseSelect')">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
              <div class="row">
                <div style="padding: 10px">{{ $t('messages.toolWaferEdgeSpace') }}:</div>
                <div>
                  <el-input-number :controls="false" v-model="data.wafer.padding"></el-input-number>
                  um
                </div>
              </div>
            </div>
          </div>

          <div class="tab settings__axis">
            <div class="tab__header">
              <div style="padding: 6px">{{ $t('messages.toolWaferFillCell') }}</div>
            </div>
            <div class="tab__body">
              <div class="row">
                <div style="padding: 10px">cell:</div>
                <!-- <el-cascader class="selectManyClass must-left" size="middle" style="width: 82%" :options="TeamOptions" v-model="selectTeamProject">
                  <template slot-scope="{ node, data }">
                    <div @click="getTeamProjectData(node, data)">
                      <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="@/assets/icons/personnel/团队名.svg" />
                      {{ data.label }}
                    </div>
                  </template>
                </el-cascader> -->
                <el-select style="padding-top: 10px; margin-right: 26px" v-model="data.cell.name" disabled filterable
                  :placeholder="$t('messages.toolJJpleaseSelect')">
                  <el-option v-for="item in cellOptions" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="row">
                <div style="padding: 10px">{{ $t('messages.toolWaferCellSpace') }}:</div>
                <div>
                  <el-input-number :controls="false" v-model="data.cell.space"></el-input-number>
                  um
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="WaferDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="WaferLayoutDialogVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";
import { AutoToolWaferLayout } from "@/utils/tools/wafer_layout_faster";

const WaferDefault = {
  wafer: {
    radius: 100,
    center: { x: 0, y: 0 },
    layer: 0,
    padding: 5,
  },
  cell: {
    name: "cell1",
    object: null,
    space: 10,
  },
};
export default {
  data() {
    return {
      unit: "um",
      selectTeamProject: null,
      layerOptions: [
        { value: 0, label: "图层0" },
        { value: 1, label: "图层1" },
        { value: 2, label: "图层2" },
      ],
      cellOptions: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
      ],

      fileLayerList: [],
      cellLayerList: [],

      WaferLayoutDialogVisible: false,
      data: WaferDefault,

      TeamOptions: [],

      all_layer: [],
      canvas2D: null,
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      done();
    },

    WaferDialogOpened() {
      this.WaferSetDefault();
      this.WaferSizeUpdate();
    },

    WaferDialogSelect(type) {
      this.WaferSizeUpdate();
    },

    WaferSizeUpdate() {},

    WaferDialogOK() {
      this.WaferLayoutDialogVisible = false;

      let conf = {
        wafer: this.data.wafer,
        cell: this.data.cell,
        fileLayerList: this.fileLayerList,
        fileLayerDict: this.fileLayerDict,
      };

      this.$message({
        message: i18n.t("messages.toolWaferPlaceWarn"),
        type: "warning",
      });

      setTimeout(() => {
        let tool = new AutoToolWaferLayout();
        try {
          let data = tool.run(conf);
          bus.$emit("TOOL_WAFER_LAYOUT_RUN", data);
        } catch (error) {
          this.$message({
            message: i18n.t(error),
            type: "error",
          });
        }
      }, 500);
    },

    WaferSetDefault() {
      this.Wafer = JSON.parse(JSON.stringify(WaferDefault));
    },
    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;
      let fileLayerList = this.$store.state.fileLayerList;
      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
          });
        }
      });

      this.fileLayerDict = {};
      this.fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item;
        }
      });
      this.data.wafer.layer = this.fileLayerList[0].layerNumber;
    },
  },
  mounted() {
    bus.$on("TOOL_WAFER_LAYOUT", (data) => {
      this.data.cell.object = this.$store.state.activeCell;
      this.data.cell.name = this.$store.state.activeCell.name;
      this.cellLayerInit();
      this.WaferLayoutDialogVisible = true;
    });
  },
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
}

.tab {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .tab__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }

  .tab__body {
    background: #ffffff;
    display: flex;
    display: -webkit-flex;

    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 32px);
    flex-direction: column;
  }
}

.el-input-number {
  width: 255px;
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    // width: 100px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    // padding: 0 !important;
    padding-left: 10px;
    text-align: left;
    display: inline-block;
  }
}

.el-select {
  width: 255px;
  /deep/ .is-disabled {
    background-color: #b2b2b2 !important;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input:disabled {
    background-color: #b2b2b2 !important;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }

  /deep/ .el-input__inner:disabled {
    background-color: #b2b2b2 !important;
  }
}
.el-table {
  width: 100%;
  background: #ffffff;
  // border: 1px solid #DDDDDD;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    background: #ffffff;
  }
  .disabled {
    color: #b2b2b2 !important;
  }

  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 150px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 10px 0px 10px;
  .row_item {
    padding: 10px;
    margin-left: 100px;
  }
}

.settings {
  display: flex;
  flex-direction: column;
  display: inline-block;
  width: 100%;

  .settings__cell {
    width: 100%;

    .settings_item_cell__header {
    }
    .settings_item_cell__body {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }

  .settings__cap {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .cap-item {
      width: 200px;
      width: 32%;
      height: 300px;
    }
  }
  .settings__axis {
    margin-top: 10px;
    width: 100%;
  }
}

.image {
  width: 100%;
  margin: auto;
}
.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
