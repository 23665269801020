<template>
  <div>
    <!-- 关于 -->
    <div class="aboutDialog">
      <el-dialog :title="$t('messages.About')" :visible.sync="aboutDialogVisible" :modal="false"
        :close-on-click-modal="false">
        <div class="QLayoutIcon">
          <img alt="" src="../../assets/shoye-QLayout.png">
        </div>
        <span class="info">{{$t("messages.QuantumChipDesignIndustrialSoftware")}}</span>
        <a class="link" href="https://qeda.originqc.com.cn" target="_blank">qeda.originqc.com.cn</a>
        <span class="version">{{$t("messages.Version")}}： v2.4.0</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="aboutDialogVisible = false" size="mini" type="primary">{{$t("messages.sure")}}</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="feedbackDialog">
      <el-dialog :title="$t('messages.feedback')" :visible.sync="feedbackDialogVisible" :modal="false"
        :close-on-click-modal="false">
        <div class="feedback_title">
          <div class="feedback_title--info">{{$t("messages.feedback_title")}}<span class="link" href="https://qeda.originqc.com.cn">bykyrj@originqc.com</span></div>
        </div>
        <div class="feedback_info">{{$t("messages.feedback_info")}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="feedbackDialogVisible = false" size="mini" type="primary">{{$t("messages.sure")}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import bus from "../common/bus";
export default {
  data() {
    return {
      aboutDialogVisible: false,
      feedbackDialogVisible: false,
    };
  },
  mounted() {
    bus.$on("about", (data) => {
      this.aboutDialogVisible = true;
    });
    bus.$on("showFeedback", (data) => {
      this.feedbackDialogVisible = true;
    });
  },
  beforeDestroy() {
    bus.$off("about");
    bus.$off("showFeedback");
  },
};
</script>

<style lang="less" scoped>
.aboutDialog {
  color: #333;
  /deep/.el-dialog {
    width: 388px;
    height: 292px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    margin: 20px 0 10px;
    padding: 0;
    height: 170px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    .QLayoutIcon {
      width: 140px;
      height: 34px;
      // overflow: hidden;
      // position: relative;

      img {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 34px;
        // padding-left: 20px;
      }
    }
    .info {
      margin-top: 18px;
      font-size: 18px;
    }
    .link {
      width: 150px;
      height: 20px;
      color: #10579a;
      text-decoration: underline;
      margin: 40px 0 10px;
    }
    .version {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }
  /deep/.el-dialog__footer {
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
.feedbackDialog {
  /deep/.el-dialog {
    width: 388px;
    height: 242px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    // white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    margin: 10px 0 0 0;
    padding: 0;
    height: 130px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    .feedback_title {
      color: #333;
      width: 300px;
      margin-top: 18px;
      font-size: 12px;
      // height: 30px;
      margin-bottom: 20px;
      .feedback_title--info {
        width: 100%;
        word-wrap: break-word;
        text-align: center;
      }
    }
    .link {
      width: 150px;
      height: 20px;
      padding: 2px 0;
      color: #0C7DE6;
      // text-decoration: underline;
      border-bottom: 1px solid #0C7DE6;
    }
    .feedback_info {
      font-size: 12px;
      color: #666666;
      // padding-bottom: 5px;
      width: 324px;
      max-width: 324px;
      word-wrap: break-word;
      text-align: center;
    }
  }
  /deep/.el-dialog__footer {
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>