<template>
  <div class="loadPDKDialog">
    <el-dialog title="加载PDK" :visible.sync="loadPDKDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="loadPDKDialogClose" @opened="initStatus">
      <div class="inputArea">
        <span class="inputInfo">{{$t('messages.itemBelong')}}:</span>
        <div class="radioArea">
          <el-radio v-model="projectRadio" label="1" @change="projectBelong()">{{$t('messages.person')}}</el-radio>
          <el-radio v-model="projectRadio" label="2" @change="projectBelong()">{{$t('messages.team')}}</el-radio>
        </div>
      </div>
      <div class="inputArea">
        <span class="inputInfo">{{$t('messages.userAndTeamName')}}:</span>
        <el-select class="userSelect" v-if="projectRadio==='1'" v-model="form_userName" filterable :placeholder="$t('messages.placeholder_0')" disabled>
          <el-option v-for="item in options_userName" :key="item.snow_id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <el-select v-else-if="projectRadio==='2'" v-model="form_teamName" :placeholder="$t('messages.placeholder_0')" @change="teamNameSelect">
          <el-option v-for="item in options_teamName" :key="item.snow_id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </div>
      <div class="inputArea">
        <span class="inputInfo">{{$t('messages.projectName')}}:</span>
        <el-select v-if="projectRadio==='1'" v-model="form_projectName" :placeholder="$t('messages.placeholder_0')" @change="userProNameSelect" @focus="getUserProName">
          <el-option v-for="item in options_userProName" :key="item.project_gns" :label="item.value" :value="item.snow_id">
          </el-option>
        </el-select>
        <el-select v-else-if="projectRadio==='2'" v-model="form_projectName" :placeholder="$t('messages.placeholder_0')" @change="teamProNameSelect" @focus="getTeamProjectNameList">
          <el-option v-for="item in options_teamProName" :key="item.project_gns" :label="item.value" :value="item.snow_id">
          </el-option>
        </el-select>
      </div>
      <!-- PDK分类 -->
      <div class="inputArea">
        <span class="inputInfo">{{$t('messages.PDKClassification')}}:</span>
        <div class="radioArea">
          <el-radio v-model="PDKRadio" label="1">{{$t('messages.officialPDK')}}</el-radio>
          <el-radio v-model="PDKRadio" label="2">{{$t('messages.personalPDK')}}</el-radio>
        </div>
      </div>
      <div class="inputArea" v-if="PDKRadio=='1'">
        <span class="inputInfo">{{$t('messages.select')}}:</span>
        <el-select v-model="form_officialPDKOption" :placeholder="$t('messages.placeholder_0')" @change="PDKOptionSelect" @focus="PDKOptionFocus">
          <el-option v-for="item in options_PDKOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- 上传 -->
      <div class="uploadArea" v-else-if="PDKRadio=='2'">
        <span class="inputInfo">{{$t('messages.select')}}:</span>
        <el-upload name="gds" ref="importGDS" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-change="uploadFile" :on-remove="removeFile" :auto-upload="false" :multiple=true>
          <el-button size="small" type="primary">{{$t('messages.selectFile')}}</el-button>
        </el-upload>
      </div>
      <div v-show="PDKRadio=='2'">
        <div class="file-item">
          <div :class="{'upload-ok': drfload, 'file-success': true}">drf.json</div>
          <div :class="{'upload-ok': drcload, 'file-success': true}">drc.py</div>
          <div :class="{'upload-ok': xstload, 'file-success': true}">xst.json</div>
          <div :class="{'upload-ok': tfload, 'file-success': true}">tf.json</div>
          <div :class="{'upload-ok': libload, 'file-success': true}">lib.json</div>
          <!-- <div :class="{'upload-ok': zipload, 'file-success': true}">file.zip</div> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading" @click="loadPDKDialogSureBtn" size="mini" class="deactivate" ref="loadPDKDialogBtn" disabled>{{$t("messages.sure")}}</el-button>
        <el-button @click="loadPDKDialogCancelButton" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "@/components/common/bus";
import {
  //引入api
  getFileList_api, //获取文件列表//NOSONAR
  getCellList_api, //获取cell列表//NOSONAR
  searchFile_api, //搜索项目/文件
  getTeamList_api, //获取团队名列表
  getTeamProjectList_api, //获取团队项目列表
  getTeamPermissionList_api, //团队下项目权限//NOSONAR
  lockCell_api, //锁文件//NOSONAR
  selectLockCell_api, //NOSONAR
  getTree_api, //获取文件树//NOSONAR
  getUserPermission_api, //2.1获取团队成员权限
} from "@/api/file/file.js";
import { loadPDK } from "@/api/api";
import { getPDKFileFn } from "../fileList/function/fileListPublicFn.js";
import { batchSavePDKFn_fileSize } from "../fileList/function/objectStorageFn.js";
import {
  putObject_api,
  savePDKInof_api,
  getPDKInfo_api,
  copyObject_api,
} from "@/api/objectStorage/objectStorage.js";
import Md5 from "js-md5";
export default {
  data() {
    return {
      loading: false,
      drfload: false,
      drcload: false,
      tfload: false,
      zipload: false,
      xstload: false,
      libload: false,
      loadPDKDialogVisible: false, //显示隐藏
      userName: "", //用户名
      userId: "", //用户id
      teamId: "", //团队id
      team_gns: "", //团队GNS
      projectRadio: "1", //项目归属
      PDKRadio: "1", //PDK归属
      project_gns: "", //项目gns
      form_userName: "", //表单中用户名
      form_teamName: "", //表单中团队名
      form_projectName: "", //表单中项目名
      form_officialPDKOption: "", //表单中官方PDK
      options_userName: [], //用户名选项
      options_teamName: [], //团队名选项
      options_userProName: [], //个人项目选项
      options_teamProName: [], //团队项目选项
      options_PDKOption: [
        {
          value: 123,
          label: "PDK1",
          pdkid: "QEDApdkFilePath",
        },
      ], //PDK选项
      treeNameList: [], //团队名列表
    };
  },
  methods: {
    initStatus() {
      this.projectRadio == "1";
      this.form_projectName = "";
      this.form_officialPDKOption = "";
      this.drcload = false;
      this.tfload = false;
      this.xstload = false;
      this.zipload = false;
      this.drfload = false;
      this.libload = false;
      try {
        this.$refs.importGDS.clearFiles();
      } catch { }
    },
    //PDK项目归属
    projectBelong() {
      if (this.projectRadio == "1") {
        this.form_userName = this.userName;
      } else {
        this.form_teamName = "";
      }
      this.form_projectName = "";
      this.form_officialPDKOption = "";
      this.drcload = false;
      this.tfload = false;
      this.xstload = false;
      this.zipload = false;
      this.drfload = false;
      this.libload = false;
      try {
        this.$refs.importGDS.clearFiles();
      } catch { }
    },
    //PDK团队名选择
    teamNameSelect(data) {
      this.options_teamName.forEach((item) => {
        if (item.name == data) {
          this.team_gns = item.gns;
          this.teamId = item.snow_id;
          this.form_teamName = item.name;
          this.form_projectName = "";
          this.form_officialPDKOption = "";
          this.PDKRadio = "1";
        }
      });
    },
    //PDK个人项目选择
    userProNameSelect(data) {
      let index = this.options_userProName.findIndex(
        (item) => item.snow_id === data
      );
      this.project_gns = this.options_userProName[index].project_gns;
    },
    //PDK个人项目获取
    async getUserProName() {
      this.options_userProName.splice(0, this.options_userProName.length);
      let res = await searchFile_api({
        parent_gns: "",
        category: "项目",
        category_child: "个人项目",
        start: 0,
        rows: 50,
        key_words: "",
        types: null,
      });
      if (res.code == 200000) {
        this.project_gns = "";
        res.data.data.forEach((item) => {
          this.options_userProName.push({
            value: item._source.name,
            project_gns: item._source.gns,
            snow_id: item._source.snow_id,
          });
        });
      }
    },
    //PDK团队项目选择
    teamProNameSelect(data) {
      let index = this.options_teamProName.findIndex(
        (item) => item.snow_id === data
      );
      this.project_gns = this.options_teamProName[index].project_gns;
    },
    //PDK团队项目获取
    async getTeamProjectNameList() {
      if (this.form_teamName === "") {
        bus.$emit("errDialog", this.$t("messages.fileTip5"));
      } else {
        this.options_teamProName.splice(0, this.options_teamProName.length);
        let res = await getTeamProjectList_api({
          team_gns: this.team_gns,
          snow_team_id: this.teamId,
          start: 0,
          rows: 50,
          key_words: "",
        });
        if (res.code == 200000) {
          this.project_gns = "";
          res.data.data.forEach((item) => {
            this.options_teamProName.push({
              value: item._source.name,
              project_gns: item._source.gns,
              snow_id: item._source.snow_id,
            });
          });
        }
      }
    },
    //官方PDK选择
    PDKOptionSelect(data) {
      // 选择官方PDK
      this.form_officialPDKOption = data;
    },
    //官方PDK
    PDKOptionFocus() { },
    //上传
    uploadFile() {
      let files = this.$refs.importGDS.uploadFiles;
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file.name == "drf.json") {
          this.drf = file;
          this.drfload = true;
        } else if (file.name == "tf.json") {
          this.tf = file;
          this.tfload = true;
        } else if (file.name == "xst.json") {
          this.xst = file;
          this.xstload = true;
        } else if (file.name == "lib.json") {
          this.lib = file;
          this.libload = true;
        } else if (file.name == "drc.py") {
          this.drc = file;
          this.drcload = true;
        }
      }
      this.PDKDialogBtnActive();
    },
    removeFile(file) {
      if (file.name == "drf.json") {
        this.drf = null;
        this.drfload = false;
      } else if (file.name == "tf.json") {
        this.tf = null;
        this.tfload = false;
      } else if (file.name == "xst.json") {
        this.xst = null;
        this.xstload = false;
      } else if (file.name == "lib.json") {
        this.lib = null;
        this.libload = false;
      } else if (file.name == "drc.py") {
        this.drc = null;
        this.drcload = false;
      }
      this.PDKDialogBtnActive();
    },
    //PDK关闭按钮
    loadPDKDialogClose() {
      this.resetPDKDialog();
    },
    //PDK确定按钮
    async loadPDKDialogSureBtn() {
      this.loading = true;
      let that = this;
      let save_res = null;
      this.save_drf = null;
      this.save_tf = null;
      // this.save_zip = null
      this.save_lib = null;
      this.save_xst = null;
      this.save_drc = null;
      let project_gns = that.project_gns.split("/");
      let project_snow_id = project_gns[project_gns.length - 1];
      let team_snow_id = null; //NOSONAR
      if (this.teamId.length != 0) {
        team_snow_id = this.teamId;
        // 团队PDK
        let permision = await getUserPermission_api({
          gns: that.project_gns,
        });
        if (permision.code === 200000) {
          // 1,2 can write
          let per_id = permision.data.permission_id;
          if (per_id == 3) {
            // 没有权限
            this.loading = false;
            this.$message.error(this.$t("messages.project_no_permission"));
            this.clearPDKDialog();
            return;
          }
        }
      }
      // 使用官方PDK
      if (this.PDKRadio == 1) {
        let select_pdk = this.options_PDKOption.filter((elem) => {
          return elem.value == this.form_officialPDKOption;
        });
        let official_pdk = select_pdk[0];
        if (official_pdk.pdkid) {
          // let pdk_res = await getPDKInfo_api({
          //   project_snow_id: official_pdk.pdkid,
          // });
          // if (pdk_res.code === 200000) {
          // let url = pdk_res.data.url_data;
          // let url_data = {
          //   drf: url.drf,
          //   tf: url.tf,
          //   xst: url.xst,
          //   lib: url.lib,
          //   drc: url.drc,
          // };
          // let bucket_name = localStorage.getItem("bucket_name");
          // let time = parseInt(new Date().getTime() / 1000);
          // let suffix = ".json";
          // let team_snow_id = null; //NOSONAR
          // if (this.teamId.length != 0) {
          //   team_snow_id = this.teamId;
          // }
          // let keys = Object.keys(url_data);
          let project_gns = that.project_gns;
          let project_snow_id = project_gns.split('/')[project_gns.split('/').length - 1];
          let batchSaveRes = await batchSavePDKFn_fileSize([], project_snow_id, "PDK_FILE", 1, 2)
          if (batchSaveRes.code == 200000) {
            this.save_tf = true;
            this.save_drf = true;
            this.save_lib = true;
            this.save_xst = true;
            this.save_drc = true;
            bus.$emit("deviceVueUpdatePDK", project_snow_id);
            bus.$emit("symbolVueUpdatePDK", project_snow_id);
            bus.$emit("rightVueUpdatePDK", project_snow_id);
          } else {
            this.save_tf = false;
            this.save_drf = false;
            this.save_lib = true;
            this.save_xst = false;
            this.save_drc = false;
          }

          // for (let index = 0; index < keys.length; index++) {
          //   let type = keys[index];
          //   if (type == "drc") {
          //     suffix = ".py";
          //   }
          //   let src_url = url_data[type];
          //   let folder_name =
          //     "user_" +
          //     localStorage.getItem("userId") +
          //     "/" +
          //     project_gns +
          //     "/pdk/" +
          //     type +
          //     "_" +
          //     time +
          //     suffix;
          //   let formData = new FormData(); //新建表单对象
          //   formData.append("time", time);
          //   formData.append("source_path", src_url);
          //   formData.append("target_bucket_name", bucket_name);
          //   formData.append("source_bucket_name", bucket_name);
          //   formData.append("folder_name", folder_name);
          //   let apiKey = localStorage.getItem("apiKey");
          //   let minio_name = localStorage.getItem("minio_name");
          //   let res = await copyObject_api(formData, {
          //     sign: Md5(`${apiKey}_${time}`),
          //     username: minio_name,
          //   });
          // if (res.code === 200000) {
          //   save_res = await savePDKInof_api({
          //     url_data: { [type]: res.data.object_path },
          //     project_snow_id: project_gns,
          //     team_snow_id: team_snow_id,
          //   });
          //   if (save_res.code === 200000) {
          //     switch (type) {
          //       case "tf":
          //         this.save_tf = true;
          //         break;
          //       case "drf":
          //         this.save_drf = true;
          //         break;
          //       case "lib":
          //         // this.save_zip = true
          //         this.save_lib = true;
          //         bus.$emit("deviceVueUpdatePDK", project_snow_id);
          //         bus.$emit("symbolVueUpdatePDK", project_snow_id);
          //       case "xst":
          //         this.save_xst = true;
          //         break;
          //       case "drc":
          //         this.save_drc = true;
          //         break;
          //       default:
          //         break;
          //     }
          //   } else {
          //     switch (type) {
          //       case "tf":
          //         this.save_tf = false;
          //         break;
          //       case "drf":
          //         this.save_drf = false;
          //         break;
          //       case "lib":
          //         // this.save_zip = false
          //         this.save_lib = true;
          //       case "xst":
          //         this.save_xst = false;
          //         break;
          //       case "drc":
          //         this.save_drc = false;
          //         break;
          //       default:
          //         break;
          //     }
          //   }
          //   if (this.save_drf && this.save_tf) {
          //     bus.$emit("rightVueUpdatePDK", project_snow_id);
          //   }
          // } else {
          //   switch (type) {
          //     case "tf":
          //       this.save_tf = false;
          //       break;
          //     case "drf":
          //       this.save_drf = false;
          //       break;
          //     case "lib":
          //       // this.save_zip = false
          //       this.save_lib = true;
          //     case "xst":
          //       this.save_xst = false;
          //       break;
          //     case "drc":
          //       this.save_drc = false;
          //       break;
          //     default:
          //       break;
          //   }
          // }
          // }
          // }
        }
      }
      // 使用个人PDK
      else if (this.PDKRadio == 2) {
        let files = [];
        let types = [];
        if (this.drf) {
          files.push(this.drf);
          types.push("drf");
        }
        if (this.tf) {
          files.push(this.tf);
          types.push("tf");
        }
        if (this.xst) {
          files.push(this.xst);
          types.push("xst");
        }
        if (this.lib) {
          files.push(this.lib);
          types.push("lib");
        }
        // if (this.zip) {
        //   files.push(this.zip)
        //   types.push("zip")
        // }
        if (this.drc) {
          files.push(this.drc);
          types.push("drc");
        }
        if (files && types) {
          if (this.drf || this.tf || this.xst || this.lib || this.drc) {
            save_res = await this.savePDK(files, types);
          } else {
            this.loading = false;
            return;
          }
        }
      }
      this.save_drf = this.save_drf == null ? true : this.save_drf;
      this.save_tf = this.save_tf == null ? true : this.save_tf;
      this.save_lib = this.save_lib == null ? true : this.save_lib;
      // this.save_zip = this.save_zip == null ? true : this.save_zip
      this.save_xst = this.save_xst == null ? true : this.save_xst;
      this.save_drc = this.save_drc == null ? true : this.save_drc;
      let error_msg = "";
      if (!this.save_drf) {
        error_msg += "drf ";
      }
      if (!this.save_tf) {
        error_msg += "tf ";
      }
      if (!this.save_lib) {
        error_msg += "lib ";
      }
      // if(!this.save_zip) {
      //   error_msg += "zip "
      // }
      if (!this.save_xst) {
        error_msg += "xst ";
      }
      if (!this.save_drc) {
        error_msg += "drc ";
      }
      if (
        this.save_drf ||
        this.save_tf ||
        this.save_xst ||
        this.save_drc ||
        this.save_lib
      ) {
        this.$message({ message: "Save PDK success", type: "success" });
        await getPDKFileFn(project_snow_id);
        if (this.save_tf || this.save_drf) {
          bus.$emit("PDKLoadEnd", project_snow_id);
        }
        this.resetPDKDialog();
        this.loading = false;
      } else {
        this.$message({ message: "Save PDK failed", type: "error" });
        this.resetPDKDialog();
        this.loading = false;
      }
      this.loading = false;
    },
    async savePDK(files, types) {
      // let time = parseInt(new Date().getTime() / 1000);
      // let apiKey = localStorage.getItem("apiKey");
      // let minio_name = localStorage.getItem("minio_name");
      let len = files.length;
      let u8Array = []
      let that = this;
      let project_snow_id = that.project_gns.split("/")[that.project_gns.split("/").length - 1];

      for (let index = 0; index < len; index++) {
        const file = files[index];
        const type = types[index];
        // 读入文件，以字节流上传到对象存储保存，并记录到数据库
        let reader = new FileReader();
        reader.readAsArrayBuffer(file.raw);
        let result = new Promise((resolve) => {
          reader.onload = (evt) => {
            resolve(evt);
          };
        });
        // 获取pdk，验证是否是第一次上传pdk
        await result.then(async (evt) => {
          const data_u8 = new Uint8Array(reader.result);
          if (type == "zip") {
            u8Array.push({
              zip: file.raw,
              name: file.name,
              type
            })
          } else {
            u8Array.push({
              data_u8,
              name: file.name,
              type
            })
          }
          // let blob = new Blob([u8_data]);
          // let project_gns = that.project_gns.split("/");
          // project_gns = project_gns[project_gns.length - 1];
          // let formData = new FormData(); //新建表单对象
          // let res = null;
          // if (type == "zip") {
          //   formData.append("zip_file", file.raw);
          //   formData.append("project_snow_id", project_gns);
          //   res = await loadPDK(formData, {
          //     sign: Md5(`${apiKey}_${time}`),
          //     username: minio_name,
          //   });
          // } else {
          //   formData.append("file", blob);
          //   let bucket_name = localStorage.getItem("bucket_name");
          //   let time = parseInt(new Date().getTime() / 1000);
          //   let suffix = ".json";
          //   if (type == "drc") {
          //     suffix = ".py";
          //   }
          //   let folder_name =
          //     "user_" +
          //     localStorage.getItem("userId") +
          //     "/" +
          //     project_gns +
          //     "/pdk/" +
          //     type +
          //     "_" +
          //     time +
          //     suffix;
          //   formData.append("time", time);
          //   formData.append("bucket_name", bucket_name);
          //   formData.append("folder_name", folder_name);
          //   formData.append("file_type", "PDK_FILE");
          //   formData.append("object_name", "");
          //   let apiKey = localStorage.getItem("apiKey");
          //   let minio_name = localStorage.getItem("minio_name");
          //   res = await putObject_api(formData, {
          //     sign: Md5(`${apiKey}_${time}`),
          //     username: minio_name,
          //   });
          // }

          // if (res.code === 200000) {
          //   let team_snow_id = null;
          //   if (this.teamId.length != 0) {
          //     team_snow_id = this.teamId;
          //   }

          //   let save_res = await savePDKInof_api({
          //     //NOSONAR
          //     url_data: { [type]: res.data.object_path },
          //     project_snow_id: project_gns,
          //     team_snow_id: team_snow_id,
          //   });
          //   switch (type) {
          //     case "drf":
          //       this.save_drf = true;
          //       bus.$emit("rightVueUpdatePDK", project_gns, false);
          //       break;
          //     case "tf":
          //       this.save_tf = true;
          //       bus.$emit("rightVueUpdatePDK", project_gns, true);
          //       break;
          //     // case "zip":
          //     //   this.save_zip = true
          //     //   bus.$emit("deviceVueUpdatePDK", project_gns);
          //     //   bus.$emit("symbolVueUpdatePDK", project_gns);
          //     case "lib":
          //       this.save_lib = true;
          //       bus.$emit("deviceVueUpdatePDK", project_gns);
          //       bus.$emit("symbolVueUpdatePDK", project_gns);
          //     case "xst":
          //       this.save_xst = true;
          //       break;
          //     case "drc":
          //       this.save_drc = true;
          //       break;
          //     default:
          //       break;
          //   }
          // } else {
          //   switch (type) {
          //     case "tf":
          //       this.save_tf = false;
          //       break;
          //     case "drf":
          //       this.save_drf = false;
          //       break;
          //     case "lib":
          //       this.save_lib = false;
          //     // case "zip":
          //     //   this.save_zip = false
          //     case "xst":
          //       this.save_xst = false;
          //       break;
          //     case "drc":
          //       this.save_drc = false;
          //       break;
          //     default:
          //       break;
          //   }
          // }
        });
      }
      let batchSaveRes = await batchSavePDKFn_fileSize(u8Array, project_snow_id, "PDK_FILE", 1, 1)
      if (batchSaveRes.code === 200000) {
        this.save_drf = true;
        this.save_tf = true;
        this.save_lib = true;
        this.save_xst = true;
        this.save_drc = true;
        // bus.$emit("rightVueUpdatePDK", project_snow_id, false);
        bus.$emit("rightVueUpdatePDK", project_snow_id, true);
        bus.$emit("deviceVueUpdatePDK", project_snow_id);
        bus.$emit("symbolVueUpdatePDK", project_snow_id);
      } else {
        this.save_tf = false;
        this.save_drf = false;
        this.save_lib = false;
        this.save_xst = false;
        this.save_drc = false;
      }
    },
    //PDK取消按钮
    loadPDKDialogCancelButton() {
      this.resetPDKDialog();
    },
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    //激活判断
    PDKDialogBtnActive() {
      if (this.loadPDKDialogVisible) {
        if (
          (this.form_userName !== "" || this.form_teamName !== "") &&
          this.form_projectName !== ""
        ) {
          if (this.PDKRadio == "1" && this.form_officialPDKOption !== "") {
            this.activateBtn(this.$refs.loadPDKDialogBtn);
          } else if (
            this.PDKRadio == "2" &&
            (this.drf || this.tf || this.xst || this.lib || this.drc)
          ) {
            this.activateBtn(this.$refs.loadPDKDialogBtn);
          } else {
            this.disableBtn(this.$refs.loadPDKDialogBtn);
          }
        } else {
          this.disableBtn(this.$refs.loadPDKDialogBtn);
        }
      }
    },
    //重置表单
    resetPDKDialog() {
      if (this.PDKRadio == "2") {
        this.$refs.importGDS.clearFiles();
      }
      this.projectRadio = "1";
      this.PDKRadio = "1";
      this.project_gns = "";
      this.team_gns = "";
      this.teamId = "";
      this.form_teamName = "";
      this.form_projectName = "";
      this.form_officialPDKOption = "";
      this.loadPDKDialogVisible = false;
      this.drf = null;
      this.tf = null;
      this.xst = null;
      this.zip = null;
      this.drc = null;
    },
    clearPDKDialog() {
      if (this.PDKRadio == "2") {
        this.$refs.importGDS.clearFiles();
      }
      // this.projectRadio = "1"
      this.PDKRadio = "1";
      this.project_gns = "";
      this.team_gns = "";
      this.teamId = "";
      this.form_teamName = "";
      this.form_projectName = "";
      this.form_officialPDKOption = "";
      this.drf = null;
      this.tf = null;
      this.xst = null;
      this.zip = null;
      this.drc = null;
      this.loading = false;
    },
  },
  async created() {
    this.userName = localStorage.getItem("userName");
    this.form_userName = this.userName;
    this.userId = localStorage.getItem("userId");
    let teamList = await getTeamList_api({
      start: 0,
      rows: 50,
      key_word: "",
    });
    if (teamList.code === 200000) {
      this.options_teamName.splice(0, this.options_teamName.length);
      teamList.data.data.forEach((item) => {
        this.options_teamName.push({
          category: "",
          creator_id: item.creator_id,
          gns: item.gns,
          role: item.role,
          snow_id: item.snow_team_id,
          name: item.team_name,
          isTeam: true,
        });
      });
    }
  },
  mounted() {
    bus.$on("loadPDK", (data) => {
      this.loadPDKDialogVisible = true;
    });
  },
  watch: {
    form_userName(val) {
      this.PDKDialogBtnActive();
    },
    form_teamName(val) {
      this.PDKDialogBtnActive();
    },
    form_projectName(val) {
      this.PDKDialogBtnActive();
    },
    form_officialPDKOption(val) {
      this.PDKDialogBtnActive();
    },
  },
};
</script>
<style lang="less" scoped>
.loadPDKDialog {
  /deep/.el-dialog {
    width: 686px;
    min-height: 376px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 35px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .userSelect {
      /deep/.el-input__inner {
        color: #c0c4cc !important;
      }
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .projectNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .fileNameIcon {
      position: absolute;
      top: 24px;
      right: 11px;
      z-index: 1000;
      transition: transform 0.3s;
      color: #999999;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #999999;
    }
  }
  .uploadArea {
    width: 100%;
    min-height: 40px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .upload-demo {
      width: 80%;
      display: flex;
      align-items: center;
      /deep/.el-upload-list__item-name {
        margin-left: 20px;
      }
      /deep/.el-upload-list__item:first-child {
        margin-top: 0px;
      }
    }
  }
  .deactivate {
    background-color: #aac6f5 !important;
    border-color: #aac6f5 !important;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
.file-item {
  display: flex;
  .file-success {
    width: 100px;
    border: 1px solid #ddd;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 5px;
    background: #ddd;
    border-radius: 4px;
  }
  .upload-ok {
    border: 1px solid #67c23a;
    color: #ffffff;
    background: #67c23a;
  }
}
</style>