<template>
  <div>
    <DrcDialog></DrcDialog>
  </div>
</template>

<script>
import DrcDialog from './DrcDialog.vue'

export default {
  components: {
    DrcDialog,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
  mounted() {},
  watch: {},
}
</script>

<style lang="less" scoped></style>
