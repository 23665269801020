// var window.CanvasKit = null
// var window.CanvasKitFont = null

const FillType = {
  F0: [1, 20, 0, 0, 45, 0, 0, 1], //斜线1
  F1: [1, 20, 0, 0, 45, 0, 0, 1], //斜线1
  F2: [5, 15, 0, 0, 45, 0, 0, 1], //斜线2
  F3: [1, 20, 0, 0, 135, 0, 0, 1], //斜线3
  F4: [1, 20, 0, 0, 45, 1, 0, 1], //斜网格
  F5: [2, 20, 5, 10, 45, 0, 0, 1], //间隙线
  F6: [5, 15, 10, 5, 45, 0, 0, 1], //循环方格点
  F7: [2, 6, 0, 0, 45, 1, 1, 1], //点阵密集
  F8: [15, 25, 0, 0, 45, 1, 1, 1], //点阵
  F9: [1, 20, 10, 10, 45, 1, 0, 1], //循环十字
  F10: [2, 40, 20, 20, 0, 1, 0, 1], //循环十字2
  F11: [6, 40, 10, 10, 45, 1, 0, 1], //特殊纹理1
  F12: [5, 20, 40, 10, 45, 1, 0, 2], //特殊纹理2
  F13: [2, 10, 20, 10, 45, 1, 0, 1], //特殊纹理3
  F14: [4, 20, 20, 20, 45, 1, 0, 1], //特殊纹理4
}

//图层面板边框缩略图参数
const BorderType = {
  B1: [0, 0, 0, 0],
  B2: [15, 9, 15, 9],
  B3: [6, 0, 0, 6],
  B4: [3, 3, 3, 3],
  B5: [1.5, 3, 1.5, 3],
}
//图层面板边框缩略图参数
const QedaGraphicBorderTypeImg = {
  B1: [0, 0, 0, 0],
  B2: [5, 3, 5, 3],
  B3: [2, 0, 0, 2],
  B4: [1, 1, 1, 1],
  B5: [0.5, 1, 0.5, 1],
}

const FillShader = `
    uniform float4 col; //颜色
    uniform float width; //线宽
    uniform float space; //网格间距
    uniform float line_widht; //线段长度
    uniform float dot; //线段空隙间距
    uniform float angle; //旋转角度
    uniform float only_side; //只绘制一边 0 || 1 默认0
    uniform float use_point_grid; //使用点阵 0 || 1 默认0
    uniform float scale; //缩放 默认1
    uniform float2 position; //位移
    uniform float axis_scale;//坐标系缩放
    float grid(float value, float width, float space, float vertical, float line_widht, float dot) {
        float total = line_widht + dot; //线长加间距 -----  ----- -----
        float pos = mod(vertical, total);
        float dot_weight = 0;
        if(pos <= line_widht){
            dot_weight = width + 1; //计算点距权重
        }
        return mod(value + width * 0.5, space) - width + dot_weight;
    }
    half4 main(float2 p) {
        float4 color; //输出颜色
        float4 color_bg; //背景颜色
        float deg =  angle * (3.1415926/ 180);
        mat3 matrix = mat3( //变换矩阵
        float3(cos(deg) * scale,sin(deg),0),
        float3(-sin(deg),cos(deg) * scale,0),
        float3(1000000, 1000000,1)
        );
        p *= axis_scale;
        p += 2000;
        // p -= position;
        float2 pp = (matrix * float3(p.x,p.y,1)).xy; //旋转后像素坐标
        float mx = 1-smoothstep(0, 1, grid(pp.x, width, space, pp.y - line_widht/2, line_widht, dot));
        float my;
        if(bool(only_side)){
            my = 1-smoothstep(0, 1, grid(pp.y, width, space, pp.x - line_widht/2, line_widht, dot));
        }
        color = mix(color_bg, col, clamp(mx + my, 0.0, col.w));//网格
        if(bool(use_point_grid)){
            color = mix(color_bg, col, clamp(mx + my - 1, 0.0, col.w));//点阵
        }
        return half4(color);
    }
`

LayerInfo = [
  {
    layerName: 'none',
    lock: false,
    hide: false,
    layerNumber: 0,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal1',
    lock: false,
    hide: false,
    layerNumber: 1,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal2',
    lock: false,
    hide: false,
    layerNumber: 2,
    color: '#7DAEF7',
    shape: 'F6',
    border: 'B1',
    borderColor: '#7DAEF7',
    isExport: true,
  },
  {
    layerName: 'Metal3',
    lock: false,
    hide: false,
    layerNumber: 3,
    color: '#9B51EE',
    shape: 'F3',
    border: 'B1',
    borderColor: '#9B51EE',
    isExport: true,
  },
  {
    layerName: 'Metal3-Flip',
    lock: false,
    hide: false,
    layerNumber: 4,
    color: '#C660F1',
    shape: 'F3',
    border: 'B1',
    borderColor: '#C660F1',
    isExport: true,
  },
  {
    layerName: 'Metal4',
    lock: false,
    hide: false,
    layerNumber: 5,
    color: '#A6B65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#A6B65A',
    isExport: true,
  },
  {
    layerName: 'Metal4-Flip',
    lock: false,
    hide: false,
    layerNumber: 6,
    color: '#7FB65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#7FB65A',
    isExport: true,
  },
  {
    layerName: 'Indium',
    lock: false,
    hide: false,
    layerNumber: 7,
    color: '#E8CE3B',
    shape: 'F1',
    border: 'B1',
    borderColor: '#E8CE3B',
    isExport: true,
  },
  {
    layerName: 'Indium-Flip',
    lock: false,
    hide: false,
    layerNumber: 8,
    color: '#DEB22B',
    shape: 'F3',
    border: 'B1',
    borderColor: '#DEB22B',
    isExport: true,
  },
  {
    layerName: 'TiN',
    lock: false,
    hide: false,
    layerNumber: 9,
    color: '#B069E7',
    shape: 'F1',
    border: 'B1',
    borderColor: '#B069E7',
    isExport: true,
  },
  {
    layerName: 'TiN-Flip',
    lock: false,
    hide: false,
    layerNumber: 10,
    color: '#D469E7',
    shape: 'F3',
    border: 'B1',
    borderColor: '#D469E7',
    isExport: true,
  },
  {
    layerName: 'Junction',
    lock: false,
    hide: false,
    layerNumber: 11,
    color: '#E60012',
    shape: 'F4',
    border: 'B1',
    borderColor: '#E60012',
    isExport: true,
  },
  {
    layerName: 'Label',
    lock: false,
    hide: false,
    layerNumber: 12,
    color: '#FFFFFF',
    shape: 'F7',
    border: 'B1',
    borderColor: '#00FFFF',
    isExport: true,
  },
]
;(function () {
  // SkMatrix: [scaleX, 0, tranX, 0, scaleY, tranY, 0, 0, 1]
  function Canvas2DKernel(canvas_elem) {
    this.canvas_elem = canvas_elem || null

    // 清除
    this.isClear = false

    // cell 缓存
    this.cells_cache = {}

    // 边框画笔
    this.border_paints = {}
    // 填充画笔
    this.fill_paints = {}

    this.width = 400
    this.height = 400
    this.tran = {}

    this.surface = null
    this.paints = {}

    this.drawFrameFunc = this.drawFrame.bind(this)
    this.clearFrameFunc = this.clearFrame.bind(this)

    this.log_name = this.constructor.name
  }

  Canvas2DKernel.prototype.init = function () {}

  Canvas2DKernel.prototype.clear = function () {
    if (this.canvas_elem) {
      this.surface.requestAnimationFrame(this.clearFrameFunc)
    }
  }

  Canvas2DKernel.prototype.render = function (canvas_elem, cell) {
    this.canvas_elem = canvas_elem
    this.cell = cell
    console.error(this.log_name, this.cell)
    if (!this.canvas_elem) {
      console.error('canvas对象为空无法加载!')
      return
    }
    if (!this.cell) {
      console.error('cell对象为空无法加载!')
      return
    }
    console.error(this.log_name, this.cell)

    // 创建surface
    const surface = window.CanvasKit.MakeCanvasSurface(this.canvas_elem.id)
    if (!surface) {
      console.error(this.log_name, 'Could not make surface')
      return
    }
    // 获取canvas宽、高
    this.width = this.canvas_elem.clientWidth
    this.height = this.canvas_elem.clientHeight
    // 设置字体
    this.setFont()

    // 获取缩放比例以及平移变换
    this.tran = this.getCenterTran(this.cell.bounding_box())

    // 画笔填充
    this.setPaints()

    // 绑定绘制函数
    this.surface = surface
    this.surface.requestAnimationFrame(this.drawFrameFunc)
  }

  Canvas2DKernel.prototype.setFont = function () {
    this.label_anchor = 'o' // “n”, “s”, “e”, “w”, “o”, “ne”, “nw”, “se”, or “sw”.
    this.label_size_pt = 20 // 磅（skia字体单位）
    if (this.width < this.height) {
      this.label_size_pt = this.width / 25 // 磅（skia字体单位）
    } else {
      this.label_size_pt = this.height / 25 // 磅（skia字体单位）
    }
    let dpi = 96 // dpi: 一般电脑为96
    this.label_size_px = (this.label_size_pt * dpi) / 72 // 转为像素大小
  }

  Canvas2DKernel.prototype.setPaints = function () {
    function hex2rgb(hex) {
      return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255, 1]
    }
    function hexstr2rgb(hex_str) {
      let hex = parseInt(hex_str.substring(1), 16)
      return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255, 1]
    }
    this.fillFact = window.CanvasKit.RuntimeEffect.Make(FillShader) //图形填充纹理
    for (let i = 0; i < LayerInfo.length; i++) {
      let item = LayerInfo[i]

      // 设置文字字体大小和画笔
      let font_rgba = hexstr2rgb(item.color)
      let font_size = this.label_size_pt
      let font_font = new window.CanvasKit.Font(null, font_size)
      const font_paint = new window.CanvasKit.Paint()
      font_paint.setColor(window.CanvasKit.Color4f(font_rgba[0], font_rgba[1], font_rgba[2], font_rgba[3]))
      font_paint.setStyle(window.CanvasKit.PaintStyle.Fill)
      font_paint.setAntiAlias(true)
      const font_path_paint = new window.CanvasKit.Paint()
      font_path_paint.setColor(window.CanvasKit.Color4f(font_rgba[0], font_rgba[1], font_rgba[2], font_rgba[3]))
      font_path_paint.setStyle(window.CanvasKit.PaintStyle.Stroke)
      font_path_paint.setStrokeWidth(1 / this.tran.scale)
      font_path_paint.setAntiAlias(true)

      // 设置边框画笔
      let border_rgba = hexstr2rgb(item.borderColor)
      let border_line_width = 1
      const border_paint = new window.CanvasKit.Paint()
      border_paint.setColor(window.CanvasKit.Color4f(border_rgba[0], border_rgba[1], border_rgba[2], border_rgba[3]))
      border_paint.setStyle(window.CanvasKit.PaintStyle.Stroke)
      border_paint.setStrokeWidth(border_line_width / this.tran.scale)
      border_paint.setAntiAlias(true)

      // 设置填充画笔
      let fill_rgba = hexstr2rgb(item.color)
      let fill_type = item.shape
      let fill_line_width = 1
      const fill_paint = new window.CanvasKit.Paint()
      fill_paint.setShader(this.fillFact.makeShader([...window.CanvasKit.Color4f(fill_rgba[0], fill_rgba[1], fill_rgba[2]), ...FillType[fill_type], 0, 0, this.tran.scale]))
      fill_paint.setAntiAlias(true)
      fill_paint.setStrokeWidth(fill_line_width / this.tran.scale)

      this.paints[item.layerNumber] = {
        font_font: font_font,
        font_path_paint: font_path_paint,
        font_paint: font_paint,
        border_paint: border_paint,
        fill_paint: fill_paint,
      }
    }
    //
  }

  // canvas缓存cache
  Canvas2DKernel.prototype.libraryCache = function () {
    function cellPaths(items) {
      if (!items) {
        return []
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        let flexpath = items[i]
        const points = flexpath.points
        const path = new window.CanvasKit.Path()
        path.moveTo(points[0][0], points[0][1])
        for (let j = 1, len = points.length; j < len; j++) {
          path.lineTo(points[j][0], points[j][1])
        }
        res.push({
          path: path,
          layer: flexpath.layers[0] % 12,
        })
      }
      return res
    }

    function cellPolygons(items) {
      if (!items) {
        return []
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        let polygon = items[i]
        if (!polygon) continue
        const path = new window.CanvasKit.Path()
        const points = polygon.points
        path.moveTo(points[0][0], points[0][1])
        for (let j = 1, len = points.length; j < len; j++) {
          path.lineTo(points[j][0], points[j][1])
        }
        path.close()
        res.push({
          path: path,
          layer: polygon.layer % 12,
        })
      }
      return res
    }

    function cellLabels(items) {
      if (!items) {
        return []
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        let label = items[i]
        let origin = [label.origin[0], label.origin[1]]
        const path = new window.CanvasKit.Path()
        path.moveTo(origin[0] - 3 / scale, origin[1])
        path.lineTo(origin[0] + 3 / scale, origin[1])
        path.moveTo(origin[0], origin[1] - 3 / scale)
        path.lineTo(origin[0], origin[1] + 3 / scale)
        res.push({
          path: path,
          text: label.text,
          origin: origin,
          rotation: label.rotation,
          anchor: label.anchor,
          magnification: label.magnification,
          layer: label.layer % 12,
        })
      }
      return res
    }

    function cellReferences(items) {
      if (!items) {
        return []
      }
      let res = []
      for (let i = 0, len = items.length; i < len; i++) {
        const ref = items[i]
        let x_reflection = ref.x_reflection ? -1 : 1
        res.push({
          name: ref.cell.name,
          origin: ref.origin,
          x_reflection: x_reflection,
          // rotation: ref.rotation * Math.PI / 180,
          rotation: ref.rotation,
          magnification: ref.magnification,
          references: cellReferences(ref.cell.references),
        })
      }
      return res
    }
    if (!this.library) return
    let cells = this.library.cells
    this.cells_cache = {}
    let scale = this.tran.scale
    for (let i = 0; i < cells.length; i++) {
      let cell = cells[i]
      let paths = cellPaths(cell.paths)
      let polygons = cellPolygons(cell.polygons)
      let labels = cellLabels(cell.labels)
      let references = cellReferences(cell.references)
      this.cells_cache[cell.name] = {
        bounding_box: cell.bounding_box(),
        paths: paths,
        labels: labels,
        polygons: polygons,
        references: references,
      }
    }
    //
  }

  Canvas2DKernel.prototype.getCell = function () {
    let cells = this.library.cells
    let cell = null
    for (let i = 0; i < cells.length; i++) {
      if (this.cell_name == cells[i].name) {
        cell = cells[i]
        break
      }
    }
    return cell
  }

  // 获取最佳视角中心位置
  Canvas2DKernel.prototype.getCenterTran = function (box) {
    if (!box) return { scale: 1, dx: 0, dy: 0 }
    // let box = this.cell.bounding_box()
    const s = 9 / 10
    const window_width = this.width * s
    const window_height = this.height * s
    const box_width = box[1][0] - box[0][0]
    const box_height = box[1][1] - box[0][1]
    let scale = 1
    if (box_height > box_width) {
      scale = window_height / box_height
    } else {
      scale = window_width / box_width
    }
    let dx = this.width / 2 - (box[0][0] + box_width / 2) * scale
    let dy = this.height / 2 + (box[0][1] + box_height / 2) * scale
    return {
      scale: scale,
      dx: dx,
      dy: dy,
    }
  }
  Canvas2DKernel.prototype.anchorTran = function (anchor, len, pt) {
    // canvaskit字体在右上角，按照此位置转换到对应的8个方位
    // let dpi = 96 // dpi: 一般电脑为96
    // let px = (pt * dpi) / 72 // 转为像素大小
    let tranX = len * pt * 0.6
    let tranY = 1 * pt
    if (anchor == 'o') {
      return { x: tranX / 2, y: tranY / 2 }
    }
    if (anchor == 'e') {
      return { x: tranX, y: tranY / 2 }
    }
    if (anchor == 's') {
      return { x: tranX / 2, y: tranY }
    }
    if (anchor == 'w') {
      return { x: tranX, y: tranY / 2 }
    }
    if (anchor == 'n') {
      return { x: tranX / 2, y: 0 }
    }
    if (anchor == 'ne') {
      return { x: 0, y: 0 }
    }
    if (anchor == 'se') {
      return { x: 0, y: tranY }
    }
    if (anchor == 'nw') {
      return { x: tranX, y: 0 }
    }
    if (anchor == 'sw') {
      return { x: tranX, y: tranY }
    }
    return { x: 0, y: 0 }
  }

  // 绘制library
  Canvas2DKernel.prototype.drawLibrary = function (canvas) {
    function drawPaths(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let path = items[i]
        let p = paints[path.layer]
        canvas.drawPath(path.path, p.border_paint)
      }
    }
    function drawPolygons(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let polygon = items[i]
        let p = paints[polygon.layer]
        canvas.drawPath(polygon.path, p.fill_paint)
        canvas.drawPath(polygon.path, p.border_paint)
      }
    }
    function drawLabels(canvas, items) {
      function anchorTran(anchor, len, pt) {
        // canvaskit字体在右上角，按照此位置转换到对应的8个方位
        // let dpi = 96 // dpi: 一般电脑为96
        // let px = (pt * dpi) / 72 // 转为像素大小
        let offset = 4
        let tranX = len * pt * 0.6
        let tranY = 1 * pt

        if (anchor == 'o') {
          return { x: tranX / 2, y: tranY / 2 }
        }
        if (anchor == 'e') {
          return { x: 0, y: tranY / 2 }
        }
        if (anchor == 's') {
          return { x: tranX / 2, y: tranY + offset }
        }
        if (anchor == 'w') {
          return { x: tranX, y: tranY / 2 }
        }
        if (anchor == 'n') {
          return { x: tranX / 2, y: 0 - offset }
        }
        if (anchor == 'ne') {
          return { x: 0, y: 0 - offset }
        }
        if (anchor == 'se') {
          return { x: 0, y: tranY + offset }
        }
        if (anchor == 'nw') {
          return { x: tranX, y: 0 - offset }
        }
        if (anchor == 'sw') {
          return { x: tranX, y: tranY + offset }
        }
        return { x: 0, y: 0 }
      }
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let label = items[i]
        let p = paints[label.layer]
        let x = label.origin[0]
        let y = label.origin[1]
        let s = label.magnification // 字体缩放系数
        let tran = anchorTran(label.anchor, label.text.length, label_size_pt)
        // 根据字体方位来确定平移关系
        canvas.save()
        canvas.drawPath(label.path, p.font_path_paint)
        // canvas.drawCircle(x, y, 0.5, p.fill_paint)
        // canvas.drawCircle(x, y, 0.5, p.font_path_paint)
        canvas.translate(-x, y)
        canvas.scale(1 * s, -1 * s)
        canvas.translate(x / s, -y)
        canvas.drawText(label.text, x / s - tran.x, y + tran.y, p.font_paint, p.font_font)
        canvas.restore()
      }
    }
    function drawReferences(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        const ref = items[i]
        const ref_cell = cells[ref.name]
        canvas.save()
        canvas.concat([ref.magnification, 0, ref.origin[0], 0, ref.magnification * ref.x_reflection, ref.origin[1], 0, 0, 1])
        canvas.rotate((ref.rotation * 180) / Math.PI, 0, 0)
        drawPaths(canvas, ref_cell.paths)
        drawPolygons(canvas, ref_cell.polygons)
        drawLabels(canvas, ref_cell.labels)
        drawReferences(canvas, ref_cell.references)
        canvas.restore()
      }
    }
    if (!this.cells_cache) return
    let paints = this.paints
    let cells = this.cells_cache
    let cell = cells[this.cell_name]
    let label_size_pt = this.label_size_pt
    if (!cell) return
    drawPaths(canvas, cell.paths)
    drawPolygons(canvas, cell.polygons)
    drawLabels(canvas, cell.labels)
    drawReferences(canvas, cell.references)
  }

  // 绘制Cell
  Canvas2DKernel.prototype.drawCell = function (canvas) {
    function drawPaths(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let path = items[i]
        let p = paints[path.layer]
        canvas.drawPath(path.path, p.border_paint)
      }
    }
    function drawPolygons(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let polygon = items[i]
        let p = paints[polygon.layer]
        canvas.drawPath(polygon.path, p.fill_paint)
        canvas.drawPath(polygon.path, p.border_paint)
      }
    }
    function drawLabels(canvas, items) {
      function anchorTran(anchor, len, pt) {
        // canvaskit字体在右上角，按照此位置转换到对应的8个方位
        // let dpi = 96 // dpi: 一般电脑为96
        // let px = (pt * dpi) / 72 // 转为像素大小
        let offset = 4
        let tranX = len * pt * 0.6
        let tranY = 1 * pt

        if (anchor == 'o') {
          return { x: tranX / 2, y: tranY / 2 }
        }
        if (anchor == 'e') {
          return { x: 0, y: tranY / 2 }
        }
        if (anchor == 's') {
          return { x: tranX / 2, y: tranY + offset }
        }
        if (anchor == 'w') {
          return { x: tranX, y: tranY / 2 }
        }
        if (anchor == 'n') {
          return { x: tranX / 2, y: 0 - offset }
        }
        if (anchor == 'ne') {
          return { x: 0, y: 0 - offset }
        }
        if (anchor == 'se') {
          return { x: 0, y: tranY + offset }
        }
        if (anchor == 'nw') {
          return { x: tranX, y: 0 - offset }
        }
        if (anchor == 'sw') {
          return { x: tranX, y: tranY + offset }
        }
        return { x: 0, y: 0 }
      }
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        let label = items[i]
        let p = paints[label.layer]
        let x = label.origin[0]
        let y = label.origin[1]
        let s = label.magnification // 字体缩放系数
        let tran = anchorTran(label.anchor, label.text.length, label_size_pt)
        // 根据字体方位来确定平移关系
        canvas.save()
        canvas.drawPath(label.path, p.font_path_paint)
        // canvas.drawCircle(x, y, 0.5, p.fill_paint)
        // canvas.drawCircle(x, y, 0.5, p.font_path_paint)
        canvas.translate(-x, y)
        canvas.scale(1 * s, -1 * s)
        canvas.translate(x / s, -y)
        canvas.drawText(label.text, x / s - tran.x, y + tran.y, p.font_paint, p.font_font)
        canvas.restore()
      }
    }
    function drawReferences(canvas, items) {
      if (!items) return
      for (let i = 0, len = items.length; i < len; i++) {
        const ref = items[i]
        const ref_cell = cells[ref.name]
        canvas.save()
        canvas.concat([ref.magnification, 0, ref.origin[0], 0, ref.magnification * ref.x_reflection, ref.origin[1], 0, 0, 1])
        canvas.rotate((ref.rotation * 180) / Math.PI, 0, 0)
        drawPaths(canvas, ref_cell.paths)
        drawPolygons(canvas, ref_cell.polygons)
        drawLabels(canvas, ref_cell.labels)
        drawReferences(canvas, ref_cell.references)
        canvas.restore()
      }
    }
    if (!this.cells_cache) return
    let paints = this.paints
    let cell = this.cell
    let label_size_pt = this.label_size_pt
    if (!cell) return
    let paths = this.cell.get_paths(false, -1)
    let polygons = this.cell.get_polygons(false, false, -1)

    // drawPaths(canvas, cell.paths)
    // drawPolygons(canvas, cell.polygons)
    // drawLabels(canvas, cell.labels)
    // drawReferences(canvas, cell.references)
  }

  Canvas2DKernel.prototype.drawFrame = function (canvas) {
    canvas.drawColor(window.CanvasKit.BLACK)
    canvas.save()
    canvas.concat([this.tran.scale, 0, this.tran.dx, 0, -this.tran.scale, this.tran.dy, 0, 0, 1])
    // this.drawLibrary(canvas)
    this.drawCell(canvas)
    canvas.restore()
    // this.surface.requestAnimationFrame(this.drawFrameFunc)
  }

  // 清除画布
  Canvas2DKernel.prototype.clearFrame = function (canvas) {
    canvas.drawColor(window.CanvasKit.BLACK)
  }

  Canvas2DKernel.prototype.update = function () {
    this.surface.requestAnimationFrame(this.drawFrameFunc)
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Canvas2DKernel
  } else {
    window.Canvas2DKernel = Canvas2DKernel
  }
})()
