<template>
  <div>
    <el-dialog title="InLinePad" :visible.sync="InLinePadDialogVisible" width="1108px" :before-close="handleClose"
      :modal="false" @opened="InLinePadDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{ active: selectInLinePadRule, 'Header-tabs__item': true }"
                @click="InLinePadDialogSelect('rule')">{{ $t('messages.toolRules') }}</div>
              <div :class="{ active: selectInLinePadSize, 'Header-tabs__item': true }"
                @click="InLinePadDialogSelect('size')">{{ $t('messages.toolSize') }}</div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectInLinePadRule">
            <div class="tool-main__normal">
              <div class="main-group">
                <div class="main-label">a（{{ $t('messages.toolInLinePadDistance') }}:</div>
                <el-input-number :controls="false" v-model="InLinePad.rule.a"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">l1（{{ $t('messages.toolInLinePadLayer1') }}）:</div>
                <el-input-number :controls="false" v-model="InLinePad.rule.l1"></el-input-number>
              </div>
              <div class="main-group">
                <div class="main-label">l2（{{ $t('messages.toolInLinePadLayer2') }}）:</div>
                <el-input-number :controls="false" v-model="InLinePad.rule.l2"></el-input-number>
              </div>
            </div>
          </div>

          <!-- <div class="normal-rule" v-if="selectInLinePadSize">
            <div class="normal-rule-item" v-for="k in Object.keys(InLinePad.size)" :key="k" v-if="InLinePad.size[k].change">
              <div v-if="k !== 'indium_layer' && k !== 'indium_flip_layer' && k !== 'tin_layer' && k !== 'tin_flip_layer'">
                <div class="rule-item__label">{{ InLinePad.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-input-number :controls="false" v-model="InLinePad.size[k].value" @change="InLinePadSizeUpdate" :min="InLinePad.size[k].min" :max="InLinePad.size[k].max"></el-input-number>
                  &nbsp;{{ InLinePad.size[k].unit }}
                </div>
              </div>
              <div v-if="k === 'indium_layer' || k === 'indium_flip_layer' || k === 'tin_layer' || k === 'tin_flip_layer'">
                <div class="rule-item__label">{{ InLinePad.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-select v-model="InLinePad.size[k].value" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="InLinePadSizeUpdate">
                    <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                      <div class="layerOption">
                        <div class="layerImgBox">
                          <span style="float: left">{{ item.layerNumber }}</span>
                          <img :src="item.image" alt="" class="layerImage" />
                        </div>
                        <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div> -->

          <div class="normal-rule" v-if="selectInLinePadSize">
            <div class="normal-rule-item">
              <div class="rule-item__label">B：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="InLinePad.size.B.value" :min="InLinePad.size.B.min"
                  :max="InLinePad.size.B.max" @change="InLinePadSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">B1：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="InLinePad.size.B1.value" :min="InLinePad.size.B1.min"
                  :max="InLinePad.size.B1.max" @change="InLinePadSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="InLinePad.size.A.value" :min="InLinePad.size.A.min"
                  :max="InLinePad.size.A.max" @change="InLinePadSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A1：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="InLinePad.size.A1.value" :min="InLinePad.size.A1.min"
                  :max="InLinePad.size.A1.max" @change="InLinePadSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolInLinePadIndiumLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="InLinePad.size.indium_layer.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="InLinePadSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolInLinePadIndiumFlipLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="InLinePad.size.indium_flip_layer.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="InLinePadSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolInLinePadTinLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="InLinePad.size.tin_layer.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="InLinePadSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolInLinePadTinFlipLayer') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="InLinePad.size.tin_flip_layer.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="InLinePadSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img alt="" v-if="selectInLinePadRule" class="ann-image"
              src="../../../assets/icons/tools/Inlinepad-规则.svg" />
            <img alt="" v-if="selectInLinePadSize" class="ann-image"
              src="../../../assets/icons/tools/Inlinepad-尺寸.svg" />
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <canvas class="ann-image" id="InLinePadCtx" width="270" height="270"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="InLinePadDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="InLinePadDialogVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";

// import { AutoToolInLinePad } from '@/utils/tools/index'
const AutoToolInLinePad = require("@/utils/tools/inlinepad");
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");

const InLinePadDefault = {
  rule: {
    a: 10,
    l1: 2,
    l2: 3,
  },
  size: {
    B: { name: "B", value: 30, min: 25, max: 60, change: true, unit: "um" },
    B1: { name: "B1", value: 21, min: 18, max: 40, change: true, unit: "um" },
    A: { name: "A", value: 26, min: 20, max: 80, change: true, unit: "um" },
    A1: { name: "A1", value: 16, min: 10, max: 60, change: true, unit: "um" },
    pad_w: {
      name: "pad-w",
      value: 38,
      min: 0,
      max: 100,
      change: false,
      unit: "",
    },
    pad_h: {
      name: "pad-h",
      value: 38,
      min: 0,
      max: 100,
      change: false,
      unit: "",
    },
    indium_tolerance: {
      name: "indium-tolerance",
      value: 0.01,
      min: 40,
      max: 100,
      change: false,
      unit: "",
    },
    indium_layer: {
      name: window.i18n.t("messages.toolInLinePadIndiumLayer"),
      value: 7,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    indium_datatype: {
      name: "indium-datatype",
      value: 0,
      min: 0,
      max: 255,
      change: false,
      unit: "",
    },
    indium_flip_layer: {
      name: window.i18n.t("messages.toolInLinePadIndiumFlipLayer"),
      value: 8,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    indium_flip_datatype: {
      name: "indium-flip-datatype",
      value: 0,
      min: 0,
      max: 255,
      change: false,
      unit: "",
    },
    tin_tolerance: {
      name: "tin_tolerance",
      value: 0.01,
      min: 1e-2,
      max: 10,
      change: false,
      unit: "",
    },
    tin_layer: {
      name: window.i18n.t("messages.toolInLinePadTinLayer"),
      value: 9,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    tin_datatype: {
      name: "tin_datatype",
      value: 0,
      min: 0,
      max: 255,
      change: false,
      unit: "",
    },
    tin_flip_layer: {
      name: window.i18n.t("messages.toolInLinePadTinFlipLayer"),
      value: 10,
      min: 0,
      max: 255,
      change: true,
      unit: "",
    },
    tin_flip_datatype: {
      name: "B",
      value: 0,
      min: 0,
      max: 255,
      change: false,
      unit: "",
    },
  },
};

export default {
  data() {
    return {
      unit: "um",

      InLinePadDialogVisible: false,
      selectInLinePadRule: true,
      selectInLinePadSize: false,
      InLinePadTableData: [],
      InLinePad: InLinePadDefault,
      cellLayerList: [],
      fileLayerList: [],
      canvas2D: null,
    };
  },
  created() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();
    }, 1000);
  },
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = !item.selected;
        });
      }
    },
    InLinePadDialogOpened() {
      this.InLinePadSetDefault();
      this.InLinePadSizeUpdate();

      this.InLinePad.size.indium_layer.value =
        this.fileLayerList[0].layerNumber;
      this.InLinePad.size.indium_flip_layer.value =
        this.fileLayerList[0].layerNumber;
      this.InLinePad.size.tin_layer.value = this.fileLayerList[0].layerNumber;
      this.InLinePad.size.tin_flip_layer.value =
        this.fileLayerList[0].layerNumber;
    },

    InLinePadDialogSelect(type) {
      if (type == "rule") {
        this.selectInLinePadRule = true;
        this.selectInLinePadSize = false;
      }
      if (type == "size") {
        this.selectInLinePadRule = false;
        this.selectInLinePadSize = true;
      }
      this.InLinePadSizeUpdate();
    },

    InLinePadSizeUpdate() {
      let size = {};
      for (let k in this.InLinePad.size) {
        size[k] = this.InLinePad.size[k].value;
      }
      let rule = this.InLinePad.rule;

      let tc = new AutoToolInLinePad();
      let element = tc.InlinePadCell2D(
        [0, 0],
        size,
        rule.l1,
        rule.l2,
        Math.PI / 4,
        Math.PI + Math.PI / 4
      );
      let lib = new QGdstk.Library(element.name, 1e-6, 1e-9);
      lib.add(element);
      lib.add(element.dependencies(true));
      this.canvas2D.render(
        document.getElementById("InLinePadCtx"),
        lib,
        element.name
      );
    },
    InLinePadDialogOK() {
      let size = {};
      for (let k in this.InLinePad.size) {
        size[k] = this.InLinePad.size[k].value;
      }
      let conf = {
        max_distance: this.InLinePad.rule.a,
        size: size,
        connect: {
          top_layer: this.InLinePad.rule.l1,
          top_datatype: 0,
          bottom_layer: this.InLinePad.rule.l2,
          bottom_datatype: 0,
        },
        fileLayerList: this.$store.state.fileLayerList,
      };
      this.InLinePadDialogVisible = false;

      if (this.bus_type == "TOOL_INLINEPAD") {
        this.$message({
          message: i18n.t("messages.toolTipInlinepadPlaceWait"),
          type: "warning",
        });
        setTimeout(() => {
          bus.$emit("TOOL_INLINEPAD_RUN", conf);
        }, 500);
      }
      if (this.bus_type == "TOOL_INLINEPAD_BIG") {
        bus.$emit("TOOL_INLINEPAD_BIG_RUN", conf);
      }
    },

    InLinePadSetDefault() {
      this.InLinePad = JSON.parse(JSON.stringify(InLinePadDefault));

      // this.InLinePad = InLinePadDefault
      // this.InLinePad = JSON.parse(JSON.stringify(InLinePadDefault))
    },

    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;
      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });
      this.fileLayerDict = {};
      this.fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerDict[item.layerNumber] = item;
        }
      });
    },
  },
  mounted() {
    bus.$on("TOOL_INLINEPAD", (data) => {
      this.cellLayerInit();
      this.InLinePadDialogVisible = true;
      this.bus_type = "TOOL_INLINEPAD";
    });
    bus.$on("TOOL_INLINEPAD_BIG", (data) => {
      this.InLinePadDialogVisible = true;
      this.bus_type = "TOOL_INLINEPAD_BIG";
    });
  },
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }

    .cavity-body-tab {
      width: 489px;
    }

    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;

    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;

  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }

  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}

.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;

  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;

          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 150px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 150px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 250px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 250px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 250px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
        /deep/.el-input__icon {
          line-height: 28px;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}
.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
