<template>
  <div>
    <!-- 检测线 -->
    <div class="testLineDialog">
      <el-dialog title="检测线" :visible.sync="testLineDialogVisible" :modal="false" :close-on-click-modal="false">

        <div class="testLineMain">
          <div class="minWidthArea">
            <span style="padding-right:89px">两条线最小距离:</span>
            <div>
              <el-input v-model="value_minWidth"></el-input>um
            </div>
          </div>
          <el-table class="layerTable" ref="layerTable" :data="table_testLineLayer" tooltip-effect="dark"
            style="width: 100%" highlight-current-row @current-change="selectLayer">
            <el-table-column prop="layerNumber" label="图层编号" width="100">
            </el-table-column>
            <el-table-column prop="layerName" label="图层">
            </el-table-column>
            <el-table-column type="selection" width="55">
            </el-table-column>
          </el-table>
        </div>

        <div class="testLineLabel">
          <div class="labelTitle">标注图</div>
          <div class="labelWindow">
            <div class="labelImg">
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="boardOptionDialogSureBtn" type="primary" @click="testLineDialogSureBtn"
            size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="testLineDialogVisible = false;resetTestLineDialog()" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import bus from "../common/bus";
export default {
  data() {
    return {
      testLineDialogVisible: false,
      value_backgroundColor: "",
      table_testLineLayer: [],
      value_minWidth: "",

      //测试数据
      layerDatas: [
        {
          layerName: "Metal1",
          lock: false,
          hide: false,
          layerNumber: 1,
          color: "#1955FF",
          shape: "F1",
          border: "B1",
          borderColor: "#1955FF",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Metal2",
          lock: false,
          hide: false,
          layerNumber: 2,
          color: "#7DAEF7",
          shape: "F6",
          border: "B1",
          borderColor: "#7DAEF7",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Metal3",
          lock: false,
          hide: false,
          layerNumber: 3,
          color: "#9B51EE",
          shape: "F3",
          border: "B1",
          borderColor: "#9B51EE",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Metal3-Flip",
          lock: false,
          hide: false,
          layerNumber: 4,
          color: "#C660F1",
          shape: "F3",
          border: "B1",
          borderColor: "#C660F1",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Metal4",
          lock: false,
          hide: false,
          layerNumber: 5,
          color: "#A6B65A",
          shape: "F3",
          border: "B1",
          borderColor: "#A6B65A",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Metal4-Flip",
          lock: false,
          hide: false,
          layerNumber: 6,
          color: "#7FB65A",
          shape: "F3",
          border: "B1",
          borderColor: "#7FB65A",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Indium",
          lock: false,
          hide: false,
          layerNumber: 7,
          color: "#E8CE3B",
          shape: "F1",
          border: "B1",
          borderColor: "#E8CE3B",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Indium-Flip",
          lock: false,
          hide: false,
          layerNumber: 8,
          color: "#DEB22B",
          shape: "F3",
          border: "B1",
          borderColor: "#DEB22B",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "TiN",
          lock: false,
          hide: false,
          layerNumber: 9,
          color: "#B069E7",
          shape: "F1",
          border: "B1",
          borderColor: "#B069E7",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "TiN-Flip",
          lock: false,
          hide: false,
          layerNumber: 10,
          color: "#D469E7",
          shape: "F3",
          border: "B1",
          borderColor: "#D469E7",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Junction",
          lock: false,
          hide: false,
          layerNumber: 11,
          color: "#E60012",
          shape: "F4",
          border: "B1",
          borderColor: "#E60012",
          image: "",
          snow_id: "",
          isExport: true,
        },
        {
          layerName: "Label",
          lock: false,
          hide: false,
          layerNumber: 12,
          color: "",
          shape: "",
          border: "B1",
          borderColor: "#000000",
          image: "",
          snow_id: "",
          isExport: true,
        },
      ],
    };
  },
  mounted() {
    bus.$on("testLine", (data) => {
      this.testLineDialogVisible = true;

      this.all_layer = this.$store.state.cellLayerDatas;
      this.TiNPadTableData = this.all_layer.map((item) => {
        const ob = {
          layerName: item.layerName,
          distance: 10,
          selected: false,
          datatype: 0,
          layer: item.layerNumber,
        };
        return ob;
      });
      this.TiNPadInPillarDialogVisible = true;

      if (this.$route.query.hasOwnProperty("id")) {
        let file_snow_id = this.$route.query.id.split("/")[0];
        let index = this.$store.state.layerDatas.findIndex(
          (item) => item.file_snow_id === file_snow_id
        );
        if (index !== -1) {
          this.table_testLineLayer =
            this.$store.state.layerDatas[index].layerDatas;
        }
      }
    });
  },
  methods: {
    testLineDialogSureBtn() {},
    resetTestLineDialog() {},
    selectLayer() {},
  },
};
</script>

<style lang="less" scoped>
.testLineDialog {
  color: #333;
  /deep/.el-dialog {
    width: 808px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 10px;
    max-height: 700px;
    overflow: hidden;
    display: flex;
  }

  .testLineMain {
    width: 480px;
    max-height: 770px;
    min-height: 270px;
    margin: 0 10px;
    background-color: #f4f4f4;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    .minWidthArea {
      position: relative;
      width: 460px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/.el-input {
        width: 90px;
        height: 28px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 90px;
        height: 28px;
        padding-left: 15px;
        border-radius: 2px;
        background-color: #ffffff !important;
      }
    }

    .layerTable {
      /deep/.el-table__header-wrapper {
        .has-gutter {
          .el-table__cell:first-child {
            text-align: center;
          }
        }
      }
      /deep/.el-table__body-wrapper {
        max-height: 550px;
        overflow-y: scroll;
        .el-table__row {
          .el-table__cell:first-child {
            text-align: center;
          }
        }
      }
    }
  }
  .testLineLabel {
    width: 280px;
    max-height: 770px;
    min-height: 270px;
    margin: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #dddddd;
    border-radius: 4px;
    .labelTitle {
      width: 280px;
      height: 30px;
      border-bottom: 1px solid #dddddd;
      background-color: #f4f4f4;
      line-height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
    }
    .labelWindow {
      width: 280px;
      min-height: 280px;
      flex: 1;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;

      .labelImg {
        width: 265px;
        height: 265px;
        background-color: #ffffff;
      }
    }
  }

  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>