<template>
  <div class="container" v-show="drcShow">
    <el-dialog id="drc-editor" :title="$t('messages.Drc')" custom-class="dialog-window" :visible.sync="dialogVisible"
      :show-close="false" :before-close="handleDialogClose" :modal="false" @opened="dialogOpened">
      <div slot="title" class="medium">
        <div class="centers"><span>{{$t('messages.Drc')}}</span></div>
        <div class="icons">
          <i class="el-icon-minus" style="font-size: 24px" @click="minusDrc"></i>
          <!-- <i class="el-icon-full-screen" style="font-size: 24px"></i> -->
          <i class="el-icon-close" style="font-size: 24px" @click="handleCloseDrcDialog"></i>
        </div>
      </div>
      <Sidebar class="sidebar"></Sidebar>
      <div class="fileSite">
        <div>{{$t('messages.drcSite')}}：</div>
        <div>{{$store.state.topInfo.name}}></div>
        <div>{{$store.state.proInfo.name}}></div>
        <div>{{$store.state.fileInfo.name}}></div>
        <div>{{$store.state.cellInfo.name}}</div>
      </div>
      <div class="grid-container">
        <div class="gird-item grid-item-file">
          <div class="tab">
            <div class="tab-header">DRC{{$t('messages.fileList')}}</div>
            <div class="tab-body" style="overflow-y:auto;" v-loading="listLoading">
              <div @contextmenu.prevent="onContextmenu($event, item)" class="item" v-for="item, key in drcList"
                :key="key" v-show="item!==null">
                <div v-if="item && !item.edit" :class="{active: item.selected, 'item-text': true}"
                  @dblclick="selectDrcFile(item)">
                  <span class="iconfont icon-drc_drcwenjian"></span>
                  <div class="drc-name" :title="item.name">{{item.name}}</div>
                  <span v-show="item.origin" class="iconfont icon-drc_guanlian item-flex"></span>
                </div>
                <div class="item-text" v-else-if="item && item.edit">
                  <el-input v-focus :ref="key" v-model="item.name" minlength="3" maxlength="30" size="mini"
                    @change="changeName(item)" @keyup.enter.native="changeName(item)" @blur="changeName(item)"
                    @keyup.esc.native="cancelRename(item, key)" @keyup.native="diykeyUp"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gird-item grid-item-code">
          <div class="tab">
            <div class="tab-header-tab">
              <el-tabs v-model="activeTabName" type="border-card" closable @tab-remove="closeDialog"
                @tab-click="handleClickTab($event)">
                <el-tab-pane v-for="item of editableTabs" :key="item.name" :name="item.name">
                  <span slot="label" style="font-size: 13px; line-height: 26px; display: flex">
                    <i class="iconfont icon-drc_drcwenjian" style="padding-right: 6px; font-size: 18px"></i>
                    <div class="tab-name"><span style="padding-right: 2px; font-size: 18px"
                        v-if="!item.isSaved">*</span>{{ item.name }}</div>
                  </span>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="tab-body" id="codeDiv">
              <div v-show="currentDrc">
                <code-mirror ref="codes" v-model="code"></code-mirror>
              </div>
              <div v-show="!currentDrc"></div>
            </div>
          </div>
        </div>
        <div class="gird-item grid-item-log">
          <div class="tab">
            <div class="tab-header">{{$t('messages.logs')}}</div>
            <div class="tab-body" style="font-size:12px;padding:5px;">{{codeError}}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delDrcVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.deletePcellTips') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitDeleteDrc">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="delDrcVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.pcellFileImport')" :visible.sync="fileImportVisible" width="575px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <div>
          <el-upload class="upload-demo" ref="upload" action="https://jsonplaceholder.typicode.com/posts/"
            :file-list="drcFileList" :auto-upload="false" multiple accept=".py" :limit="5">
            <el-button slot="trigger" size="small" type="primary">{{$t('messages.selectPcellFile')}}</el-button>
            <el-button :loading="importFileLoading" style="margin-left: 10px;" size="small" type="success"
              @click="submitImport">{{$t('messages.upload')}}</el-button>
            <div slot="tip" class="el-upload__tip">{{$t('messages.uploadDrcFile')}}</div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel" @click="fileImportVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsDrcVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsDrcList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitReplaceFile">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceFile"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="closeTipsVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.saveChanges') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="handleCloseTab(true)">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="danger" size="mini" @click="handleCloseTab(false)">
          {{ $t('messages.closeWithoutSaving') }}
        </el-button>
        <el-button type="cancel" @click="closeTipsVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="progressVisible" width="575px" :before-close="handleClose"
      :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.stop_progress') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="sureCloseProgress">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="progressVisible=false"
          size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="fileNotSavedVisible" width="575px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.fileNotSaved') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="fileNotSaveSaveAll">
          {{ $t('messages.saveAll') }}
        </el-button>
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="notSaveAll">
          {{ $t('messages.notSave') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="fileNotSavedVisible=false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Md5 from "js-md5";
import CodeMirror from "@/components/common/CodeEditor.vue";
import { saveAs } from "file-saver";
import bus from "@/components/common/bus";
import i18n from "@/common/lang/i18n";
import Sidebar from "./Sidebar.vue";
import {
  createDrc,
  editDrc,
  deleteDrc,
  getDrcList,
  importDrc,
  getOfficialPDKInfo,
} from "@/api/api";
import { deepCopy } from "@/utils/utils";
import {
  apiDrcUpload,
  apiDrcTaskRun,
  apiDrcTaskResult,
  apiDrcTest,
} from "@/api/drc/drc.js";
import { cellToGDSu8 } from "@/components/homes/fileList/function/fileListPublicFn";
import { saveJSON, downloadBlob, deepClone } from "@/utils/utils";
import _ from "lodash";
import { downloadFileData } from "@/components/homes/fileList/function/objectStorageFn";
export default {
  components: {
    Sidebar,
    CodeMirror,
  },
  data() {
    return {
      clickRoute: null,
      targetName: null,
      delDrcVisible: false,
      closeTipsVisible: false,
      saveToLibraryDialogVisible: false,
      existsDrcVisible: false,
      existsDrcNameVisible: false,
      fileImportVisible: false,
      importFileLoading: false,
      previewImageVisible: false,
      uploadPreviewLoading: false,
      code: "",
      selectedText: "",
      dialogVisible: false,
      lastIndex: 0,
      args: [],
      drcList: [],
      editableTabs: [],
      activeTabName: "",
      currentDrc: null,
      currentIndex: -2,
      lineNum: 0,
      listLoading: false,
      delDrc: null,
      oldName: "",
      codeError: "",
      drcFileList: [],
      existsDrcList: [],
      initStatus: true,
      replaceLoading: false,
      progress_percent: 0,
      start_run: false,
      progressVisible: false,
      drcShow: true,
      fileNotSavedVisible: false,
      task_timer: null,
      reNameDrc: null,
    };
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus();
      },
    },
  },
  created() {},
  methods: {
    diykeyUp(e) {
      let bak = deepCopy(e.target.value);
      if (!e.target.value) {
        e.target.value = bak;
      }
      e.target.value = e.target.value.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g,
        ""
      );
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
      e.target.value = e.target.value.replace(/[\u0391-\uFFE5]/gi, "");
    },
    handleClose(done) {
      done();
    },
    handleDialogClose(done) {
      if (this.start_run) {
        this.progressVisible = true;
        return;
      }
      done();
    },
    notSaveAll() {
      for (let i = 0; i < this.editableTabs.length; i++) {
        this.editableTabs[i].isSaved = true;
      }
      this.handleCloseDrcDialog();
    },
    handleCloseDrcDialog() {
      let editStatus = false;
      for (let i = 0; i < this.editableTabs.length; i++) {
        if (!this.editableTabs[i].isSaved) {
          editStatus = true;
          break;
        }
      }
      if (editStatus) {
        this.fileNotSavedVisible = true;
        return;
      }
      if (this.start_run) {
        this.progressVisible = true;
        return;
      }
      this.editableTabs.forEach((item) => {
        sessionStorage.removeItem(item.snow_id);
      });
      this.activeTabName = "";
      this.editableTabs = [];
      this.drcList = [];
      this.drcFileList = [];
      this.existsDrcList = [];
      this.initStatus = true;
      this.currentIndex = -2;
      this.dialogVisible = false;
      this.code = "";
      try {
        this.$refs.codes.codeMirror.setValue(this.code);
        this.$refs.codes.codeMirror.clearHistory();
      } catch {}
      this.currentDrc = false;
      this.fileNotSavedVisible = false;
      bus.$emit("updateOpenDRCStatus", false);
    },
    //弹出弹窗
    closeDialog(targetName) {
      if (this.currentDrc.isSaved) {
        this.removeTab(targetName);
        return;
      }
      this.closeTipsVisible = true;
      this.targetName = targetName;
    },
    async getOfficialPDK() {
      let project_snow_id = this.$store.state.proInfo.projectId;

      let res = await getOfficialPDKInfo({ project_snow_id: project_snow_id });
      if (res.code == 200000) {
        if (res.data.url_data) {
          let drc_url = res.data.url_data.drc;
          let drc_data = await this.getDRCObjectData(drc_url);
          let origin_data = {
            name: "drc",
            snow_id: "43a950da-9e11-41a6-8f6f-4e591db714bb",
            selected: false,
            drc: drc_data,
            origin: true,
          };
          this.drcList.unshift(origin_data);
        } else {
          this.drcList.unshift(null);
        }
      }
    },
    async updateOfficialPDK() {
      let project_snow_id = this.$store.state.proInfo.projectId;

      let res = await getOfficialPDKInfo({ project_snow_id: project_snow_id });
      if (res.code == 200000) {
        if (res.data.url_data) {
          let drc_url = res.data.url_data.drc;
          let drc_data = await this.getDRCObjectData(drc_url);
          let origin_data = {
            name: "drc",
            snow_id: "43a950da-9e11-41a6-8f6f-4e591db714bb",
            selected: false,
            drc: drc_data,
            origin: true,
          };
          this.drcList[0] = origin_data;
        } else {
          this.drcList[0] = null;
        }
      }
    },
    selectDrcItem(targetName) {
      let index = this.drcList.findIndex((item) => {
        if (item) {
          return item.name == targetName;
        }
      });
      for (let i = 0; i < this.drcList.length; i++) {
        if (this.drcList[i]) {
          this.drcList[i].selected = false;
        }
      }
      this.selectDrcFile(this.drcList[index]);
    },
    removeTab(targetName) {
      let deductIndex = this.editableTabs.findIndex((item) => {
        if (item) {
          return item.name == targetName;
        }
      });
      sessionStorage.removeItem(this.editableTabs[deductIndex].snow_id);
      this.editableTabs.splice(deductIndex, 1);
      if (this.editableTabs.length > 0) {
        if (this.activeTabName == targetName) {
          this.currentDrc =
            this.editableTabs[deductIndex - 1 > -1 ? deductIndex - 1 : 0];
          this.activeTabName = this.currentDrc.name;
          deductIndex -= 1;
        }
        this.currentIndex = this.drcList.findIndex((item) => {
          if (item) {
            return item.snow_id == this.currentDrc.snow_id;
          }
        });
        this.selectDrcItem(this.currentDrc.name);
      } else {
        this.code = "";
        let editor = this.$refs.codes.codeMirror;
        editor.setValue(this.code);
        editor.clearHistory();
        this.currentDrc = null;
      }
    },
    handleCloseTab(Save) {
      if (Save) {
        this.saveDrc();
        this.removeTab(this.targetName);
      } else {
        this.removeTab(this.targetName);
      }
      this.closeTipsVisible = false;
    },
    //tab栏单击事件
    handleClickTab(route) {
      this.initStatus = true;
      this.clickRoute = route;
      if (route !== this.$route.fullPath) {
        this.clickTab(this.clickRoute);
      }
      this.selectDrcItem(route.name);
      setTimeout(() => {
        this.initStatus = false;
      }, 500);
    },
    async clickTab(route) {
      let save_obj = {
        drc: this.code,
      };
      sessionStorage.setItem(this.currentDrc.snow_id, JSON.stringify(save_obj));
      // 解决bug-1663临时方案，切换tab自动保存
      if (!this.currentDrc.origin) {
        let params = new FormData();
        let json_blob = saveJSON(save_obj, this.currentDrc.name);
        // params.append("name", this.currentDrc.name);
        params.append("file", json_blob);
        params.append("snow_id", this.currentDrc.snow_id);
        params.append("is_cover", 0);
        params.append("compile_status", 1);
        let res = await editDrc(params);
      }

      this.$refs.codes.codeMirror.clearHistory();
      this.currentDrc.isSaved = true;

      let targetName = route.name;
      this.currentDrc = this.drcList.filter((item) => {
        if (item && item.name == targetName) {
          return item;
        }
      })[0];
      let all_params = sessionStorage.getItem(this.currentDrc.snow_id);
      all_params = JSON.parse(all_params);
      let editor = this.$refs.codes.codeMirror;
      this.code = all_params.drc;
      editor.setValue(this.code);
    },
    async onContextmenu(event, item) {
      if (item.origin) return;
      this.$contextmenu({
        items: [
          {
            label: this.$t("messages.create"),
            onClick: () => {
              this.createNewDrcFile();
            },
            icon: "iconfont icon-xinjian",
          },
          {
            label: this.$t("messages.rename"),
            onClick: () => {
              item.edit = true;
              this.reNameDrc = item;
              this.oldName = deepCopy(item.name);
            },
            icon: "iconfont icon-zhongmingming",
          },
          {
            label: this.$t("messages.delete"),
            onClick: () => {
              this.delDrc = item;
              this.delDrcVisible = true;
            },
            icon: "iconfont icon-shanchu",
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 1000, // 菜单样式 z-index
        minWidth: 170, // 主菜单最小宽度
      });
    },
    async dialogOpened() {
      await this.getDrcListData();
      let editor = this.$refs.codes.codeMirror;
      editor.setSize(
        "auto",
        window.getComputedStyle(document.getElementById("codeDiv")).height
      );
      for (let i = 0; i < this.drcList.length; i++) {
        if (this.drcList[i]) {
          if (sessionStorage.getItem(this.drcList[i].snow_id)) {
            sessionStorage.removeItem(this.drcList[i].snow_id);
          }
        }
      }
    },
    changeParams() {
      if (this.initStatus) return;
      if (!this.currentDrc) return;
      if (this.currentDrc.origin) return;
      this.currentDrc.isSaved = false;
      let save_obj = {
        drc: this.code,
      };
      sessionStorage.setItem(this.currentDrc.snow_id, JSON.stringify(save_obj));
    },
    async getObjectData(fileUrl) {
      let apiKey = localStorage.getItem("apiKey");
      let minio_name = localStorage.getItem("minio_name");
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      let file_content = await fetch("/file/api/v1/get_object_data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          username: minio_name,
          sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
          time,
          bucket_name,
          object_path: fileUrl,
        }),
      });
      let array_buffer = await file_content.arrayBuffer();
      let u8 = new Uint8Array(array_buffer);
      let enc = new TextDecoder("utf-8");
      return JSON.parse(enc.decode(u8));
    },
    async getDRCObjectData(fileUrl) {
      let apiKey = localStorage.getItem("apiKey");
      let minio_name = localStorage.getItem("minio_name");
      let bucket_name = localStorage.getItem("bucket_name");
      let time = parseInt(new Date().getTime() / 1000);
      let file_content = await fetch("/file/api/v1/get_object_data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          username: minio_name,
          sign: Md5(`${apiKey}_${time}`),
        },
        body: JSON.stringify({
          time,
          bucket_name,
          object_path: fileUrl,
        }),
      });
      let array_buffer = await file_content.arrayBuffer();
      let u8 = new Uint8Array(array_buffer);
      let enc = new TextDecoder("utf-8");
      return enc.decode(u8);
    },
    async getDrcListData() {
      this.listLoading = true;
      let res = await getDrcList();
      if (res.code == 200000) {
        this.drcList = res.data.map((item) => {
          let obj = {
            edit: false,
            selected: false,
            isSaved: true,
            origin: false,
          };
          return Object.assign(item, obj);
        });
        await this.getOfficialPDK();
        if (this.currentIndex > -1) {
          this.currentDrc = this.drcList[this.currentIndex];
          this.drcList[this.currentIndex].selected = true;
          let old_eaitTabs = deepClone(this.editableTabs);
          for (let i = 0; i < old_eaitTabs.length; i++) {
            for (let j = 0; j < this.drcList.length; j++) {
              if (
                this.drcList[j] &&
                old_eaitTabs[i].snow_id == this.drcList[j].snow_id
              ) {
                this.editableTabs[i] = this.drcList[j];
              }
            }
          }
        }
        this.listLoading = false;
      }
      this.listLoading = false;
    },
    axiosChangDrcName: _.debounce(async function (params) {
      try {
        let res = await editDrc(params);
        if (res.code == 200000) {
          this.$message.success(this.$t("messages.operationSuccess"));
          this.listLoading = true;
          setTimeout(() => {
            this.getDrcListData();
          }, 500);
        }
      } catch (e) {
        this.reNameDrc.name = this.oldName;
      }
    }),
    async changeName(row) {
      if (row.name == this.oldName) {
        row.edit = false;
        return;
      }
      if (!this.checkName(row.name)) {
        this.$message.error(this.$t("messages.drcNameError"));
        return;
      }
      row.edit = false;
      let params = new FormData();
      params.append("name", row.name);
      params.append("file", "");
      params.append("snow_id", row.snow_id);
      params.append("compile_status", row.compile_status);
      this.axiosChangDrcName(params);
    },
    cancelRename(row) {
      row.name = this.oldName;
      row.edit = false;
    },
    async getDRCORIGIN(row) {
      return new Promise((resolve) => {
        const res_file = {
          drc: row.drc,
        };
        resolve(res_file);
      });
    },
    async selectDrcFile(row) {
      this.initStatus = true;
      let res_file = {};
      for (let i = 0; i < this.drcList.length; i++) {
        if (this.drcList[i]) {
          this.drcList[i].edit = false;
          this.drcList[i].selected = false;
          this.drcList[i].isSaved = true;
          if (this.drcList[i].snow_id == row.snow_id) {
            this.currentIndex = i;
          }
        }
      }
      row.selected = true;
      this.currentDrc = row;
      this.activeTabName = row.name;
      if (!_.find(this.editableTabs, row)) {
        this.editableTabs.push(row);
      }
      if (sessionStorage.getItem(row.snow_id)) {
        res_file = JSON.parse(sessionStorage.getItem(row.snow_id));
      } else {
        if (!row.origin) {
          res_file = await this.getObjectData(this.currentDrc.url);
        } else {
          res_file = await this.getDRCORIGIN(row);
        }
      }
      sessionStorage.setItem(this.currentDrc.snow_id, JSON.stringify(res_file));
      let editor = this.$refs.codes.codeMirror;
      this.code = res_file.drc;
      editor.setValue(this.code);
      editor.clearHistory();
      setTimeout(() => {
        this.initStatus = false;
      }, 1000);
    },
    axiosCreateNewDrc: _.debounce(async function (params) {
      let res = await createDrc(params);
      if (res.code == 200000) {
        this.lastIndex += 1;
        setTimeout(() => {
          this.getDrcListData();
        }, 500);
        setTimeout(() => {
          this.drcList[this.drcList.length - 1].edit = true;
          this.oldName = deepCopy(this.drcList[this.drcList.length - 1].name);

          try {
            this.$refs[this.drcList.length - 1].focus();
          } catch (e) {}
        }, 1000);
      }
    }),
    async submitDeleteDrc() {
      let params = {
        snow_ids: [this.delDrc.snow_id],
      };
      this.axiosDeleteDrc(params);
    },
    axiosDeleteDrc: _.debounce(async function (params) {
      let res = await deleteDrc(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.deleteSuccess"));
        this.listLoading = true;
        if (
          this.editableTabs.filter((item) => item.name == this.delDrc.name)
            .length > 0
        ) {
          this.removeTab(this.delDrc.name);
        }
        setTimeout(() => {
          this.getDrcListData();
        }, 500);
        this.delDrcVisible = false;
      }
    }),
    getLastIndex() {
      if (this.drcList.length > 0) {
        let index_arr = [0];
        for (let i = 0; i < this.drcList.length; i++) {
          if (this.drcList[i]) {
            if (
              typeof (
                Number(this.drcList[i].name.split("drc_")[1]) == "number"
              ) &&
              !isNaN(Number(this.drcList[i].name.split("drc_")[1]))
            ) {
              index_arr.push(Number(this.drcList[i].name.split("drc_")[1]));
            }
          }
        }
        if (index_arr.length > 1) {
          index_arr = index_arr.sort((left, right) => right - left);
        }
        return index_arr[0];
      }
      return 0;
    },
    checkName(name) {
      return name.substr(0, 3) == "drc";
    },
    async createNewDrcFile() {
      let params = new FormData();
      this.lastIndex = this.getLastIndex();

      let params_obj = {
        drc: "",
      };
      let json_blob = saveJSON(params_obj, `drc_${this.lastIndex + 1}`);
      params.append("name", `drc_${this.lastIndex + 1}`);
      params.append("file", json_blob);
      params.append("is_cover", 0);
      this.axiosCreateNewDrc(params);
    },
    axiosdeleteDrc: _.debounce(async function (params) {
      let res = await deleteDrc(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.deleteSuccess"));
        this.listLoading = true;
        if (
          this.editableTabs.filter((item) => item.name == this.delDrc.name)
            .length > 0
        ) {
          this.removeTab(this.delDrc.name);
        }
        setTimeout(() => {
          this.getDrcListData();
        }, 500);
        this.delDrcVisible = false;
      }
    }),
    async submitdeleteDrc() {
      let params = {
        snow_ids: [this.delDrc.snow_id],
      };
      this.axiosdeleteDrc(params);
    },
    async saveDrc() {
      if (!this.currentDrc) return;
      if (this.currentDrc.origin) return;
      let save_obj = {
        drc: this.code,
      };
      let params = new FormData();
      let json_blob = saveJSON(save_obj, this.currentDrc.name);
      // params.append("name", this.currentDrc.name);
      params.append("file", json_blob);
      params.append("snow_id", this.currentDrc.snow_id);
      params.append("is_cover", 0);
      params.append("compile_status", 1);
      let res = await editDrc(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.saveSuccess"));
        this.currentDrc.isSaved = true;
        sessionStorage.setItem(
          this.currentDrc.snow_id,
          JSON.stringify(save_obj)
        );
      }
    },
    async updateDRCStatus(status) {
      if (!this.currentDrc) return;
      if (this.currentDrc.origin) return;
      let save_obj = {
        drc: this.code,
      };
      this.currentDrc.compile_status = status;
      let params = new FormData();
      let json_blob = saveJSON(save_obj, this.currentDrc.name);
      // params.append("name", this.currentDrc.name);
      params.append("file", json_blob);
      params.append("snow_id", this.currentDrc.snow_id);
      params.append("is_cover", 0);
      params.append("compile_status", status);
      let res = await editDrc(params);
      if (res.code == 200000) {
        sessionStorage.setItem(
          this.currentDrc.snow_id,
          JSON.stringify(save_obj)
        );
      }
    },
    toUint8Arr(str) {
      const buffer = [];
      for (let i of str) {
        const _code = i.charCodeAt(0);
        if (_code < 0x80) {
          buffer.push(_code);
        } else if (_code < 0x800) {
          buffer.push(0xc0 + (_code >> 6));
          buffer.push(0x80 + (_code & 0x3f));
        } else if (_code < 0x10000) {
          buffer.push(0xe0 + (_code >> 12));
          buffer.push(0x80 + ((_code >> 6) & 0x3f));
          buffer.push(0x80 + (_code & 0x3f));
        }
      }
      return Uint8Array.from(buffer);
    },
    async runDrc() {
      let file_u8 = this.toUint8Arr(this.code);
      const drc_file = new Blob([file_u8]);
      const gds_file = new Blob([cellToGDSu8(this.$store.state.activeCell)]);
      let formData = new FormData(); //新建表单对象
      formData.append("files", gds_file, "test.gds"); //把文件二进制对象添加到表单对象里
      formData.append("files", drc_file, "test.py"); //把文件二进制对象添加到表单对象里
      try {
        let res = await apiDrcUpload(formData);
        if (res.code == 200) {
          this.runProgress = true;
          let file_md5 = res.data;
          this.taskRun(file_md5);
          this.start_run = true;
        }
      } catch {
        this.codeError = this.$t("messages.drcUploadError");
        this.taskStop();
      }
    },
    taskRun(file_md5) {
      apiDrcTaskRun({
        filename: file_md5,
        cellname: this.$store.state.activeCell.name,
      }).then((data) => {
        if (data.code == 200) {
          this.codeError = [];
          this.progress_percent = 0;
          this.task_timer = setInterval(() => {
            apiDrcTaskResult(data.data).then((data) => {
              if (data.code == 200) {
                if (data.data.task_status == "SUCCESS") {
                  this.progress_percent = 100;
                  this.codeError = JSON.parse(data.data.task_result);
                  for (let i = 0; i < this.codeError.length; i++) {
                    this.codeError[i].data = this.codeError[i].data.splice(
                      0,
                      999
                    );
                  }
                  clearInterval(this.task_timer);
                  this.start_run = false;
                  this.$message.success(this.$t("messages.drcRunSuccess"));
                  this.minusDrc();
                  bus.$emit("openDRCtools", data.data);
                  this.codeError = this.$t("messages.drcRunSuccess");
                  this.updateDRCStatus(3);
                  this.taskStop();
                } else {
                  if (this.progress_percent < 90) this.progress_percent += 3;
                  bus.$emit("updateProgress", this.progress_percent);
                }
              }
              if (data.code == 500) {
                this.codeError = data.data.task_result;
                this.updateDRCStatus(2);
                this.taskStop();
              }
            });
          }, 1000);
        }
      });
    },
    // 暂停任务
    taskStop() {
      if (this.task_timer) {
        clearInterval(this.task_timer);
        this.progress_percent = 0;
        this.start_run = false;
        bus.$emit("stopProgress", true);
      }
    },
    sureCloseProgress() {
      this.taskStop();
      this.progressVisible = false;
      this.handleCloseDrcDialog();
    },
    transPyToJSON(file) {
      return new Promise(function (resolve, reject) {
        let res_file = {};
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
          res_file["drc"] = reader.result;
          resolve(res_file);
        };
      });
    },
    async submitImport() {
      this.importFileLoading = true;
      let files = this.$refs.upload.uploadFiles;
      if (files.length == 0) {
        this.importFileLoading = false;
        return;
      }
      let params = new FormData();
      for (let i = 0; i < files.length; i++) {
        let res_file = await this.transPyToJSON(files[i].raw);
        let drc_blob = saveJSON(res_file, files[i].name);
        let upload_file = new File([drc_blob], files[i].name);
        params.append("file", upload_file);
      }

      params.append("is_cover", 0);
      let res = {};
      try {
        res = await importDrc(params);
      } catch {
        this.importFileLoading = false;
      }
      if (res.code == 200000) {
        if (res.data.name_exists.length > 0) {
          this.existsDrcList = res.data.name_exists;
          this.existsDrcVisible = true;
        } else {
          this.$message.success(this.$t("messages.importSucceeded"));
          this.$refs.upload.clearFiles();
          setTimeout(() => {
            this.getDrcListData();
          }, 500);
        }
        this.fileImportVisible = false;
        this.importFileLoading = false;
      }
    },
    async submitReplaceFile() {
      let existsFiles = [];

      for (let i = 0; i < this.existsDrcList.length; i++) {
        existsFiles.push(
          this.$refs.upload.uploadFiles.filter(
            (item) => item.name.split(".py")[0] == this.existsDrcList[i]
          )[0]
        );
      }
      let params = new FormData();
      for (let i = 0; i < existsFiles.length; i++) {
        let res_file = await this.transPyToJSON(existsFiles[i].raw);
        let drc_blob = saveJSON(res_file, existsFiles[i].name);
        let upload_file = new File([drc_blob], existsFiles[i].name);
        params.append("file", upload_file);
      }

      params.append("is_cover", 1);
      let res = await importDrc(params);
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.importSucceeded"));
        this.$refs.upload.clearFiles();
        this.existsDrcList = [];
        this.existsDrcVisible = false;
      }
    },
    cancelSubmitReplaceFile() {
      this.$refs.upload.clearFiles();
      this.existsDrcList = [];
      this.existsDrcVisible = false;
    },
    async saveAllDrclFile() {
      let waitsaveDrcs = this.editableTabs.filter(
        (item) => item.isSaved == false && item.origin == false
      );
      bus.$emit("screen_lock", true);
      for (let i = 0; i < waitsaveDrcs.length; i++) {
        let drc_obj = sessionStorage.getItem(waitsaveDrcs[i].snow_id);
        let drc_blob = saveJSON(JSON.parse(drc_obj), waitsaveDrcs[i].name);
        let params = new FormData();
        // params.append("name", waitsaveDrcs[i].name);
        params.append("file", drc_blob);
        params.append("snow_id", waitsaveDrcs[i].snow_id);
        params.append("compile_status", 1);
        let res = await editDrc(params);
        if (res.code == 200000) {
          waitsaveDrcs[i].isSaved = true;
        }
      }
      this.$message.success(this.$t("messages.saveSuccess"));
      bus.$emit("screen_lock", false);
    },
    async fileNotSaveSaveAll() {
      let waitsaveDrcs = this.editableTabs.filter(
        (item) => item.isSaved == false && item.origin == false
      );
      bus.$emit("screen_lock", true);
      for (let i = 0; i < waitsaveDrcs.length; i++) {
        let drc_obj = sessionStorage.getItem(waitsaveDrcs[i].snow_id);
        let drc_blob = saveJSON(JSON.parse(drc_obj), waitsaveDrcs[i].name);
        let params = new FormData();
        // params.append("name", waitsaveDrcs[i].name);
        params.append("file", drc_blob);
        params.append("snow_id", waitsaveDrcs[i].snow_id);
        params.append("compile_status", 1);
        let res = await editDrc(params);
        if (res.code == 200000) {
          waitsaveDrcs[i].isSaved = true;
        }
      }
      this.$message.success(this.$t("messages.saveSuccess"));
      bus.$emit("screen_lock", false);
      this.handleCloseDrcDialog();
    },
    minusDrc() {
      this.drcShow = false;
      bus.$emit("minusDrcDialog", true);
      bus.$emit("updateOpenDRCStatus", true);
    },
  },

  mounted() {
    bus.$on("PDKDialog", (data) => {
      this.dialogVisible = true;
      bus.$emit("updateOpenDRCStatus", true);
    });
    bus.$on("drc-save", (data) => {
      this.saveDrc();
    });
    bus.$on("drc-saveAll", (data) => {
      this.saveAllDrclFile();
    });
    bus.$on("drc-create", (data) => {
      this.createNewDrcFile();
    });
    bus.$on("drc-import", (data) => {
      this.fileImportVisible = true;
    });
    bus.$on("drc-export", (data) => {
      if (!this.currentDrc) return;
      if (this.currentDrc.compile_status !== 3) {
        this.$message.error(this.$t("messages.exportDRCError"));
        return;
      }
      const blob = new Blob([this.code], { type: "text/plain;charset=utf-8" });
      saveAs(blob, "drc.py");
    });
    bus.$on("drc-selectAll", (data) => {
      this.$refs.codes.codeMirror.execCommand("selectAll");
    });
    bus.$on("drc-undo", (data) => {
      this.$refs.codes.codeMirror.undo();
    });
    bus.$on("drc-redo", (data) => {
      this.$refs.codes.codeMirror.redo();
    });
    bus.$on("drc-run", (data) => {
      if (
        !this.activeTabName ||
        this.editableTabs.length == 0 ||
        !this.currentDrc
      )
        return;
      if (!this.currentDrc.isSaved) {
        this.saveDrc();
      }
      this.codeError = this.$t("messages.startCompile");
      setTimeout(() => {
        this.runDrc();
      }, 1000);
    });
    bus.$on("drc-stop", (data) => {
      this.taskStop();
    });
    bus.$on("maxDrcDialog", async () => {
      await this.updateOfficialPDK();
      this.drcShow = true;
    });
    bus.$on("closeDrcDialog", (data) => {
      this.handleCloseDrcDialog();
      setTimeout(() => {
        this.drcShow = true;
      }, 500);
    });
  },
  watch: {
    code: {
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.changeParams();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
// * {
//   color: #333333;
// }

.container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .fileSite {
    & > div {
      display: inline-block;
      padding: 5px 0 10px 0;
      font-size: 13px;
      color: #333333;
    }
  }
}

.sidebar {
  width: 100%;
}

.grid-container {
  height: calc(100% - 30px);
  width: 100%;
  margin: 0px 0px 10px 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 450px 1fr;
  grid-template-columns: 240px 926px;
  grid-auto-flow: row dense;
  .grid-item {
    background-color: #ddd;
    text-align: center;
  }
  .grid-item-file {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .grid-item-code {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .grid-item-log {
    grid-row-start: 2;
    grid-row-end: 9;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.tab {
  display: inline-block;
  background: #f4f4f4;
  width: 100%;
  height: 100%;
  border: 1px solid #dddddd;
  .tab-header {
    height: 26px;
    line-height: 26px;
    padding-left: 10px;
    width: calc(100% - 10px);
    font-size: 13px;
    color: #333333;
    font-weight: bold;
    display: flex;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }

  .tab-body {
    background: #ffffff;
    width: 100%;
    height: calc(100% - 31px);
    max-height: calc(100% - 31px);
    display: flex;
    flex-direction: column;
    display: -webkit-flex;
    box-sizing: border-box;
    overflow-y: auto;
    .drc-name {
      display: inline-block;
      vertical-align: middle;
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .addParams {
      cursor: pointer;
      color: #0c7de6;
      font-size: 13px;
    }
    .item {
      // padding: 5px;
      font-size: 14px;
      color: #333333;
      .item-flex {
        float: right;
      }
    }
    .item:hover {
      background: #dce6f8;
      cursor: pointer;
    }
    .iconfont {
      font-size: 20px !important;
      vertical-align: middle;
      color: #0c7de6;
    }
    .item-text {
      padding: 5px;
    }
    .active {
      background: #dce6f8;
      border-right: solid 4px #0c7de6;
    }
    /deep/ .el-input__inner {
      background: transparent !important;
    }
    .el-select {
      // width: 200px;
      /deep/ .el-select__caret {
        color: #a3a3a3 !important;
      }
      /deep/ .el-input__suffix {
        top: 3px;
        right: 0px;
      }
      /deep/ .el-input__icon {
        line-height: inherit;
      }

      /deep/ .el-input__suffix-inner {
        display: inline-block;
      }
      /deep/ .el-input__inner {
        height: 27px;
        max-height: 30px !important;
        line-height: 30px;
        font-size: 12px !important;
        background: #fff !important;
        padding: 5px 10px !important;
        background-color: #fff !important;
        border-radius: 5px 0px 0px 5px;
      }
    }
  }

  .tab-header-tab {
    height: 26px;
    line-height: 26px;
    width: 100%;
    font-size: 13px;
    color: #333333;
    font-weight: bold;
    .tab-name {
      display: inline-block;
      width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    /deep/ .el-tabs__item {
      // border: 1px solid #e4e7ed !important;
      border-bottom: none;
      border-top: none;
      // height: 100%;
      line-height: 26px;
      height: 26px;
      font-size: 12px;
      width: 175px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      background-color: #fff;
    }
    /deep/ .el-tabs--border-card {
      border: none !important;
      box-shadow: none !important;
    }
    /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      background-color: #378ddd;
      color: #fff !important;
    }
    /deep/
      .el-tabs--top.el-tabs--border-card
      > .el-tabs__header
      .el-tabs__item:nth-child(2) {
      padding-left: 10px !important;
    }
    /deep/ #tab-2 {
      padding-left: 10px !important;
    }
    /deep/.el-tabs__nav {
      display: flex;
    }
    /deep/.el-tabs__nav-scroll {
      height: 26px;
    }
    /deep/ .el-tabs__nav-prev,
    /deep/ .el-tabs__nav-next {
      line-height: 26px;
    }
    /deep/.el-tabs__content {
      padding: 0;
    }
    .layoutbox {
      flex: 1;
      background-color: #fff;
      position: relative;
      /deep/.el-dialog__body {
        padding-left: 30px;
      }
    }
    /deep/.el-icon-close {
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }
}

.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}

/deep/ .dialog-window {
  width: 1200px;
  height: 800px;
}

/deep/ .el-dialog__body {
  width: calc(100% - 22px);
  height: calc(100% - 95px);
  padding: 10px !important;
}

.el-input-number {
  width: 200px;
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    // width: 100px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}

.el-select {
  width: 200px;
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}
.el-table {
  width: 100%;
  background: #ffffff;
  // border: 1px solid #DDDDDD;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    background: #ffffff;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
}

.image {
  width: 100%;
  margin: auto;
}
.stopBtn {
  display: inline-block;
}
// /deep/ .el-dialog__header {
//   height: 30px !important;
// }
.medium {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
  div {
    flex: 1;
  }
  .centers {
    span {
      font-size: 13px !important;
      text-align: center;
      font-size: 16px;
      color: #606266;
      padding-left: 10px;
    }
  }
  .icons {
    display: flex;
    justify-content: flex-end;
    i {
      color: #5f6368;
      font-size: 18px !important;
      display: block;
      padding: 0 7px;
      height: 40px;
      line-height: 40px;
    }
    i:hover {
      background: #dcdfe6;
      cursor: pointer;
    }
    .el-icon-close:hover {
      background: #f00;
      color: #fff;
    }
  }
}
</style>
