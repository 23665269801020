<template>
  <div>
    <div class="side">
      <!-- 版图工具栏 -->
      <div class="layoutToolbar">
        <!-- 简单编辑图标 -->
        <div class="simpleEdit_layout">
          <div class="toolbarIcon" v-for="(item, index) in editIcon_layout" :key="item.cn_en + index">
            <button @click="simpleEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false" :disabled="showProgress">
              <span :class="item.icon"></span>
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="middleLine"></div>
        <div class="simpleEdit_layout">
          <div class="toolbarIcon" v-for="(item, index) in operation_layout" :key="item.cn_en + index">
            <button @click="simpleEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false" :disabled="showProgress">
              <span :class="item.icon"></span>
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
        </div>
        <div class="middleLine"></div>
        <div class="simpleEdit_layout">
          <div class="toolbarIcon" v-for="(item, index) in start_layout" :key="item.cn_en + index">
            <button @click="simpleEditActiveFn(item, index)" @mouseenter="item.isShowInfo = true"
              @mouseleave="item.isShowInfo = false" :disabled="showProgress">
              <span :class="item.icon"></span>
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
          <div class="toolbarIcon" v-for="(item, index) in end_layout" :key="item.cn_en + index">
            <button :disabled="!showProgress" @click="simpleEditActiveFn(item, index)"
              @mouseenter="item.isShowInfo = true" @mouseleave="item.isShowInfo = false">
              <span :class="item.icon"></span>
              <div class="dropInfo" v-if="item.isShowInfo">
                <div>{{ $t('messages.' + item.cn_en) }}</div>
              </div>
            </button>
          </div>
        </div>
        <el-progress v-show="showProgress" style="width:30%;" :stroke-width="8"
          :percentage="progress_percent"></el-progress>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "@/components/common/bus";
export default {
  data() {
    return {
      //版图对应工具栏
      // 简单编辑图标
      editIcon_layout: [
        {
          icon: "iconfont icon-baocun",
          label: "保存", //提示信息
          cn_en: "save", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "save", //调用方法
        },
        {
          icon: "iconfont icon-drc_baocunsuoyou",
          label: "保存所有", //提示信息
          cn_en: "saveAll", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "saveAll", //调用方法
        },
        {
          icon: "iconfont icon-xinjian",
          label: "新建", //提示信息
          cn_en: "new", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "create", //调用方法
        },
        {
          icon: "iconfont icon-daoru",
          label: "导入", //提示信息
          cn_en: "import", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "import", //调用方法
        },
        {
          icon: "iconfont icon-daochu",
          label: "导出", //提示信息
          cn_en: "export", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "export", //调用方法
        },
      ],
      operation_layout: [
        // {
        //   icon: 'iconfont icon-jianqie',
        //   label: '剪切', //提示信息
        //   cn_en: 'cut', //中英文切换
        //   isActive: false, //是否激活
        //   btnActive: true, //按钮是否活跃(置灰状态)
        //   btnDisabled: false, //是否禁用
        //   isShowInfo: false, //是否显示文本信息
        //   func: 'cut', //调用方法
        // },
        // {
        //   icon: 'iconfont icon-fuzhi',
        //   label: '复制', //提示信息
        //   cn_en: 'copy', //中英文切换
        //   isActive: false, //是否激活
        //   btnActive: true, //按钮是否活跃(置灰状态)
        //   btnDisabled: false, //是否禁用
        //   isShowInfo: false, //是否显示文本信息
        //   func: 'copy', //调用方法
        // },
        // {
        //   icon: 'iconfont icon-niantie',
        //   label: '粘贴', //提示信息
        //   cn_en: 'paste', //中英文切换
        //   isActive: false, //是否激活
        //   btnActive: true, //按钮是否活跃(置灰状态)
        //   btnDisabled: false, //是否禁用
        //   isShowInfo: false, //是否显示文本信息
        //   func: 'paste', //调用方法
        // },
        // {
        //   icon: 'iconfont icon-shanchu',
        //   label: '删除', //提示信息
        //   cn_en: 'delete', //中英文切换
        //   isActive: false, //是否激活
        //   btnActive: true, //按钮是否活跃(置灰状态)
        //   btnDisabled: false, //是否禁用
        //   isShowInfo: false, //是否显示文本信息
        //   func: 'delete', //调用方法
        // },
        {
          icon: "iconfont icon-drc_quanxuan",
          label: "全选", //提示信息
          cn_en: "SelectionAll", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "selectAll", //调用方法
        },
        {
          icon: "iconfont icon-chexiao",
          label: "撤销", //提示信息
          cn_en: "undo", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "undo", //调用方法
        },
        {
          icon: "iconfont icon-huifu",
          label: "恢复", //提示信息
          cn_en: "redo", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "redo", //调用方法
        },
      ],
      start_layout: [
        {
          icon: "iconfont icon-drc_bianyi1",
          label: "编译", //提示信息
          cn_en: "run", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "run", //调用方法
        },
      ],
      end_layout: [
        {
          icon: "iconfont icon-zhongzhi danger",
          label: "终止", //提示信息
          cn_en: "stop", //中英文切换
          isActive: false, //是否激活
          btnActive: true, //按钮是否活跃(置灰状态)
          btnDisabled: false, //是否禁用
          isShowInfo: false, //是否显示文本信息
          func: "stop", //调用方法
        },
      ],
      progress_percent: 0,
      showProgress: false,
    };
  },
  mounted() {
    document.addEventListener("keydown", function (e) {
      const key = e.key.toLowerCase();
    });
    bus.$on("updateProgress", (data) => {
      this.showProgress = true;
      this.progress_percent = Number(data);
    });
    bus.$on("stopProgress", (data) => {
      this.updateProgressStatus(false);
    });
  },
  beforeDestroy() {
    bus.$off("openCoordinate");
  },
  methods: {
    //取消所有图标的激活状态
    cancelSelectAll() {
      if (!this.editIcon_layout) return;
      this.editIcon_layout.forEach((item) => {
        item.isActive = false;
      });
    },
    //返回选择模式
    returnToSelect() {},
    simpleEditActiveFn(item, index) {
      switch (item.func) {
        case "save":
          bus.$emit("drc-save", item);
          break;
        case "saveAll":
          bus.$emit("drc-saveAll", item);
          break;
        case "create":
          bus.$emit("drc-create", item);
          break;
        case "import":
          bus.$emit("drc-import", item);
          break;
        case "export":
          bus.$emit("drc-export", item);
          break;
        case "cut":
          bus.$emit("drc-cut", item);
          break;
        case "copy":
          bus.$emit("drc-copy", item);
          break;
        case "paste":
          bus.$emit("drc-paste", item);
          break;
        case "delete":
          bus.$emit("drc-delete", item);
          break;
        case "selectAll":
          bus.$emit("drc-selectAll", item);
          break;
        case "undo":
          bus.$emit("drc-undo", item);
          break;
        case "redo":
          bus.$emit("drc-redo", item);
          break;
        case "run":
          bus.$emit("drc-run", item);
          break;
        case "stop":
          bus.$emit("drc-stop", item);
          break;
        default:
          break;
      }
    },
    updateProgressStatus(status) {
      let that = this;
      that.showProgress = status;
    },
  },
  created() {
    this.cancelSelectAll();
  },
};
</script>
<style>
/* 文字提示 */
.el-tooltip__popper.is-dark {
  background-color: rgb(189, 185, 185) !important;
  color: #fff !important;
}
</style>
<style lang="less" scoped>
.side {
  width: 100%;
  height: 30px;
  user-select: none;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-start;
  .layoutToolbar {
    font-size: 12px;
    width: 100%;
    height: 30px;
    // box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.17);
    z-index: 999;
    background: #ffffff;
    display: flex;
    align-items: center;
    .simpleEdit_layout {
      height: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    .toolbarIcon {
      margin: 0 5px;
      display: inline-block;
      position: relative;
      height: 30px;
      line-height: 30px;
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 30px;
        display: flex;
        width: 24px;
        align-items: center;
        // display: flex;
        // align-items: center;
        justify-content: center;
      }
      button:disabled {
        opacity: 0.5;
      }
      .dropInfo {
        z-index: 1000;
        position: absolute;
        display: inline-block;
        top: 30px;
        left: 0;
        height: 24px;
        white-space: nowrap;
        padding: 0 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
        background-color: #ffffff;
        // display: flex;
        align-items: center;
        div {
          display: inline-block;
          margin-top: 3px;
        }
      }
      .iconfont {
        scale: 1.2;
      }
    }
  }
}
.middleLine {
  width: 1px;
  height: 25px;
  background: #999999;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.danger {
  color: #de5454;
  font-size: 18px;
}
</style>
