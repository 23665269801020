// import { QedaDevice } from '../qeda-device'
var QGdstk = null
var AutoToolLibs = require('./libs')
// var Device = require('../qeda-component')
import { Device } from '../qeda-device'

export class AutoToolSqidZline {
  constructor() {
    QGdstk = window.QGdstk
    this.libs = new AutoToolLibs()
    this.qeda_device = new Device()
    this.conf = {
      line: [],
      type: 'SQUID-1',
      direction: '上',
      d1: 10, // 距离
      d2: 10,
      size: { layer: 0, datatype: 0, b: 40, c: 4, d: 4, e: 2, f: 3, g: 7 },
    }
  }

  // 运行
  generate = function (conf) {
    this.conf = conf

    let line = this.conf.line
    let size = this.conf.size

    // 创建path
    let path = new QGdstk.FlexPath(line, 1, 0, 'round', 'flush', 0, null, 1e-2, true, true, 0, 0)

    // 生成zline器件
    let cell_zline = this.qeda_device.Z_line_1(size.zline_layer, size.datatype, size.b, size.c, size.d, size.e, size.f, size.g)
    let zline_box = cell_zline.bounding_box()
    let zline_h = zline_box[1][1] - zline_box[0][1]
    // let zline_w = zline_box[1][0] - zline_box[0][0]

    let cell_squid = null
    let squid_ref_rotation = 0
    let squid_layer = conf.size.squid_layer

    if (this.conf.type == 'SQUID-1') {
      cell_squid = this.qeda_device.Squid_1(squid_layer)
    }
    if (this.conf.type == 'SQUID-2') {
      cell_squid = this.qeda_device.Squid_2(squid_layer)
    }
    if (this.conf.type == 'SQUID-3') {
      cell_squid = this.qeda_device.Squid_3(squid_layer)
    }
    if (this.conf.type == 'SQUID-4') {
      cell_squid = this.qeda_device.Squid_4(squid_layer, squid_layer, squid_layer, squid_layer, 0)
    }
    if (this.conf.type == 'SQUID-5') {
      cell_squid = this.qeda_device.Squid_4(squid_layer, squid_layer, squid_layer, squid_layer, 0)
    }
    let squid_box = cell_squid.bounding_box()
    let squid_h = squid_box[1][1] - squid_box[0][1]

    let ref_zline = new QGdstk.Reference(cell_zline, [0, -zline_h - squid_h - this.conf.d2], 0, 1, false, 1, 1, null)
    let ref_squid = new QGdstk.Reference(cell_squid, [0, 0 - squid_h], squid_ref_rotation, 1, false, 1, 1, null)
    var O = new QGdstk.Label('O', [0, 0])
    let cell_squid_zline = new QGdstk.Cell('Squid-Z-Line')
    cell_squid_zline.add([ref_squid, ref_zline, O])

    let line_direction = this.libs.angle_direction(line[0], line[1])

    let ref_squid_zline_origin = [line[0][0] + this.conf.d1 * Math.cos(line_direction), line[0][1] + this.conf.d1 * Math.sin(line_direction)]
    let ref_squid_zline_rotation = 0
    if (this.conf.direction == 'left') {
      ref_squid_zline_rotation = Math.PI / 2 + line_direction + Math.PI / 2
    }
    if (this.conf.direction == 'right') {
      ref_squid_zline_rotation = Math.PI / 2 + line_direction - Math.PI / 2
    }

    let ref_squid_zline = new QGdstk.Reference(cell_squid_zline, ref_squid_zline_origin, ref_squid_zline_rotation, 1, false, 1, 1, null)
    let cell_2d = new QGdstk.Cell('squid-z-line-2d')
    var start_pos = new QGdstk.Label('0', this.conf.line[0])
    var end_pos = new QGdstk.Label('1', this.conf.line[1])
    cell_2d.add([path, ref_squid_zline, start_pos, end_pos])

    // 转换kernel
    let cell_zline_k = this.libs.GdstkCell2KernelCell(cell_zline, this.conf.fileLayerList)
    let cell_squid_k = this.libs.GdstkCell2KernelCell(cell_squid, this.conf.fileLayerList)
    // let ref_zline_k = new window.Kernel.Reference(cell_zline_k, [0, -zline_h - squid_h - this.conf.d2], 0, 1, false, 1, 1, [0, 0])
    // let ref_squid_k = new window.Kernel.Reference(cell_squid_k, [0, 0 - squid_h], squid_ref_rotation, 1, false, 1, 1, [0, 0])
    let ref_zline_k = new window.Kernel.Reference()
    ref_zline_k.cell = cell_zline_k
    ref_zline_k.origin = [0, -zline_h - squid_h - this.conf.d2]
    let ref_squid_k = new window.Kernel.Reference()
    ref_squid_k.cell = cell_squid_k
    ref_squid_k.origin = [0, 0 - squid_h]
    ref_squid_k.rotation = squid_ref_rotation

    let cell_squid_zline_k = new window.Kernel.Cell('Squid-Z-Line')
    cell_squid_zline_k.add_reference(ref_zline_k)
    cell_squid_zline_k.add_reference(ref_squid_k)
    // let ref_squid_zline_k = new window.Kernel.Reference(cell_squid_zline_k, ref_squid_zline_origin, ref_squid_zline_rotation, 1, false, 1, 1, [0, 0])
    let ref_squid_zline_k = new window.Kernel.Reference()
    ref_squid_zline_k.cell = cell_squid_zline_k
    ref_squid_zline_k.origin = ref_squid_zline_origin
    ref_squid_zline_k.rotation = ref_squid_zline_rotation

    return {
      cell_2d: cell_2d,
      // add_cells: [cell_squid_zline, cell_zline, cell_squid],
      // add_references: [ref_squid_zline],
      add_cells: [cell_squid_zline_k, cell_zline_k, cell_squid_k],
      add_references: [ref_squid_zline_k],
    }
  }
  Z_line_1 = function (layer = 0, datatype = 0, b = 40, c = 4, d = 4, e = 2, f = 3, g = 7) {
    // vars
    var a = 2 * g + 2 * e + d

    // points
    var p1 = [-d / 2, 0]
    var p2 = [-(d / 2 + e), 0]
    var p3 = [-(d / 2 + e), b - 2 * e - c]
    var p4 = [-(d / 2 + f), b - 2 * e - c]
    var p5 = [-(d / 2 + f), b - e - c]
    var p6 = [-d / 2, b - e - c]
    var p7 = [d / 2, 0]
    var p8 = [d / 2 + e, 0]
    var p9 = [d / 2 + e, b - e]
    var p10 = [a / 2, b - e]
    var p11 = [a / 2, b]
    var p12 = [-a / 2, b]
    var p13 = [-a / 2, b - e]
    var p14 = [d / 2, b - e]

    // cell
    var poly_left = new QGdstk.Polygon([p1, p2, p3, p4, p5, p6], layer, datatype)
    var poly_right = new QGdstk.Polygon([p7, p8, p9, p10, p11, p12, p13, p14], layer, datatype)
    var O1 = new QGdstk.Label('O1', [0, 0], 's', 0, 1, false, 0, 0)
    var O = new QGdstk.Label('O', [0, 0], 'n', 0, 1, false, 0, 0)
    O1.set_gds_property(1, kp_pro_str)
    O.set_gds_property(1, kp_pro_str)

    var cell = new QGdstk.Cell('Z_line_1')
    cell.add([poly_left, poly_right, O1])
    cell.add(O)
    return cell
  }

  Zline = function () {}
}
