<template>
  <div>
    <el-dialog :title="$t('messages.toolJJtitle')" :visible.sync="JJDialogVisible" width="1238px" :lock-scroll="true"
      :before-close="handleClose" :modal="false" @opened="JJDialogOpened">
      <div class="dialog-body">
        <div class="settings">
          <div class="tab settings__cell">
            <div class="tab__header settings_item_cell__header">
              <div style="padding: 6px">{{ $t('messages.toolJJdevice') }}</div>
              <el-button type="success" size="mini" @click="getJJPosition">{{ $t('messages.toolJJget') }}</el-button>
            </div>
            <div class="tab__body settings_item_cell__body">
              <el-table class="el-table" :data="JJ.device" ref="deviceTable" height="175px"
                header-cell-class-name="table_header">
                <el-table-column :label="$t('messages.toolJJlayerNumber')" prop="layer">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.layer" :placeholder="$t('messages.toolJJpleaseSelect')">
                      <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                        :value="item.layerNumber">
                        <div class="layerOption">
                          <div class="layerImgBox">
                            <span style="float: left">{{ item.layerNumber }}</span>
                            <img :src="item.image" alt="" class="layerImage" />
                          </div>
                          <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('messages.toolJJtype')" prop="type">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.type" filterable :placeholder="$t('messages.toolJJpleaseSelect')">
                      <el-option v-for="item in $t('messages.toolJJTypeOptions')" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('messages.toolJJresitance')" prop="resitance">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.resitance"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.toolJJresitanceMin')" prop="resitance">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.resitance_min"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.toolJJresitanceMax')" prop="resitance">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.resitance_max"></el-input-number>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="$t('messages.toolJJcrossBitCap')" name="cross_bit">
              <div class="tab__body">
                <div class="row-item" style="margin: 10px 10px 0px 10px">
                  <div style="padding: 6px; width: 100px">{{ $t('messages.toolJJdirection') }}:</div>
                  <el-select v-model="JJ.cap.cross_bit.direction" filterable
                    :placeholder="$t('messages.toolJJpleaseSelect')">
                    <el-option v-for="item in directionOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
                <el-table class="el-table" :data="table.cross_bit.positions" ref="deviceTable" height="175px"
                  header-cell-class-name="table_header">
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_0" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_0"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_1" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_1"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_2" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_2"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_3" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_3"></el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="$t('messages.toolJJadjustableCCap_0')" name="adjustable_coupling_0">
              <div class="tab__body">
                <div class="row-item" style="margin: 10px 10px 0px 10px">
                  <div style="padding: 6px; width: 100px">{{ $t('messages.toolJJdirection') }}:</div>
                  <el-select v-model="JJ.cap.adjustable_coupling_0.direction" filterable
                    :placeholder="$t('messages.toolJJpleaseSelect')">
                    <el-option v-for="item in directionOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>

                <el-table class="el-table" :data="table.adjustable_coupling_0.positions" ref="deviceTable"
                  height="175px" header-cell-class-name="table_header">
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_0" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_0"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_1" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_1"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_2" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_2"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_3" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_3"></el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="$t('messages.toolJJadjustableCCap_90')" name="adjustable_coupling_90">
              <div class="tab__body">
                <div class="row-item" style="margin: 10px 10px 0px 10px">
                  <div style="padding: 6px; width: 100px">{{ $t('messages.toolJJdirection') }}:</div>
                  <el-select v-model="JJ.cap.adjustable_coupling_90.direction" filterable
                    :placeholder="$t('messages.toolJJpleaseSelect')">
                    <el-option v-for="item in directionOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>

                <el-table class="el-table" :data="table.adjustable_coupling_90.positions" ref="deviceTable"
                  height="175px" header-cell-class-name="table_header">
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_0" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_0"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_1" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_1"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_2" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_2"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_3" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_3"></el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
            <el-collapse-item :title="$t('messages.toolJJtest')" name="test_junction">
              <div class="tab__body">
                <div class="row-item" style="margin: 10px 10px 0px 10px">
                  <div style="padding: 6px; width: 100px">{{ $t('messages.toolJJdirection') }}:</div>
                  <el-select v-model="JJ.cap.test_junction.direction" filterable
                    :placeholder="$t('messages.toolJJpleaseSelect')">
                    <el-option v-for="item in directionOptions" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
                <el-table class="el-table" :data="table.test_junction.positions" ref="deviceTable" height="175px"
                  header-cell-class-name="table_header">
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_0" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_0"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_1" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_1"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_2" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_2"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJnumber')" prop="id_3" width="50"></el-table-column>
                  <el-table-column :label="$t('messages.toolJJposition')" prop="pos_3"></el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>

          <div class="tab settings__axis">
            <div class="tab__header">
              <div style="padding: 6px">{{ $t('messages.toolJJoriginCoordinates') }}</div>
            </div>
            <div class="tab__body">
              <div class="row-item" style="margin: 10px 10px 0px 10px">
                <div style="padding: 10px">x:</div>
                <el-input-number :controls="false" v-model="JJ.axis.origin.x"
                  @change="adjustJJCenter"></el-input-number>
                <div style="padding: 10px">y:</div>
                <el-input-number :controls="false" v-model="JJ.axis.origin.y"
                  @change="adjustJJCenter"></el-input-number>
                <div style="margin-left: 10px; padding: 4px">
                  <!-- <el-button type="success" size="mini" @click="adjustJJCenter">{{ $t('messages.toolJJadjust') }}</el-button> -->
                  <el-button type="success" size="mini"
                    @click="selectPos">{{ $t('messages.toolJJselectPos') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="JJExport">{{ $t('messages.toolJJexport') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";

const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");

import { ToolJJ } from "@/utils/tools/jj";

import { export_json_to_excel } from "@/excel/Export2Excel";

export default {
  data() {
    return {
      JJDialogVisible: false,
      activeNames: [],

      unit: "um",

      directionOptions: [
        { value: 0, label: "0°" },
        { value: 45, label: "45°" },
        { value: 90, label: "90°" },
        { value: 135, label: "135°" },
        { value: 180, label: "180°" },
        { value: 225, label: "225°" },
        { value: 270, label: "270°" },
        { value: 315, label: "315°" },
      ],
      deviceOptions: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
      ],
      typeOptions: [
        {
          value: "cross_bit",
          label: window.i18n.t("messages.toolJJcrossBitCap"),
        },
        {
          value: "adjustable_coupling_0",
          label: window.i18n.t("messages.toolJJadjustableCCap_0"),
        },
        {
          value: "adjustable_coupling_90",
          label: window.i18n.t("messages.toolJJadjustableCCap_90"),
        },
        { value: "test_junction", label: window.i18n.t("messages.toolJJtest") },
      ],
      resitanceOptions: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
      ],

      JJ: {
        device: [
          {
            layer: 1,
            device: 0,
            type: "cross_bit",
            resitance: 0,
            resitance_min: 0,
            resitance_max: 100,
          },
          {
            layer: 2,
            device: 0,
            type: "adjustable_coupling_0",
            resitance: 0,
            resitance_min: 0,
            resitance_max: 100,
          },
          {
            layer: 3,
            device: 0,
            type: "adjustable_coupling_90",
            resitance: 0,
            resitance_min: 0,
            resitance_max: 100,
          },
          {
            layer: 4,
            device: 0,
            type: "test_junction",
            resitance: 0,
            resitance_min: 0,
            resitance_max: 100,
          },
        ],
        cap: {
          cross_bit: {
            direction: 0,
            positions: [],
          },
          adjustable_coupling_0: {
            direction: 0,
            positions: [],
          },
          adjustable_coupling_90: {
            direction: 0,
            positions: [],
          },
          test_junction: {
            direction: 0,
            positions: [],
          },
        },
        axis: {
          origin: { x: 0, y: 0 },
        },
      },

      positions: null,

      table: {
        cross_bit: {
          direction: 0,
          positions: [],
        },
        adjustable_coupling_0: {
          direction: 0,
          positions: [],
        },
        adjustable_coupling_90: {
          direction: 0,
          positions: [],
        },
        test_junction: {
          direction: 0,
          positions: [],
        },
      },
      crossBitTableData: [],
      adjustableCoupling0TableData: [],
      adjustableCoupling90TableData: [],
      testJunctionTableData: [],

      fileLayerList: [],
      cellLayerList: [],

      canvas2D: null,
      tool: null,
    };
  },
  created() {
    this.tool = new ToolJJ();
  },
  methods: {
    handleChange(val) {},
    handleClose(done) {
      done();
    },
    JJDialogOpened() {},

    JJDialogSelect(type) {
      this.JJSizeUpdate();
    },

    JJSizeUpdate() {},

    JJDialogOK() {
      this.JJDialogVisible = false;
      this.$message({
        message: i18n.t("messages.toolJJPlaceWarn"),
        type: "warning",
      });
    },

    // 更新表格位置信息
    updatePositionTableData(cap) {
      let origin = this.JJ.axis.origin;
      this.activeNames = [];
      for (let k in this.table) {
        this.table[k].positions = [];

        let items = cap[k].positions;
        let tmp = {};
        for (let i = 0; i < items.length; i++) {
          if (i % 4 == 0) {
            if (i > 0) {
              this.table[k].positions.push(tmp);
            }
            tmp = {};
          } else {
          }
          tmp[`id_${i % 4}`] = i;
          tmp[`pos_${i % 4}`] = `(${items[i].x - origin.x} -, ${
            items[i].y - origin.y
          })`;
        }
        if (tmp && items.length > 0) this.table[k].positions.push(tmp);

        if (this.table[k].positions.length > 0) {
          this.activeNames.push(k);
        }
      }
    },

    // 获取jj位置
    getJJPosition() {
      let positions = this.tool.getPositions(
        this.$store.state.activeCell,
        this.JJ.device
      );
      this.positions = positions;

      for (let k in this.JJ.cap) {
        this.JJ.cap[k].positions = [];
      }
      for (let k in positions) {
        if (this.JJ.cap[k].positions) {
          positions[k].forEach((element) => {
            this.JJ.cap[k].positions.push(element);
          });
        } else {
          this.JJ.cap[k].positions = JSON.parse(JSON.stringify(positions[k]));
        }
      }

      this.updatePositionTableData(this.JJ.cap);
    },

    // 调整中心
    adjustJJCenter() {
      this.updatePositionTableData(this.JJ.cap);
    },

    // 选取点
    selectPos() {
      bus.$emit("TOOL_JJ_SELECT_POS", null);
      this.JJDialogVisible = false;
    },

    // 导出excel
    JJExport() {
      let origin = this.JJ.axis.origin;
      let device_dict = {};
      this.JJ.device.forEach((item) => {
        device_dict[item.type] = item;
      });
      let tableData = [];
      let positions = [];
      let index = 1;
      // 生成表格数据
      for (let k in this.JJ.cap) {
        let type_sort = 0;
        let type = "";
        if (k === "cross_bit") {
          type_sort = 1;
          type = window.i18n.t("messages.toolJJcrossBitCap");
        }
        if (k === "adjustable_coupling_0") {
          type_sort = 2;
          type = window.i18n.t("messages.toolJJadjustableCCap_0");
        }
        if (k === "adjustable_coupling_90") {
          type_sort = 3;
          type = window.i18n.t("messages.toolJJadjustableCCap_90");
        }
        if (k === "test_junction") {
          type_sort = 4;
          type = window.i18n.t("messages.toolJJtest");
        }
        let pos = this.JJ.cap[k].positions;
        for (let i = 0; i < pos.length; i++) {
          let u = (pos[i].x - origin.x).toFixed(3);
          let v = (pos[i].y - origin.y).toFixed(3);
          let data = {
            index: index,
            chip: k,
            u: u,
            v: v,
            line_width: 10,
            res_min: device_dict[k].resitance_min,
            res_max: device_dict[k].resitance_max,
            type_sort: type_sort,
            type: type,
          };
          positions.push([pos[i].x, pos[i].y, data]);
          index += 1;
        }
      }

      try {
        // 根据芯片位置，计算jj所在芯片行列
        let jj_positions = this.tool.getChipPositions(
          this.$store.state.activeCell,
          positions
        );
        jj_positions.forEach((element) => {
          tableData.push(element[2]);
        });
      } catch (error) {
        this.$message({
          message: error,
          type: "error",
        });
        return;
      }

      // 表格排序导出
      tableData = tableData.sort((a, b) => a.type_sort - b.type_sort);
      require.ensure([], () => {
        const fieldName = [
          window.i18n.t("messages.toolJJTabNumber"),
          window.i18n.t("messages.toolJJTabChipSplit"),
          "U/um",
          "V/um",
          window.i18n.t("messages.toolJJTabDesignLineWidth"),
          window.i18n.t("messages.toolJJTabResitanceMin"),
          window.i18n.t("messages.toolJJTabResitanceMax"),
          window.i18n.t("messages.toolJJTabType"),
        ];
        const filterVal = [
          "index",
          "chip",
          "u",
          "v",
          "line_width",
          "res_min",
          "res_max",
          "type",
        ];
        const data = tableData.map((v) => filterVal.map((j) => v[j]));
        export_json_to_excel(
          fieldName,
          data,
          window.i18n.t("messages.toolJJTabName")
        );
      });
    },
    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;
      this.cellLayerList = [];

      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            layerNumber: item.layerNumber,
          });
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();
    }, 2000);
    bus.$on("TOOL_JJ", (data) => {
      this.cellLayerInit();
      this.JJDialogVisible = true;
    });
    bus.$on("TOOL_JJ_SELECT_POS_OK", (data) => {
      this.JJDialogVisible = true;
      this.JJ.axis.origin.x = data.x;
      this.JJ.axis.origin.y = data.y;
      this.adjustJJCenter();
    });
  },
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-collapse-item__header {
  height: 26px;
  background: #f4f4f4;
  border: 1px solid #dddddd;
}

/deep/ .el-dialog__body {
  padding: 20px !important;
  height: 750px !important;
  overflow-y: auto;
  .dialog-body {
    display: flex;
  }
}

.tab {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .tab__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    justify-content: space-between;
  }

  .tab__body {
    background: #ffffff;
    display: flex;
    display: -webkit-flex;

    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 32px);
    flex-direction: column;
  }
}

.el-input-number {
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 120px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 10 !important;
    text-align: center;
    display: inline-block;
  }
}

.el-select {
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    text-align: left;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}
.el-table {
  width: 100%;
  background: #ffffff;
  // border: 1px solid #DDDDDD;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    background: #ffffff;
  }
  .disabled {
    color: #b2b2b2 !important;
  }

  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 150px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 10 !important;
    text-align: left;
    display: inline-block;
  }
}

.row-item {
  display: flex;
}

.settings {
  display: flex;
  flex-direction: column;
  display: inline-block;
  height: 100%;
  width: 100%;

  .settings__cell {
    width: 100%;

    .settings_item_cell__header {
    }
    .settings_item_cell__body {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }

  .settings__cap {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .cap-item {
      width: 200px;
      width: 32%;
      height: 300px;
    }
  }
  .settings__axis {
    margin-top: 10px;
    width: 100%;
  }
}

.image {
  width: 100%;
  margin: auto;
}
.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .export-excel-wrapper {
    width: 100px;
  }
}
</style>
