<template>
  <div class="schematicDraw">
    <!-- 耦合线功能面板 -->
    <div class="couplingLineDialog">
      <el-dialog :title="$t('messages.CouplingLine')" :visible.sync="couplingLineDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="closeCouplingLineDialog">
        <!-- 角度设置 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.AngleSetting")}}:</span>
          <el-select popper-class="optionClass" @change="couplingLineAngleChange" v-model="value_couplingLineAngle" :placeholder="$t('messages.pleaseEnterAngle')">
            <el-option v-for="item in AngleOption" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </div>
        <!-- 颜色 -->
        <div class="inputArea">
          <span class="inputInfo">{{$t("messages.color")}}:</span>
          <div class="fillColor__btn">
            <el-color-picker v-model="value_couplingLineColor" @change="couplingLineColorChange"></el-color-picker>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="couplingLineDialogSureBtn" size="mini" class="deactivate" ref="couplingLineDialogSureBtn" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="couplingLineDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 创建引脚 -->
    <div class="createPinDialog">
      <el-form :model="createPinForm" :rules="rules" ref="createPinForm" class="demo-ruleForm">
        <el-dialog :title="$t('messages.CreatePin')" :visible.sync="createPinDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="closeCreatePinDialog">
          <!-- 引脚名称 -->
          <div class="inputArea">
            <span class="inputInfo">{{$t("messages.PinName")}}:</span>
            <el-form-item prop="form_pinName">
              <el-input v-model="createPinForm.form_pinName" :placeholder="$t('messages.pleaseEnterPinName')" :maxlength="50"></el-input>
            </el-form-item>
          </div>
          <!-- 类型 -->
          <div class="inputArea">
            <span class="inputInfo">{{$t("messages.type")}}:</span>
            <el-select popper-class="optionClass" v-model="value_pinType" :placeholder="$t('messages.pleaseEnterType')">
              <el-option v-for="item in pinTypeArr" :key="item.value" :value="item.value" :label="item.label">
              </el-option>
            </el-select>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="createPinDialogSureBtn" size="mini" class="deactivate" ref="createPinDialogSureBtn" disabled>{{$t("messages.sure")}}</el-button>
            <el-button @click="createPinDialogCancelBtn" size="mini">{{$t("messages.cancel")}}</el-button>
          </span>
        </el-dialog>
      </el-form>
    </div>
    <!-- 标签 -->
    <div class="labelDialog">
      <el-dialog :title="$t('messages.Label')" :visible.sync="labelDialogVisible" :modal="false" :close-on-click-modal="false" :before-close="labelDialogClose">
        <el-form :model="schemaLabelForm" :rules="rules" ref="schemaLabelForm" class="demo-ruleForm">
          <!-- 文本内容 -->
          <div class="inputArea">
            <span>{{$t('messages.aText')}}:</span>
            <el-form-item prop="form_labelText">
              <el-input v-model="schemaLabelForm.form_labelText" :placeholder="$t('messages.placeholder_1')" maxlength="20" show-word-limit>
              </el-input>
            </el-form-item>
          </div>
          <!-- 颜色 -->
          <div class="inputArea">
            <span class="inputInfo">{{$t("messages.color")}}:</span>
            <div class="fillColor__btn">
              <el-color-picker v-model="value_labelColor" @change="labelColorChange"></el-color-picker>
            </div>
          </div>
          <!-- 字体 -->
          <div class="inputArea">
            <span>{{$t('messages.Font')}}:</span>
            <el-select popper-class="optionClass" v-model="value_fontFamily" :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in fontFamily" :key="item.value" :value="item.value" :label="item.label">
              </el-option>
            </el-select>
          </div>
          <!-- 字体大小 -->
          <div class="inputArea">
            <span>{{$t('messages.fontSize')}}:</span>
            <el-form-item prop="form_fontWeight">
              <el-input v-model="schemaLabelForm.form_fontWeight" :placeholder="$t('messages.placeholder_1')"></el-input>
            </el-form-item>
          </div>
          <!-- 参考点位置 -->
          <div class="inputArea">
            <span>{{$t('messages.ReferenceLocation')}}:</span>
            <el-select popper-class="optionClass" v-model="value_referencePoint" :placeholder="$t('messages.placeholder_0')">
              <el-option v-for="item in referencePointLocation" :key="item.value" :value="item.value" :label="item.label">
              </el-option>
            </el-select>
          </div>
          <!-- 网络标签 -->
          <div class="inputArea">
            <span style="padding-right:90px">{{$t('messages.networkLabel')}}:</span>
            <div class="radioArea">
              <el-radio v-model="value_networkLabel" label="1" @change="networkLabelChange">{{$t('messages.yes')}}</el-radio>
              <el-radio v-model="value_networkLabel" label="2" @change="networkLabelChange">{{$t('messages.no')}}</el-radio>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="labelDialogSureBtn" type="primary" @click="labelDialogSureBtn" size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="labelDialogCancelBtn()" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bus from "@/components/common/bus";
export default {
  data() {
    let englishNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(
          this.$t('messages.fileTip2')
        );
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {//NOSONAR
        callback(
          this.$t('messages.fileTip2')
        );
      } else {
        callback();
      }
    };
    return {
      //耦合线
      couplingLineDialogVisible: false,//耦合线弹窗显示
      value_couplingLineAngle: "90°",
      AngleOption: [
        {
          value: "90°",
          label: "90°",
        },
        {
          value: "135°",
          label: "135°",
        },
        {
          value: "0°",
          label: this.$t('messages.anyAngle'),
        },
      ],
      value_couplingLineColor: "#8F00FF",
      //引脚
      createPinDialogVisible: false,//创建引脚弹窗显示
      createPinForm: {
        form_pinName: "",//引脚名称
      },
      value_pinType: this.$t('messages.input'),//引脚类型
      pinTypeArr: [
        {
          label: this.$t('messages.input'),
          value: "input",
        },
        {
          label: this.$t('messages.output'),
          value: "output",
        },
        {
          label: this.$t('messages.inputAndOutput'),
          value: "IO",
        },
      ],//引脚类型种类
      //标签
      labelDialogVisible: false,
      schemaLabelForm: {
        form_labelText: "",
        form_fontWeight: "10",
      },
      value_fontFamily: this.$t('messages.BoldTypeface'),//字体
      value_labelColor: "#777777",
      fontFamily: [
        {
          value: "heiti",
          label: this.$t('messages.BoldTypeface')
        },
        {
          value: "songti",
          label: this.$t('messages.SongTypeface')
        },
      ],//字体
      value_referencePoint: this.$t('messages.Up'),//参考点位置
      referencePointLocation: [//参考点位置数组
        {
          value: "o",
          label: this.$t('messages.Center'),
        },
        {
          value: "n",
          label: this.$t('messages.Up'),
        },
        {
          value: "s",
          label: this.$t('messages.Down'),
        },
        {
          value: "w",
          label: this.$t('messages.Left'),
        },
        {
          value: "e",
          label: this.$t('messages.Right'),
        },
        {
          value: "nw",
          label: this.$t('messages.upperLeft'),
        },
        {
          value: "ne",
          label: this.$t('messages.rightUpper'),
        },
        {
          value: "sw",
          label: this.$t('messages.bottomLeft'),
        },
        {
          value: "se",
          label: this.$t('messages.lowerRight'),
        },
      ],
      value_networkLabel: "2",
      rules: {
        form_pinName: [{ validator: englishNameRule, trigger: "blur" }],
        form_labelText: [{ validator: englishNameRule, trigger: "blur" }],
      },
    }
  },
  mounted() {
    bus.$on("openCouplingLine", data => {
      this.disableCouplingLine()
      this.couplingLineDialogVisible = true
    })
    bus.$on("selectCouplingLine", data => {
      this.couplingLineDialogSureBtn()
    })
    bus.$on("openCreatePin", data => {
      this.createPinDialogVisible = true
    })
    bus.$on("openLabel", data => {
      this.labelDialogVisible = true
    })
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    ////耦合线
    couplingLineDialogSureBtn() {
      this.couplingLineDialogVisible = false
      let data = {
        couplingLineAngle: this.value_couplingLineAngle,
        couplingLineColor: this.value_couplingLineColor,
      }
      bus.$emit('addLine', data)
      bus.$emit('schemaSiderBarLineActive')

    },
    couplingLineDialogCancelBtn() {
      this.couplingLineDialogVisible = false
    },
    closeCouplingLineDialog() {
      this.couplingLineDialogVisible = false
    },
    couplingLineColorChange(data) {
      this.value_couplingLineColor = data;
    },
    couplingLineAngleChange(data) {
      this.value_couplingLineAngle = data;
    },
    //耦合线确认按钮禁用
    disableCouplingLine() {
      if (
        this.value_couplingLineAngle === "" ||
        this.value_couplingLineColor === ""
      ) {
        this.disableBtn(this.$refs.couplingLineDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.couplingLineDialogSureBtn);
      }
    },
    ////引脚
    createPinDialogSureBtn() {
      this.createPinDialogVisible = false
      if (this.value_pinType === this.$t('messages.input')) {
        this.value_pinType = "input"
      }
      let pinNameList = this.createPinForm.form_pinName.split(',')
      if (pinNameList.join('') !== '') {
        let data = {
          pinName: this.createPinForm.form_pinName,
          pinType: this.value_pinType,
        }
        bus.$emit('addPin', data)
        bus.$emit('schemaSiderBarPinActive')
      } else {
        this.resetPinDialog()
        bus.$emit("errDialog", this.$t('messages.fileTip10'))
      }
    },
    createPinDialogCancelBtn() {
      this.createPinDialogVisible = false
    },
    closeCreatePinDialog() {
      this.createPinDialogVisible = false
    },
    resetPinDialog() {
      this.createPinForm.form_pinName = ""
      this.value_pinType = this.$t('messages.input')
    },
    //引脚确认按钮禁用
    disablePin() {
      this.$refs.createPinForm.validate((valid) => {
        if (valid) {
          if (
            this.createPinForm.form_pinName === "" ||
            this.value_pinType === ""
          ) {
            this.disableBtn(this.$refs.createPinDialogSureBtn);
          } else {
            this.activateBtn(this.$refs.createPinDialogSureBtn);
          }
        } else {
          this.disableBtn(this.$refs.createPinDialogSureBtn);
        }
      })
    },
    //标签
    labelDialogClose() {
      this.labelDialogVisible = false
    },
    networkLabelChange() {

    },
    labelDialogSureBtn() {
      this.labelDialogVisible = false
      if (this.value_referencePoint === this.$t('messages.Up')) {
        this.value_referencePoint = "n"
      }
      let data = {
        formText: this.schemaLabelForm.form_labelText,
        color: this.value_labelColor,
        fontFamily: this.value_fontFamily,
        formWeight: this.schemaLabelForm.form_fontWeight,
        referencePoint: this.value_referencePoint,
        networkLabel: this.value_networkLabel,
      }
      bus.$emit('addLabel', data)
      bus.$emit('schemaSiderBarLabelActive')

    },
    labelDialogCancelBtn() {
      this.labelDialogVisible = false
    },
    labelColorChange(data) {
      this.value_labelColor = data;
    },
    disableLabel() {
      this.$refs.schemaLabelForm.validate((valid) => {
        if (valid) {
          if (
            this.schemaLabelForm.form_labelText === "" ||
            this.schemaLabelForm.form_fontWeight === "" ||
            this.value_labelColor === ""
          ) {
            this.disableBtn(this.$refs.labelDialogSureBtn);
          } else {
            this.activateBtn(this.$refs.labelDialogSureBtn);
          }
        } else {
          this.disableBtn(this.$refs.labelDialogSureBtn);
        }
      })
    },
  },
  beforeDestroy() {
    bus.$off("openCouplingLine")
    bus.$off("openCreatePin")
    bus.$off("openLabel")
  },
  watch: {
    value_couplingLineAngle() {
      this.disableCouplingLine()
    },
    value_couplingLineColor() {
      this.disableCouplingLine()
    },
    value_pinType() {
      this.disablePin()
    },
    "createPinForm.form_pinName"() {
      this.disablePin()
    },
    value_labelColor() {
      this.disableLabel()
    },
    "schemaLabelForm.form_labelText"() {
      this.disableLabel()
    },
    "schemaLabelForm.form_fontWeight"() {
      this.disableLabel()
    },
  }
}
</script>
<style lang="less" scoped>
.schematicDraw {
  //耦合线功能面板
  .couplingLineDialog {
    /deep/.el-dialog {
      width: 500px;
      font-size: 14px;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }
    /deep/.el-dialog__body {
      font-weight: 400;
      padding: 20px 20px 10px 15px;
      .inputArea {
        width: 100%;
        height: 34px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-input {
          width: 330px;
          height: 34px;
          background: #f4f4f4;
          border-radius: 2px;
        }
        .el-input__inner {
          height: 34px;
        }
        .el-select {
          width: 330px;
          height: 34px;
          border-radius: 2px;
          .el-input__inner {
            height: 34px;
          }
          .el-input__icon {
            line-height: 34px;
          }
        }
        .fillColor__btn {
          width: 330px;
          height: 30px;
          text-align: center;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          overflow: hidden;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
        .el-color-picker {
          width: 330px;
          height: 30px;
        }
        .el-color-picker__trigger {
          height: 100%;
          width: 100%;
          border: none;
          padding: 0;
          .el-icon-arrow-down {
            color: #ccc !important;
          }
        }
        .el-color-picker__color {
          border: none;
        }
        .el-color-picker__color-inner {
          height: 100%;
          width: 100%;
        }
      }
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
  }
  //创建引脚
  .createPinDialog {
    /deep/.el-dialog {
      width: 500px;
      font-size: 14px;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
    }
    /deep/.el-dialog__body {
      font-weight: 400;
      padding: 20px 20px 10px 15px;
      .inputArea {
        width: 100%;
        height: 34px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-input {
          width: 330px;
          height: 34px;
          background: #f4f4f4;
          border-radius: 2px;
        }
        .el-input__inner {
          height: 34px;
        }
        .el-select {
          width: 330px;
          height: 34px;
          border-radius: 2px;
          .el-input__inner {
            height: 34px;
          }
          .el-input__icon {
            line-height: 34px;
          }
        }
      }
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
    .deactivate {
      background-color: #aac6f5 !important;
      border-color: #aac6f5 !important;
    }
  }
  //标签
  .labelDialog {
    color: #333;
    /deep/.el-dialog {
      width: 500px;
      font-size: 14px;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      white-space: nowrap;
    }
    /deep/.el-dialog__body {
      font-weight: 400;
      padding: 20px 0 25px;
      max-height: 700px;
      overflow: hidden;
      .fillColor__btn {
        width: 330px;
        height: 30px;
        text-align: center;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
      .el-color-picker {
        width: 330px;
        height: 30px;
      }
      .el-color-picker__trigger {
        height: 100%;
        width: 100%;
        border: none;
        padding: 0;
        .el-icon-arrow-down {
          color: #ccc !important;
        }
      }
      .el-color-picker__color {
        border: none;
      }
      .el-color-picker__color-inner {
        height: 100%;
        width: 100%;
      }
    }
    .inputArea {
      position: relative;
      width: 460px;
      height: 60px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/.el-select {
        width: 330px;
        height: 30px;
        border-radius: 2px;
      }
      /deep/.el-input {
        width: 330px;
        height: 30px;
        background: #f4f4f4;
        border-radius: 2px;
      }
      /deep/.el-input__icon {
        line-height: 30px;
      }
      /deep/.el-input__inner {
        height: 30px;
      }
      /deep/.el-input__count-inner {
        background: #f4f4f4;
      }
      .radioArea {
        width: 516px;
        height: 30px;
        display: flex;
        align-items: center;
        /deep/.el-radio__label {
          padding-right: 30px;
        }
      }
    }
    .labelOption {
      background-color: #f4f4f4;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      margin-bottom: 10px;
    }
    /deep/.el-progress--line {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    /deep/.el-progress-bar {
      padding-right: 40px;
    }
    /deep/.el-button--primary {
      background-color: #4385f2;
      border-color: #4385f2;
    }
    /deep/.el-button--default {
      background-color: #b0b0b0;
      border-color: #b0b0b0;
      color: #ffffff;
    }
    /deep/.el-button--mini {
      padding: 8px 25px;
      font-size: 14px;
    }
  }
}
</style>