var QGdstk = null
var KDTreeFast = null
var AutoToolLibs = require('./libs')
var QGeosJs = require('./geos')
var CanvasFill = require('./canvas_fill')

function testgeos() {
  let poly = new QGeosJs('Polygon', [
    [
      [0, 0],
      [10, 0],
      [0, 10],
      [0, 0],
    ],
  ])
  let Point = new QGeosJs('Point', [20, 0])
  let d = poly.distance(Point)

  // let = new QGeosJs('MultiLineString', lines)
}
testgeos()

function offScreenFillPolygon(cell, scale) {
  if (!cell) return []
  if (cell.polygons.length == 0) return []
  let box = cell.bounding_box()
  let tranX = box[0][0]
  let tranY = box[0][1]

  let paths = new Path2D()
  cell.polygons.forEach(element => {
    if (element) {
      element.translate(-box[0][0], -box[0][1])
      element.scale(1 / scale, 1 / scale, [0, 0])
      let points = element.get_points()

      let p = new Path2D()
      p.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        p.lineTo(points[i][0], points[i][1])
      }
      p.closePath()
      paths.addPath(p)
    }
  })

  // 绘制获取图像
  box = cell.bounding_box()
  if (!box) return []
  let width = Math.ceil(box[1][0] - box[0][0])
  let height = Math.ceil(box[1][1] - box[0][1])
  let canvas_off = new OffscreenCanvas(width, height)
  let ctx = canvas_off.getContext('2d')
  ctx.strokeStyle = 'rgb(5,0,0)'
  ctx.fillStyle = 'rgb(5,0,0)'
  ctx.stroke(paths)
  ctx.fill(paths)

  let img = ctx.getImageData(0, 0, width, height)

  // 获取 填充像素点
  let fill_points = []
  let data = img.data
  let row = 0,
    col = 0
  for (let i = 0; i < data.length; i++) {
    if (i > 0 && i % width == 0) {
      row += 1
    }
    col = i - row * width
    if (data[4 * i] > 0) {
      fill_points.push([col * scale + tranX, row * scale + tranY])
      // fill_points.push([col, row])
    }
  }

  return fill_points
}
;(function () {
  function AutoToolTiNPadInpillar(library, cell) {
    QGdstk = window.QGdstk
    KDTreeFast = window.KDTreeFast
    this.library = library
    this.cell = cell
    this.libs = new AutoToolLibs()
    this.rules = []
    this.kd_rules = []
    this.fill_layers = {}
    this.conf = {
      avoid: [],
      size: {
        A: 20,
        A1: 10,
        indium_shape: 'Round',
        indium_tolerance: 0.01,
        indium_layer: 7,
        indium_flip_layer: 8,
        tin_shape: 'Round',
        tin_tolerance: 0.01,
        tin_layer: 9,
        tin_flip_layer: 10,
      },
      place_polygon: [
        [3000, 3000],
        [30000, 3000],
        [30000, 30000],
        [3000, 30000],
      ],
      dig_polygons: [
        [
          [10000, 10000],
          [20000, 10000],
          [20000, 20000],
          [10000, 20000],
        ],
      ],
      grid_step: 40,
      min_distance: 50,
    }
    this.console_name = '[TiNPad]:'
  }

  AutoToolTiNPadInpillar.prototype.setConf = function (conf) {
    this.conf = conf
  }

  AutoToolTiNPadInpillar.prototype.canvasFill = function (paths, polygons, step, box) {
    let cell = new window.QGdstk.Cell('fill')

    if (paths.length > 0) {
      paths.forEach(element => {
        let p = element.copy()
        p.translate(-box[0][0], -box[0][1])
        p.scale(1 / step)
        cell.add(p)
      })
    }
    if (polygons.length > 0) {
      polygons.forEach(element => {
        let p = element.copy()
        p.translate(-box[0][0], -box[0][1])
        p.scale(1 / step, 1 / step, [0, 0])
        cell.add(p)
      })
    }

    // let box = cell.bounding_box()

    let canvas = document.getElementById('fill')

    let canvasFill = new CanvasFill(canvas, cell)
    let res = canvasFill.run()
    // let res = canvasFill.getfill()
    setTimeout(() => {

    }, 2000)
  }

  AutoToolTiNPadInpillar.prototype.run = function (data) {
    this.conf = data

    if (!this.cell) {
      return
    }
    // 创建避开kd树集合
    this.kd_rules = []
    let canvas = document.getElementById('fill')

    for (let i = 0; i < this.conf.avoid.length; i++) {
      let item = this.conf.avoid[i]

      // let path_step = Math.floor(item.distance / Math.cos(Math.PI / 6))
      let path_step = item.distance
      // let path_step = item.distance / 2
      // let path_distance = path_step / Math.sin(Math.atan(path_step / item.distance))
      let path_distance = path_step / Math.cos(Math.PI / 6)
      let path_points = []

      // 按图层区分paths
      let paths = this.cell.get_paths(true, null, item.layer, item.datatype)
      if (paths) {
        let tmp_points = this.libs.split_paths(paths, path_step)
        tmp_points.forEach(e => {
          path_points.push(e)
        })
      }
      // 获取指定图层多边形
      let polygons = this.cell.get_polygons(true, false, null, item.layer, item.datatype)
      if (polygons) {
        let tmp_points = this.libs.split_polygons_lines(polygons, path_step)
        tmp_points.forEach(e => {
          path_points.push(e)
        })
      }


      // 多边形填充
      let cell = new window.QGdstk.Cell('fill')
      cell.add(polygons)
      let polygon_step = path_step
      let polygon_distance = path_distance
      let polygon_points = offScreenFillPolygon(cell, polygon_step)


      // kdtree
      // if (points) {
      // 创建避开的KDTree
      this.kd_rules.push({
        // kdtree: new QGdstk.KDTree(points),
        path_kdtree: new QGdstk.KDTree(path_points),
        polygon_kdtree: new QGdstk.KDTree(polygon_points),
        path_distance: path_distance,
        polygon_distance: polygon_distance,
        distance: item.distance,
        layer: item.layer,
        datatype: item.datatype,
      })
      // }
    }

    // 获取放置位置
    let place_pos = this.getPlacePos()
    let res = this.placeCell(place_pos)
    return res
  }

  AutoToolTiNPadInpillar.prototype.checkRule = function (pos, shape, radius, rules) {
    function rect_tran(center = [0, 0], w = 10, angle = 0) {
      /*用gdspy库获取2D 方形8个关键点位经过旋转平移后的点位信息
            3 - 2 - 1
            |       |
            4       0
            |       |
            5 - 6 - 7
        */
      points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      p = new QGdstk.Polygon(points, 0, 0)
      p.rotate(angle)
      p.translate(center[0], center[1])
      return p.points
    }
    if (shape == 'Round') {
      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i]
        let path_indices = rule.path_kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.path_distance + radius)
        if (path_indices.length > 0) return -1
        let polygon_indices = rule.polygon_kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.polygon_distance + radius)
        if (polygon_indices.length > 0) return -1

        // let indices = rule.kdtree.neighborhood_indices_pos(pos[0], pos[1], rule.distance + radius)
        // if (indices.length > 0) {
        //   return -1
        // }
      }
      return 0
    }

    if (shape == 'Rectangle') {
      let angle_list = [0, Math.PI / 4]
      for (let j = 0; j < angle_list.length; j++) {
        let is_ok = true
        let angle = angle_list[j]
        for (let i = 0; i < rules.length; i++) {
          let rule = rules[i]
          // let r = rule.distance
          let bound_points = rect_tran(pos, radius, angle)
          for (let k = 0; k < bound_points.length; k++) {
            let p = bound_points[k]
            let path_indices = rule.path_kdtree.neighborhood_indices_pos(p[0], p[1], rule.path_distance)
            if (path_indices.length > 0) {
              is_ok = false
              break
            }
            let polygon_indices = rule.polygon_kdtree.neighborhood_indices_pos(p[0], p[1], rule.polygon_distance)
            if (polygon_indices.length > 0) {
              is_ok = false
              break
            }
            // let indices = rule.kdtree.neighborhood_indices_pos(p[0], p[1], r)
            // if (indices.length > 0) {
            //   is_ok = false
            //   break
            // }
          }
        }
        if (is_ok) {
          return angle
        }
      }
      return -1
    }
  }
  AutoToolTiNPadInpillar.prototype.printProxy = function (points) {
    let p = []
    for (let i = 0; i < points.length; i++) {
      p.push([points[i][0], points[i][1]])
    }
  }

  AutoToolTiNPadInpillar.prototype.proxyToArray = function (points) {
    let p = []
    for (let i = 0; i < points.length; i++) {
      p.push([points[i][0], points[i][1]])
    }
    return p
  }

  AutoToolTiNPadInpillar.prototype.scanLineFillPolygon = function (box, polygons, step) {
    let x_min = box[0][0] / step
    let y_min = box[0][1] / step
    let x_max = box[1][0] / step
    let y_max = box[1][1] / step
    let x_len = Math.floor(x_max - x_min)
    let y_len = Math.floor(y_max - y_min)

    let lines = []
    for (let i = 0; i < x_len; i++) {
      lines.push([
        [x_min + i, y_min],
        [x_min + i, y_max],
      ])
    }

    for (let i = 0; i < polygons.length; i++) {
      for (let j = 0; j < polygons[i].length; j++) {
        polygons[i][j] = [polygons[i][j][0] / step, polygons[i][j][1] / step]
      }
    }

    let multiLineString = new QGeosJs('MultiLineString', lines)
    let multiPolygon = new QGeosJs('MultiPolygon', [polygons])
    let inter = multiLineString.intersection(multiPolygon)
    let col_lines = []
    if (!inter) return []
    if (inter.type == 'GeometryCollection') {
      let geos_arr = inter.coordinates
      for (let i = 0; i < geos_arr.length; i++) {
        let tmp_geo = geos_arr[i]
        if (tmp_geo.type == 'LineString') {
          col_lines.push(tmp_geo.coordinates)
        }
      }
    }
    if (inter.type == 'MultiLineString') {
      col_lines = inter.coordinates
    }
    if (inter.type == 'LineString') {
      col_lines.push(inter.coordinates)
    }
    return col_lines
  }

  AutoToolTiNPadInpillar.prototype.getPlacePos = function () {
    let place_polygon_op = new QGdstk.Polygon(this.conf.place_polygon, 0, 0)
    let box = place_polygon_op.bounding_box()
    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]

    // let x_offset = x_min < 0 ? -x_min : 0
    // let y_offset = y_min < 0 ? -y_min : 0
    let x_offset = -x_min
    let y_offset = -y_min

    place_polygon_op.translate(x_offset, y_offset)
    box = place_polygon_op.bounding_box()

    // 获取roi区域
    let roi = null
    let dig_polygons = this.conf.dig_polygons
    if (dig_polygons) {
      let dig_polygons_op = []
      for (let i = 0; i < dig_polygons.length; i++) {
        if (dig_polygons[i].length < 3) {
          continue
        }
        let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
        p2.translate(x_offset, y_offset)
        dig_polygons_op.push(p2)
      }
      roi = QGdstk.boolean(place_polygon_op, dig_polygons_op, 'not')
    } else {
      roi = [place_polygon_op]
    }

    // 计算步长
    let step_grid = this.conf.grid_step
    // let step = Math.floor(this.conf.min_distance / step_grid)

    let polygons = []
    for (let p_idx = 0; p_idx < roi.length; p_idx++) {
      let ppp = roi[p_idx].points
      ppp.push(ppp[0])
      polygons.push(this.proxyToArray(ppp))
    }

    // 自身kdtree
    let self_min_distance = this.conf.min_distance * this.conf.min_distance - 0.01
    let kdtree_self = new KDTreeFast.KdTree(2)
    let res = []

    // 获取线列线
    let col_lines = this.scanLineFillPolygon(box, polygons, step_grid)
    // 进度
    let length = col_lines.length
    let process_n = Math.ceil(length / 10)
    // let n = 0
    for (let i = 0; i < col_lines.length; i++) {
      if (i % process_n == 0) {

      } else if (i == length - 1) {

      }

      let line = col_lines[i]
      let p1 = line[0]
      let p2 = line[1]
      // let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      let pos_arr = []
      // 散点
      if (dy > 0) {
        for (let k = 0; k < dy; k++) {
          pos_arr.push([p1[0] * step_grid - x_offset, (p1[1] + k) * step_grid - y_offset])
        }
      }
      if (dy < 0) {
        for (let k = 0; k > dy; k--) {
          pos_arr.push([p1[0] * step_grid - x_offset, (p1[1] + k) * step_grid - y_offset])
        }
      }
      if (pos_arr.length < 1) continue

      for (let k = 0; k < pos_arr.length; k++) {
        let pos = pos_arr[k]
        let inds = kdtree_self.within(pos, self_min_distance)
        if (inds.length > 0) {
          continue
        }
        let angle = this.checkRule(pos, this.conf.size.tin_shape, this.conf.size.A / 2, this.kd_rules)
        if (angle === -1) {
          continue
        }
        res.push({ pos: pos, angle: angle })
        kdtree_self.add(pos, k)
      }
    }
    return res
  }

  AutoToolTiNPadInpillar.prototype.getPlacePosBK = function () {
    let place_polygon_op = new QGdstk.Polygon(this.conf.place_polygon, 0, 0)
    let box = place_polygon_op.bounding_box()
    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]
    // let x_offset = x_min < 0 ? -x_min : 0
    // let y_offset = y_min < 0 ? -y_min : 0
    let x_offset = -x_min
    let y_offset = -y_min
    place_polygon_op.translate(x_offset, y_offset)

    let dig_polygons_op = []
    let dig_polygons = this.conf.dig_polygons
    for (let i = 0; i < dig_polygons.length; i++) {
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      p2.translate(x_offset, y_offset)
      dig_polygons_op.push(p2)
    }

    let roi = QGdstk.boolean(place_polygon_op, dig_polygons_op[0], 'not')

    // 计算步长
    let step_grid = this.conf.grid_step
    let step = Math.floor(this.conf.min_distance / step_grid)
    let x_offset_step = Math.floor(x_offset / step_grid)
    let y_offset_step = Math.floor(y_offset / step_grid)

    // 计算grid行列数量
    let row_num = Math.floor((y_max - y_min) / step_grid + step * 2)
    let col_num = Math.floor((x_max - x_min) / step_grid + step * 2)
    let grid_one = new ArrayBuffer(row_num)
    for (let n = 0; n < row_num; n++) {
      grid_one[n] = new Uint8Array(col_num)
    }

    let res = []
    for (let p_idx = 0; p_idx < roi.length; p_idx++) {
      // 遍历需要放置的多边形区域
      let poly = roi[p_idx]
      // 将多边形的首尾点连接起来
      poly.points.push(poly.points[0])

      this.printProxy(poly.points)
      // 线性填充多边形
      // let fill = this.libs.fillPolygonRC(this.proxyToArray(poly.points), step_grid, 1)
      let rr = fill.cc
      let cc = fill.rr

      // 进度变量
      let process = Math.ceil(rr.length / 20)
      let len = rr.length
      for (let idx = 0; idx < len; idx++) {
        // 进度显示
        if (idx % process == 0) {
        } else if (idx == len - 1) {
        }
        let r = parseInt(rr[idx])
        let c = parseInt(cc[idx])
        // 获取真实位置为: 行数*网格步长-偏移量
        let pos = [r * step_grid - x_offset, c * step_grid - y_offset]
        let has_other = false
        // 快速扫描周边有没有其他tinpad
        for (let i = r - step; i < r + step; i++) {
          for (let j = c - step; j < c + step; j++) {
            if (grid_one[i + step][j + step] === 1) {
              has_other = true
              break
            }
          }
          if (has_other) {
            break
          }
        }
        if (has_other) {
          continue
        }
        let angle = this.checkRule(pos, this.conf.size.tin_shape, this.conf.size.A / 2, this.kd_rules)
        if (angle === -1) {
          continue
        }
        try {
          grid_one[r + step][c + step] = 1
        } catch (error) {}
        res.push({ pos: pos, angle: angle })
      }
    }

    return res
  }

  AutoToolTiNPadInpillar.prototype.pathsTranLines = function (paths) {
    let lines = []
    for (let i = 0; i < paths.length; i++) {
      lines.push(paths[i].spine())
    }
    return lines
  }

  AutoToolTiNPadInpillar.prototype.placeCell = function (pos) {
    let cell_tin_dium = this.TiNIndium(this.conf.size)

    let references = []
    for (let i = 0; i < pos.length; i++) {
      let t = pos[i]
      let ref = new QGdstk.Reference(cell_tin_dium, t.pos, t.angle, 1, false, 1, 1, null)
      references.push(ref)
    }

    let log = this.total(references.length)
    let data = {
      add_cells: [cell_tin_dium],
      add_references: references,
      log: log,
    }
    return data
  }

  AutoToolTiNPadInpillar.prototype.getRoiArea = function (place_polygon, dig_polygons) {
    let place_poly = new QGdstk.Polygon(place_polygon)
    let dig_polygons_op = []
    for (let i = 0; i < dig_polygons.length; i++) {
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      dig_polygons_op.push(p2)
    }
    let roi = QGdstk.boolean(place_poly, dig_polygons_op, 'not')
    return roi
  }

  // 统计报告
  AutoToolTiNPadInpillar.prototype.total = function (inpillar_num) {
    let radius = this.conf.size.A1 / 2
    let shape = this.conf.size.indium_shape
    let inpillar_area = 0
    if (shape == 'Round') {
      // inpillar_area = inpillar_num * Math.PI * radius * radius
      let Indium = QGdstk.ellipse([0, 0], this.conf.size.A1 / 2, (inner_radius = null), (initial_angle = 0), (final_angle = 0), (tolerance = this.conf.size.indium_tolerance), this.conf.size.indium_layer, this.conf.size.indium_datatype)
      inpillar_area = inpillar_num * Indium.area()
    }
    if (shape == 'Rectangle') {
      inpillar_area = inpillar_num * (2 * radius) * (2 * radius)
    }
    let place = this.conf.place_polygon
    let place_poly = new QGdstk.Polygon(place)

    let dig_polygons_op = []
    let dig_polygons = this.conf.dig_polygons
    for (let i = 0; i < dig_polygons.length; i++) {
      if (dig_polygons[i].length == 0) {
        continue
      }
      let p2 = new QGdstk.Polygon(dig_polygons[i], 0, 0)
      dig_polygons_op.push(p2)
    }
    let roi = QGdstk.boolean(place_poly, dig_polygons_op, 'not', 0.01, 0, 0)

    let roi_area = 0
    for (let i = 0; i < roi.length; i++) {
      roi_area += roi[i].area()
    }

    let account = 0
    if (roi_area > 0) account = (100 * inpillar_area) / roi_area

    return {
      inpillar_number: inpillar_num,
      inpillar_radius: radius,
      inpillar_shape: shape,
      inpillar_total_area: inpillar_area,
      roi_area: roi_area,
      account: account,
    }
  }

  // indium_shape, indium_layer = 7, indium_datatype = 0, indium_flip_layer = 8, indium_flip_datatype = 0, tin_shape, tin_layer = 9, tin_datatype = 0, tin_flip_layer = 10, tin_flip_datatype = 0, A = 26, A1 = 16, tolerance = 0.01
  AutoToolTiNPadInpillar.prototype.TiNIndium = function (data) {
    let size = data || {
      A: 26,
      indium_tolerance: 0.01,
      indium_shape: 'Round',
      indium_layer: 7,
      indium_datatype: 0,
      indium_flip_layer: 8,
      indium_flip_datatype: 0,

      A1: 16,
      tin_tolerance: 0.01,
      tin_shape: 'Round',
      tin_layer: 9,
      tin_datatype: 0,
      tin_flip_layer: 10,
      tin_flip_datatype: 0,
    }
    let TiN = null
    let TiN_Flip = null
    if (size.tin_shape === 'Round') {
      TiN = QGdstk.ellipse([0, 0], size.A / 2, null, 0, 0, size.tin_tolerance, size.tin_layer, size.tin_datatype)
      TiN_Flip = QGdstk.ellipse([0, 0], size.A / 2, null, 0, 0, size.tin_tolerance, size.tin_flip_layer, size.tin_flip_datatype)
    }
    if (size.tin_shape === 'Rectangle') {
      let w = size.A / 2
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      TiN = new QGdstk.Polygon(points, size.tin_layer, size.tin_datatype)
      TiN_Flip = new QGdstk.Polygon(points, size.tin_flip_layer, size.tin_flip_datatype)
    }
    let Indium = null
    let Indium_Flip = null
    if (size.indium_shape === 'Round') {
      Indium = QGdstk.ellipse([0, 0], size.A1 / 2, null, 0, 0, size.indium_tolerance, size.indium_layer, size.indium_datatype)
      Indium_Flip = QGdstk.ellipse([0, 0], size.A1 / 2, null, 0, 0, size.indium_tolerance, size.indium_flip_layer, size.indium_flip_datatype)
    }
    if (size.indium_shape === 'Rectangle') {
      let w = size.A1 / 2
      let points = [
        [w, 0],
        [w, w],
        [0, w],
        [-w, w],
        [-w, 0],
        [-w, -w],
        [0, -w],
        [w, -w],
      ]
      Indium = new QGdstk.Polygon(points, size.indium_layer, size.indium_datatype)
      Indium_Flip = new QGdstk.Polygon(points, size.indium_flip_layer, size.indium_flip_datatype)
    }

    let cell = new QGdstk.Cell('TiN-Induim')
    cell.add([TiN, TiN_Flip, Indium, Indium_Flip])
    return cell
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AutoToolTiNPadInpillar
  } else {
    window.AutoToolTiNPadInpillar = AutoToolTiNPadInpillar
  }
})()
