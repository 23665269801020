export class AutoToolWaferLayout {
  constructor() {
    this.conf = {
      wafer: {
        radius: 10,
        center: { x: 0, y: 0 },
        layer: 0,
        padding: 0,
      },
      cell: {
        object: null,
        name: 'cell1',
        space: 10,
      },
    }
  }

  run(conf) {
    // 辅助函数：检查给定位置是否可用
    // padding: 判断矩形四个角是否在圆内
    function is_position_available(radius, obj, padding) {
      let x = obj.x
      let y = obj.y
      let width = obj.width
      let height = obj.height
      let max_rr = (radius - padding) * (radius - padding)
      // 矩形左下角点是否在容器内
      if (x * x + y * y > max_rr) {
        return false
      }
      // 矩形右上角点是否在容器内
      if ((x + width) * (x + width) + (y + height) * (y + height) > max_rr) {
        return false
      }
      // 矩形右下角点是否在容器内
      if ((x + width) * (x + width) + y * y > max_rr) {
        return false
      }
      // 矩形左上角点是否在容器内
      if (x * x + (y + height) * (y + height) > max_rr) {
        return false
      }
      return true
    }

    // 辅助函数：在给定布局中找到最佳位置

    function most_dense_packing(radius, obj, padding) {
      // 创建空的容器布局
      let layout = {
        width: radius,
        height: radius,
        objects: [],
      }

      let num_w = Math.floor(radius / obj.width) + 1
      let num_h = Math.floor(radius / obj.height) + 1

      // 快速查找
      let res_max = []
      let num_max = 10

      for (let num_x = 0; num_x < num_max; num_x++) {
        for (let num_y = 0; num_y < num_max; num_y++) {
          let objs = []
          for (let i = -num_w; i < num_w + 1; i++) {
            let x = i * obj.width - (obj.width * num_x) / num_max
            for (let j = -num_h; j < num_h + 1; j++) {
              let y = j * obj.height - (obj.height * num_y) / num_max
              let element = { x: x, y: y, width: obj.width, height: obj.height }
              if (is_position_available(radius, element, padding)) objs.push(element)
            }
          }
          if (objs.length > res_max.length) {
            res_max = objs
          }
        }
      }

      layout.objects = res_max
      return layout
    }

    this.conf = conf
    let cell = this.conf.cell.object

    // 计算最大数量
    let box = cell.bounding_box()
    let rect = {
      width: box[1][0] - box[0][0],
      height: box[1][1] - box[0][1],
    }

    if (rect.width === -Infinity || rect.height === -Infinity) {
      throw window.i18n.t('messages.toolJJerrorCell')
    }

    // 创建objects
    let space = conf.cell.space
    let padding = conf.wafer.padding
    let obj = { width: rect.width + space, height: rect.height + space }

    // 计算cell需要平移到中心的偏移量
    let cell_tx = -box[0][0]
    let cell_ty = -box[0][1]
    let wafer_tx = conf.wafer.center.x
    let wafer_ty = conf.wafer.center.y

    // 最密堆积
    let res = most_dense_packing(conf.wafer.radius, obj, padding)
    res.objects.forEach(obj => {
      obj.x = obj.x + space / 2 + wafer_tx + cell_tx
      obj.y = obj.y + space / 2 + wafer_ty + cell_ty
      obj.width = obj.width - space
      obj.height = obj.height - space
    })

    return this.placeCell(res.objects)
  }

  placeCell(objects) {
    let cell = this.conf.cell.object
    let wafer_cell = new window.Kernel.Cell(cell.name + '_WaferLayout')
    for (let i = 0; i < objects.length; i++) {
      let obj = objects[i]
      let ref = new window.Kernel.Reference()
      ref.cell = cell
      ref.origin = [obj.x, obj.y]
      wafer_cell.add_reference(ref)
    }
    let circle = new window.Kernel.GdsEllipse([this.conf.wafer.center.x, this.conf.wafer.center.y], this.conf.wafer.radius, this.conf.wafer.radius, 200)
    circle.layer = this.conf.fileLayerDict[this.conf.wafer.layer].layerNumber
    circle.id = this.conf.fileLayerDict[this.conf.wafer.layer].id

    wafer_cell.add_polygon(circle)
    let data = {
      add_cells: [wafer_cell],
    }
    return data
  }
}
