<template>
  <div>
    <div class="header" onselectstart="javascript:return false;">
      <!-- logo+选择菜单 -->
      <div class="h-left">
        <img alt="" class="logo" @click="toHome" src="../../../assets/logo QLayout.svg" />
        <!-- 导航栏 -->
        <div class="menuBox" v-if="this.$store.state.routeFor.indexOf('/home/layout') !== -1">
          <!-- 共享菜单 -->
          <vue-file-toolbar-menu :content="share_menu" v-if="cell_type == '0'" />
          <!-- 原理图菜单 -->
          <vue-file-toolbar-menu :content="schematicDiagram_menu" v-else-if="cell_type == '3'" />
          <!-- 版图菜单 -->
          <vue-file-toolbar-menu :content="my_menu" v-else />
        </div>
        <div class="menuBox" v-if="this.$store.state.routeFor.indexOf('/3d-view') !== -1">
          <!-- 共享菜单 -->
          <vue-file-toolbar-menu :content="menu_3d" />
        </div>
      </div>
      <!-- 用户&选项 -->
      <div class="h-right">
        <!-- 图标 -->
        <!-- <el-dropdown> -->
        <!-- <div class="old-version" @click="toOldVersion">{{ $t('messages.oldVersion') }}</div> -->

        <el-dropdown v-show="showCellHistory">
          <div class="historyInfo">
            <img alt="" class="history-icon" src="../../../assets/icons/file/迭代_还原.svg" />
          </div>
          <el-dropdown-menu class="historyPanel" slot="dropdown">
            <div class="history-title">
              <h5>{{ $t('messages.messageBoard.editHistory') }}</h5>
            </div>
            <hr class="history-tilte-line" />
            <div class="history-list-item" v-for="(item, index) in cellHistorys" :key="index" :id="index">
              <img class="history-img" alt="" src="../../../assets/personalCenter/个人默认头像.png" />
              <div class="history-item-left">
                <div class="history-item-left-top">
                  <div class="history-item-left-top-text">
                    <span class="history-high-light">{{ item.teamName }}</span>
                    <span>{{ $t('messages.messageBoard.team') }}</span>
                    <span class="history-high-light">{{ item.userName }}</span>
                    <span>{{ $t('messages.messageBoard.member') }}</span>
                    <span>{{ $t('messages.messageBoard.editCurrentCell') }}</span>
                  </div>
                  <div class="history-item-left-top-btn">
                    <div class="show-triangle" v-if="item.hide"></div>
                    <div class="hide-triangle" v-if="!item.hide"></div>
                    <el-button class="history-show-hide-btn" type="text" v-if="item.hide"
                      @click.stop="showHistory(index)">{{ $t('messages.messageBoard.show') }}</el-button>
                    <el-button class="history-show-hide-btn" type="text" v-if="!item.hide"
                      @click.stop="showHistory(index)">{{ $t('messages.messageBoard.hide') }}</el-button>
                  </div>
                </div>
                <div class="history-item-left-mid" v-if="!item.hide">
                  <span>{{ item.teamName }}{{ $t('messages.messageBoard.team') }}
                    {{ item.userName }}{{ $t('messages.messageBoard.memberDone') }}{{ getHistoryDesc(item) }}{{ $t('messages.messageBoard.operation') }}</span>
                </div>
                <div class="history-item-left-end">
                  <span class="history-time">{{ item.time }}</span>
                </div>
              </div>
            </div>
            <!-- <el-dropdown-item class="history-list-item">
              <div v-for="(item, index) in cellHistorys" :key="index" :id="index">
                <img class="history-img" alt="" src="../../../assets/personalCenter/个人默认头像.png" />
                <span>{{ item.func }}</span>
              </div>
            </el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>

        <div class="headerChangeLang">
          <img alt="" v-if="isLanguageCN" class="language-icon" @click.stop="changeLang()"
            src="../../../assets/icons/file/中文.svg" />
          <img alt="" v-else class="language-icon" @click.stop="changeLang()" src="../../../assets/icons/file/英文.svg" />
        </div>
        <img alt="" src="../../../assets/personalCenter/个人默认头像.png" />
        <span class="username">{{ this.$store.state.username ? this.$store.state.username : username }}</span>
        <el-dropdown>
          <span class="el-icon-arrow-down setting el-dropdown-link"></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="toSelf">{{ $t('messages.userCenter') }}</span>
            </el-dropdown-item>
            <!-- <el-dropdown-item><span @click="changePsd">安全设置</span>
          </el-dropdown-item> -->
            <el-dropdown-item>
              <span style="display: inline-block; width: 100%; height: 100%"
                @click="dialogVisible = true">{{ $t('messages.quit') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="dialog">
      <!-- 点击退出登录弹出框 -->
      <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible">
        <span>{{ $t('messages.reallyQuit') }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">{{ $t('messages.cancel') }}</el-button>
          <el-button size="small" type="primary" @click="exit">{{ $t('messages.sure') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog title="Import GDS file" :visible.sync="uploadDialogVisible" :close-on-click-modal="false"
        :before-close="handleCloseUploadDialog">
        <el-upload ref="upload" :show-file-list="true" class="upload-demo" action="" :auto-upload="false"
          :on-change="uploadChanged">
          <el-button slot="trigger" size="small" type="primary">Select File</el-button>
          <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">Upload</el-button>
        </el-upload>
      </el-dialog>
    </div>

    <!-- 绘制菜单 -->
    <EditMenu></EditMenu>
    <DrawMenu></DrawMenu>
    <!-- 工具菜单 -->
    <DrcMenu></DrcMenu>
    <ToolMenuX></ToolMenuX>
    <OptionMenu></OptionMenu>
    <TestLine></TestLine>
    <HelpMenu></HelpMenu>
    <SchematicDrawMenu></SchematicDrawMenu>
    <SchematicOptionMenu></SchematicOptionMenu>
    <!-- 加载PDK -->
    <LoadPDK></LoadPDK>
    <!-- 生成PDK -->
    <GeneratePDK></GeneratePDK>
  </div>
</template>
<script>
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import ToolMenuX from "../tool/ToolMenuX.vue";
import DrcMenu from "@/components/homes/header/drc/DrcMenu.vue";
import EditMenu from "../EditMenu.vue";
import DrawMenu from "../DrawMenu.vue";
import OptionMenu from "../OptionMenu.vue";
import TestLine from "../TestLine.vue";
import HelpMenu from "../HelpMenu.vue";
import SchematicDrawMenu from "../SchematicDrawMenu.vue";
import SchematicOptionMenu from "../SchematicOptionMenu.vue";
import LoadPDK from "./LoadPDK.vue";
import GeneratePDK from "./GeneratePDK.vue";
import bus from "../../common/bus";
import "@/assets/iconfont/iconfont.css";
import defaultColor from "../../../assets/layer/默认图形填充.jpg"; //NOSONAR
import { postExit, postImportGDS, getExportGDS } from "@/api/api.js"; //NOSONAR
import { logout_api } from "@/api/login/login.js";
import { forceFileDownload } from "../../common/tools"; //NOSONAR
import { getUserInfo_api } from "@/api/user/user.js";
import { downloadFileData } from "../fileList/fileUtils";
import {
  getProjectDetail,
  getHistoryCell_api, //获取近期浏览
  addHistoryCell_api, //设置近期浏览
  parsingGns_api, //gns路径解析
} from "@/api/file/file.js";
import * as kdtreefast from "../../../utils/kdtree-fast/kdtree_fast";
import { getCurrentLayout } from "@/utils/graphic_cell/qeda-cell-layout";
import { deepClone } from "../../../utils/utils";
import {
  getCellIndexData,
  recGetCellIndex,
  getCapSymbolIdByEncapsFrom,
} from "../fileList/function/fileListPublicFn";
import { getPermission } from "../fileList/function/fileListApiFn";
window.KDTreeFast = kdtreefast;
const alignChMap = {
  0: "竖向中线对齐",
  1: "横向中线对齐",
  2: "左对齐",
  3: "右对齐",
  4: "下对齐",
  5: "上对齐",
  6: "竖向中线对齐",
  7: "横向中线对齐",
};
export default {
  components: {
    VueFileToolbarMenu,
    EditMenu,
    DrawMenu,
    ToolMenuX,
    DrcMenu,
    OptionMenu,
    TestLine,
    HelpMenu,
    SchematicDrawMenu,
    SchematicOptionMenu,
    LoadPDK,
    GeneratePDK,
  },
  data() {
    return {
      username: localStorage.getItem("username"),
      dialogVisible: false,
      createProject: false, // 新建项目弹窗
      createFile: false,
      uploadDialogVisible: false,
      projectName: "", //项目名称
      fileName: "",
      options: [
        {
          value: 1,
        },
        {
          value: 2,
        },
      ],
      value: "",
      clickComponent: false,
      isLanguageCN: true,
      history: false,
      historyArr: [],
      noHistory: [
        {
          html: `<span style="flex:1;line-height:30px;padding-left:25px;" class="historyItem">${this.$t(
            "messages.browsingRecord"
          )}</span>`,
        },
      ],
      //导入GDS
      importDialogVisible: false,
      transmissionQueue: [
        {
          name: "文件1名称",
          info: "进行中",
        },
        {
          name: "文件2名称",
          info: "进行中",
        },
        {
          name: "文件3名称",
          info: "进行中",
        },
        {
          name: "文件4名称",
          info: "等待中",
        },
        {
          name: "文件4名称",
          info: "等待中",
        },
      ],
      //另存为单元是否打开单元
      isOpenCell: false,
      showCoordinate: true,
      showCoordinateInstanceName: true,
      showCoordinateLabelName: true,
      showCoordinateKeyParameter: true,
      showCoordinatePinName: true,
      showCoordinatePortName: true,
      showCoordinateNetLable: true,
      showCoordinateOtherLabel: true,
      batchCreateCell: false,
      //siderbar是否激活画图功能
      isDraw: false,
      //导航栏切换
      cell_type: "0",
      //只读模式
      readOnly: false,
      //是否可以打开符号
      hasCapSymbolToOpen: false,
      capSymbolIdList: [], //打开符号的封装符号
      //历史记录
      showCellHistory: false,
      cellHistorys: [],
    };
  },
  mounted() {
    // this.changeLang();
    let self = this;
    self.$bus.$on("clickJudge", (data) => {
      this.clickComponent = data;
    });
    bus.$on("addHistoryCell", (data) => {
      this.addHistoryCell(data);
    });
    if (!this.username) {
      getUserInfo_api().then((res2) => {
        localStorage.setItem("userId", res2.data.user_id);
        this.$store.commit("setUserName", res2.data.display_name);
        localStorage.setItem("userName", res2.data.display_name);
      });
    }
    // bus.$on("updateFileTree", (data) => {
    //   if (Array.isArray(data)) {
    //     this.batchCreateCell = true;
    //   }
    // });
    bus.$on("siderBarDrawActive", (data) => {
      this.isDraw = true;
    });
    bus.$on("changeMenuAndSidebar", (data) => {
      let currentLayout = getCurrentLayout();
      this.cell_type = data;
    });
    bus.$on("cellHistoryUpdate", (data) => {
      // let list = data;
      // this.cellHistorys = list;
      let currentLayout = getCurrentLayout();

      //关闭画板
      if (data.close) {
        //画板全部关闭
        if (!currentLayout) {
          this.showCellHistory = false;
          this.cellHistorys = [];
        }
        return;
      }
      //打开原理图
      if (data?.cell?.$$?.ptrType.name == "Schema*") {
        this.showCellHistory = false;
        this.cellHistorys = [];
        return;
      }
      //打开个人版图
      if (!data?.cell?.js_obj?.WS) {
        this.showCellHistory = false;
        this.cellHistorys = [];
        return;
      }
      this.showCellHistory = true;
      let msgList = data.cell.js_obj.WS.msgList;
      if (
        data.active ||
        currentLayout == "INIT" ||
        (currentLayout &&
          data.cell.snow_id == currentLayout.RENDER_CELL.snow_id)
      ) {
        msgList.forEach((obj) => {
          if (obj.hide == undefined) {
            obj.hide = true;
          }
        });
        if (this.cellHistorys != msgList) {
          this.cellHistorys = deepClone(msgList).sort(
            (a, b) => new Date(b.time) - new Date(a.time)
          );
        }
      }
    });

    bus.$on("resetShowCoordinate", (data) => {
      this.showCoordinate = true;
    });
  },
  beforeDestroy() {
    bus.$off("clickJudge");
    bus.$off("addHistoryCell");
    bus.$off("siderBarDrawActive");
    bus.$off("changeMenuAndSidebar");
    bus.$off("cellHistoryUpdate");
    bus.$off("resetShowCoordinate");
  },
  methods: {
    toOldVersion() {
      window.open("http://qedatools12.originqc.com.cn/#/home/layout", "_blank");
    },
    clickFileMenu() {},
    toHome() {
      bus.$emit("closeLayerList", true);
      this.$router.push("/home");
    },
    handleCloseUploadDialog(done) {
      this.$refs.upload.clearFiles();
      done();
    },
    uploadChanged(file, fileList) {
      if (file.size > 500 * 1024) {
        fileList.splice(-1, 1);
        return this.$message.error(this.$t("messages.fileImportFailed"));
      } else {
        let splitList = file.name.split(".");
        if (splitList.pop() != "gds" || file.size < 10) {
          fileList.splice(-1, 1);
          return this.$message.error(this.$t("messages.fileFormatError"));
        }
        // 需要不设置limit，实现替换
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }
      }
    },
    sureCreate() {
      this.createNewProject();
    },
    sureCreateFile() {
      this.creatNewFile();
    },
    submitUpload(e) {
      let files = this.$refs.upload.uploadFiles;
      if (!files.length) {
        return;
      }
      const params = new FormData();
      params.append("file", files[0].raw);
      let project_id = this.$store.state.projectData.id;
      if (!project_id) {
        return this.$message.error(this.$t("messages.loadProject"));
      }
      params.append("project_id", project_id);
      postImportGDS(params).then(
        async (body) => {
          if (body.code / 100000 == 2) {
            this.uploadDialogVisible = false;
            this.$refs.upload.clearFiles();
            let newData = await getProjectDetail(project_id);
            if (newData.code / 100000 == 2) {
              return this.$store.commit("setProjectData", newData.data); //更新显示
            }
          } else {
            return this.$message.error(body.message);
          }
        },
        (error) => {}
      );
    },
    async importGDS() {
      bus.$emit("openImportDialog", true);
    },
    async exportGDS() {
      let fileNode = this.$store.state.activeNode.parent;
      if (fileNode.isTeam) {
        let permissionRes = await getPermission(fileNode.data.gns);
        if (permissionRes.code === 200000) {
          this.$store.state.fileInfo.teamRule = permissionRes.data.permission;
          if (
            this.$store.state.fileInfo.teamRule === "可删除、编辑" ||
            this.$store.state.fileInfo.teamRule === "只读"
          ) {
            bus.$emit("exportGDS", true);
          } else {
            bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
          }
        }
      } else {
        bus.$emit("exportGDS", true);
      }
    },
    async exportDXF() {
      let fileNode = this.$store.state.activeNode.parent;
      if (fileNode.isTeam) {
        let permissionRes = await getPermission(fileNode.data.gns);
        if (permissionRes.code === 200000) {
          this.$store.state.fileInfo.teamRule = permissionRes.data.permission;
          if (
            this.$store.state.fileInfo.teamRule === "可删除、编辑" ||
            this.$store.state.fileInfo.teamRule === "只读"
          ) {
            bus.$emit("exportDXF", true);
          } else {
            bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
          }
        }
      } else {
        bus.$emit("exportDXF", true);
      }
    },
    //导出原理图文件
    async exportSchemaFile() {
      let fileNode = this.$store.state.activeNode.parent;
      if (fileNode.isTeam) {
        let permissionRes = await getPermission(fileNode.data.gns);
        if (permissionRes.code === 200000) {
          this.$store.state.fileInfo.teamRule = permissionRes.data.permission;
          if (
            this.$store.state.fileInfo.teamRule === "可删除、编辑" ||
            this.$store.state.fileInfo.teamRule === "只读"
          ) {
            bus.$emit("exportSchema", true);
          } else {
            bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
          }
        }
      } else {
        bus.$emit("exportSchema", true);
      }
    },
    exportSchemaFileFn() {
      let schFile = {};
      let fileName = this.$store.state.fileInfo.name;
      let schFileId = this.$store.state.fileInfo.fileId;
      if (this.$store.state.schemaFileTable.length) {
        let index = this.$store.state.schemaFileTable.findIndex(
          (item) => item.fileId === schFileId
        );
        schFile = this.$store.state.schemaFileTable[index].fileData;
        let u8 = schFile.dump_with_dep();
        let u8_data = schFile.to_file();

        downloadFileData(u8_data, fileName, "oqpro");
      }
    },
    // 进入团队中心
    toTeamInfo() {
      if (this.isOpenCell) {
        bus.$emit("jumpToOtherPage", "toTeamInfo");
      } else {
        this.$router.push("/user/teaminfo");
        this.$store.commit("updateOpenedTab", []);
      }
    },
    //进入个人中心
    toSelf() {
      if (this.isOpenCell) {
        bus.$emit("jumpToOtherPage", "toSelf");
      } else {
        this.$router.push("/user");
        this.$store.commit("updateOpenedTab", []);
      }
    },
    // 修改密码
    changePsd() {
      if (this.isOpenCell) {
        bus.$emit("jumpToOtherPage", "changePsd");
      } else {
        this.$router.push("/password");
      }
    },
    // 退出登录
    async exit() {
      if (this.isOpenCell) {
        bus.$emit("jumpToOtherPage", "exit");
      } else {
        logout_api().then((res) => {
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("user");
          localStorage.removeItem("apiKey");
          localStorage.removeItem("bucket_name");
          localStorage.removeItem("minio_name");
          localStorage.removeItem("qid");
          this.$router.push("/login");
        });
      }
    },
    changeLang() {
      this.isLanguageCN = !this.isLanguageCN;
      if (this.isLanguageCN) {
        this.changeLangCN();
      } else {
        this.changeLangEN();
      }
      this.noHistory = [
        {
          html: `<span style="flex:1;line-height:30px;padding-left:25px;" class="historyItem">${this.$t(
            "messages.browsingRecord"
          )}</span>`,
        },
      ];
    },
    changeLangCN() {
      window.lang = "zh";
      this.$i18n.locale = window.lang;
      this.$bus.$emit("changeLang", "zh");
      localStorage.setItem("lang", "zh");
      this.$bus.$emit("cellHistoryUpdate", null);
    },
    changeLangEN() {
      window.lang = "en";
      this.$i18n.locale = window.lang;
      this.$bus.$emit("changeLang", "en");
      localStorage.setItem("lang", "en");
      this.$bus.$emit("cellHistoryUpdate", null);
    },
    getHistoryDesc(item) {
      let descMap = {
        DRAW_LINE: `${i18n.t("messages.messageBoard.DRAW_LINE")}`,
        DRAW_CIRCLE: `${i18n.t("messages.messageBoard.DRAW_CIRCLE")}`,
        DRAW_RECT: `${i18n.t("messages.messageBoard.DRAW_RECT")}`,
        DRAW_POLYGON: `${i18n.t("messages.messageBoard.DRAW_POLYGON")}`,
        DRAW_LABEL: `${i18n.t("messages.messageBoard.DRAW_LABEL")}`,
        DRAW_KP: `${i18n.t("messages.messageBoard.DRAW_KP")}`,
        DRAW_RULER: `${i18n.t("messages.messageBoard.DRAW_RULER")}`,
        DRAW_3D_CUT_AREA: `${i18n.t("messages.messageBoard.DRAW_3D_CUT_AREA")}`,
        DRAW_3D_RENDER_AREA: `${i18n.t(
          "messages.messageBoard.DRAW_3D_RENDER_AREA"
        )}`,
        MOVE: `${i18n.t("messages.messageBoard.MOVE")}`,
        STRETCH: `${i18n.t("messages.messageBoard.STRETCH")}`,
        ROTATE: `${i18n.t("messages.messageBoard.ROTATE")}`,
        MIRROR: `${i18n.t("messages.messageBoard.MIRROR")}`,
        MIRRORX: `${i18n.t("messages.messageBoard.MIRRORX")}`,
        MIRRORY: `${i18n.t("messages.messageBoard.MIRRORY")}`,
        ALIGN_PICK_POINT: `${i18n.t("messages.messageBoard.ALIGN_PICK_POINT")}`,
        ALIGN_PICK_LINE: `${i18n.t("messages.messageBoard.ALIGN_PICK_LINE")}`,
        ALIGN: `${i18n.t("messages.messageBoard.ALIGN")}`,
        DELETE: `${i18n.t("messages.messageBoard.DELETE")}`,
        ADD_DEVICE: `${i18n.t("messages.messageBoard.ADD_DEVICE")}`,
        PASTE: `${i18n.t("messages.messageBoard.PASTE")}`,
        CUT: `${i18n.t("messages.messageBoard.CUT")}`,
        MERGE: `${i18n.t("messages.messageBoard.MERGE")}`,
        CUT_AREA: `${i18n.t("messages.messageBoard.CUT_AREA")}`,
        SLICE: `${i18n.t("messages.messageBoard.SLICE")}`,
        ARRAY: `${i18n.t("messages.messageBoard.ARRAY")}`,
        BREAK: `${i18n.t("messages.messageBoard.BREAK")}`,
        BOOLEAN: `${i18n.t("messages.messageBoard.BOOLEAN")}`,
        PROPERTY: `${i18n.t("messages.messageBoard.PROPERTY")}`,
        TOOL_AIRBRIDGE: `${i18n.t("messages.messageBoard.TOOL_AIRBRIDGE")}`,
        CONNECT_LINE: `${i18n.t("messages.messageBoard.CONNECT_LINE")}`,
        TOOL_SQUID_ZLINE_RUN: `${i18n.t(
          "messages.messageBoard.TOOL_SQUID_ZLINE_RUN"
        )}`,
        TOOL_WAVEGUIDE_RUN: `${i18n.t(
          "messages.messageBoard.TOOL_WAVEGUIDE_RUN"
        )}`,
        TOOL_CAVITY_RUN: `${i18n.t("messages.messageBoard.TOOL_CAVITY_RUN")}`,
      };
      let desc = descMap[item.func];
      if (item.tool) {
        desc = descMap[item.tool];
      }
      if (item.func == "ALIGN") {
        if (item.params !== undefined) {
          desc = alignChMap[item.params];
        }
      }
      if (item.isUndo) {
        desc += `${i18n.t("messages.messageBoard.undo")}`;
      } else if (item.isRedo) {
        desc += `${i18n.t("messages.messageBoard.redo")}`;
      }
      return desc;
    },
    // 获取近期浏览
    async getHistoryCell() {
      //清空上次历史记录
      this.historyArr.splice(0, this.historyArr.length);
      let historyArray = []; //历史记录数组
      //获取所有历史记录
      let res = await getHistoryCell_api({});

      if (res.code === 200000) {
        for (let i = 0; i < res.data.length; i++) {
          //获取gns解析信息
          let gnsRes = await parsingGns_api({
            gns: res.data[i]._source.gns,
          });

          if (gnsRes.code === 200000) {
            let cellInfo = [];
            let fileId =
              res.data[i]._source.gns.split("/")[
                res.data[i]._source.gns.split("/").length - 2
              ];
            let cellId =
              res.data[i]._source.gns.split("/")[
                res.data[i]._source.gns.split("/").length - 1
              ];
            let projectId =
              res.data[i]._source.gns.split("/")[
                res.data[i]._source.gns.split("/").length - 3
              ];
            let cell_type = res.data[i]._source.types;
            let file_type = 1;
            if (cell_type === 3) {
              file_type = 3;
            }
            let userId = "";
            let topName =
              res.data[i]._source.category_child == "团队cell"
                ? gnsRes.data.name.split("/")[
                    gnsRes.data.name.split("/").length - 4
                  ]
                : localStorage.getItem("userName");
            let teamId =
              res.data[i]._source.category_child == "团队cell"
                ? res.data[i]._source.gns.split("/")[
                    res.data[i]._source.gns.split("/").length - 4
                  ]
                : "";
            let fileName =
              gnsRes.data.name.split("/")[
                gnsRes.data.name.split("/").length - 2
              ];
            let cellName =
              gnsRes.data.name.split("/")[
                gnsRes.data.name.split("/").length - 1
              ];
            let proName =
              gnsRes.data.name.split("/")[
                gnsRes.data.name.split("/").length - 3
              ];
            let iconType = "";
            if (cell_type === 1) {
              iconType = "iconfont icon-bantuming historyIcon";
            } else if (cell_type === 2) {
              iconType = "iconfont icon-qijianming historyIcon";
            } else if (cell_type === 3) {
              iconType = "iconfont icon-yuanlitu historyIcon";
            }
            if (res.data[i]._source.category_child == "团队cell") {
              cellInfo = [
                {
                  teamId,
                  name: topName,
                  teamRole: "",
                },
                {
                  projectId,
                  name: proName,
                  teamRule: gnsRes.data.permission[0].hasOwnProperty(
                    "permission"
                  )
                    ? gnsRes.data.permission[0].permission
                    : "不可见",
                },
                {
                  fileId,
                  name: fileName,
                  teamRule: gnsRes.data.permission[0].hasOwnProperty(
                    "permission"
                  )
                    ? gnsRes.data.permission[0].permission
                    : "不可见",
                  file_type,
                },
                {
                  name: cellName,
                  cellId,
                  teamRule: gnsRes.data.permission[0].hasOwnProperty(
                    "permission"
                  )
                    ? gnsRes.data.permission[0].permission
                    : "不可见",
                  isSaved: true,
                  isReadOnly: false,
                  cell_type,
                },
              ];
            } else {
              cellInfo = [
                {
                  userId,
                  name: topName,
                },
                {
                  projectId,
                  name: proName,
                },
                {
                  fileId: fileId,
                  name: fileName,
                  file_type,
                },
                {
                  cellId: cellId,
                  name: cellName,
                  isSaved: true,
                  isReadOnly: false,
                  cell_type,
                },
              ];
            }

            historyArray.push({
              cellInfo,
              html: `
              <span class="historyItem" title=${gnsRes.data.name}><span class="${iconType}"></span><span class="historyText">${res.data[i]._source.name}</span></span>
                `,
              click: () => {
                //点击触发更新方法,传递对应snow_id进行比较
                // this.updataHistoryCell(res.data[i]._source.snow_id);
                // window.historyClick = true;

                //跳转对应页面
                this.jumpToClickTab(res.data[i]._source.snow_id);
              },
            });
          }
        }

        if (historyArray.length) {
          this.history = true;
          this.historyArr = historyArray;
        } else {
          this.history = false;
        }
      }
    },

    //跳转到点击的历史记录对应页面
    jumpToClickTab(data) {
      let index = this.historyArr.findIndex(
        (item) => item.cellInfo[3].cellId === data
      );
      if (index !== -1) {
        bus.$emit("loading", true);
        let cellInfo = this.historyArr[index].cellInfo;

        let cellData = cellInfo[3];
        let fileData = cellInfo[2];
        let projectData = cellInfo[1];
        let topData = cellInfo[0];
        let isTeam = false;
        let isReadOnly = false;
        if (cellData.hasOwnProperty("teamRule")) {
          isTeam = true;
          if (cellData.teamRule == "可删除、编辑") {
            isReadOnly = false;
          } else if (cellData.teamRule == "只读") {
            isReadOnly = true;
          } else {
            bus.$emit("errDialog", i18n.t("messages.userCellPermission"));
            return;
          }
        }

        let cellId = cellData.cellId;
        let fileId = fileData.fileId;
        let projectId = projectData.projectId;
        let topId = isTeam ? topData.teamId : topData.userId;

        let topGns = isTeam ? `gns://${topId}` : "";
        let projectGns = isTeam
          ? `gns://${topId}/${projectId}`
          : `gns://${projectId}`;
        let fileGns = `${projectGns}/${fileId}`;
        let cellGns = `${fileGns}/${cellId}`;

        let cellNode = {
          data: {
            snow_id: cellId,
            gns: cellGns,
            name: cellData.name,
            isTeam,
            cell_type: cellData.cell_type,
          },
        };
        let fileNode = {
          data: {
            snow_id: fileId,
            gns: fileGns,
            name: fileData.name,
            isTeam,
            file_type: fileData.file_type,
          },
        };
        let projectNode = {
          data: {
            snow_id: projectId,
            gns: projectGns,
            name: projectData.name,
            isTeam,
          },
        };
        let topNode = {
          data: {
            snow_id: topId,
            gns: topGns,
            name: topData.name,
            isTeam,
          },
        };

        cellNode.parent = fileNode;
        fileNode.parent = projectNode;
        projectNode.parent = topNode;

        let obj = {
          cellNode,
          isReadOnly,
        };

        bus.$emit("openCellInHeader", obj);
      }
    },

    // historyHover() {

    // },

    //新建历史记录条目
    async addHistoryCell(data) {
      let res = await addHistoryCell_api({ snow_id: data });
      if (res.code === 200000) {
      }
    },
    showHistory(index) {
      this.cellHistorys[index].hide = !this.cellHistorys[index].hide;
    },

    async openSymbolList() {
      this.capSymbolIdList = [];
      let fileGns = this.$store.state.activeNode.parent.data.gns;
      let fileId = this.$store.state.fileInfo.fileId;
      let cellId = this.$store.state.cellInfo.cellId;
      let cellIndexData = await getCellIndexData(fileId);
      this.capSymbolIdList = await getCapSymbolIdByEncapsFrom(
        cellIndexData,
        fileGns,
        cellId
      );

      if (this.capSymbolIdList.length) {
        this.hasCapSymbolToOpen = true;
      } else {
        this.hasCapSymbolToOpen = false;
      }
    },
  },
  watch: {
    getOpenedTab: {
      handler(val) {
        if (val.length > 0) {
          this.isOpenCell = true;
        } else {
          this.isOpenCell = false;
        }
      },
    },
    cellInfo(val) {
      this.readOnly = val.isReadOnly;
    },
  },
  computed: {
    getOpenedTab() {
      return this.$store.state.openedTab;
    },
    cellInfo() {
      if (this.$store.state.cellInfo !== undefined) {
        this.readOnly = this.$store.state.cellInfo.isReadOnly;
      }
    },
    share_menu() {
      return [
        // 文件
        {
          text: this.$t("messages.file"),
          menu: [
            //新建
            {
              html: `<span class="iconfont icon-xinjian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.new"
              )}</span>`,
              click: () => {
                bus.$emit("headerCreateCell", true);
              },
            },
            //分割线
            {
              is: "separator",
            },
            //近期浏览
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.recentBrowsing"
              )}</span>`,
              menu: this.history ? this.historyArr : this.noHistory,
            },
            //生成PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.generatePDK"
              )}</span>`,
              click: () => {
                bus.$emit("generatePDK", true);
              },
            },
            //加载PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.loadPDK"
              )}</span>`,
              click: () => {
                bus.$emit("loadPDK", true);
              },
            },
            //分割线
            {
              is: "separator",
            },
            //导入
            {
              html: `<span class="iconfont icon-daoru" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.import"
              )}</span>`,
              menu: [
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importGDS"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "1");
                  },
                },
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importDXF"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "2");
                  },
                },
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importSchFile"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "3");
                  },
                },
              ],
            },
            // // 导出
          ],
          click: async () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
            await this.getHistoryCell();
          },
        },
        //共享
        {
          text: this.$t("messages.share"),
          menu: [
            {
              html: `<span class="iconfont icon-chuangjiantuandui1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.createTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-jiarutuandui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.joinTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-tuanduikongjian1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.teamInfo"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        // 工具
        {
          text: this.$t("messages.tool"),
          menu: [
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.toolAutoLine"
              )}</span>`,
              click: () => {
                let pathInfo = this.$router.resolve({
                  path: "/tools/autoline",
                });
                window.open(pathInfo.href, "_blank");
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //帮助
        {
          text: this.$t("messages.help"),
          menu: [
            {
              html: `<span class="iconfont icon-yonghuzhinan1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.UserGuide"
              )}</span>`,
              click: () => {
                bus.$emit("userGuide", true);
              },
            },
            {
              html: `<span class="iconfont icon-yanshishipin" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.VideoTutorial"
              )}</span>`,
              click: () => {
                bus.$emit("demonstrationVideo", true);
              },
            },
            {
              html: `<span class="iconfont icon-fankui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.feedback"
              )}</span>`,
              click: () => {
                bus.$emit("showFeedback", true);
              },
            },
            {
              html: `<span class="iconfont icon-guanyu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.About"
              )}</span>`,
              click: () => {
                bus.$emit("about", true);
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
      ];
    },
    //版图文件对应的菜单
    my_menu() {
      return [
        // 文件
        {
          text: this.$t("messages.file"),
          menu: [
            {
              html: `<span class="iconfont icon-xinjian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.new"
              )}</span>`,
              click: () => {
                bus.$emit("headerCreateCell", true);
              },
            },
            {
              is: "separator",
            },
            {
              html: `<span class="iconfont icon-baocun" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.save"
              )}</span><span style="flex:1;text-align:right">Ctrl+S</span>`,
              click: () => {
                bus.$emit("save", true);
                if (this.batchCreateCell) {
                  bus.$emit("saveFile", true);
                  this.batchCreateCell = false;
                }
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              is: "separator",
            },
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.recentBrowsing"
              )}</span>`,
              menu: this.history ? this.historyArr : this.noHistory,
            },
            //生成PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.generatePDK"
              )}</span>`,
              click: () => {
                bus.$emit("generatePDK", true);
              },
            },
            //加载PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.loadPDK"
              )}</span>`,
              click: () => {
                bus.$emit("loadPDK", true);
              },
            },
            {
              is: "separator",
            },
            // {
            //   html: `<span class="iconfont icon-daoru" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
            //     "messages.importGDS"
            //   )}</span>`,
            //   click: () => {
            //     // 获取项目数据
            //     bus.$emit("importDialog", "1");
            //   },
            // },

            {
              html: `<span class="iconfont icon-daoru" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.import"
              )}</span>`,
              menu: [
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importGDS"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "1");
                  },
                },
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importDXF"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "2");
                  },
                },
              ],
            },

            {
              html: `<span class="iconfont icon-daochu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.export"
              )}</span>`,
              menu: [
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.exportGDS"
                  )}</span>`,
                  click: () => {
                    // 获取项目数据
                    this.exportGDS();
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.exportDXF"
                  )}</span>`,
                  click: () => {
                    // 获取项目数据
                    this.exportDXF();
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },

            // {
            //   html: `<span class="iconfont icon-daochu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
            //     "messages.exportGDS"
            //   )}</span>`,
            //   click: () => {
            //     // 获取项目数据
            //     this.exportGDS();
            //   },
            //   disabled: !this.isOpenCell || this.readOnly,
            // },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
            this.getHistoryCell();
          },
        },
        // 编辑
        {
          text: this.$t("messages.edit"),
          menu: [
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.select"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-zhengtixuanze" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.FullSelect"
                  )}</span>`,
                  click: () => {
                    bus.$emit("overallSelection", true);
                    bus.$emit("siderBarActive", "overallSelection");
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-bufenxuanze" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.PartialSelect"
                  )}</span>`,
                  click: () => {
                    bus.$emit("partialSelection", true);
                    bus.$emit("siderBarActive", "partialSelection");
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              is: "separator",
            },
            {
              html: `<span class="iconfont icon-jianqie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.cut"
              )}</span><span style="flex:1;text-align:right">Ctrl+X</span>`,
              click: () => bus.$emit("cut", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-fuzhi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.copy"
              )}</span><span style="flex:1;text-align:right">Ctrl+C</span>`,
              click: () => {
                bus.$emit("copy", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-niantie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.paste"
              )}</span><span style="flex:1;text-align:right">Ctrl+V</span>`,
              click: () => bus.$emit("paste", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-chexiao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.undo"
              )}</span><span style="flex:1;text-align:right">Ctrl+Z</span>`,
              click: () => bus.$emit("undo", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-huifu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.redo"
              )}</span><span style="flex:1;text-align:right">Ctrl+Y</span>`,
              click: () => bus.$emit("redo", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              is: "separator",
            },
            {
              html: `<span class="iconfont icon-yidong" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Move"
              )}</span><span style="flex:1;text-align:right">D</span>`,
              click: () => {
                bus.$emit("move", true);
                bus.$emit("siderBarActive", "move");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-xuanzhuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Rotate"
              )}</span><span style="flex:1;text-align:right">Alt+Shift+R</span>`,
              click: () => {
                bus.$emit("rotate", true);
                bus.$emit("siderBarActive", "rotate");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-lashen" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.draw"
              )}</span><span style="flex:1;text-align:right">S</span>`,
              click: () => {
                bus.$emit("partialSelection", true);
                bus.$emit("siderBarActive", "stretch");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Flip"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-shuipingfanzhuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.FlipHorizontal"
                  )}</span>`,
                  click: () => {
                    bus.$emit("horizontalFlipDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-chuizhifanzhuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.FlipVertical"
                  )}</span>`,
                  click: () => {
                    bus.$emit("verticalFlipDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              is: "separator",
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.QuickAlign"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-dianduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.PointAlign"
                  )}</span>`,
                  click: () => {
                    bus.$emit("pointAlignmentDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-xianduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.LineAlign"
                  )}</span>`,
                  click: () => {
                    bus.$emit("lineAlignmentDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.BasicTools"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-qiege" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.Slice"
                  )}</span><span style="flex:1;text-align:right">Ctrl+Q</span>`,
                  click: () => {
                    bus.$emit("cutting", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-quyuqiechu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.Nibble"
                  )}</span><span style="flex:1;text-align:right">Shift+C</span>`,
                  click: () => {
                    bus.$emit("areaExcision", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-hebing1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.Merge"
                  )}</span><span style="flex:1;text-align:right">Shift+M</span>`,
                  click: () => {
                    bus.$emit("merge", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.AdvancedTools"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-zuoduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.Align"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignmentDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-boeryunsuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.Boolean"
                  )}</span>`,
                  click: () => {
                    bus.$emit("booleanDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-zhenlie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.ArrayCopy"
                  )}</span>`,
                  click: () => {
                    bus.$emit("matrixDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Hierarchy"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.DescendRead"
                  )}</span><span style="flex:1;text-align:right">Shift+x</span>`,
                  click: () => {
                    bus.$emit("enterlowerLevel", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.DescendEdit"
                  )}</span><span style="flex:1;text-align:right">x</span>`,
                  click: () => {
                    bus.$emit("editLowerLevel", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.Return"
                  )}</span><span style="flex:1;text-align:right">Shift+b</span>`,
                  click: () => {
                    bus.$emit("backUpperLevel", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.ReturntoTop"
                  )}</span>`,
                  click: () => {
                    bus.$emit("backTop", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  is: "separator",
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.MakeCell"
                  )}</span>`,
                  click: () => {
                    if (this.isOpenCell) {
                      bus.$emit("saveAsCellDialog", true);
                    } else {
                      bus.$emit("errDialog", this.$t("messages.fileTip6"));
                    }
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.Flatten"
                  )}</span>`,
                  click: () => {
                    bus.$emit("breakUpDialog", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //绘制
        {
          text: this.$t("messages.toDraw"),
          menu: [
            {
              html: `<span class="iconfont icon-xian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Line"
              )}</span><span style="flex:1;text-align:right">E</span>`,
              click: () => {
                bus.$emit("func", "dogleg");
                bus.$emit("siderBarActive", "dogleg");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-juxing" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Rectangle"
              )}</span><span style="flex:1;text-align:right">R</span>`,
              click: () => {
                bus.$emit("func", "rectangle");
                bus.$emit("siderBarActive", "rectangle");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-duobianxing" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Polygon"
              )}</span><span style="flex:1;text-align:right">shift+P</span>`,
              click: () => {
                bus.$emit("func", "polygon");
                bus.$emit("siderBarActive", "polygon");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-yuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Circle"
              )}</span><span style="flex:1;text-align:right"></span>`,
              click: () => {
                bus.$emit("func", "circle");
                bus.$emit("siderBarActive", "circle");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-wenbentuxing" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Text"
              )}</span><span style="flex:1;text-align:right"></span>`,
              click: () => {
                bus.$emit("textGraphics", true);
                bus.$emit("siderBarActive", "textGraphics");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.AuxiliaryTool"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.Rule"
                  )}</span><span style="flex:1;text-align:right">K</span>`,
                  click: () => {
                    bus.$emit("ruler", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.Label"
                  )}</span><span style="flex:1;text-align:right">L</span>`,
                  click: () => {
                    bus.$emit("label", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.KeyPoint"
                  )}</span><span style="flex:1;text-align:right">P</span>`,
                  click: () => {
                    bus.$emit("keyPoint", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.view3D.ui.draw_3d_section_area"
              )}</span><span style="flex:1;text-align:right"></span>`,
              click: () => {
                bus.$emit("draw3DCutBox", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.view3D.ui.draw_3d_view_area"
              )}</span><span style="flex:1;text-align:right"></span>`,
              click: () => {
                bus.$emit("draw3DArea", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        // 工具
        {
          text: this.$t("messages.tool"),
          menu: [
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.toolAutoLine"
              )}</span>`,
              click: () => {
                let pathInfo = this.$router.resolve({
                  path: "/tools/autoline",
                });
                window.open(pathInfo.href, "_blank");
              },
            },
            // {
            //   html: `<span style="padding-left:5px;padding-left:25px">${this.$t('messages.toolBigLayout')}</span>`,
            //   click: () => {
            //     bus.$emit('initLayerList', true)
            //     setTimeout(() => {
            //       bus.$emit('TOOL_BIG_LIBRARY', true)
            //     }, 200)
            //   },
            // },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.SplittheLayoutbylayer"
              )}</span>`,
              click: () => {
                bus.$emit("initLayerList", true);
                setTimeout(() => {
                  bus.$emit("TOOL_SPLIT_CELL", true);
                }, 200);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.connectLine"
              )}</span>`,
              click: () => {
                bus.$emit("initLayerList", true);
                setTimeout(() => {
                  bus.$emit("TOOL_CONNECT_LINE", true);
                }, 200);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Superconductivity"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.resonators"
                  )}</span>`,
                  click: () => {
                    bus.$emit("TOOL_CAVITY", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.CPWs"
                  )}</span>`,
                  click: () => {
                    bus.$emit("TOOL_WAVEGUIDE", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.AirBridge"
                  )}</span>`,
                  click: () => {
                    bus.$emit("initLayerList", true);
                    setTimeout(() => {
                      bus.$emit("TOOL_AIRBRIDGE", true);
                    }, 200);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">WBPad</span>`,
                  click: () => {
                    bus.$emit("TOOL_WBPAD", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">InLinePad</span>`,
                  click: () => {
                    bus.$emit("TOOL_INLINEPAD", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">TiNPad&Inpillar</span>`,
                  click: () => {
                    bus.$emit("initLayerList", true);
                    setTimeout(() => {
                      bus.$emit("TOOL_TINPAD_INPILLAR", true);
                    }, 200);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.AutomaticLayoutoftheSQUIDandZ_line"
                  )}</span>`,
                  click: () => {
                    bus.$emit("TOOL_SQUID_ZLINE", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.toolJJheader"
                  )}</span>`,
                  click: () => {
                    bus.$emit("TOOL_JJ", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span style="padding-left:5px">${this.$t(
                    "messages.toolWaferLayout"
                  )}</span>`,
                  click: () => {
                    bus.$emit("TOOL_WAFER_LAYOUT", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //共享
        {
          text: this.$t("messages.share"),
          menu: [
            {
              html: `<span class="iconfont icon-chuangjiantuandui1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.createTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-jiarutuandui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.joinTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-tuanduikongjian1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.teamInfo"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //设置
        {
          text: this.$t("messages.setUp"),
          menu: [
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.DisplaySettings"
              )}</span>`,
              click: () => {
                bus.$emit("boardOption", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: this.showCoordinate
                ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinate"></span><span style="padding-left:5px" v-if="showCoordinate">${this.$t(
                    "messages.CoordinateDisplay"
                  )}</span>`
                : `<span style="padding-left:25px">${this.$t(
                    "messages.CoordinateDisplay"
                  )}</span>`,
              click: () => {
                bus.$emit("openCoordinate", true);
                this.showCoordinate = !this.showCoordinate;
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //视图
        {
          text: this.$t("messages.view"),
          menu: [
            {
              html: `<span class="iconfont icon-fangda1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Zoomout"
              )}</span>`,
              click: () => {
                bus.$emit("boardAmplification", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span class="iconfont icon-suoxiao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Zoomin"
              )}</span>`,
              click: () => {
                bus.$emit("boardReduction", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.ZoomFit"
              )}</span><span style="flex:1;text-align:right">F</span>`,
              click: () => {
                bus.$emit("boardAdaptation", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.ObjectFitSelection"
              )}</span>`,
              click: () => {
                bus.$emit("objectAdaptation", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.ZoomFitSelection"
              )}</span><span style="flex:1;text-align:right">V</span>`,
              click: () => {
                bus.$emit("regionalAmplification", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.view3D.name"
              )}</span>`,
              click: () => {
                bus.$emit("3DCellView", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //检测
        {
          text: this.$t("messages.check"),
          menu: [
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.CheckLine"
              )}</span>`,
              click: () => {
                bus.$emit("initLayerList", true);
                setTimeout(() => {
                  bus.$emit("TOOL_CHECK_LINE", true);
                }, 200);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.Drc"
              )}</span>`,
              click: () => {
                setTimeout(() => {
                  bus.$emit("PDKDialog", true);
                }, 200);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },

        // pcell
        // {
        //   // text: this.$t('messages.PCellGen'),
        //   text: "Pcell",
        //   menu: [
        //     {
        //       html: `<span class="iconfont icon-yonghuzhinan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
        //         "messages.PCellGen"
        //       )}</span>`,
        //       click: () => {
        //         // bus.$emit('PcellGenerate', true)
        //         let pathInfo = this.$router.resolve({
        //           path: "/pcell",
        //         });
        //         window.open(pathInfo.href, "_blank");
        //       },
        //     },
        //   ],
        //   click: () => {
        //     if (this.isDraw) {
        //       bus.$emit("esc", true);
        //       this.isDraw = false;
        //     }
        //   },
        // },

        //帮助
        {
          text: this.$t("messages.help"),
          menu: [
            {
              html: `<span class="iconfont icon-yonghuzhinan1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.UserGuide"
              )}</span>`,
              click: () => {
                bus.$emit("userGuide", true);
              },
            },
            {
              html: `<span class="iconfont icon-yanshishipin" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.VideoTutorial"
              )}</span>`,
              click: () => {
                bus.$emit("demonstrationVideo", true);
              },
            },
            {
              html: `<span class="iconfont icon-fankui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.feedback"
              )}</span>`,
              click: () => {
                bus.$emit("showFeedback", true);
              },
            },
            {
              html: `<span class="iconfont icon-guanyu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.About"
              )}</span>`,
              click: () => {
                bus.$emit("about", true);
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
      ];
    },
    //3d菜单
    menu_3d() {
      return [
        {
          text: this.$t("messages.file"),
          menu: [
            {
              html: `<span class="iconfont icon-gongyi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.view3D.ui.load_file"
              )}</span>`,
              click: () => {
                bus.$emit("loadCraftFile", true);
              },
            },
            {
              html: `</span><span style="padding-left:25px">${this.$t(
                "messages.view3D.ui.reset_model"
              )}</span>`,
              click: () => {
                bus.$emit("reset3dModel", true);
              },
            },
          ],
        },
      ];
    },
    //原理图文件对应的菜单
    schematicDiagram_menu() {
      return [
        // 文件
        {
          text: this.$t("messages.file"),
          menu: [
            //新建
            {
              html: `<span class="iconfont icon-xinjian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.new"
              )}</span>`,
              click: () => {
                bus.$emit("headerCreateCell", true);
              },
            },
            //分割线
            {
              is: "separator",
            },
            //保存
            {
              html: `<span class="iconfont icon-baocun" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.save"
              )}</span><span style="flex:1;text-align:right">Ctrl+S</span>`,
              click: () => {
                // bus.$emit('saveSchema', true)
                // if (this.batchCreateCell) {
                bus.$emit("saveFile", true);
                //   this.batchCreateCell = false
                // }
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //另存为
            {
              html: `<span class="iconfont icon-lingcunwei" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.saveAs"
              )}</span>`,
              click: () => {
                bus.$emit("saveAs", true);
              },
              disabled: !this.isOpenCell,
            },
            //检查
            {
              html: `<span class="iconfont icon-jiancha" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.checkSchematicDiagram"
              )}</span>`,
              click: () => {
                let project_id = this.$store.state.proInfo.projectId;
                this.$store.commit("getShemaCheckSettings", project_id);
                let tableData = this.$store.state.currentSchemaCheckSettings;
                bus.$emit("checkSchema", tableData);
              },
              disabled: !this.isOpenCell,
            },
            //打开符号
            {
              html: `<span class="iconfont icon-dakaifuhao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.openSymbol"
              )}</span>`,
              click: () => {
                bus.$emit("openSymbol", this.capSymbolIdList);
              },
              disabled: !this.isOpenCell || !this.hasCapSymbolToOpen,
            },
            //封装
            {
              html: `<span class="iconfont icon-fengzhuang" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.encapsulation"
              )}</span>`,
              click: () => {
                bus.$emit("openEncapsulation", true);
              },
              disabled: !this.isOpenCell,
            },
            //输出网表
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.outputNetlist"
              )}</span>`,
              click: () => {
                bus.$emit("openNetlist", true);
              },
              disabled: !this.isOpenCell,
            },
            //生成PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.generatePDK"
              )}</span>`,
              click: () => {
                bus.$emit("generatePDK", true);
              },
            },
            //加载PDK
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.loadPDK"
              )}</span>`,
              click: () => {
                bus.$emit("loadPDK", true);
              },
            },
            //分割线
            {
              is: "separator",
            },
            //近期浏览
            {
              html: `<span style="padding-left:25px"></span><span>${this.$t(
                "messages.recentBrowsing"
              )}</span>`,
              menu: this.history ? this.historyArr : this.noHistory,
            },
            {
              is: "separator",
            },
            //导入
            {
              html: `<span class="iconfont icon-daoru" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.import"
              )}</span>`,
              menu: [
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.importSchFile"
                  )}</span>`,
                  click: () => {
                    bus.$emit("importDialog", "3");
                  },
                },
              ],
            },
            // 导出
            {
              html: `<span class="iconfont icon-daochu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.export"
              )}</span>`,
              menu: [
                {
                  html: `<span style="padding-left:25px"></span><span>${this.$t(
                    "messages.exportSchFile"
                  )}</span>`,
                  click: () => {
                    this.exportSchemaFile();
                  },
                },
              ],
            },
          ],
          click: async () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
            if (this.cell_type == "3") {
              await this.openSymbolList();
            }
            this.getHistoryCell();
          },
        },
        // 编辑
        {
          text: this.$t("messages.edit"),
          menu: [
            //撤回
            {
              html: `<span class="iconfont icon-chexiao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.undo"
              )}</span><span style="flex:1;text-align:right">Ctrl+Z</span>`,
              click: () => bus.$emit("undo", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            //恢复
            {
              html: `<span class="iconfont icon-huifu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.redo"
              )}</span><span style="flex:1;text-align:right">Ctrl+Y</span>`,
              click: () => bus.$emit("redo", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            // 剪切
            {
              html: `<span class="iconfont icon-jianqie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.cut"
              )}</span><span style="flex:1;text-align:right">Ctrl+X</span>`,
              click: () => bus.$emit("cut", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            // 复制
            {
              html: `<span class="iconfont icon-fuzhi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.copy"
              )}</span><span style="flex:1;text-align:right">Ctrl+C</span>`,
              click: () => {
                bus.$emit("copy", true);
              },
              disabled: !this.isOpenCell,
            },
            //粘贴
            {
              html: `<span class="iconfont icon-niantie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.paste"
              )}</span><span style="flex:1;text-align:right">Ctrl+V</span>`,
              click: () => bus.$emit("paste", true),
              disabled: !this.isOpenCell || this.readOnly,
            },
            {
              is: "separator",
            },
            // 移动
            {
              html: `<span class="iconfont icon-yidong" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Move"
              )}</span><span style="flex:1;text-align:right">D</span>`,
              click: () => {
                bus.$emit("move", true);
                bus.$emit("siderBarActive", "move");
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //拉伸导线
            // 阵列
            {
              html: `<span class="iconfont icon-zhenlie" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.ArrayCopy"
              )}</span>`,
              click: () => {
                bus.$emit("matrixDialog", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            // 旋转
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Rotate"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-shunshizhenxuanzhuan90du" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.RotatedClockwise"
                  )}</span>`,
                  click: () => {
                    bus.$emit("RotatedClockwise", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-nishizhenxuanzhuan90du" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.RotatedCounterclockwise"
                  )}</span>`,
                  click: () => {
                    bus.$emit("RotatedCounterclockwise", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            //翻转
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Flip"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-shuipingfanzhuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.FlipHorizontal"
                  )}</span>`,
                  click: () => {
                    bus.$emit("FlipHorizontal", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-chuizhifanzhuan" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.FlipVertical"
                  )}</span>`,
                  click: () => {
                    bus.$emit("FlipVertical", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            //对齐
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Align"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                {
                  html: `<span class="iconfont icon-zuoduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignLeft"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignLeft", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-youduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignRight"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignRight", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-shangduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignTop"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignTop", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-xiaduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignBottom"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignBottom", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-hengxiangzhongxianduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignHorizontalCenter"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignHorizontalCenter", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                {
                  html: `<span class="iconfont icon-shuxiangzhongxianduiqi" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.alignVerticalCenter"
                  )}</span>`,
                  click: () => {
                    bus.$emit("alignVerticalCenter", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            {
              is: "separator",
            },
            //层级
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Hierarchy"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell,
              menu: [
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.DescendRead"
                  )}</span><span style="flex:1;text-align:right">Shift+x</span>`,
                  click: () => {
                    bus.$emit("enterlowerLevel", true);
                  },
                  disabled: !this.isOpenCell,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.Return"
                  )}</span><span style="flex:1;text-align:right">Shift+b</span>`,
                  click: () => {
                    bus.$emit("backUpperLevel", true);
                  },
                  disabled: !this.isOpenCell,
                },
                {
                  html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                    "messages.ReturntoTop"
                  )}</span>`,
                  click: () => {
                    bus.$emit("backTop", true);
                  },
                  disabled: !this.isOpenCell,
                },
              ],
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //创建
        {
          text: this.$t("messages.create"),
          menu: [
            //耦合线
            {
              html: `<span class="iconfont icon-ouhexian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.CouplingLine"
              )}</span><span style="flex:1;text-align:right">E</span>`,
              click: () => {
                bus.$emit("openCouplingLine", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //创建引脚
            {
              html: `<span class="iconfont icon-chuangjianyinjiao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.CreatePin"
              )}</span><span style="flex:1;text-align:right">P</span>`,
              click: () => {
                bus.$emit("openCreatePin", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //标签
            {
              html: `<span class="iconfont icon-chuangjianbiaoqian" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.label"
              )}</span><span style="flex:1;text-align:right">L</span>`,
              click: () => {
                bus.$emit("openLabel", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //设置
        {
          text: this.$t("messages.setUp"),
          menu: [
            //显示设置
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.DisplaySettings"
              )}</span>`,
              click: () => {
                bus.$emit("schematicBoardOption", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //检查设置
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.CheckSettings"
              )}</span>`,
              click: () => {
                bus.$emit("openCheckOption", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //视图
        {
          text: this.$t("messages.view"),
          menu: [
            //画板放大
            {
              html: `<span class="iconfont icon-fangda1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Zoomout"
              )}</span>`,
              click: () => {
                bus.$emit("boardAmplification", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //画板缩小
            {
              html: `<span class="iconfont icon-suoxiao" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.Zoomin"
              )}</span>`,
              click: () => {
                bus.$emit("boardReduction", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //画板适应
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.ZoomFit"
              )}</span><span style="flex:1;text-align:right">F</span>`,
              click: () => {
                bus.$emit("boardAdaptation", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //区域放大
            {
              html: `<span style="padding-left:25px">${this.$t(
                "messages.ZoomFitSelection"
              )}</span><span style="flex:1;text-align:right">V</span>`,
              click: () => {
                bus.$emit("regionalAmplification", true);
              },
              disabled: !this.isOpenCell || this.readOnly,
            },
            //显示标签
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.displayLabel"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                //实例名称
                {
                  html: this.showCoordinateInstanceName
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinateInstanceName"></span><span style="padding-left:5px" v-if="showCoordinateInstanceName">${this.$t(
                        "messages.InstanceName"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.InstanceName"
                      )}</span>`,
                  click: () => {
                    this.showCoordinateInstanceName =
                      !this.showCoordinateInstanceName;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                // 符号名称
                {
                  html: this.showCoordinateLabelName
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinateLabelName"></span><span style="padding-left:5px" v-if="showCoordinateLabelName">${this.$t(
                        "messages.LabelName"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.LabelName"
                      )}</span>`,
                  click: () => {
                    this.showCoordinateLabelName =
                      !this.showCoordinateLabelName;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                //关键参数
                {
                  html: this.showCoordinateKeyParameter
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinateKeyParameter"></span><span style="padding-left:5px" v-if="showCoordinateKeyParameter">${this.$t(
                        "messages.KeyParameter"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.KeyParameter"
                      )}</span>`,
                  click: () => {
                    this.showCoordinateKeyParameter =
                      !this.showCoordinateKeyParameter;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                // 引脚名称
                {
                  html: this.showCoordinatePinName
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinatePinName"></span><span style="padding-left:5px" v-if="showCoordinatePinName">${this.$t(
                        "messages.PinName"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.PinName"
                      )}</span>`,
                  click: () => {
                    this.showCoordinatePinName = !this.showCoordinatePinName;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                //端口名称
                {
                  html: this.showCoordinatePortName
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinatePortName"></span><span style="padding-left:5px" v-if="showCoordinatePortName">${this.$t(
                        "messages.PortName"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.PortName"
                      )}</span>`,
                  click: () => {
                    this.showCoordinatePortName = !this.showCoordinatePortName;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                //网络标签
                {
                  html: this.showCoordinateNetLable
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinateNetLable"></span><span style="padding-left:5px" v-if="showCoordinateNetLable">${this.$t(
                        "messages.networkLabel"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.networkLabel"
                      )}</span>`,
                  click: () => {
                    this.showCoordinateNetLable = !this.showCoordinateNetLable;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                //其他标签
                {
                  html: this.showCoordinateOtherLabel
                    ? `<span class="iconfont icon-gougou" style="font-size:20px" v-if="showCoordinateOtherLabel"></span><span style="padding-left:5px" v-if="showCoordinateOtherLabel">${this.$t(
                        "messages.OtherLabel"
                      )}</span>`
                    : `<span style="padding-left:25px">${this.$t(
                        "messages.OtherLabel"
                      )}</span>`,
                  click: () => {
                    this.showCoordinateOtherLabel =
                      !this.showCoordinateOtherLabel;
                    let data = {
                      showCoordinateInstanceName:
                        this.showCoordinateInstanceName,
                      showCoordinateLabelName: this.showCoordinateLabelName,
                      showCoordinateKeyParameter:
                        this.showCoordinateKeyParameter,
                      showCoordinatePinName: this.showCoordinatePinName,
                      showCoordinatePortName: this.showCoordinatePortName,
                      showCoordinateNetLable: this.showCoordinateNetLable,
                      showCoordinateOtherLabel: this.showCoordinateOtherLabel,
                    };
                    bus.$emit("displayLabel", data);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
            //高亮
            {
              html: `<span style="padding-left:5px;padding-left:25px">${this.$t(
                "messages.Highlight"
              )}</span>`,
              click: () => {},
              disabled: !this.isOpenCell || this.readOnly,
              menu: [
                //高亮网络
                {
                  html: `<span class="iconfont icon-gaoliangwangluo1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.HighlightNetwork"
                  )}</span><span style="flex:1;text-align:right">H</span>`,
                  click: () => {
                    bus.$emit("HighlightNetwork", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
                //移除高亮
                {
                  html: `<span class="iconfont icon-yichuwangluo1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                    "messages.RemoveHighlights"
                  )}</span><span style="flex:1;text-align:right">Shift+H</span>`,
                  click: () => {
                    bus.$emit("RemoveHighlights", true);
                  },
                  disabled: !this.isOpenCell || this.readOnly,
                },
              ],
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //共享
        {
          text: this.$t("messages.share"),
          menu: [
            {
              html: `<span class="iconfont icon-chuangjiantuandui1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.createTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-jiarutuandui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.joinTeam"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
            {
              html: `<span class="iconfont icon-tuanduikongjian1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.teamInfo"
              )}</span>`,
              click: () => {
                this.toTeamInfo();
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
        //帮助
        {
          text: this.$t("messages.help"),
          menu: [
            {
              html: `<span class="iconfont icon-yonghuzhinan1" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.UserGuide"
              )}</span>`,
              click: () => {
                bus.$emit("userGuide", true);
              },
            },
            {
              html: `<span class="iconfont icon-yanshishipin" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.VideoTutorial"
              )}</span>`,
              click: () => {
                bus.$emit("demonstrationVideo", true);
              },
            },
            {
              html: `<span class="iconfont icon-fankui" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.feedback"
              )}</span>`,
              click: () => {
                bus.$emit("showFeedback", true);
              },
            },
            {
              html: `<span class="iconfont icon-guanyu" style="font-size:20px"></span><span style="padding-left:5px">${this.$t(
                "messages.About"
              )}</span>`,
              click: () => {
                bus.$emit("about", true);
              },
            },
          ],
          click: () => {
            if (this.isDraw) {
              bus.$emit("esc", true);
              this.isDraw = false;
            }
          },
        },
      ];
    },
  },
  created() {
    setTimeout(() => {
      this.table_alignmentLayer = this.$store.state.layerDatas;
      this.booleanLayer = this.$store.state.layerDatas;
    }, 1000);
    if (localStorage.getItem("lang")) {
      window.lang = localStorage.getItem("lang");
      this.$i18n.locale = window.lang;
    }
    if (window.lang === "zh") {
      this.isLanguageCN = true;
    } else {
      this.isLanguageCN = false;
    }
  },
};
</script>
<style lang="less" scoped>
.header {
  height: 30px !important;
  background-color: #378ddd;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 25px;
  // 图标
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .h-left {
    height: 100%;
    display: flex;
    padding-left: 10px;
    .logo {
      width: 160px;
      height: 100px;
      margin: -35px;
    }
    .menuBox {
      margin-left: -20px;
    }
  }
}
// 菜单栏样式
/deep/ .bar {
  height: 100% !important;
}
/deep/ .bar-button {
  font-size: 12px !important;
}
/deep/.bar-menu-item {
  width: 170px;
  height: 16px;
  line-height: 16px;
  color: #333;
  position: relative;
}
/deep/.bar-menu-item:hover {
  color: #0c7de6;
}
/deep/.bar-menu-separator {
  margin: 2px 0 !important;
}
// 输入框
/deep/.inname .el-input {
  width: 100%;
  margin-bottom: 20px;
  > input {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  > span {
    right: 12%;
  }
}
.selectType {
  width: 80%;
  margin: 0 auto;
  /deep/ .el-select {
    width: 100%;
  }
}
/deep/ .el-dialog__body {
  padding: 30px 20px;
}
/deep/ .el-select-dropdown__item {
  background-color: red !important;
  width: 1000px !important;
}
/deep/.historyItem {
  line-height: 32px;
  padding-left: 5px;
  width: 170px;
  display: flex;
  align-items: center;
}

/deep/.historyIcon::before {
  font-size: 20px;
  padding-right: 5px;
}

/deep/.historyText {
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/.hoverItem {
  line-height: 30px;
  padding-left: 16px;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 10000;
  border: 1px solid #999999;
  background-color: #fff;
  width: 182px;
}
/deep/.bar-menu-item.disabled:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}
//>箭头符号
/deep/.material-icons {
  font-size: 14px;
}
</style>
<style>
/* 全局定义顶部弹框 */
/* .el-dialog__wrapper {
  z-index: 10001 !important;
} */
.el-dialog {
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
.el-dialog__body {
  padding: 10px 40px;
}
.inname {
  padding-top: 10px;
}
.el-dropdown-menu {
  z-index: 10000 !important;
}
.el-dropdown {
  display: flex;
  align-items: center;
}
.h-right {
  display: flex;
  align-items: center;
}
.headerChangeLang {
  cursor: pointer;
}
.login-head-lang-line {
  margin: 0 14px;
}
.language-icon {
  width: 25px !important;
  height: 25px !important;
  font-weight: bold;
  vertical-align: top;
}
.username {
  font-size: 12px;
}
.setting {
  color: #fff !important;
  cursor: pointer;
  padding: 5px;
}
.el-radio__input .el-radio__inner {
  border: 1px solid #818181;
}
.el-radio__input.is-checked .el-radio__inner {
  background-color: #fff;
  border-color: #0c7de6;
}
.el-radio__inner::after {
  background-color: #0c7de6;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #333333;
}
.deactivate {
  background-color: #aac6f5 !important;
  border-color: #aac6f5 !important;
}
.old-version {
  font-size: 12px;
  padding: 2px 4px;
  background: #ffffff;
  border-radius: 2px;
  color: #378ddd;
  margin-right: 12px;
  cursor: pointer;
}
.historyInfo {
  cursor: pointer;
}
.history-icon {
  width: 25px !important;
  height: 25px !important;
  font-weight: bold;
  vertical-align: top;
}
.historyPanel {
  padding: 0;
  width: 370px;
  max-height: 532px;
  right: 179px;
  overflow: scroll;
}
.history-title {
  padding: 14px;
}
.history-tilte-line {
  width: 340px;
  margin: 0 auto;
  color: #dddddd;
  opacity: 0.5;
}
.history-list-item {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 8px;
  font-size: 12px;
  display: flex;
}
.history-item-left {
  width: 100%;
}
.history-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 8px;
}
.history-high-light {
  color: #378ddd;
}
.history-time {
  font-size: 12px;
  color: #999999;
}
.history-item-left-top {
  display: flex;
  justify-content: space-between;
}
.history-item-left-mid {
  margin-top: 8px;
  border-radius: 8px;
  background-color: #dddddd;
  height: auto;
  padding-top: 6px;
  padding-left: 7px;
  padding-bottom: 7px;
  padding-right: 4px;
  color: #666666;
}
.history-item-left-end {
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
.history-show-hide-btn {
  color: #0c7de6;
  padding: 0;
}
.history-item-left-top-btn {
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.show-triangle {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #0c7de6;
}
.hide-triangle {
  margin-top: 4px;
  margin-left: -8px;
  border-top: 10px solid #0c7de6;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.history-item-left-top-text {
  max-width: 240px;
}
</style>
