<template>
  <div>
    <!-- 画板设计 -->
    <div class="boardOptionDialog">
      <el-dialog :title="$t('messages.DisplaySettings')" :visible.sync="boardOptionDialogVisible" :modal="false" :close-on-click-modal="false">
        <div class="inputArea">
          <span>{{$t('messages.backgroundColor')}}:</span>
          <div class="fillColor__btn">
            <el-color-picker v-model="value_backgroundColor" @change="backgroundColorChange"></el-color-picker>
          </div>
        </div>
        <div class="inputArea">
          <span>{{$t('messages.gridColor')}}:</span>
          <div class="fillColor__btn">
            <el-color-picker v-model="value_gridColor" @change="gridColorChange"></el-color-picker>
          </div>
        </div>

        <div class="inputArea">
          <span style="padding-right:90px">{{$t('messages.isGridVisible')}}:</span>
          <div class="radioArea">
            <el-radio v-model="gridVisible" label="1" style="padding-right:50px">{{$t('messages.yes')}}</el-radio>
            <el-radio v-model="gridVisible" label="2">{{$t('messages.no')}}</el-radio>
          </div>
        </div>

        <div class="inputArea">
          <span style="padding-right:117px">{{$t('messages.adsorption')}}:</span>
          <div class="radioArea">
            <el-radio v-model="adsorption" label="1" style="padding-right:50px" @change="isAdsorption=true">{{$t('messages.yes')}}</el-radio>
            <el-radio v-model="adsorption" label="2" @change="isAdsorption=false">{{$t('messages.no')}}</el-radio>
          </div>
        </div>

        <div class="inputArea" v-if="isAdsorption">
          <span class="labelInput">
            <span style="padding-right:89px">{{$t('messages.adsorptionAccuracy')}}:</span>
            <el-input v-model="value_adsorptionAccuracy" @focus="getPrecisionOld" @blur="getPrecision"></el-input>um
          </span>
        </div>

        <div class="inputArea">
          <span>{{$t('messages.unit')}}:</span>
          <el-select disabled popper-class="optionClass" v-model="value_unit" :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in unit" :key="item.value" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" ref="boardOptionDialogSureBtn" type="primary" @click="boardOptionDialogSureBtn" size="mini" disabled>{{$t("messages.sure")}}</el-button>
          <el-button @click="boardOptionDialogVisible = false;resetBoardOptionDialog()" size="mini">{{$t("messages.cancel")}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import bus from "../common/bus";
export default {
  data() {
    return {
      boardOptionDialogVisible: false,
      value_backgroundColor: "",
      value_gridColor: "",
      gridVisible: "1",
      adsorption: "1",
      value_adsorptionAccuracy: 1,
      adsorptionAccuracyOld: null,
      value_unit: "um",
      unit: [
        // { value: "nm" },
        { value: "um" },
        // { value: "mm" },
      ],
      isAdsorption: true,
    };
  },
  mounted() {
    bus.$on("openBoardOption", (data) => {
      this.value_backgroundColor = data.backgroundColor;
      // this.$store.commit('setPrecision', data.adsorp_precision)
      this.value_gridColor = data.gridColor;
      if (data.showgrid) {
        this.gridVisible = "1";
      } else {
        this.gridVisible = "2";
      }
      if (data.adsorption) {
        this.adsorption = "1";
      } else {
        this.adsorption = "2";
      }
      this.value_adsorptionAccuracy = data.adsorp_precision;
      this.boardOptionDialogVisible = true;
    });
  },
  beforeDestroy() {
    bus.$off("openBoardOption");
  },
  methods: {
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },

    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    boardOptionDialogSureBtn() {
      let data = [];
      let gridVisible;
      let adsorption;

      if (this.gridVisible === "1") {
        gridVisible = true;
      } else {
        gridVisible = false;
      }
      if (this.adsorption === "1") {
        adsorption = true;
      } else {
        adsorption = false;
      }
      if (this.isAdsorption) {
        data = [
          this.value_backgroundColor,
          this.value_gridColor,
          gridVisible,
          adsorption,
          this.value_adsorptionAccuracy,
          this.value_unit,
        ];
      } else {
        data = [
          this.value_backgroundColor,
          this.value_gridColor,
          gridVisible,
          adsorption,
          this.value_unit,
        ];
      }

      this.$store.commit("setPrecision", this.value_adsorptionAccuracy);
      bus.$emit("boardDesign", data);
      this.resetBoardOptionDialog();
    },

    disableLabel() {
      if (
        this.value_backgroundColor === null ||
        this.value_gridColor === null ||
        this.value_adsorptionAccuracy === ""
      ) {
        this.disableBtn(this.$refs.boardOptionDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.boardOptionDialogSureBtn);
      }
    },

    // 获取格点吸附精度初始值
    getPrecisionOld() {
      this.adsorptionAccuracyOld = this.$store.state.precision;
    },

    // 获取格点吸附精度
    getPrecision() {
      if (
        Number(this.value_adsorptionAccuracy) >= 0.001 &&
        Number(this.value_adsorptionAccuracy) <= 10000 &&
        /^[1-9]\d{0,4}(\.\d{1,3})?$|^0(\.\d{1,3})?$/.test(
          Number(this.value_adsorptionAccuracy)
        )
      ) {
        this.$store.commit("setPrecision", this.value_adsorptionAccuracy);
        bus.$emit("getPrecision", this.value_adsorptionAccuracy);
      } else {
        bus.$emit("errDialog", "吸附精度范围限制在0.001~10000um");
        this.value_adsorptionAccuracy = this.adsorptionAccuracyOld;
      }
    },

    resetBoardOptionDialog() {
      this.boardOptionDialogVisible = false;
      this.value_backgroundColor = "#000";
      this.value_gridColor = "#fff";
      this.gridVisible = "1";
      this.adsorption = "1";
      this.value_adsorptionAccuracy =
        this.$store.state.value_adsorptionAccuracy;
      this.value_unit = "um";
    },

    backgroundColorChange(data) {
      this.value_backgroundColor = data;
    },
    gridColorChange(data) {
      this.value_gridColor = data;
    },
  },
  watch: {
    value_backgroundColor() {
      this.disableLabel();
    },
    value_gridColor() {
      this.disableLabel();
    },
    value_adsorptionAccuracy() {
      this.disableLabel();
    },
    adsorption() {
      this.disableLabel();
    },
  },
};
</script>

<style lang="less" scoped>
.boardOptionDialog {
  color: #333;
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 35px;
    max-height: 700px;
    overflow: hidden;
  }

  .inputArea {
    position: relative;
    width: 495px;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 348px;
      height: 30px;
      border-radius: 2px;
    }

    /deep/.el-input {
      width: 348px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    /deep/.el-input__count-inner {
      background: #f4f4f4;
    }
    .labelInput {
      /deep/.el-input {
        width: 175px;
        height: 30px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 175px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
      }
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 30px;
      }
    }
    .fillColor__btn {
      width: 348px;
      height: 30px;
      text-align: center;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      overflow: hidden;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    /deep/.el-color-picker {
      width: 348px;
      height: 30px;
    }
    /deep/.el-color-picker__trigger {
      height: 100%;
      width: 100%;
      border: none;
      padding: 0;
      .el-icon-arrow-down {
        color: #ccc !important;
      }
    }
    /deep/.el-color-picker__color {
      border: none;
    }
    /deep/.el-color-picker__color-inner {
      height: 100%;
      width: 100%;
    }
  }
  .labelOption {
    background-color: #f4f4f4;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>