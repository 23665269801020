<template>
  <div>
    <!-- 点对齐弹窗 -->
    <div class="lineAlignmentDialog">
      <el-dialog :title="lineAlignment ? $t('messages.LineAlign') : $t('messages.PointAlign')"
        :visible.sync="lineAlignmentDialogVisible" :modal="false" :close-on-click-modal="false"
        :before-close="closeDialog">
        <div class="inputArea">
          <span class="inputInfo" style="padding-right: 30px">{{ $t('messages.AlignmentDistance') }}</span>
          <span class="alignmentInput">
            <span style="padding-right: 20px">X:</span>
            <el-input v-model="lineAlignmentXInput" @focus="getOldInputData(lineAlignmentXInput)"
              @blur="lineAlignmentXInputBlur"></el-input>
            um
          </span>
          <span class="alignmentInput">
            <span style="padding-right: 20px">Y:</span>
            <el-input v-model="lineAlignmentYInput" @focus="getOldInputData(lineAlignmentYInput)"
              @blur="lineAlignmentYInputBlur"></el-input>
            um
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="deactivate" type="primary" @click="lineAlignmentDialogSureBtn"
            ref="lineAlignmentDialogSureBtn" size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="lineAlignmentDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 对齐窗口 -->
    <div class="alignmentDialog">
      <el-dialog :title="$t('messages.Align')" :visible.sync="alignmentDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeDialog">
        <div class="inputArea">
          <span class="inputInfo">{{ $t('messages.Alignment') }}:</span>
          <el-select popper-class="optionClass" v-model="value_alignmentType"
            :placeholder="$t('messages.placeholder_0')" @change="alignmentTypeChange">
            <el-option v-for="item in options_alignmentType" :key="item.value" :value="item.value">
              <span style="width: 20px; height: 20px"><img alt="" style="width: 100%; height: 100%"
                  :src="item.img" /></span>
              <span>{{ item.value }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- <div class="inputArea">
          <span class="inputInfo">对齐图层1:</span>
          <el-input suffix-icon="el-icon-arrow-right" class="alignmentLayer" v-model="value_alignmentLayer1" placeholder="请选择对齐图层" @focus="alignmentLayerVisible1 = true;">
          </el-input>
          <el-row>
            <el-button circle @click="showLayer2=false">－</el-button>
            <el-button circle type="primary" @click="showLayer2=true">＋</el-button>
          </el-row>
        </div>
        <div class="inputArea" v-if="showLayer2">
          <span class="inputInfo">对齐图层2:</span>
          <el-input suffix-icon="el-icon-arrow-right" class="alignmentLayer" v-model="value_alignmentLayer2" placeholder="请选择对齐图层" @focus="alignmentLayerVisible2 = true;">
          </el-input>
          <el-row>
            <el-button circle @click="showLayer2=false">－</el-button>
          </el-row>
        </div> -->
        <!-- 对齐窗口图层选择 -->
        <!-- <div class="layerDialog">
          <el-dialog title="选择图层1" :visible.sync="alignmentLayerVisible1" :modal="false" :close-on-click-modal="false">
            <el-table class="layerTable" ref="layerTable1" :data="table_alignmentLayer" tooltip-effect="dark" :show-header="false" style="width: 100%" highlight-current-row @current-change="selectLayer1">
              <el-table-column prop="layerNumber" label="图层编号" width="35">
              </el-table-column>
              <el-table-column prop="image" label="图层颜色" width="35">
                <template slot-scope="scope">
                  <img alt="" :src="scope.row.image" style="height:15px;display:inline-block;padding:4px 0" />
                </template>
              </el-table-column>
              <el-table-column prop="layerName" label="图层名">
              </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="layerDialogSureBtn1" size="mini">{{$t("messages.sure")}}</el-button>
              <el-button @click="alignmentLayerVisible1 = false" size="mini">{{$t("messages.cancel")}}</el-button>
            </span>
          </el-dialog>
        </div>
        <div class="layerDialog">
          <el-dialog title="选择图层2" :visible.sync="alignmentLayerVisible2" :modal="false" :close-on-click-modal="false">
            <el-table class="layerTable" ref="layerTable2" :data="table_alignmentLayer" tooltip-effect="dark" :show-header="false" style="width: 100%" highlight-current-row @current-change="selectLayer2">
              <el-table-column prop="layerNumber" label="图层编号" width="35">
              </el-table-column>
              <el-table-column prop="image" label="图层颜色" width="35">
                <template slot-scope="scope">
                  <img alt="" :src="scope.row.image" style="height:15px;display:inline-block;padding:4px 0" />
                </template>
              </el-table-column>
              <el-table-column prop="layerName" label="图层名">
              </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="layerDialogSureBtn2" size="mini">{{$t("messages.sure")}}</el-button>
              <el-button @click="alignmentLayerVisible2 = false" size="mini">{{$t("messages.cancel")}}</el-button>
            </span>
          </el-dialog>
        </div> -->
        <!-- <div class="inputArea" style="display:flex;margin-top:10px">
            <span class="inputInfo" style="padding-right:10px">对齐距离:</span>
            <div style="flex:1">
              <div style="padding:10px">
                <el-radio v-model="alignDistanceX" label="1" @change="aliXOpen=true">打开</el-radio>
                <el-radio v-model="alignDistanceX" label="2" @change="aliXOpen=false">关闭</el-radio>
                <span class="alignmentInput"><span style="padding-right:20px">X:</span>
                  <el-input v-model="alignmentXInput" :disabled="aliXOpen?false:true"></el-input>um
                </span>
              </div>
              <div style="padding:10px">
                <el-radio v-model="alignDistanceY" label="1" @change="aliYOpen=true">打开</el-radio>
                <el-radio v-model="alignDistanceY" label="2" @change="aliYOpen=false">关闭</el-radio>
                <span class="alignmentInput"><span style="padding-right:20px">Y:</span>
                  <el-input v-model="alignmentYInput" :disabled="aliYOpen?false:true"></el-input>um
                </span>
              </div>
            </div>
          </div> -->
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" class="deactivate" @click="alignmentDialogApplicationBtn" ref="alignApplicatBtn" size="mini" disabled>应 用</el-button> -->
          <el-button type="primary" class="deactivate" @click="alignmentDialogSureBtn" ref="alignSureBtn" size="mini"
            disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="alignmentDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 翻转弹窗 -->
    <!-- 水平翻转 -->
    <div class="horizontalFlipDialog">
      <el-dialog :title="$t('messages.flip')" :visible.sync="horizontalFlipDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeDialog">
        <div class="inputArea">
          <span class="inputInfo" style="padding-right: 160px">{{ $t('messages.axisLocation') }}</span>
          <span class="alignmentInput">
            <span style="padding-right: 20px">X:</span>
            <el-input v-model="horizontalFlipInput" @focus="getOldInputData(horizontalFlipInput)"
              @blur="horizontalFlipInputBlur"></el-input>
            um
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" ref="horizontalFlipSureBtn" @click="horizontalFlipDialogSureBtn"
            size="mini">{{ $t('messages.sure') }}</el-button>
          <el-button @click="horizontalFlipDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 垂直翻转 -->
    <div class="verticalFlipDialog">
      <el-dialog :title="$t('messages.flip')" :visible.sync="verticalFlipDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeDialog">
        <div class="inputArea">
          <span class="inputInfo" style="padding-right: 160px">{{ $t('messages.axisLocation') }}:</span>
          <span class="alignmentInput">
            <span style="padding-right: 20px">Y:</span>
            <el-input v-model="verticalFlipInput" @focus="getOldInputData(verticalFlipInput)"
              @blur="verticalFlipInputBlur"></el-input>
            um
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" ref="verticalFlipSureBtn" @click="verticalFlipDialogSureBtn"
            size="mini">{{ $t('messages.sure') }}</el-button>
          <el-button @click="verticalFlipDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 布尔运算 -->
    <div class="booleanDialog">
      <el-dialog :title="$t('messages.Boolean')" :visible.sync="booleanDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeDialog">
        <div class="inputArea">
          <span>{{ $t('messages.layer') }}:</span>
          <el-select popper-class="optionClass" v-model="value_booleanLayer" :placeholder="$t('messages.placeholder_0')"
            @change="booleanLayerSelect">
            <el-option v-for="item in booleanLayer" :key="item.layerNumber" :value="item.layerName">
              <span style="width: 35px">{{ item.layerNumber }}</span>
              <img alt="" :src="item.image" style="width: 25px; height: 15px" />
              <span style="">{{ item.layerName }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="inputArea booleanDialogSelect">
          <span style="padding-right: 50px">{{ $t('messages.select') }}:</span>
          <el-select popper-class="optionClass" v-model="value_booleanMethod"
            :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in booleanMethod" :key="item.value" :value="item.name"></el-option>
          </el-select>
          <span class="alignmentInput">
            <span style="padding-right: 5px"></span>
            <!-- <span style="padding-right:5px">by:</span> -->
            <!-- <el-input v-model="booleanInput"></el-input>um -->
          </span>
        </div>
        <div class="inputArea">
          <span style="padding-right: 60px">{{ $t('messages.OriginalGraphics') }}:</span>
          <div class="radioArea">
            <el-radio v-model="booleanSave" label="1" @change="booleanSaveFn()">{{ $t('messages.Retain') }}</el-radio>
            <el-radio v-model="booleanSave" label="2" @change="booleanSaveFn()">{{ $t('messages.delete') }}</el-radio>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" class="deactivate" @click="booleanDialogApplicationBtn" ref="booleanApplicatBtn" size="mini" disabled>应 用</el-button> -->
          <el-button class="deactivate" ref="booleanDialogSureBtn" type="primary" @click="booleanDialogSureBtn"
            size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="booleanDialogCancelBtn" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 阵列 -->
    <div class="matrixDialog">
      <el-dialog :title="$t('messages.ArrayCopy')" :visible.sync="matrixDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeDialog">
        <div class="inputArea">
          <span style="flex: 1">{{ $t('messages.rowsCells') }}</span>
          <span class="matrixInput">
            <span style="padding-right: 5px">{{ $t('messages.row') }}=</span>
            <el-input v-model="rowInput" @focus="getOldInputData(rowInput)" @blur="rowInputBlur"></el-input>
          </span>
          <span class="matrixInput">
            <span style="padding-right: 5px">{{ $t('messages.column') }}=</span>
            <el-input v-model="listInput" @focus="getOldInputData(listInput)" @blur="listInputBlur"></el-input>
          </span>
        </div>
        <div class="inputArea">
          <span style="flex: 1">{{ $t('messages.RowVector') }}(x,y)</span>
          <span class="matrixInput">
            <span style="padding-right: 5px">X =</span>
            <el-input v-model="rowVectorX" @focus="getOldInputData(rowVectorX)" @blur="rowVectorXBlur"></el-input>
            um
          </span>
          <span class="matrixInput">
            <span style="padding-right: 5px">Y =</span>
            <el-input v-model="rowVectorY" @focus="getOldInputData(rowVectorY)" @blur="rowVectorYBlur"></el-input>
            um
          </span>
        </div>
        <div class="inputArea">
          <span style="flex: 1">{{ $t('messages.ColumnVector') }}(x,y)</span>
          <span class="matrixInput">
            <span style="padding-right: 5px">X =</span>
            <el-input v-model="listVectorX" @focus="getOldInputData(listVectorX)" @blur="listVectorXBlur"></el-input>
            um
          </span>
          <span class="matrixInput">
            <span style="padding-right: 5px">Y =</span>
            <el-input v-model="listVectorY" @focus="getOldInputData(listVectorY)" @blur="listVectorYBlur"></el-input>
            um
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button ref="matrixDialogSureBtn" type="primary" @click="matrixDialogSureBtn"
            size="mini">{{ $t('messages.sure') }}</el-button>
          <el-button @click="matrixDialogCancelBtn()" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 另存为 -->
    <div class="saveAsCellDialog">
      <el-dialog :title="$t('messages.MakeCell')" :visible.sync="saveAsCellDialogVisible" :modal="false"
        :close-on-click-modal="false" :before-close="closeSaveAsDialog">
        <el-form :model="saveAsCellForm" :rules="rules" ref="saveAsCellForm" class="demo-ruleForm">
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.name') }}:</span>
            <el-form-item prop="value_cellName">
              <el-input v-model="saveAsCellForm.value_cellName" :placeholder="$t('messages.placeholder_1')"
                maxlength="50"></el-input>
            </el-form-item>
          </div>
          <div class="inputArea">
            <span class="inputInfo">{{ $t('messages.path') }}:</span>
            <el-form-item prop="treeNodeInfo">
              <el-select v-model="saveAsCellForm.treeNodeInfo" :placeholder="$t('messages.placeholder_0')">
                <el-option :value="saveAsCellForm.treeNodeInfo">
                  <el-tree class="tree-line" :indent="0" node-key="id" highlight-current :props="props"
                    @node-click="handleNodeClick" :load="loadNode" lazy v-if="refreshTree">
                    <span class="custom-tree-node" slot-scope="{ node }">
                      <img alt="" class="treeIcon" v-if="node.level == 1 && node.data.isTeam == false"
                        src="../../assets/icons/file/个人用户名.svg" />
                      <img alt="" class="treeIcon" v-if="node.level == 1 && node.data.isTeam == true"
                        src="../../assets/icons/file/团队名.svg" />
                      <img alt="" class="treeIcon" v-if="node.level == 2" src="../../assets/icons/file/项目名.svg" />
                      <img alt="" class="treeIcon" v-if="node.level == 3" src="../../assets/icons/file/版图文件.svg" />
                      <span>{{ node.label }}</span>
                    </span>
                  </el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="inputArea">
            <span style="padding-right: 46px">{{ $t('messages.type') }}:</span>
            <div class="radioArea">
              <el-radio v-model="saveAsType" label="1">{{ $t('messages.fileCell') }}</el-radio>
              <el-radio v-model="saveAsType" label="2">{{ $t('messages.componentCell') }}</el-radio>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="deactivate" ref="saveAsCellSureBtn" @click="saveAsCellDialogSureBtn"
            size="mini" disabled>{{ $t('messages.sure') }}</el-button>
          <el-button @click="saveAsCellDialogCancelButton" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 打散 -->
    <div class="breakUpDialog">
      <el-dialog :title="$t('messages.Flatten')" :visible.sync="breakUpDialogVisible" :modal="false"
        :close-on-click-modal="false">
        <div class="inputArea">
          <span style="padding-right: 60px">{{ $t('messages.Flattten') }}:</span>
          <div class="radioArea">
            <el-radio v-model="breakUpLayer" label="1">{{ $t('messages.OneLayer') }}</el-radio>
            <el-radio v-model="breakUpLayer" label="2">{{ $t('messages.all') }}</el-radio>
          </div>
        </div>
        <div class="inputArea">
          <span style="padding-right: 46px">{{ $t('messages.ParameterizedCell') }}:</span>
          <div class="radioArea">
            <el-radio v-model="breakUpSave" label="1">{{ $t('messages.Retain') }}</el-radio>
            <el-radio v-model="breakUpSave" label="2">{{ $t('messages.Flatten') }}</el-radio>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="breakUpDialogSureBtn" size="mini">{{ $t('messages.sure') }}</el-button>
          <el-button @click="breakUpDialogVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import { getFileLayerListFromTable } from "./rightList/layerListFn.js";
import {
  getUserProjectList_api, //获取个人用户项目目录
  getFileList_api, //获取文件列表
  getTeamProjectList_api, //获取团队项目列表
  getTeamPermissionList_api,
} from "@/api/file/file.js";
export default {
  data() {
    let fileNameRule = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (
        [...value][0] == " " ||
        [...value][[...value].length - 1] == " "
      ) {
        callback(
          new Error("字符需大于1，仅支持英文、数字、和符号+ - _ & () [] . ~")
        );
      } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
        //NOSONAR
        callback(
          new Error("字符需大于1，仅支持英文、数字、和符号+ - _ & () [] . ~")
        );
      } else {
        callback();
      }
    };
    return {
      rules: {
        value_cellName: [{ validator: fileNameRule, trigger: "blur" }],
      },
      //线/点对齐
      lineAlignmentDialogVisible: false,
      lineAlignment: false,
      lineAlignmentXInput: 0,
      lineAlignmentYInput: 0,
      oldInputData: 0,
      //对齐窗口
      alignmentDialogVisible: false,
      options_alignmentType: [
        {
          value: this.$t("messages.alignLeft"),
          img: require("../../assets/icons/sidebar/左对齐.svg"),
        },
        {
          value: this.$t("messages.alignRight"),
          img: require("../../assets/icons/sidebar/右对齐.svg"),
        },
        {
          value: this.$t("messages.alignTop"),
          img: require("../../assets/icons/sidebar/上对齐.svg"),
        },
        {
          value: this.$t("messages.alignBottom"),
          img: require("../../assets/icons/sidebar/下对齐.svg"),
        },
        {
          value: this.$t("messages.alignHorizontalCenter"),
          img: require("../../assets/icons/sidebar/横向中线对齐.svg"),
        },
        {
          value: this.$t("messages.alignVerticalCenter"),
          img: require("../../assets/icons/sidebar/竖向中线对齐.svg"),
        },
      ],
      value_alignmentType: "",
      showLayer2: false,
      alignDistanceX: "1",
      alignDistanceY: "1",
      value_alignmentLayer1: "",
      value_alignmentLayerNum1: null,
      value_alignmentLayer2: "",
      value_alignmentLayerNum2: null,
      alignmentLayerVisible1: false,
      alignmentLayerVisible2: false,
      alignmentXInput: null,
      alignmentYInput: null,
      aliXOpen: true,
      aliYOpen: true,
      table_alignmentLayer: [],
      //水平翻转
      horizontalFlipDialogVisible: false,
      horizontalFlipInput: 0,
      //垂直翻转
      verticalFlipDialogVisible: false,
      verticalFlipInput: 0,
      //布尔运算
      booleanDialogVisible: false,
      value_booleanLayer: "",
      value_booleanLayerNum: null,
      booleanLayer: [],
      value_booleanMethod: "",
      booleanMethod: [
        { name: "or" },
        { name: "and" },
        { name: "xor" },
        { name: "not" },
      ],
      booleanInput: 0,
      booleanSave: "1",
      // 阵列
      matrixDialogVisible: false,
      rowInput: 0,
      listInput: 0,
      rowVectorX: 0,
      rowVectorY: 0,
      listVectorX: 0,
      listVectorY: 0,
      //打散
      breakUpDialogVisible: false,
      breakUpLayer: "1",
      breakUpSave: "1",
      //另存为单元
      saveAsCellDialogVisible: false,
      saveAsCellForm: {
        treeNodeInfo: "",
        value_cellName: "",
      },
      saveAsType: "1",
      props: {
        label: "name",
        children: "zones",
        isLeaf: "isLeaf",
      },
      node: null,
      refreshTree: true,
    };
  },
  mounted() {
    this.$bus.$on("changeLang", (data) => {
      let fileNameRule = (rule, value, callback) => {
        if (value === "") {
          callback();
        } else if (
          [...value][0] == " " ||
          [...value][[...value].length - 1] == " "
        ) {
          callback(this.$t("messages.fileTip2"));
        } else if (!/^[\s&()~+-._\[\]0-9a-zA-Z]+$/.test(value)) {
          //NOSONAR
          callback(this.$t("messages.fileTip2"));
        } else {
          callback();
        }
      };
      this.rules.value_cellName[0].validator = fileNameRule;
    });
    bus.$on("horizontalFlipDialog", () => {
      this.horizontalFlipDialogVisible = true;
    });
    bus.$on("verticalFlipDialog", () => {
      this.verticalFlipDialogVisible = true;
    });
    bus.$on("pointAlignmentDialog", () => {
      this.lineAlignment = false;
      this.lineAlignmentDialogVisible = true;
      this.disabledLineAlignment();
    });
    bus.$on("lineAlignmentDialog", () => {
      this.lineAlignment = true;
      this.lineAlignmentDialogVisible = true;
      this.disabledLineAlignment();
    });
    bus.$on("alignmentDialog", () => {
      this.alignmentDialogVisible = true;
      if (this.$route.query.hasOwnProperty("id")) {
        let file_snow_id = this.$route.query.id.split("/")[0];
        let index = this.$store.state.layerDatas.findIndex(
          (item) => item.file_snow_id === file_snow_id
        );
        if (index !== -1) {
          this.table_alignmentLayer =
            this.$store.state.layerDatas[index].layerDatas;
        }
      }
    });
    bus.$on("booleanDialog", () => {
      this.booleanDialogVisible = true;
      let file_snow_id = this.$store.state.fileInfo.fileId;
      let fileLayerListObj = getFileLayerListFromTable(file_snow_id);
      this.booleanLayer = fileLayerListObj.fileLayerDatas;
    });
    bus.$on("matrixDialog", () => {
      this.matrixDialogVisible = true;
    });
    bus.$on("saveAsCellDialog", () => {
      this.saveAsCellDialogVisible = true;
      this.refreshTree = true;
    });
    bus.$on("breakUpDialog", () => {
      this.breakUpDialogVisible = true;
    });
    this.$bus.$on("changeLang", (data) => {
      this.options_alignmentType = [
        {
          value: this.$t("messages.alignLeft"),
          img: require("../../assets/icons/sidebar/左对齐.svg"),
        },
        {
          value: this.$t("messages.alignRight"),
          img: require("../../assets/icons/sidebar/右对齐.svg"),
        },
        {
          value: this.$t("messages.alignTop"),
          img: require("../../assets/icons/sidebar/上对齐.svg"),
        },
        {
          value: this.$t("messages.alignBottom"),
          img: require("../../assets/icons/sidebar/下对齐.svg"),
        },
        {
          value: this.$t("messages.alignHorizontalCenter"),
          img: require("../../assets/icons/sidebar/横向中线对齐.svg"),
        },
        {
          value: this.$t("messages.alignVerticalCenter"),
          img: require("../../assets/icons/sidebar/竖向中线对齐.svg"),
        },
      ];
    });
  },
  beforeDestroy() {
    bus.$off("horizontalFlipDialog");
    bus.$off("verticalFlipDialog");
    bus.$off("pointAlignmentDialog");
    bus.$off("lineAlignmentDialog");
    bus.$off("alignmentDialog");
    bus.$off("booleanDialog");
    bus.$off("matrixDialog");
    bus.$off("saveAsCellDialog");
    bus.$off("breakUpDialog");
  },
  methods: {
    //点击获取老数据
    // @focus="getPrecisionOld" @blur="getPrecision"
    getOldInputData(data) {
      this.oldInputData = data;
    },
    //失焦检测数据
    testInputData(data) {
      let absData = Math.abs(data);
      if (/^(\-(\d*))$/.test(absData)) {
        return true;
      } else {
        if (/^[1-9]\d{0,4}(\.\d{1,3})?$|^0(\.\d{1,3})?$/.test(absData)) {
          return true;
        } else {
          bus.$emit("errDialog", this.$t("messages.numberLimit1"));
          return false;
        }
      }
    },
    testInputIntData(data) {
      if (/^[1-9]\d{0,4}?$/.test(data) || data === 0) {
        //NOSONAR
        return true;
      } else {
        bus.$emit("errDialog", this.$t("messages.numberLimit2"));
        return false;
      }
    },
    //关闭弹窗,切换回选择模式
    closeDialog() {
      this.lineAlignmentDialogVisible = false;
      this.horizontalFlipDialogVisible = false;
      this.verticalFlipDialogVisible = false;
      this.booleanDialogVisible = false;
      this.matrixDialogVisible = false;
      this.alignmentDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },
    //禁止按键
    disableBtn(ref) {
      ref.$el.classList.add("deactivate");
      ref.$el.classList.add("is-disabled");
      ref.$el.disabled = true;
    },
    //激活按键
    activateBtn(ref) {
      ref.$el.classList.remove("deactivate");
      ref.$el.classList.remove("is-disabled");
      ref.$el.disabled = false;
    },
    ////点对齐/线对齐
    lineAlignmentXInputBlur() {
      if (!this.testInputData(this.lineAlignmentXInput)) {
        this.lineAlignmentXInput = this.oldInputData;
      }
    },
    lineAlignmentYInputBlur() {
      if (!this.testInputData(this.lineAlignmentYInput)) {
        this.lineAlignmentYInput = this.oldInputData;
      }
    },
    lineAlignmentDialogSureBtn() {
      if (this.lineAlignment) {
        let alignmentInput = [
          this.lineAlignmentXInput,
          this.lineAlignmentYInput,
        ];
        bus.$emit("lineAlignment", alignmentInput);
        this.lineAlignmentDialogVisible = false;
      } else {
        let alignmentInput = [
          this.lineAlignmentXInput,
          this.lineAlignmentYInput,
        ];
        bus.$emit("pointAlignment", alignmentInput);
        this.lineAlignmentDialogVisible = false;
      }
      bus.$emit("cancelSidebarSelect", true);
    },
    lineAlignmentDialogCancelBtn() {
      this.lineAlignmentDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },

    //线对齐,点对齐按键激活控制
    disabledLineAlignment() {
      if (this.lineAlignmentXInput === "" || this.lineAlignmentYInput === "") {
        this.disableBtn(this.$refs.lineAlignmentDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.lineAlignmentDialogSureBtn);
      }
    },

    ////对齐
    selectLayer1(row) {
      if (row !== null) {
        this.value_alignmentLayer1 = row.layerName;
        this.value_alignmentLayerNum1 = row.layerNumber;
      }
    },
    selectLayer2(row) {
      if (row !== null) {
        this.value_alignmentLayer2 = row.layerName;
        this.value_alignmentLayerNum2 = row.layerNumber;
      }
    },
    layerDialogSureBtn1() {
      if (this.showLayer2) {
        if (this.value_alignmentLayer1 == this.value_alignmentLayer2) {
          this.$message.error(this.$t("messages.fileTip14"));
          this.value_alignmentLayer1 = "";
        } else {
          this.alignmentLayerVisible1 = false;
        }
      } else {
        this.alignmentLayerVisible1 = false;
      }
    },
    layerDialogSureBtn2() {
      if (this.value_alignmentLayer1 == this.value_alignmentLayer2) {
        this.$message.error(this.$t("messages.fileTip14"));
        this.value_alignmentLayer2 = "";
      } else {
        this.alignmentLayerVisible2 = false;
      }
    },
    alignmentDialogApplicationBtn() {
      let alignmentData = [this.value_alignmentType];
      bus.$emit("alignment", alignmentData);
    },
    alignmentDialogSureBtn() {
      let alignmentData = [this.value_alignmentType];
      bus.$emit("alignment", alignmentData);
      this.alignmentDialogVisible = false;
      this.resetAlignmentDialog();
      bus.$emit("cancelSidebarSelect", true);
    },
    alignmentDialogCancelBtn() {
      this.alignmentDialogVisible = false;
      this.resetAlignmentDialog();
      bus.$emit("cancelSidebarSelect", true);
    },
    resetAlignmentDialog() {
      this.value_alignmentType = "";
    },
    //应用按键激活控制
    disabledApplicat() {
      if (this.value_alignmentType == "") {
        this.disableBtn(this.$refs.alignSureBtn);
      } else {
        this.activateBtn(this.$refs.alignSureBtn);
      }
    },
    //图层选择
    handleSelectionChange() {},
    //图层编号
    indexMethod(index) {
      return index * 1 + 1;
    },
    alignmentTypeChange() {},
    alignmentLayerChange() {},
    ////水平翻转
    horizontalFlipInputBlur() {
      if (!this.testInputData(this.horizontalFlipInput)) {
        this.horizontalFlipInput = this.oldInputData;
      }
    },
    horizontalFlipDialogSureBtn() {
      bus.$emit("horizontalFlip", this.horizontalFlipInput);
      this.horizontalFlipDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },
    horizontalFlipDialogCancelBtn() {
      this.horizontalFlipDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },
    disabledHorizontalFlip() {
      if (this.horizontalFlipInput === "") {
        this.disableBtn(this.$refs.horizontalFlipSureBtn);
      } else {
        this.activateBtn(this.$refs.horizontalFlipSureBtn);
      }
    },
    ////垂直翻转
    verticalFlipInputBlur() {
      if (!this.testInputData(this.verticalFlipInput)) {
        this.verticalFlipInput = this.oldInputData;
      }
    },
    verticalFlipDialogSureBtn() {
      bus.$emit("verticalFlip", this.verticalFlipInput);
      this.verticalFlipDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },
    verticalFlipDialogCancelBtn() {
      this.verticalFlipDialogVisible = false;
      bus.$emit("cancelSidebarSelect", true);
    },
    disabledVerticalFlip() {
      if (this.verticalFlipInput === "") {
        this.disableBtn(this.$refs.verticalFlipSureBtn);
      } else {
        this.activateBtn(this.$refs.verticalFlipSureBtn);
      }
    },
    ////布尔运算
    booleanDialogSureBtn() {
      let data = {
        layerNumber: this.value_booleanLayerNum,
        method: this.value_booleanMethod,
        booleanSave: this.booleanSave,
      };

      bus.$emit("boolean", data);
      this.booleanDialogVisible = false;
      this.resetBooleanDialog();
      bus.$emit("cancelSidebarSelect", true);
    },
    booleanDialogCancelBtn() {
      this.booleanDialogVisible = false;
      this.resetBooleanDialog();
      bus.$emit("cancelSidebarSelect", true);
    },
    //布尔运算图层选择
    booleanLayerSelect(data) {
      let selectData = this.booleanLayer.filter((item) => {
        return item.layerName == data;
      });
      this.value_booleanLayerNum = selectData[0].layerNumber;
    },
    //布尔运算保留-删除
    booleanSaveFn() {},
    // 布尔运算确认按钮激活控制
    disabledBoolean() {
      if (this.value_booleanMethod === "") {
        // if (this.value_booleanLayer === '' || this.value_booleanMethod === '') {
        this.disableBtn(this.$refs.booleanDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.booleanDialogSureBtn);
      }
    },
    //
    booleanDialogApplicationBtn() {},
    //布尔运算重置按钮
    resetBooleanDialog() {
      this.value_booleanLayer = "";
      this.value_booleanLayerNum = null;
      this.value_booleanMethod = "";
      this.booleanInput = 0;
      this.booleanSave = "1";
    },
    ////阵列
    //阵列确定按钮
    rowInputBlur() {
      if (!this.testInputIntData(this.rowInput)) {
        this.rowInput = this.oldInputData;
      }
    },
    listInputBlur() {
      if (!this.testInputIntData(this.listInput)) {
        this.listInput = this.oldInputData;
      }
    },
    rowVectorXBlur() {
      if (!this.testInputData(this.rowVectorX)) {
        this.rowVectorX = this.oldInputData;
      }
    },
    rowVectorYBlur() {
      if (!this.testInputData(this.rowVectorY)) {
        this.rowVectorY = this.oldInputData;
      }
    },
    listVectorXBlur() {
      if (!this.testInputData(this.listVectorX)) {
        this.listVectorX = this.oldInputData;
      }
    },
    listVectorYBlur() {
      if (!this.testInputData(this.listVectorY)) {
        this.listVectorY = this.oldInputData;
      }
    },
    matrixDialogSureBtn() {
      this.matrixDialogVisible = false;
      bus.$emit("loading", true);
      setTimeout(() => {
        let matrixArr = [
          this.rowInput,
          this.listInput,
          this.rowVectorX,
          this.rowVectorY,
          this.listVectorX,
          this.listVectorY,
        ];
        bus.$emit("matrix", matrixArr);
        this.resetMatrixDialog();
      }, 100);
    },
    matrixDialogCancelBtn() {
      this.matrixDialogVisible = false;
      this.resetMatrixDialog();
    },
    //阵列确定激活控制
    disabledMatrix() {
      if (
        this.rowInput === "" ||
        this.listInput === "" ||
        this.rowVectorX === "" ||
        this.rowVectorY === "" ||
        this.listVectorX === "" ||
        this.listVectorY === ""
      ) {
        this.disableBtn(this.$refs.matrixDialogSureBtn);
      } else {
        this.activateBtn(this.$refs.matrixDialogSureBtn);
      }
    },
    //阵列重置按钮
    resetMatrixDialog() {
      this.matrixDialogVisible = false;
      this.rowInput = 0;
      this.listInput = 0;
      this.rowVectorX = 0;
      this.rowVectorY = 0;
      this.listVectorX = 0;
      this.listVectorY = 0;
      bus.$emit("cancelSidebarSelect", true);
      bus.$emit("cancelSchemaSidebarSelect", true);
    },
    ////打散
    breakUpDialogSureBtn() {
      this.breakUpDialogVisible = false;
      let breakUpData = [this.breakUpLayer, this.breakUpSave];
      bus.$emit("breakUp", breakUpData);
    },
    ////另存为单元
    saveAsCellDialogSureBtn() {
      this.$refs.saveAsCellForm.validate((valid) => {
        if (valid) {
          let data = [
            this.node,
            this.saveAsCellForm.value_cellName,
            this.saveAsType,
          ];
          bus.$emit("saveAsCell", data);
          bus.$emit("saveAsCellFn", data);
          this.resetSaveAsCell();
        }
      });
    },
    // 另存为取消
    saveAsCellDialogCancelButton() {
      this.resetSaveAsCell();
    },
    //另存为重置
    resetSaveAsCell() {
      this.saveAsCellForm.treeNodeInfo = "";
      this.saveAsCellForm.value_cellName = "";
      this.saveAsType = "1";
      this.saveAsCellDialogVisible = false;
      this.refreshTree = false;
      setTimeout(() => {
        this.$refs.saveAsCellForm.clearValidate();
      }, 500);
    },
    closeSaveAsDialog() {
      this.resetSaveAsCell();
    },
    handleNodeClick(data, node) {
      this.node = node;
      // if (node.level == '2') {
      //   if (node.data.isTeam) {
      //     if (node.data.teamRule !== '可删除、编辑') {
      //       this.saveAsCellForm.treeNodeInfo = ''
      //       bus.$emit('errDialog', this.$t('messages.userProjectPermission'))
      //     }
      //   }
      // }
      if (node.level == "3") {
        if (node.data.isTeam) {
          if (node.data.teamRule === "可删除、编辑") {
            let path = [
              node.parent.parent.data.name,
              node.parent.data.name,
              node.data.name,
            ].join("/");
            this.saveAsCellForm.treeNodeInfo = path;
          } else {
            this.saveAsCellForm.treeNodeInfo = "";
            bus.$emit("errDialog", this.$t("messages.userProjectPermission"));
          }
        } else {
          let path = [
            node.parent.parent.data.name,
            node.parent.data.name,
            node.data.name,
          ].join("/");
          this.saveAsCellForm.treeNodeInfo = path;
        }
      } else {
        this.saveAsCellForm.treeNodeInfo = "";
      }
    },
    async loadNode(node, resolve) {
      let data = [];
      if (node.level == "0") {
        this.$store.state.treeNameList.forEach((item) => {
          data.push(item);
        });
        return resolve(data);
      } else if (node.level == "1" && !node.data.isTeam) {
        let res = await getUserProjectList_api({
          start: 0,
          rows: 50,
        });
        if (res.code == 200000) {
          res.data.data.forEach((item) => {
            data.push({
              name: item._source.name,
              gns: item._source.gns,
              category: item._source.category,
              category_child: item._source.category_child,
              snow_id: item._source.snow_id,
              showInput: false,
              isTeam: false,
            });
          });
          return resolve(data);
        } else {
          bus.$emit("errDialog", this.$t("messages.code_" + res.code));
        }
      } else if (node.level == "1" && node.data.isTeam) {
        let res = await getTeamProjectList_api({
          start: 0,
          rows: 50,
          snow_team_id: node.data.snow_id,
          team_gns: node.data.gns,
          key_word: "",
        });
        if (res.code == 200000) {
          let projectGnsList = [];
          for (let i = 0; i < res.data.data.length; i++) {
            projectGnsList.push(res.data.data[i]._source.gns);
          }
          let permissionRes = await getTeamPermissionList_api({
            project_gns_list: projectGnsList,
            snow_team_id: node.data.snow_id,
          });
          if (permissionRes.code === 200000) {
            res.data.data.forEach((item) => {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                isTeam: true,
                teamRule: "",
                isLeaf: false,
              });
            });
            let realData = [];
            data.forEach((item) => {
              permissionRes.data.forEach((value) => {
                if (item.gns == value.gns) {
                  if (value.permission != "不可见") {
                    item.teamRule = value.permission;
                    realData.push(item);
                  }
                }
              });
            });
            return resolve(realData);
          } else {
            bus.$emit("errDialog", this.$t("messages.code_" + res.code));
          }
          return resolve(data);
        } else {
          bus.$emit("errDialog", this.$t("messages.code_" + res.code));
        }
      } else if (node.level == "2" && !node.data.isTeam) {
        let res = await getFileList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "文件",
          category_child: "个人文件",
        });
        if (res.code == 200000) {
          res.data.data.forEach((item) => {
            if (item._source.types === 1) {
              data.push({
                name: item._source.name,
                gns: item._source.gns,
                category: item._source.category,
                category_child: item._source.category_child,
                snow_id: item._source.snow_id,
                showInput: false,
                isTeam: false,
              });
            }
          });
          return resolve(data);
        } else {
          bus.$emit("errDialog", this.$t("messages.code_" + res.code));
        }
      } else if (node.level == "2" && node.data.isTeam) {
        let res = await getFileList_api({
          start: 0,
          rows: 200,
          parent_gns: node.data.gns,
          category: "文件",
          category_child: "团队文件",
        });
        if (res.code == 200000) {
          let fileGnsList = [];
          for (let i = 0; i < res.data.data.length; i++) {
            fileGnsList.push(res.data.data[i]._source.gns);
          }
          let permissionRes = await getTeamPermissionList_api({
            project_gns_list: fileGnsList,
            snow_team_id: node.parent.data.snow_id,
          });

          if (permissionRes.code === 200000) {
            res.data.data.forEach((item) => {
              if (item._source.types === 1) {
                data.push({
                  name: item._source.name,
                  gns: item._source.gns,
                  category: item._source.category,
                  category_child: item._source.category_child,
                  snow_id: item._source.snow_id,
                  showInput: false,
                  isTeam: true,
                  isLeaf: false,
                  teamRule: node.data.teamRule,
                });
              }
            });
          }

          let realData = [];
          data.forEach((item) => {
            permissionRes.data.forEach((value) => {
              if (item.gns == value.gns) {
                if (value.permission != "不可见") {
                  item.teamRule = value.permission;
                  realData.push(item);
                }
              }
            });
          });
          return resolve(realData);
        } else {
          bus.$emit("errDialog", this.$t("messages.code_" + res.code));
        }
      } else if (node.level >= "3") {
        return resolve([]);
      }
    },
    disableSaveAsCell() {
      if (
        this.saveAsCellForm.treeNodeInfo != "" &&
        this.saveAsCellForm.value_cellName != ""
      ) {
        this.activateBtn(this.$refs.saveAsCellSureBtn);
      } else {
        this.disableBtn(this.$refs.saveAsCellSureBtn);
      }
    },
  },
  watch: {
    horizontalFlipInput() {
      this.disabledHorizontalFlip();
    },
    verticalFlipInput() {
      this.disabledVerticalFlip();
    },
    value_alignmentType() {
      this.disabledApplicat();
    },
    value_booleanLayer() {
      this.disabledBoolean();
    },
    value_booleanMethod() {
      this.disabledBoolean();
    },
    rowInput() {
      this.disabledMatrix();
    },
    listInput() {
      this.disabledMatrix();
    },
    rowVectorX() {
      this.disabledMatrix();
    },
    rowVectorY() {
      this.disabledMatrix();
    },
    listVectorX() {
      this.disabledMatrix();
    },
    listVectorY() {
      this.disabledMatrix();
    },
    "saveAsCellForm.treeNodeInfo"() {
      this.disableSaveAsCell();
    },
    "saveAsCellForm.value_cellName"() {
      this.disableSaveAsCell();
    },
    lineAlignmentXInput() {
      this.disabledLineAlignment();
    },
    lineAlignmentYInput() {
      this.disabledLineAlignment();
    },
  },
};
</script>
<style lang="less" scoped>
//线对齐
.lineAlignmentDialog {
  /deep/.el-dialog {
    width: 533px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 30px 15px;
    max-height: 700px;
    overflow: auto;
  }
  .alignmentInput {
    padding: 0 30px;
  }
  /deep/.el-input {
    width: 50px;
    margin-right: 5px;
  }
  /deep/.el-input__inner {
    width: 50px;
    height: 24px;
    padding: 0;
    text-align: center;
    border-radius: 2px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//对齐
.alignmentDialog {
  /deep/.el-dialog {
    width: 533px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 20px 30px 15px;
    max-height: 700px;
    overflow: auto;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .alignmentLayer {
      /deep/.el-input .el-select__caret {
        transform: rotateZ(90deg);
      }
    }
    /deep/.el-input {
      width: 392px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    .inputInfo {
      margin-right: 25px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
      cursor: pointer;
    }
    .alignmentInput {
      padding: 0 65px 0 0;
      /deep/.el-input {
        width: 50px;
        height: 24px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 50px;
        height: 24px;
        padding: 0;
        text-align: center;
        border-radius: 2px;
      }
    }
    /deep/.el-row {
      display: flex;
      width: 50px;
      .el-button:nth-child(1) {
        background-color: #fff;
        color: #d44051;
        border: 1px solid #d44051;
      }
      .el-button:nth-child(2) {
        background-color: #4385f2;
        border: 1px solid #4385f2;
      }
      .el-button {
        padding: 0;
        font-size: 16px;
        display: flex;
        font-weight: bold;
        cursor: pointer;
        span {
          height: 18px;
          width: 18px;
          line-height: 18px;
        }
      }
    }
    /deep/.el-radio {
      margin-right: 50px;
    }
  }
  .inputArea:nth-child(2),
  .inputArea:nth-child(3) {
    /deep/.el-select {
      flex: 1;
      width: 330px;
      height: 30px;
      border-radius: 2px;
      margin-left: 37px;
    }
    /deep/.el-input {
      width: 330px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
  .layerDialog {
    /deep/.el-dialog__wrapper {
      overflow: visible;
    }
    /deep/.el-dialog {
      width: 337px;
    }
    /deep/.el-dialog__body {
      margin: 10px 0;
      padding: 0;
      max-height: 360px;
      overflow-y: scroll;
    }
    /deep/.el-table__cell {
      padding: 7px 0px;
    }
    /deep/.el-table .cell {
      padding-right: 0;
      height: 23px;
    }
    /deep/.el-dialog__footer {
      padding: 0 10px 12px 10px;
    }
  }
  /deep/.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #dce6f8;
  }
  /deep/.el-table__body tr.current-row > td.el-table__cell {
    background-color: #dce6f8;
  }
}
//水平翻转
.horizontalFlipDialog {
  /deep/.el-dialog {
    width: 533px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 30px 15px;
    max-height: 700px;
    overflow: auto;
  }
  .alignmentInput {
    padding: 0 30px;
  }
  /deep/.el-input {
    width: 50px;
    margin-right: 5px;
  }
  /deep/.el-input__inner {
    width: 50px;
    height: 24px;
    padding: 0;
    text-align: center;
    border-radius: 2px;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//垂直翻转
.verticalFlipDialog {
  /deep/.el-dialog {
    width: 533px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 0 30px 15px;
    max-height: 700px;
    overflow: auto;
  }
  .alignmentInput {
    padding: 0 30px;
  }
  /deep/.el-input {
    width: 50px;
    margin-right: 5px;
  }
  /deep/.el-input__inner {
    width: 50px;
    height: 24px;
    border-radius: 2px;
    padding: 0;
    text-align: center;
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//布尔运算
.booleanDialog {
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 20px 35px 15px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 392px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 392px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .alignmentInput {
      width: 30%;
      /deep/.el-input {
        width: 50px;
        height: 24px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 50px;
        height: 24px;
        padding: 0;
        text-align: center;
        border-radius: 2px;
      }
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 105px;
      }
    }
  }
  .booleanDialogSelect {
    /deep/.el-select {
      width: 220px;
      height: 30px;
      background: #fff;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 200px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
  }
  /deep/.el-progress--line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.el-progress-bar {
    padding-right: 40px;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//阵列
.matrixDialog {
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 10px 20px 25px 15px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-input {
      width: 392px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .matrixInput {
      flex: 1;
      /deep/.el-input {
        width: 50px;
        height: 24px;
        margin-right: 5px;
      }
      /deep/.el-input__inner {
        width: 50px;
        height: 24px;
        padding: 0;
        text-align: center;
        border-radius: 2px;
      }
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//打散
.breakUpDialog {
  /deep/.el-dialog {
    width: 535px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    white-space: nowrap;
  }
  /deep/.el-dialog__body {
    font-weight: 400;
    padding: 20px 20px 35px 15px;
    max-height: 700px;
    overflow: hidden;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
  }
  /deep/.el-radio__input .el-radio__inner {
    border: 1px solid #818181;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #fff;
    border-color: #0c7de6;
  }
  /deep/.el-radio__inner::after {
    background-color: #0c7de6;
  }
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//另存为单元
.saveAsCellDialog {
  color: #333333;
  /deep/.el-dialog {
    width: 686px;
    min-height: 261px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__header {
    height: 41px;
    width: 100%;
    padding: 0;
    background-color: #f4f4f4;
  }
  /deep/.el-dialog__title {
    padding: 14px 13px 12px 13px;
    display: inline-block;
    line-height: 14px;
  }
  /deep/.el-dialog__headerbtn {
    top: 0;
    right: 0;
  }
  /deep/.el-dialog__close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.el-dialog__close::before {
    width: 16px;
    height: 16px;
  }
  //输入框
  /deep/.el-dialog__body {
    padding: 20px 20px 40px 20px;
  }
  .inputArea {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-select {
      width: 516px;
      height: 30px;
      border-radius: 2px;
    }
    /deep/.el-input {
      width: 516px;
      height: 30px;
      background: #f4f4f4;
      border-radius: 2px;
    }
    /deep/.el-input__inner {
      height: 30px;
    }
    .radioArea {
      width: 516px;
      height: 30px;
      display: flex;
      align-items: center;
      /deep/.el-radio__label {
        padding-right: 140px;
      }
    }
    .nameIcon {
      position: absolute;
      top: 24px;
      right: 13px;
      z-index: 1000;
      transition: transform 0.3s;
    }
    .inputActive {
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
    /deep/.el-input__icon {
      line-height: 30px;
    }
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
//树形列表
.tree-line {
  font-size: 14px;
  i {
    padding-right: 5px;
  }
  /deep/.el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  /deep/.el-tree-node__children {
    padding-left: 15px; // 缩进量
  }
  /deep/.el-tree-node > .el-tree-node__children {
    overflow: visible;
  }
  // 竖线
  /deep/.el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -10px;
    top: -12px;
    border-width: 1px;
    border-left: 1px dashed #dddddd;
  }
  // 当前层最后一个节点的竖线高度固定
  /deep/.el-tree-node:last-child::before {
    height: 30px; // 可以自己调节到合适数值
  }
  // 横线
  /deep/.el-tree-node::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    left: -8px;
    top: 17px;
    border-width: 1px;
    border-top: 1px dashed #dddddd;
  }
  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  /deep/& > .el-tree-node::after {
    border-top: none;
  }
  /deep/& > .el-tree-node::before {
    border-left: none;
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    width: 100%;
    span {
      display: inline-block;
      width: 100%;
    }
  }
  .treeIcon {
    padding-right: 4px;
    position: relative;
    width: 22px;
    height: 20px;
    left: -80px;
    filter: drop-shadow(#0c7de6 80px 0);
    color: #0c7de6;
  }
  // 展开关闭的icon
  /deep/.el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    /deep/&.is-leaf {
      color: transparent;
    }
  }
  /deep/.el-tree-node__content > .el-tree-node__expand-icon {
    padding: 0 3px 0 0px;
  }
  /deep/.el-tree-node__content {
    height: 35px;
  }
  //更换图标
  /deep/.el-icon-caret-right:before {
    content: "";
    background: url("../../assets/zhankai.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  /deep/.el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "";
    background: url("../../assets/zhankai_1.svg");
    display: block;
    width: 11px;
    height: 11px;
    background-size: 11px;
  }
  //叶子结点样式
  /deep/.el-tree-node__expand-icon.is-leaf.el-icon-caret-right:before {
    background: transparent;
    width: 0px;
  }
  //禁止转向
  /deep/.el-tree-node__expand-icon {
    transform: none;
  }
  /deep/.el-tree-node__expand-icon.expanded {
    transform: none;
  }
}
</style>
