<template>
  <div>
    <AirBridgeDialog></AirBridgeDialog>
    <CavityDialog></CavityDialog>
    <CheckLineDialog></CheckLineDialog>
    <ConnectLineDialog></ConnectLineDialog>
    <InLinePadDialog></InLinePadDialog>
    <SquidZLineDialog></SquidZLineDialog>
    <TinIndiumDialog></TinIndiumDialog>
    <WaveGuideDialog></WaveGuideDialog>
    <WBPadDialog></WBPadDialog>
    <CellSplitDialog></CellSplitDialog>
    <JJDialog></JJDialog>
    <WaferLayoutDialog></WaferLayoutDialog>
  </div>
</template>

<script>
import AirBridgeDialog from './AirBridgeDialog.vue'
import CavityDialog from './CavityDialog.vue'
import CheckLineDialog from './CheckLineDialog.vue'
import ConnectLineDialog from './ConnectLineDialog.vue'
import InLinePadDialog from './InLinePadDialog.vue'
import SquidZLineDialog from './SquidZLineDialog.vue'
import TinIndiumDialog from './TinIndiumDialog.vue'
import WaveGuideDialog from './WaveGuideDialog.vue'
import WBPadDialog from './WBPadDialog.vue'
import CellSplitDialog from './CellSplitDialog.vue'
import JJDialog from './JJDialog.vue'
import WaferLayoutDialog from './WaferLayoutDialog.vue'

export default {
  components: {
    AirBridgeDialog,
    CavityDialog,
    CheckLineDialog,
    ConnectLineDialog,
    InLinePadDialog,
    SquidZLineDialog,
    TinIndiumDialog,
    WaveGuideDialog,
    WBPadDialog,
    CellSplitDialog,
    JJDialog,
    WaferLayoutDialog,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
  mounted() {},
  watch: {},
}
</script>

<style lang="less" scoped></style>
