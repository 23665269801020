<template>
  <div>
    <el-dialog :title="$t('messages.toolAirbridgeTitle')" :visible.sync="AirBridgeDialogVisible" width="1108px"
      :before-close="handleClose" :modal="false" @opened="AirBridgeDialogOpened">
      <div class="dialog-body">
        <div class="tool-main">
          <div class="tool-main__header">
            <div class="Header-tabs">
              <div :class="{ active: selectAirBridgeRule, 'Header-tabs__item': true }"
                @click="AirBridgeDialogSelect('rule')">{{ $t('messages.toolRules') }}</div>
              <div :class="{ active: selectAirBridgeSize, 'Header-tabs__item': true }"
                @click="AirBridgeDialogSelect('size')">{{ $t('messages.toolSize') }}</div>
            </div>
          </div>
          <div class="tool-main__body" v-if="selectAirBridgeRule">
            <div class="tool-main__item">
              <el-table class="t-table" :data="cellLayerList" ref="AirBridgeTable" height="175px"
                header-cell-class-name="table_header">
                <el-table-column :label="$t('messages.toolNumber')" prop="layerNumber"></el-table-column>
                <el-table-column :label="$t('messages.toolLayer')" prop="layerName"></el-table-column>
                <el-table-column :label="$t('messages.toolDistance') + 'd(um)'" prop="distance" width="150"
                  align="center">
                  <template slot-scope="scope">
                    <el-input-number :controls="false" v-model="scope.row.distance"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.toolAvoid')" width="100" align="center">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.selected"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table_select">
                <div class="table_select_left">{{ $t('messages.toolAll') }}</div>
                <div class="table_select_right">
                  <button class="table_select__btn"
                    @click="toggleSelection('AirBridgeTable', true)">{{ $t('messages.toolSelectAll') }}</button>
                  <button class="table_select__btn"
                    @click="toggleSelection('AirBridgeTable', false)">{{ $t('messages.toolSelected') }}</button>
                </div>
              </div>
            </div>
            <div class="tool-main__normal">
              <div class="main-group">
                <div class="main-label">{{ $t('messages.toolAirbridgeLineLayer') }}:</div>
                <el-input-number :controls="false" v-model="AirBridge.rule.place_layer" :min="0"></el-input-number>
              </div>
              <div class="main-group">
                <div class="main-label">a（{{ $t('messages.toolAirbridge2LineMinDistance') }}）:</div>
                <el-input-number v-model="AirBridge.rule.end_min_distance" :controls="false"
                  :min="10"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">b（{{ $t('messages.toolAirbridge2AirbridgeMinDistance') }}）:</div>
                <el-input-number :controls="false" v-model="AirBridge.rule.self_min_distance"
                  :min="15"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <div class="main-label">c（{{ $t('messages.toolAirbridgeStep') }}）:</div>
                <el-input-number :controls="false" v-model="AirBridge.rule.step" :min="10"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group">
                <!-- <div class="main-label">空气桥放置方法:</div> -->
                <div class="main_radio">
                  <el-radio v-model="AirBridge.rule.inflect_type" label="Avoid" @change="AirBridgeSizeUpdate">
                    {{ $t('messages.toolAirbridgeAvoidInflect') }}
                  </el-radio>
                  <el-radio v-model="AirBridge.rule.inflect_type" label="Circular" @change="AirBridgeSizeUpdate">
                    {{ $t('messages.toolAirbridgeInflectCircle') }}
                  </el-radio>
                </div>
              </div>
              <div class="main-group" v-if="AirBridge.rule.inflect_type == 'Avoid'">
                <div class="main-label">{{ $t('messages.toolAirbridge2InflectMinDistance') }}:</div>
                <el-input-number :controls="false" v-model="AirBridge.rule.inflect_avoid_min_distance"
                  :min="10"></el-input-number>
                &nbsp;{{ unit }}
              </div>
              <div class="main-group" v-if="AirBridge.rule.inflect_type == 'Circular'">
                <div class="main-label">{{ $t('messages.toolAirbridgeInflectCircleRadius') }}:</div>
                <el-input-number :controls="false" v-model="AirBridge.rule.inflect_circular_radius"
                  :min="10"></el-input-number>
                &nbsp;{{ unit }}
              </div>
            </div>
          </div>

          <div class="normal-rule" v-if="selectAirBridgeSize">
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolAirbridgeLayerPier') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="AirBridge.size.layer_pier.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="AirBridgeSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">{{ $t('messages.toolAirbridgeLayerDeck') }}：</div>
              <div class="rule-item__input">
                <el-select v-model="AirBridge.size.layer_deck.value"
                  :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="AirBridgeSizeUpdate">
                  <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber"
                    :value="item.layerNumber">
                    <div class="layerOption">
                      <div class="layerImgBox">
                        <span style="float: left">{{ item.layerNumber }}</span>
                        <img :src="item.image" alt="" class="layerImage" />
                      </div>
                      <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="normal-rule-item">
              <div class="rule-item__label">A：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="AirBridge.size.A.value" :min="AirBridge.size.A.min"
                  :max="AirBridge.size.A.max" @change="AirBridgeSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A1：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="AirBridge.size.A1.value" :min="AirBridge.size.A1.min"
                  :max="AirBridge.size.A1.max" @change="AirBridgeSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A2：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="AirBridge.size.A2.value" :min="AirBridge.size.A2.min"
                  :max="AirBridge.size.A2.max" @change="AirBridgeSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>

            <div class="normal-rule-item">
              <div class="rule-item__label">A3：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="AirBridge.size.A3.value" :min="AirBridge.size.A3.min"
                  :max="AirBridge.size.A3.max" @change="AirBridgeSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="normal-rule-item">
              <div class="rule-item__label">A4：</div>
              <div class="rule-item__input">
                <el-input-number :controls="false" v-model="AirBridge.size.A4.value" :min="AirBridge.size.A4.min"
                  :max="AirBridge.size.A4.max" @change="AirBridgeSizeUpdate"></el-input-number>
                &nbsp;um
              </div>
            </div>
          </div>

          <!-- <div class="normal-rule" v-if="selectAirBridgeSize">
            <div class="normal-rule-item" v-for="k in Object.keys(AirBridge.size)" :key="k" v-if="AirBridge.size[k].change">
              <div v-if="k !== 'layer_pier' && k !== 'layer_deck'" :key="AirBridge.size[k].name">
                <div class="rule-item__label">{{ AirBridge.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-input-number :controls="false" v-model="AirBridge.size[k].value" @change="AirBridgeSizeUpdate" :min="AirBridge.size[k].min" :max="AirBridge.size[k].max"></el-input-number>
                  &nbsp;{{ AirBridge.size[k].unit }}
                </div>
              </div>

              <div v-if="k === 'layer_pier' || k === 'layer_deck'" :key="AirBridge.size[k].name">
                <div class="rule-item__label">{{ AirBridge.size[k].name }}：</div>
                <div class="rule-item__input">
                  <el-select v-model="AirBridge.size[k].value" :placeholder="$t('messages.toolWaveGuidePleaseSelect')" @change="AirBridgeSizeUpdate">
                    <el-option v-for="item in fileLayerList" :key="item.layerNumber" :label="item.layerNumber" :value="item.layerNumber">
                      <div class="layerOption">
                        <div class="layerImgBox">
                          <span style="float: left">{{ item.layerNumber }}</span>
                          <img :src="item.image" alt="" class="layerImage" />
                        </div>
                        <span class="layerName" style="color: #8492a6; font-size: 13px">{{ item.layerName }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="Ann_image" style="margin-right: 11px">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.toolSizeImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <img alt="" v-if="selectAirBridgeRule" class="ann-image" src="../../../assets/icons/tools/空气桥-规则.svg" />
            <img alt="" v-if="selectAirBridgeSize" class="ann-image" src="../../../assets/icons/tools/空气桥-尺寸.svg" />
          </div>
        </div>
        <div class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 6px">{{ $t('messages.tool2dImage') }}</div>
          </div>
          <div class="Ann_image__body">
            <canvas class="ann-image" id="AirBridgeCtx" width="270" height="270"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="AirBridgeDialogOK">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="AirBridgeDialogVisible = false"
          size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import bus from "../../common/bus";
import i18n from "../../../common/lang/i18n";
const AutoToolCanvasComponent = require("../../../utils/tools/canvas_component");
const Canvas2DKernel = require("../../../utils/tools/canvas_2d_kernel");
const AutoToolAirBridge = require("../../../utils/tools/air_bridge_circle");
// import { AutoToolAirBridge } from '../../../utils/tools/air_bridge_circle'

// const AirBridgeDefault = {
//   rule: {
//     end_min_distance: 50,
//     self_min_distance: 85,
//     step: 200,
//     step_small: 10,
//     place_layer: 2,
//     inflect_type: 'Avoid', // 0： 避开拐角， 1：拐角圆弧放置
//     inflect_avoid_min_distance: 20,
//     inflect_circular_radius: 200,
//   },
//   size: {
//     layer_pier: { name: 'Layer-Pier', value: 3, min: 0, max: 255, change: true, unit: '' },
//     datatype_pier: { name: '材料-桥墩', value: 0, min: 0, max: 255, change: false, unit: '' },
//     layer_deck: { name: 'Layer-Deck', value: 5, min: 0, max: 255, change: true, unit: '' },
//     datatype_deck: { name: '材料-桥面', value: 0, min: 0, max: 255, change: false, unit: '' },
//     A: { name: 'A', value: 48, min: 40, max: 100, change: true, unit: 'um' },
//     A1: { name: 'A1', value: 17, min: 10, max: 30, change: true, unit: 'um' },
//     A2: { name: 'A2', value: 16, min: 10, max: 50, change: true, unit: 'um' },
//     A3: { name: 'A3', value: 14, min: 10, max: 30, change: true, unit: 'um' },
//     A4: { name: 'A4', value: 21, min: 15, max: 40, change: true, unit: 'um' },
//   },
// }
export default {
  data() {
    return {
      unit: "um",
      // tool_ab: new AutoToolAirBridge(),
      AirBridgeDialogVisible: false,
      selectAirBridgeRule: true,
      selectAirBridgeSize: false,
      cellLayerList: [],
      AirBridge: {
        rule: {
          end_min_distance: 50,
          self_min_distance: 85,
          step: 200,
          step_small: 10,
          place_layer: 2,
          inflect_type: "Avoid", // 0： 避开拐角， 1：拐角圆弧放置
          inflect_avoid_min_distance: 20,
          inflect_circular_radius: 200,
        },
        size: {
          layer_pier: {
            name: window.i18n.t("messages.toolAirbridgeLayerPier"),
            value: 3,
            min: 0,
            max: 255,
            change: true,
            unit: "",
          },
          datatype_pier: {
            name: "材料-桥墩",
            value: 0,
            min: 0,
            max: 255,
            change: false,
            unit: "",
          },
          layer_deck: {
            name: window.i18n.t("messages.toolAirbridgeLayerDeck"),
            value: 5,
            min: 0,
            max: 255,
            change: true,
            unit: "",
          },
          datatype_deck: {
            name: "材料-桥面",
            value: 0,
            min: 0,
            max: 255,
            change: false,
            unit: "",
          },
          A: {
            name: "A",
            value: 48,
            min: 40,
            max: 100,
            change: true,
            unit: "um",
          },
          A1: {
            name: "A1",
            value: 17,
            min: 10,
            max: 30,
            change: true,
            unit: "um",
          },
          A2: {
            name: "A2",
            value: 16,
            min: 10,
            max: 50,
            change: true,
            unit: "um",
          },
          A3: {
            name: "A3",
            value: 14,
            min: 10,
            max: 30,
            change: true,
            unit: "um",
          },
          A4: {
            name: "A4",
            value: 21,
            min: 15,
            max: 40,
            change: true,
            unit: "um",
          },
        },
      },
      fileLayerList: [],
      cellLayerList: [],
      canvas2D: null,
      bus_type: "TOOL_AIRBRIDGE",
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      if (this.CavityDialogVisible) {
        this.civityTableData = [];
      }
      done();
    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.$refs[table_name].data.forEach((item) => {
          item.selected = !item.selected;
        });
      }
    },
    AirBridgeDialogOpened() {
      // this.AirBridgeSetDefault()
      this.AirBridgeSizeUpdate();
      this.AirBridge.size.layer_deck.value = this.fileLayerList[0].layerNumber;
      this.AirBridge.size.layer_pier.value = this.fileLayerList[0].layerNumber;
    },
    AirBridgeDialogSelect(type) {
      this.selectAirBridgeSize = false;
      this.selectAirBridgeRule = false;
      if (type == "rule") {
        this.selectAirBridgeRule = true;
      }
      if (type == "size") {
        this.$nextTick(() => {
          this.selectAirBridgeSize = true;
        });
      }
      this.AirBridgeSizeUpdate();
    },
    AirBridgeSizeUpdate() {
      let size = {};
      for (let k in this.AirBridge.size) {
        size[k] = this.AirBridge.size[k].value;
      }
      let element = null;
      let tool_ab = new AutoToolAirBridge();
      element = tool_ab.AB_1(
        size.layer_pier,
        size.datatype_pier,
        size.layer_deck,
        size.datatype_deck,
        size.A,
        size.A1,
        size.A2,
        size.A3,
        size.A4
      );
      if (element) {
        let lib = new QGdstk.Library(element.name, 1e-6, 1e-9);
        lib.add(element);
        this.canvas2D.render(
          document.getElementById("AirBridgeCtx"),
          lib,
          element.name
        );
      }
    },
    AirBridgeDialogOK() {
      let layer_data = [];
      this.cellLayerList.forEach((item) => {
        if (item.selected) {
          layer_data.push({
            layer: item.layerNumber,
            distance: item.distance,
            datatype: 0,
          });
        }
      });
      let size = {};
      for (let k in this.AirBridge.size) {
        size[k] = this.AirBridge.size[k].value;
      }
      let rule = this.AirBridge.rule;
      let conf = {
        boom: size,
        end_min_distance: rule.end_min_distance,
        self_min_distance: rule.self_min_distance,
        step: rule.step,
        step_small: rule.step_small,
        corner_min_distance: 20,
        avoid: layer_data,
        inflect_type: rule.inflect_type,
        inflect_avoid_min_distance: rule.inflect_avoid_min_distance,
        inflect_circular_radius: rule.inflect_circular_radius,
        place_layer: rule.place_layer,
        place_datatype: 0,
        fileLayerList: this.$store.state.fileLayerList,
      };

      this.AirBridgeDialogVisible = false;

      if (this.bus_type == "TOOL_AIRBRIDGE") {
        this.$message({
          message: i18n.t("messages.toolAirbridgePlaceWarn"),
          type: "warning",
        });
        setTimeout(() => {
          bus.$emit("TOOL_AIRBRIDGE_RUN", conf);
        }, 500);
      }
      if (this.bus_type == "TOOL_AIRBRIDGE_BIG") {
        bus.$emit("TOOL_AIRBRIDGE_BIG_RUN", conf);
      }
    },
    AirBridgeSetDefault() {
      this.AirBridge = JSON.parse(JSON.stringify(AirBridgeDefault));
    },

    cellLayerInit() {
      let cellLayerList = this.$store.state.cellLayerList;
      this.cellLayerList = [];
      cellLayerList.forEach((item) => {
        if (item) {
          this.cellLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });

      let fileLayerList = this.$store.state.fileLayerList;
      this.fileLayerList = [];
      fileLayerList.forEach((item) => {
        if (item) {
          this.fileLayerList.push({
            id: item.id,
            layerName: item.layerName,
            distance: 10,
            selected: false,
            layerNumber: item.layerNumber,
            genLayerNumber: item.layerNumber,
            datatype: 0,
          });
        }
      });
      this.cellLayerList = this.cellLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );
      this.fileLayerList = this.fileLayerList.sort(
        (a, b) => a.layerNumber - b.layerNumber
      );
    },
    genTestData() {
      this.cellLayerList = [];
      for (let i = 0; i < 10; i++) {
        this.cellLayerList.push({
          layerName: "layer" + i,
          distance: 10,
          selected: false,
          layer: i,
          datatype: 0,
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.canvas2D = new AutoToolCanvasComponent();

      this.AirBridge = {
        rule: {
          end_min_distance: 50,
          self_min_distance: 85,
          step: 200,
          step_small: 10,
          place_layer: 2,
          inflect_type: "Avoid", // 0： 避开拐角， 1：拐角圆弧放置
          inflect_avoid_min_distance: 20,
          inflect_circular_radius: 200,
        },
        size: {
          layer_pier: {
            name: window.i18n.t("messages.toolAirbridgeLayerPier"),
            value: 3,
            min: 0,
            max: 255,
            change: true,
            unit: "",
          },
          datatype_pier: {
            name: "材料-桥墩",
            value: 0,
            min: 0,
            max: 255,
            change: false,
            unit: "",
          },
          layer_deck: {
            name: window.i18n.t("messages.toolAirbridgeLayerDeck"),
            value: 5,
            min: 0,
            max: 255,
            change: true,
            unit: "",
          },
          datatype_deck: {
            name: "材料-桥面",
            value: 0,
            min: 0,
            max: 255,
            change: false,
            unit: "",
          },
          A: {
            name: "A",
            value: 48,
            min: 40,
            max: 100,
            change: true,
            unit: "um",
          },
          A1: {
            name: "A1",
            value: 17,
            min: 10,
            max: 30,
            change: true,
            unit: "um",
          },
          A2: {
            name: "A2",
            value: 16,
            min: 10,
            max: 50,
            change: true,
            unit: "um",
          },
          A3: {
            name: "A3",
            value: 14,
            min: 10,
            max: 30,
            change: true,
            unit: "um",
          },
          A4: {
            name: "A4",
            value: 21,
            min: 15,
            max: 40,
            change: true,
            unit: "um",
          },
        },
      };
    }, 2000);
    bus.$on("TOOL_AIRBRIDGE", (data) => {
      this.AirBridgeDialogVisible = true;
      bus.$emit("updateCellLayerList", null);
      this.cellLayerInit();
      this.bus_type = "TOOL_AIRBRIDGE";
    });
    bus.$on("TOOL_AIRBRIDGE_BIG", (data) => {
      this.all_layer = this.$store.state.cellLayerDatas;
      this.cellLayerList = this.all_layer.map((item) => {
        const ob = {
          layerName: item.layerName,
          distance: 10,
          selected: false,
          datatype: 0,
          layer: item.layerNumber,
        };
        return ob;
      });
      this.AirBridgeDialogVisible = true;
      this.bus_type = "TOOL_AIRBRIDGE_BIG";
    });
  },
  watch: {},
};
</script>
<style lang="less" scoped>
* {
  color: #333333;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 25px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
/deep/ .el-dialog__body {
  padding: 20px !important;
  .dialog-body {
    display: flex;
  }
  .dialog-body__civity {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    .civity-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .civity-header {
      display: flex;
      width: 75%;
      margin-bottom: 17px;
      .civity-header__item {
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .btn1 {
        color: #0c7de6;
        background: #d8e8f5;
      }
      .btn2 {
        color: #b956bf;
        background: #ecd8ec;
      }
      .btn3 {
        color: #8c64cd;
        background: #e5e4f8;
      }
      .btn4 {
        color: #499f68;
        background: #e4f8ea;
      }
    }
    .cavity-body-tab {
      width: 489px;
    }
    .cavity-body-item {
      display: flex;
      width: 489px;
      height: 38px;
      .el-input-number {
        width: 69px;
        height: 23px;
        .el-input {
          width: 69px;
          height: 23px;
          .el-input__inner {
            width: 100%;
            height: 23px;
          }
        }
      }
      .text {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        background: #ffffff;
      }
      .name {
        width: 123px;
        height: 14px;
      }
      .xy {
        width: 29px;
        height: 14px;
      }
      .text__unit {
        width: 20px;
        height: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .cavity-table-item {
      .el-input-number {
        width: 59px;
        height: 23px;
        .el-input {
          width: 59px;
          height: 23px;
          .el-input__inner {
            width: 49px;
            height: 23px;
          }
        }
      }
    }
  }
  .table_select {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    .table_select_left {
      display: inline-block;
      width: 40%;
      margin: 6px 10px;
    }
    .table_select_right {
      display: inline-block;
      width: 55%;
      text-align: right;
      .table_select__btnGroup {
        cursor: pointer;
        font-size: 12px;
        margin-right: 20px;
        color: #0c7de6;
      }
    }
    .table_select__btn {
      padding: 1px 6px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 2px;
      margin-right: 4px;
      font-size: 12px;
    }
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input-number {
    width: 59px;
    height: 23px;
  }
  /deep/ .el-input {
    position: static !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: center;
    display: inline-block;
  }
}
.tool-main {
  background: #f4f4f4;
  width: 480px;
  display: inline-block;
  margin-right: 11px;
  height: 650px;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  .tool-main__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .Header-tabs__item {
      display: inline-block;
      cursor: pointer;
      padding: 5px 6px;
      margin-right: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 400;
    }
    .active {
      color: #0c7de6;
      border-bottom: 2px solid #0c7de6;
    }
  }
  .rule-item__title {
    width: calc(100% - 20px);
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .tool-main__body {
    padding: 10px;
    .tool-main__item {
      border: 1px solid #dddddd;
      /deep/ .el-input-number {
        width: 90px;
      }
      .rule-item {
        width: 90%;
        padding: 10px 20px;
        .rule-item__label {
          width: 80px;
          display: inline-block;
        }
        .rule-item__input {
          width: calc(100% - 80px);
          display: inline-block;
          /deep/ .el-input {
            width: 90%;
          }
          /deep/ .el-input-number {
            width: 90px;
          }
          /deep/ .el-input__inner {
            width: 280px;
            height: 28px;
            background: #ffffff !important;
            border: 1px solid #dddddd;
            border-radius: 2px;
            padding: 0 !important;
            text-align: center;
            display: inline-block;
          }
          /deep/.el-input__icon {
            line-height: 28px;
          }
        }
      }
    }
    .tool-main__normal {
      margin-top: 10px;
      .main-group {
        padding: 10px 0;
        .main-label {
          display: inline-block;
          width: 70%;
        }
        .main-radio {
          display: inline-block;
          width: 70%;
        }
        .el-input {
          width: 20%;
        }
        /deep/ .el-input-number {
          width: 100px;
        }
        /deep/ .el-input__inner {
          width: 100px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
  .tool-main__title {
    margin: 20px 0 10px 0;
    .title-font {
      display: inline-block;
      width: 50%;
    }
    .title-btn {
      width: 50%;
      text-align: right;
      display: inline-block;
      /deep/ .el-input {
        position: static !important;
        width: auto !important;
      }
      /deep/ .el-input__inner {
        width: 90px;
        height: 23px;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 0 !important;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
  }
  .normal-rule {
    font-size: 12px;
    .normal-rule-item {
      padding: 16px;
      .rule-item__label {
        width: 80px;
        display: inline-block;
      }
      .rule-item__radio {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-radio__label {
          font-size: 12px;
        }
      }
      .rule-item__input {
        width: calc(100% - 80px);
        display: inline-block;
        /deep/ .el-input-number {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input {
          width: 300px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 290px;
          height: 28px;
          background: #ffffff !important;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 0 !important;
          text-align: center;
          display: inline-block;
        }
        /deep/.el-input__icon {
          line-height: 28px;
        }
      }
    }
  }
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  max-height: 770px;
  height: auto;
  border: 1px solid #dddddd;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 32px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
  }
}

.layerOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .layerImgBox {
    display: flex;
    align-items: center;
    .layerImage {
      display: inline-block;
      height: 15px;
      margin-left: 10px;
    }
  }
  .layerName {
    width: 90px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
